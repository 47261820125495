import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { white, bsBlue } from 'constants/colors';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      height: 84,
      width: `100%`,
      margin: 0,
      padding: `0 ${theme.spacing(4)}`,

      background: `var(--bg-color)`,

      '& .MuiButton-containedPrimary': {
        background: `linear-gradient(90deg, ${bsBlue} 0%, ${bsBlue} 100%)`,
        '&:hover': {
          background: 'linear-gradient(90deg, #3788F9 0%, #0A44CA 100%)',
        },
      },
      '& .MuiCircularProgress-svg': {
        color: `var(--text-color)`,
      },
      '& .MuiTypography-root': {
        color: `var(--text-color)`,
      },
    },
    logoBox: {
      '& a': {
        display: 'flex',
      },
      '& svg': {
        color: white,
      },
    },
    linksBox: {
      '& .MuiList-root': {
        display: 'flex',
      },
      '& .MuiListItem-root': {
        padding: 0,
        marginRight: theme.spacing(4),
        whiteSpace: 'nowrap',

        '&:last-of-type': {
          marginRight: 0,
        },

        '& a': {
          color: `var(--text-color)`,
          fontWeight: 600,
          fontSize: `1.6rem`,
          lineHeight: `2.4rem`,
          padding: 0,
          '&.active': {
            color: `rgba(255, 255, 255, 0.3)`,
          },
        },
      },
    },
    profileBox: {
      minWidth: 180,
    },
  };
});

export const HeaderTheme: any = {
  transparent: {
    '--bg-color': `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)`,
    '--text-color': white,
    zIndex: 1,
  },
  blue: {
    '--bg-color': `linear-gradient(90deg, #3889FA 0%, #0942C9 100%)`,
    '--text-color': white,
  },
  black: {
    '--bg-color': `#1A1A1B`,
    '--text-color': white,
  },
};
