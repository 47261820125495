import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FollowSubscribe } from 'entities/Account';
import { FollowerState } from './types';

const initialState: FollowerState = {
  list: [],
  loading: true,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'follower',
  initialState,
  reducers: {
    getFollowerListRequest: (state, { payload }: PayloadAction<{ celebrityId: number }>) => {
      state.loading = true;
      state.error = false;
    },
    getFollowerListSuccess: (state, { payload }: PayloadAction<{ data: FollowSubscribe[] }>) => {
      state.list = payload.data;
      state.loading = false;
    },
    getFollowerListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as FollowerActions };

export default reducer;
