import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const LayoutTypes: any = {
  white: {
    '--bg-color': `#FFFFFF`,
  },
  black: {
    '--bg-color': `#1A1A1B`,
  },
};

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: `var(--bg-color)`,
      overflowX: 'hidden',
    },
    main: {
      flex: '1',
      width: '100%',
      display: 'flex',

      marginTop: theme.spacing(60 / 8 + 3), // 60 is a mobile header height
      marginBottom: theme.spacing(5),

      '& > *:not(form)': {
        width: '100%',
      },

      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(6),
      },
    },
    container: {
      maxWidth: 960,
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1248,
      },
    },
    profile: {
      marginTop: 0,
      [theme.breakpoints.up('lg')]: {
        marginTop: '-116px',
      },
    },
  };
});
