import axios from 'axios';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { CelebrityActions } from '../reducers/celebrity';

export function* getCelebritiesListSaga(
  action: ReturnType<typeof CelebrityActions.getCelebritiesListRequest>,
) {
  const { query, append } = action.payload;

  try {
    const {
      data: {
        data,
        meta: { total },
      },
    } = yield axios.get(endpoints.GET_CELEBRITIES, { params: query });
    yield put(CelebrityActions.getCelebritiesListSuccess({ data, total, append }));
  } catch (error) {
    yield put(CelebrityActions.getCelebritiesListFailure());
  }
}
