import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { StripeActions } from '../reducers/stripe';

export function* initFTOPaymentSaga(action: ReturnType<typeof StripeActions.fto.initPaymentRequest>) {
	const { tokenSymbol, address, purchase } = action.payload;

	try {
		const {
			data: { clientSecret, paymentIntentId },
		} = yield axios.post(
			getLink(endpoints.FAN_TOKEN_POST_INIT_PAYMENT, { tokenSymbol, address }),
			{ tokensCount: purchase.tokensCount },
			{
				headers: {
					'idempotency-key': uuidv4(),
				},
			},
		);
		yield put(StripeActions.fto.initPaymentSuccess({ clientSecret, paymentIntentId }));
		yield localStorage.setItem('fantokensLastPurchase', JSON.stringify({ ...purchase, paymentIntentId }));
	} catch (error) {
		yield put(StripeActions.fto.initPaymentFailure({ error }));
	}
}

export function* initNFTPaymentSaga(action: ReturnType<typeof StripeActions.nft.initPaymentRequest>) {
	const { auctionId, quantity } = action.payload;

	try {
		const {
			data: {
				data: { clientSecret, paymentIntentId },
			},
		} = yield axios.post(
			getLink(endpoints.TOKEN_BUYOUT_INITPAYMENT, { auctionId }),
			{ quantity },
			{
				headers: {
					'idempotency-key': uuidv4(),
				},
			},
		);
		yield put(StripeActions.nft.initPaymentSuccess({ clientSecret, paymentIntentId }));
	} catch (error) {
		yield put(StripeActions.nft.initPaymentFailure({ error }));
	}
}

export function* initGamePaymentSaga(action: ReturnType<typeof StripeActions.game.initPaymentRequest>) {
	const { eventId, quantity } = action.payload;

	try {
		const {
			data: {
				data: { clientSecret, paymentIntentId },
			},
		} = yield axios.post(
			getLink(endpoints.GAME_INITPAYMENT, { eventId }),
			{ quantity },
			{
				headers: {
					'idempotency-key': uuidv4(),
				},
			},
		);
		yield put(StripeActions.game.initPaymentSuccess({ clientSecret, paymentIntentId }));
	} catch (error) {
		yield put(StripeActions.game.initPaymentFailure({ error }));
	}
}
