import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions, IconButton, Button, Typography, Box, Container, Tab, Paper, Stack, Fade } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { MyWalletActions } from 'store/reducers/mywallet';
import ContentCopy from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

import { WalletActions } from 'store/reducers/wallet';
import { MoonpayActions } from 'store/reducers/moonpay';
import { FanTokenActions } from 'store/reducers/fantoken';

import { FunWalletIcon, BinanceIcon2, PolygonIcon2, EthereumIcon2, WhitebitIcon } from 'icons';

import { ROUTE_HOME } from 'constants/routes';
import { availableFTO } from 'constants/fantokens';
import { isMobile } from 'helpers/isMobile';
import { truncateAddress } from 'helpers/address';
import { blockChainCurrency } from 'helpers/blockchain';

import Transaction from './components/Transaction';
import Fantoken from './components/Fantoken';
import Balance from './components/Balance';

import { useStyles } from './styles';

const MyWallet = () => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentTab, setCurrentTab] = React.useState('wallet');
  const [copyWallet, setCopyWallet] = React.useState(false);

  const {
    wallet: {
      wallet: { address },
    },
    network: { link },
    mywallet: { myWalletIsOpen, transactions, loading, loadingBalance, balances, bsptBalance },
    fantoken: { balance: FTBalance, loadingBalance: loadingFTBalance },
  } = useSelector((state) => state);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const handleClose = () => {
    dispatch(MyWalletActions.closeMyWallet());
  };

  useEffect(() => {
    if (!address || !myWalletIsOpen) return;

    dispatch(MyWalletActions.getBalancesRequest());
    dispatch(MyWalletActions.getBsptBalanceRequest());
    dispatch(
      MyWalletActions.getTransactionListRequest({
        address,
      }),
    );
    dispatch(FanTokenActions.getAccountBalanceRequest({ address }));
  }, [address, myWalletIsOpen]);

  const getChainImage = (chainId: number) => {
    switch (chainId) {
      case 1:
      case 5:
        return <EthereumIcon2 />;
      case 56:
      case 97:
        return <BinanceIcon2 />;
      case 80001:
      case 137:
        return <PolygonIcon2 />;
      case 1875:
      case 2625:
        return <WhitebitIcon />;
      default:
        return <EthereumIcon2 />;
    }
  };

  const copyToClipboardAddress = () => {
    setCopyWallet(true);
    setTimeout(() => setCopyWallet(false), 3000);
    navigator.clipboard.writeText(address);
  };

  const handleDisconnect = () => {
    dispatch(WalletActions.disconnectWallet({}));
    handleClose();
    history.push(ROUTE_HOME);
  };

  const handleAddFunds = () => {
    handleClose();
    dispatch(MoonpayActions.toggleMoonpayModal(true));
  };

  return (
    <Dialog open={myWalletIsOpen} onClose={handleClose} maxWidth="md" TransitionComponent={Fade} fullScreen={!!isMobile()}>
      <Box sx={{ position: 'relative' }}>
        {loading || loadingBalance || loadingFTBalance ? (
          <Paper className={classes.backdrop}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} className={classes.loader}>
              <CircularProgress className={classes.loaderProgress} size={24} />
              <Typography className={classes.textLoading}>Loading....</Typography>
            </Stack>
          </Paper>
        ) : null}
        <DialogTitle className={classes.dialogTitle}>
          My Wallet
          {myWalletIsOpen ? (
            <IconButton aria-label="close" onClick={handleClose} className={classes.disableIcon}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Container sx={{ padding: '0' }}>
            <TabContext value={currentTab}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth" centered className={classes.tabList}>
                <Tab label="Wallet" value="wallet" />
                <Tab label="Transactions" value="transactions" />
              </TabList>
              <TabPanel value="wallet" sx={{ padding: '0' }} className={classes.tabPanel}>
                <Stack direction="column" spacing={0} mb={3}>
                  <Paper className={cx(classes.tabPanelAddressPaper, { [classes.copied]: copyWallet })}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                      <Typography>{truncateAddress(address)}</Typography>
                      <IconButton onClick={copyToClipboardAddress}>{!copyWallet ? <ContentCopy /> : <CheckCircleIcon sx={{ color: '#219653' }} />}</IconButton>
                    </Stack>
                  </Paper>
                  {copyWallet ? (
                    <Typography variant="caption" className={classes.copiedText}>
                      The address has been copied.
                    </Typography>
                  ) : null}
                </Stack>

                <Box className={classes.balancesWrapper}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.bsptBalance}>
                    <Typography variant="subtitle1">BSPT Balance</Typography>
                    <Typography variant="h4">{`${bsptBalance.toFixed(2) || 0} BSPT`}</Typography>
                  </Box>

                  <Box mt={3}>
                    <Typography variant="body2" color="gray" sx={{ marginBottom: 1 }}>
                      Cryptos:
                    </Typography>
                    {balances.length > 0 &&
                      balances
                        .map((item: any) => ({
                          balance: item.balance,
                          currency: blockChainCurrency(item.chainId),
                          icon: getChainImage(item.chainId),
                          handleAddFunds,
                          address,
                          network: item.network,
                        }))
                        .map((item: any, idx: number) => <Balance {...item} key={idx} />)}
                  </Box>

                  <Box mt={3}>
                    <Typography variant="body2" color="gray" sx={{ marginBottom: 1 }}>
                      Fan Tokens:
                    </Typography>
                    {!loadingBalance &&
                      FTBalance &&
                      FTBalance.filter((item: any) => Object.keys(availableFTO).includes(item.tokenSymbol)).map((item: any, idx: number) => (
                        <Fantoken {...item} key={item.tokenSymbol} />
                      ))}
                  </Box>
                </Box>

                <DialogActions className={classes.dialogActions}>
                  <Button variant="outlined" className={classes.buttonAction} onClick={handleDisconnect}>
                    Disconnect wallet
                  </Button>
                </DialogActions>
              </TabPanel>
              <TabPanel value="transactions" className={classes.tabPanel}>
                {transactions.length ? (
                  <Stack className={classes.transactions} direction="column" spacing={1}>
                    {transactions.map((item, index) => (
                      <Transaction {...item} explorerLink={`${link}tx/${item.transactionHash}`} key={index} />
                    ))}
                  </Stack>
                ) : (
                  <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ height: '100%', marginTop: -5 }}>
                    <FunWalletIcon />
                    <Typography className={classes.emptyText}>No transactions yet</Typography>
                  </Stack>
                )}
              </TabPanel>
            </TabContext>
          </Container>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default MyWallet;
