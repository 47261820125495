import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => {
  return {
    moonPayContent: {
      width: '100%',
    },
    moonPay: {
      width: '100%',
      height: 600,
      border: 'none',
    },
  };
});
