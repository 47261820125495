import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, IconButton, Typography, Container, Box } from '@mui/material';
import { CloseIcon, CheckIcon } from 'icons';
import { EmailActions } from 'store/reducers/email';
import { useDialogStyles } from 'shared/dialogs/styles';

interface ThankYouProps {
	text?: string;
}

const ThankYou = (props: ThankYouProps) => {
	const { text } = props;

	const dispatch = useDispatch();

	const { classes: dialogClasses } = useDialogStyles();

	const {
		joinEmail: { thankuModalIsOpen },
	} = useSelector((state) => state.email);

	const handleClose = () => {
		dispatch(EmailActions.joinEmail.toggleThankUModal({ open: false }));
		dispatch(EmailActions.joinEmail.sendJoinEmailSetNotified());
	};

	const thankYouMessage = text || `We're will contact you shortly.`;

	return (
		<Dialog open={thankuModalIsOpen} onClose={handleClose}>
			<IconButton onClick={handleClose} className={dialogClasses.closeBtn} size="large">
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<Container>
					<Box mt={4} textAlign="center">
						<CheckIcon />
					</Box>
					<Box mb={1} textAlign="center">
						<Typography variant="h3">Thank You!</Typography>
					</Box>
					<Box mb={4} textAlign="center">
						<Typography variant="subtitle1">{thankYouMessage}</Typography>
					</Box>
				</Container>
			</DialogContent>
		</Dialog>
	);
};

export default ThankYou;
