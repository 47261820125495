import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import { RateState } from './types';

const initialRateState: RateState = {
	loading: false,
	error: false,
	rate: null,
};

const mexc = {
	rate: createSlice({
		name: 'mexc/rate',
		initialState: initialRateState,
		reducers: {
			getRateRequest: (state, { payload }: PayloadAction<{ symbol: string }>) => {
				state.loading = true;
				state.error = false;
			},
			getRateSuccess: (state, { payload }: PayloadAction<{ rate: any }>) => {
				state.loading = false;
				state.rate = payload.rate;
			},
			getRateFailure: (state) => {
				state.loading = false;
				state.error = true;
			},
		},
	}),
};

const actions = combineSliceActions(mexc);

export { actions as MexcActions };

export default combineSliceReducers(mexc);
