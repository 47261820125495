import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import parse from 'html-react-parser';
import { useStyles } from './styles';

export const ACCEPT_COOKIE_NAME = 'accept_cookies';
export const EMAIL_COOKIE_NAME = 'email_cookies';

export const EXPIRES_PERIOD = 999; // days, no requirements for this. change if needed

export default function CookiesBanner() {
  const { classes } = useStyles();
  const [showBanner, setShowBanner] = React.useState<boolean>(!Cookies.get(ACCEPT_COOKIE_NAME));

  const handleAcceptClick = () => {
    Cookies.set(ACCEPT_COOKIE_NAME, 'true', { expires: EXPIRES_PERIOD });
    Cookies.set(EMAIL_COOKIE_NAME, 'true', { expires: EXPIRES_PERIOD });
    setShowBanner(false);
  };

  const handleDontAcceptClick = () => {
    Cookies.set(ACCEPT_COOKIE_NAME, 'false', { expires: EXPIRES_PERIOD });
    Cookies.set(EMAIL_COOKIE_NAME, 'false', { expires: EXPIRES_PERIOD });
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <Box className={classes.wrapper}>
      <Typography variant="subtitle1" component="p">
        {parse(
          `We use cookies for the proper operation of our website, collecting statistics, marketing and advertising purporses as well as remembering your settings and preferences. It will allow you to move conveniently use our website and view the advertisement you are interested in, and us - to analyze the statistics and make you attractive offers. To get more information about using cookies, please make aware of our <a href="/cookies-policy">Cookie Policy</a> and <a href="/privacy">Privacy Policy</a>.\nIf you agree with the use of cookies, please click “Accept cookies”. If you don’t agree with it, please click “Don’t accept”.`,
        )}
      </Typography>
      <Typography variant="subtitle1" component="p">
        {parse(
          `You’re able to withdraw your consent at any time. By accepting cookies I confirm that I’ve made aware of <a href="/privacy">Privacy Policy</a>. Allow “Blocksport” to track your activity across other companies apps and websites? Your data will be used to provide you with a better ads experience. “Blocksport”: Ads help support our project. Tap ”Allow” on the screen to give our advertising partners to show you personalized ads.`,
        )}
      </Typography>
      <Box className={classes.footer}>
        <Button className={classes.btn} variant="outlined" onClick={handleDontAcceptClick}>
          Don't accept
        </Button>
        <Button className={classes.btn} variant="contained" onClick={handleAcceptClick}>
          Accept
        </Button>
      </Box>
    </Box>
  );
}
