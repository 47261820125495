import {
  bscAuctionABI,
  bscCollectionABI,
  bscAuctionCollectionABI,
  bscMintABI,
  bscNFTStakeABI,
  bscBSPTStakeABI,
  bscBSPTStakeOldABI,
  bscRewardABI,
  bscTransferProxyABI,
  bscDepositABI,
  bscWhitelistABI,
  bscNFTStakeNonplatformABI,
  bscMarketplaceABI,
  bscToken1155ABI,
  bscToken721ABI,
  bscStakeFantokenABI,
  bscStakeFantokenOldABI,
  bscPitchGameABI,
  bscBridgeABI,
} from './abi/bsc';
import { ethAuctionABI, ethCollectionABI, ethAuctionCollectionABI, ethMintABI, ethNFTStakeABI, ethRewardABI, ethTransferProxyABI } from './abi/eth';
import {
  polygonAuctionABI,
  polygonAuctionCollectionABI,
  polygonCollectionABI,
  polygonNFTStakeABI,
  polygonBSPTStakeABI,
  polygonRewardABI,
  polygonTransferProxyABI,
} from './abi/polygon';
import {daoABI} from "./abi/eth/blocksportDao";

export const getBlockchainABI = (
  network: string,
  type:
    | null
    | 'auction'
    | 'collection'
    | 'mint'
    | 'auctionCollection'
    | 'stakeNFT'
    | 'stakeBSPT'
    | 'stakeBSPTOld'
    | 'reward'
    | 'transferProxy'
    | 'deposit'
    | 'whitelist'
    | 'stakeNonplatformNFT'
    | 'marketplace'
    | 'token721'
    | 'token1155'
    | 'stakeFantoken'
    | 'stakeFantokenOld'
    | 'pitchGame'
    | 'bridge'
    | 'dao',
): any => {
  switch (network) {
    case 'bsc':
      switch (type) {
        case 'bridge':
          return bscBridgeABI;
        case 'pitchGame':
          return bscPitchGameABI;
        case 'auction':
          return bscAuctionABI;
        case 'collection':
          return bscCollectionABI;
        case 'auctionCollection':
          return bscAuctionCollectionABI;
        case 'stakeNFT':
          return bscNFTStakeABI;
        case 'stakeBSPT':
          return bscBSPTStakeABI;
        case 'stakeBSPTOld':
          return bscBSPTStakeOldABI;
        case 'reward':
          return bscRewardABI;
        case 'transferProxy':
          return bscTransferProxyABI;
        case 'deposit':
          return bscDepositABI;
        case 'whitelist':
          return bscWhitelistABI;
        case 'stakeNonplatformNFT':
          return bscNFTStakeNonplatformABI;
        case 'marketplace':
          return bscMarketplaceABI;
        case 'token721':
          return bscToken721ABI;
        case 'token1155':
          return bscToken1155ABI;
        case 'stakeFantoken':
          return bscStakeFantokenABI;
        case 'stakeFantokenOld':
          return bscStakeFantokenOldABI;
        case 'dao':
            return daoABI;
        default:
          return bscMintABI;
      }
    case 'polygon':
      switch (type) {
        case 'auction':
          return polygonAuctionABI;
        case 'collection':
          return polygonCollectionABI;
        case 'auctionCollection':
          return polygonAuctionCollectionABI;
        case 'stakeNFT':
          return polygonNFTStakeABI;
        case 'stakeBSPT':
          return polygonBSPTStakeABI;
        case 'reward':
          return polygonRewardABI;
        case 'transferProxy':
          return polygonTransferProxyABI;
        case 'marketplace':
          return bscMarketplaceABI;
        case 'token721':
          return bscToken721ABI;
        case 'token1155':
          return bscToken1155ABI;
        case 'dao':
          return daoABI;
        default:
          return bscMintABI;
      }
    default:
      switch (type) {
        case 'bridge':
          return bscBridgeABI;
        case 'auction':
          return ethAuctionABI;
        case 'collection':
          return ethCollectionABI;
        case 'auctionCollection':
          return ethAuctionCollectionABI;
        case 'stakeNFT':
          return ethNFTStakeABI;
        case 'stakeBSPT':
          return bscBSPTStakeABI;
        case 'reward':
          return ethRewardABI;
        case 'transferProxy':
          return ethTransferProxyABI;
        case 'marketplace':
          return bscMarketplaceABI;
        case 'token721':
          return bscToken721ABI;
        case 'token1155':
          return bscToken1155ABI;
        case 'dao':
          return daoABI;
        default:
          return ethMintABI;
      }
  }
};
