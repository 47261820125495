import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import {call, delay, put, select} from 'redux-saga/effects';
import web3 from 'helpers/getWeb3';
import { toWei, fromWei } from 'helpers/web3';
import { basketballPithAreaNftGameAddress, currencyNativeToken } from 'contracts/addresses';
import { GameActions } from '../reducers/games';
import { TransactionActions } from '../reducers/transaction';
import { NetworkStateInterface } from '../reducers/network/types';
import { getBlockchainABI } from '../../contracts';
import {BridgeActions} from "../reducers/bridge";
import {AlertActions} from "../reducers/alert";
import {sliceTruncateHash} from "../../helpers/address";

export function* getGamesListSaga(action: ReturnType<typeof GameActions.getGamesListRequest>) {
	try {
		const {
			data: { data },
		} = yield axios.get(getLink(endpoints.GET_GAMES_LIST), {});

		if (data) {
			yield put(GameActions.getGamesListSuccess({ data }));
		}
	} catch (error) {
		// console.log({ error });
		yield put(GameActions.getGamesListFailure());
	}
}

export function* getGameSaga(action: ReturnType<typeof GameActions.getGameRequest>) {
	const { gameId } = action.payload;

	try {
		const {
			data: { data },
		} = yield axios.get(getLink(endpoints.GET_GAME, { gameId }), {});

		if (data) {
			yield put(GameActions.getGameSuccess({ data }));
		}
	} catch (error) {
		// console.log({ error });
		yield put(GameActions.getGameFailure());
	}
}

export function* purchaseTokenSaga(action: ReturnType<typeof GameActions.purchaseTokenRequest>) {
	const { gameId, address, quantity, price, currencyAsset } = action.payload;
	const { network }: NetworkStateInterface = yield select((state) => state.network);
	// @ts-ignore
	const pitchGameAddr: string = basketballPithAreaNftGameAddress[network];

	// @ts-ignore
	const pitchGameContract: any = yield new web3.eth.Contract(
		// @ts-ignore
		getBlockchainABI('bsc', 'pitchGame'),
		pitchGameAddr,
	);

	yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

	try {
		const priceWei: string = yield toWei(price.toString());
		const gasPrice:number = yield web3.eth.getGasPrice();

		// @ts-ignore
		const gasEstimate: any = yield call(
			pitchGameContract.methods.purchaseToken(gameId, address, quantity, currencyAsset, priceWei).estimateGas,
			{ from: address, gasPrice, value: currencyAsset === currencyNativeToken ? priceWei : 0},
		);

		const { transactionHash } = yield web3.eth.sendTransaction({
			value: currencyAsset === currencyNativeToken ? priceWei : 0,
			to: pitchGameAddr,
			from: address,
			gas: gasEstimate + 10000,
			gasPrice,
			data: pitchGameContract.methods.purchaseToken(gameId, address, quantity, currencyAsset, priceWei).encodeABI(),
		});

		if (transactionHash) {
			yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
			yield put(
				GameActions.purchaseTokenSuccess({
					transactionHash,
					chainId: network,
				}),
			);
		}
	} catch (error) {
		yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
		yield put(GameActions.purchaseTokenFailure());
	}
}

export function* claimGameRewardsSaga(action: ReturnType<typeof GameActions.claimRewardsRequest>) {
	const { gameId, address } = action.payload;
	const { network }: NetworkStateInterface = yield select((state) => state.network);

	// @ts-ignore
	const pitchGameAddr: string = basketballPithAreaNftGameAddress[network];

	// @ts-ignore
	const pitchGameContract = yield new web3.eth.Contract(
		// @ts-ignore
		getBlockchainABI('bsc', 'pitchGame'),
		pitchGameAddr,
	);

	yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

	try {
		const gasPrice:number = yield web3.eth.getGasPrice();

		const { transactionHash } = yield web3.eth.sendTransaction({
			to: pitchGameAddr,
			from: address,
			data: pitchGameContract.methods.claimedRewards(gameId, address).encodeABI(),
			gasPrice,
		});

		if (transactionHash) {
			yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
			yield put(
				GameActions.claimRewardsSuccess({
					hash: transactionHash,
				}),
			);
		}
	} catch (error) {
		yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
		yield put(GameActions.claimRewardsFailure());
	}
}

export function* claimRewardsFiatSaga(action: ReturnType<typeof GameActions.claimRewardsFiatRequest>) {
	const { gameId, idempotencyKey, gameAddress } = action.payload;
	try {
		const {
			data: { data },
		} = yield axios.post(getLink(endpoints.GAME_CLIME_REWARD, { gameId }), {},
			{
				headers: {
					'idempotency-key': idempotencyKey,
			},
		});

		if (data) {
			yield put(GameActions.claimRewardsFiatSuccess({ data }));
		}
	} catch (error) {
		// console.log({ error });
		yield put(GameActions.claimRewardsFiatFailure());
	}
}

export function* getCurrentGameSaga(action: ReturnType<typeof GameActions.getCurrentGameRequest>) {
	const { gameType } = action.payload;
	try {
		const {
			data: { data },
		} = yield axios.get(getLink(endpoints.GET_GAME_CURRENT), { params: { gameType } });

		if (data) {
			yield put(GameActions.getCurrentGameSuccess({ data }));
		}
	} catch (error) {
		// console.log({ error });
		yield put(GameActions.getCurrentGameFailure());
	}
}

export function* checkGameRewardsSaga(action: ReturnType<typeof GameActions.checkGameRewardsRequest>) {
	const { gameId, address } = action.payload;
	const { network }: NetworkStateInterface = yield select((state) => state.network);

	// @ts-ignore
	const pitchGameAddr: string = basketballPithAreaNftGameAddress[network];

	// @ts-ignore
	const pitchGameContract = yield new web3.eth.Contract(
		// @ts-ignore
		getBlockchainABI('bsc', 'pitchGame'),
		pitchGameAddr,
	);

	yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

	try {
		// @ts-ignore
		const { totalReward, claimed } = yield pitchGameContract.methods.rewards(gameId, address).call();

		yield put(GameActions.checkGameRewardsSuccess({ rewards: { totalReward: totalReward > 0 ? fromWei(totalReward) : 0, claimed } }));
	} catch (error) {
		// console.log({ error });
		yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
		yield put(GameActions.checkGameRewardsFailure());
	}
}

export function* watchForTransferNftCompletion(action: ReturnType<typeof GameActions.transferStatusRequest>) {
	const { transactionHash } = action.payload;
	while (true) {
		yield delay(20000);
		try {
			const {
				data: { data },
			} = yield axios.get(getLink(endpoints.GET_NFT_GAME_STATUS, { transactionHash }));

			if (data.transactionHash === transactionHash) {
				yield put(GameActions.transferStatusSuccess({
					status: 'SUCCESS',
					transactionHashBridgePayout: data.transactionHash,
					destinationChainId: data.blockchain,
				}));
				yield put(AlertActions.showAlert({ message: `Transfer success tx: ${sliceTruncateHash(data.transactionHash)}`, severity: 'success' }));
				break;
			}
		} catch (error: any) {
			if (error.response && error.response.status === 404) {
				yield put(GameActions.transferStatusFailure());
				break;
			}
			yield put(GameActions.transferStatusFailure());
			break;
		}
	}
}
