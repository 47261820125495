import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: '38%',
    },
    iconSearch: {
      margin: 8,
      color: '#ffffff',
    },
    mobileDrawer: {
      width: '100%',
      borderRadius: 0,
      '& .MuiPaper-root': {
        width: '100%',
      },
    },
    containerAppBar: {},
    appBar: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      border: 'none',
      boxShadow: 'none',
    },
    input: {
      alignItems: 'center',
      width: '100%',
      borderRadius: '30px',
      marginRight: 0,
      marginLeft: 0,
      '& .MuiOutlinedInput-root': {
        width: '100%',
        borderRadius: '30px',
        color: ' #828282',
      },
    },
    paperTextField: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    listGroup: {
      borderBottom: '1px solid #E0E0E0',
      color: '#828282',
      fontWeight: 600,
      fontSize: 16,
      padding: '20px 20px 10px',
    },
    empty: {},
    cardActions: {},
  };
});
