import { BlockChainTypeFilters, KindOfSportFilters, NFTContentTypeFilters } from 'scenes/Collectibles/scenes/List/types';

const initKindOfSportsFiltersState: KindOfSportFilters = {
  esports: false,
  football: false,
  cycling: false,
  rugby: false,
  racing: false,
  volleyball: false,
  cricket: false,
  motorsport: false,
  tennis: false,
  hockey: false,
  basketball: false,
  baseball: false,
};

const initNFTContentTypeFiltersState: NFTContentTypeFilters = {
  image: false,
  gif: false,
  video: false,
};

const initBlockChainTypeFiltersState: BlockChainTypeFilters = {
  eth: false,
  bsc: false,
  poly: false,
};

export const initFiltersState = {
  single: false,
  multiple: false,
  onSale: false,
  hotSale: false,
  minPrice: null,
  maxPrice: null,
  kindsOfSportFilters: initKindOfSportsFiltersState,
  nftContentTypeFilters: initNFTContentTypeFiltersState,
  blockChainFilters: initBlockChainTypeFiltersState,
  blockchain: '',
  auctionType: null,
};
