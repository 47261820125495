import Web3 from 'web3';
import {
  bscTestnetNode,
  bscMainnetNode,
  ethInfuraTestnetNode,
  ethAlchemyMainnetNode,
  polyTestnetNode,
  polyMainnetNode,
  goerliTestnetUrl,
  ethMainnetUrl,
  wbtTestnetNodeRpc,
  wbtMainnetNodeRpc,
} from '../constants/url';

declare const window: any;

const getWeb3Instance = (providerURL: string) => {
  return new Web3(providerURL);
};

const getWeb3 = () => {
  if (window.ethereum) {
    return getWeb3Instance(window.ethereum);
  }

  const defaultProvider =
    process.env.REACT_APP_ENV === 'production'
      ? `${ethAlchemyMainnetNode}${process.env.REACT_APP_ALCHEMY_PROJECT_ID}`
      : `${ethInfuraTestnetNode}${process.env.REACT_APP_INFURA_PROJECT_ID}`;
  return getWeb3Instance(defaultProvider);
};

const web3 = getWeb3();

const web3Eth = getWeb3Instance(process.env.REACT_APP_ENV === 'development' ? `${goerliTestnetUrl}` : `${ethMainnetUrl}`);
const web3Bsc = getWeb3Instance(process.env.REACT_APP_ENV === 'development' ? bscTestnetNode : bscMainnetNode);
const web3Poly = getWeb3Instance(process.env.REACT_APP_ENV === 'development' ? polyTestnetNode : polyMainnetNode);
const web3Whitebit = getWeb3Instance(process.env.REACT_APP_ENV === 'development' ? wbtTestnetNodeRpc : wbtMainnetNodeRpc);

export default web3;
export { web3Eth, web3Bsc, web3Poly, web3Whitebit };
