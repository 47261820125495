import React, { useState, useEffect } from 'react';
import * as routePaths from 'constants/routes';
import { useLocation } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import { useStyles, LayoutTypes } from './styles';

export interface LayoutProps {
  children: React.ReactElement;
  fullwidth?: boolean;
  isProfile?: boolean;
}

export const Layout = (props: LayoutProps) => {
  // Props
  const { children, fullwidth, isProfile } = props;

  const { classes, cx } = useStyles();
  const location = useLocation();

  const [layoutType, setLayoutType] = useState<string>('white');

  useEffect(() => {
    if ([routePaths.ROUTE_GAME_OWN_YOUR_PITCH, routePaths.ROUTE_GAME_OWN_YOUR_PITCH_NBA].includes(location.pathname)) {
      setLayoutType('black');
      return;
    }
    setLayoutType('white');
  }, [location]);

  return (
    <div className={classes.root} style={LayoutTypes[layoutType] as React.CSSProperties}>
      <Header isProfile={isProfile} />
      <main className={cx(classes.main, { [classes.container]: !fullwidth }, { [classes.profile]: isProfile })}>{children}</main>
      <Footer />
    </div>
  );
};
