
export interface Balances {
  eth: number;
  bsc: number;
}

export enum TransactionStatuses {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface BSPTBridgeState {
  deposit: Transaction;
  transfer: Transaction;
  balances: Balances;
  fee: Balances;
  availableBalance: Balances;
  loading: boolean;
  error: boolean;
}

export interface Transaction {
  status: TransactionStatuses;
  hash: string | null;
  chainId: string | null;
}
