import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_API_KEY || '';

const defaultSettings = {
	contactOptions: {
		enabled: false,
	},
	launcher: {
		mobile: {
			labelVisible: false,
		},
	},
	chat: {
		suppress: false,
	},
	contactForm: {
		suppress: false,
	},
};

const Support = () => {
	const [zestate, setZestate] = useState<any>(null);

	// Selectors
	const { account } = useSelector((state) => state.account);

	const onLoaded = () => {
		ZendeskAPI('webWidget:on', 'userEvent', (event: any) => {
			setZestate(event);
		});
	};

	useEffect(() => {
		if (zestate) {
			if (zestate.action === 'Web Widget Minimised') {
				ZendeskAPI('webWidget', 'updateSettings', {
					webWidget: { ...defaultSettings },
				});
			}
			if (zestate.action === 'Contact Form Shown') {
				if (account) {
					ZendeskAPI('webWidget', 'prefill', {
						name: {
							value: account.name || account.nickName,
						},
						email: {
							value: account.email,
						},
					});
				}
			}
		}
	}, [zestate]);

	return <Zendesk defer zendeskKey={ZENDESK_KEY} {...defaultSettings} onLoaded={onLoaded} />;
};

export default Support;
