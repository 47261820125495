import { put, select } from 'redux-saga/effects';
import web3 from 'helpers/getWeb3';
import { MAX_INT, mintAddress, stakeBSPTAddress, token1155Address, token721Address, stakeFantokenAddress } from 'contracts/addresses';
import { makeTransaction } from 'helpers/web3';
import { ApproveActions } from '../reducers/approve';
import { TransactionActions } from '../reducers/transaction';

import { NetworkStateInterface } from '../reducers/network/types';

import { getBlockchainABI } from '../../contracts';

export function* setApproveBSPTSaga(action: ReturnType<typeof ApproveActions.setApproveBSPTRequest>) {
  const { address } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  // const maxApproveAllowance = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

  // @ts-ignore
  const stakeAddr: string = stakeBSPTAddress[network];
  // @ts-ignore
  const stakeContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'stakeBSPT'),
    stakeAddr,
  );
  const rewardsAddr: string = yield stakeContract.methods.rewardToken().call({ from: address });

  // @ts-ignore
  const rewardsContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'reward'),
    rewardsAddr,
  );

  yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('in_pending'));

  try {
    const { transactionHash } = yield makeTransaction({
      to: rewardsAddr,
      from: address,
      data: rewardsContract.methods.approve(stakeAddr, MAX_INT),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('done'));
      yield put(
        ApproveActions.setApproveBSPTSuccess({
          hash: transactionHash,
        }),
      );
    }

    return transactionHash;
  } catch (error) {
    yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('fail'));
    yield put(ApproveActions.setApproveBSPTFailure());
  }
}

export function* checkApproveBSPTSaga(action: ReturnType<typeof ApproveActions.checkApproveBSPTRequest>) {
  const { address, amount } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  // @ts-ignore
  const stakeAddr = stakeBSPTAddress[network];
  // @ts-ignore
  const stakeContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'stakeBSPT'),
    stakeAddr,
  );

  const rewardsAddr: string = yield stakeContract.methods.rewardToken().call(); // dev: 0x6CdF5bA7e448770294C69a4607e1Dec2963C6fd4
  // @ts-ignore
  const rewardsContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'reward'),
    rewardsAddr,
  );

  try {
    const allowance: string = yield rewardsContract.methods.allowance(address, stakeAddr).call({ from: address });
    const isCheckApprove: boolean = Number(allowance) > Number(amount);

    yield put(
      ApproveActions.checkApproveBSPTSuccess({
        isApprove: isCheckApprove,
      }),
    );

    return isCheckApprove;
  } catch (error) {
    yield put(ApproveActions.checkApproveBSPTFailure());
  }
}

export function* setApproveSaga(action: ReturnType<typeof ApproveActions.setApproveRequest>) {
  const { ownerAddress, approveAddress, type = 'mint', tokenAddress } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  const isCollection = type === 'collection';
  // @ts-ignore
  const mintAddr: string = mintAddress[network];
  const isOldContract = !isCollection && tokenAddress.toLowerCase() === mintAddr.toLowerCase();

  let tokenAddr: string;
  let tokenContract: any;

  yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('in_pending'));

  try {
    if (isCollection) {
      // @ts-ignore
      tokenAddr = token1155Address[network];
      // @ts-ignore
      tokenContract = yield new web3.eth.Contract(getBlockchainABI(network, 'token1155'), tokenAddr);
    } else if (isOldContract) {
      // @ts-ignore
      tokenAddr = mintAddr;
      // @ts-ignore
      tokenContract = yield new web3.eth.Contract(getBlockchainABI(network, 'mint'), tokenAddr);
    } else {
      // @ts-ignore
      tokenAddr = token721Address[network];
      // @ts-ignore
      tokenContract = yield new web3.eth.Contract(getBlockchainABI(network, 'token721'), tokenAddr);
    }

    const { transactionHash } = yield makeTransaction({
      to: tokenAddr,
      from: ownerAddress,
      data: tokenContract.methods.setApprovalForAll(approveAddress, true),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('done'));
      yield put(
        ApproveActions.setApproveSuccess({
          hash: transactionHash,
        }),
      );
    }
  } catch (error) {
    yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('fail'));
    yield put(ApproveActions.setApproveFailure());
  }
}

export function* checkApproveSaga(action: ReturnType<typeof ApproveActions.checkApproveRequest>) {
  const { ownerAddress, approveAddress, tokenAddress } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const tokenContract: any = new web3.eth.Contract(getBlockchainABI(network, 'token721'), tokenAddress);

  try {
    const isApproved: boolean = yield tokenContract.methods.isApprovedForAll(ownerAddress, approveAddress).call();

    yield put(
      ApproveActions.checkApproveSuccess({
        isApproved,
      }),
    );
  } catch (error) {
    yield put(ApproveActions.checkApproveFailure());
  }
}

export function* checkApproveFantokenSaga(action: ReturnType<typeof ApproveActions.checkApproveFantokenRequest>) {
  const { address, tokenAddress, amount } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const stakeAddr = stakeFantokenAddress[network];

  // @ts-ignore
  const rewardsContract = new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'reward'),
    tokenAddress,
  );
  try {
    const allowance: string = yield rewardsContract.methods.allowance(address, stakeAddr).call({ from: address });
    const isCheckApprove: boolean = Number(allowance) > Number(amount);

    yield put(
      ApproveActions.checkApproveFantokenSuccess({
        approve: { [tokenAddress]: isCheckApprove },
      }),
    );

    return isCheckApprove;
  } catch (error) {
    yield put(ApproveActions.checkApproveFantokenFailure());
  }
}

export function* setApproveFantokenSaga(action: ReturnType<typeof ApproveActions.setApproveFantokenRequest>) {
  const { address, tokenAddress } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const stakeAddr: string = stakeFantokenAddress[network];
  // @ts-ignore
  const rewardsContract = new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'reward'),
    tokenAddress,
  );
  yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('in_pending'));

  try {
    const { transactionHash } = yield makeTransaction({
      to: tokenAddress,
      from: address,
      data: rewardsContract.methods.approve(stakeAddr, MAX_INT),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('done'));
      yield put(
        ApproveActions.setApproveFantokenSuccess({
          hash: transactionHash,
          approve: { [tokenAddress]: true },
        }),
      );
    }

    return transactionHash;
  } catch (error) {
    yield put(TransactionActions.statusApproveTransaction.setStatusApproveTransaction('fail'));
    yield put(ApproveActions.setApproveFantokenFailure());
  }
}
