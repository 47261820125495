// redux/alertSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { AlertState } from './types'

const initialState: AlertState = {
  open: false,
  message: '',
  severity: 'info',
};

const { actions, reducer }  = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    hideAlert: (state) => {
      state.open = false;
    },
  },
});

export { actions as AlertActions }

export default reducer;
