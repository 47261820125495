import axios from 'axios';
import analytics from 'analytics';
import * as endpoints from 'services/api';
import { call, put, all, select } from 'redux-saga/effects';
import web3 from 'helpers/getWeb3';
import { fromWei, toWei, makeBatchRequest, makeBatchTransaction, makeTransaction, makeBatchWaitingTransaction } from 'helpers/web3';
import { StakeHistory, StakeHistoryLog } from 'entities/History';
import { mintAddress, stakeNFTAddress, stakeBSPTAddress, stakeBSPTAddressOld, stakeFantokenAddress } from 'contracts/addresses';

import { getLink } from 'helpers/routes';
import { formatPeriod, formatFromTimestamp, roundAmountToFixed } from 'helpers/format';
import { getStakeContracts } from 'helpers/staking';
import { TransactionActions } from '../reducers/transaction';
import { StakingActions } from '../reducers/staking';
import { NetworkStateInterface } from '../reducers/network/types';
import { getBlockchainABI } from '../../contracts';
import { checkApproveBSPTSaga, setApproveBSPTSaga } from './approve';

function* getStakeHistoryLog(log: StakeHistory) {
  try {
    const amount: number = yield fromWei(log.amount.toString());

    let logInfo: StakeHistoryLog = {
      amount,
      id: log.id,
      startDate: formatFromTimestamp(log.timestamp),
      action: log.action,
    };

    if (log.action === 'stake') {
      logInfo = {
        amount,
        id: log.id,
        startDate: formatFromTimestamp(log.start),
        endDate: formatFromTimestamp(log.end),
        action: log.action,
      };
    }

    return logInfo;
  } catch (error) {
    return null;
  }
}

function* checkPeriod(network: string) {
  // @ts-ignore
  const stakeAddr: string = stakeNFTAddress[network];
  // @ts-ignore
  const stakeContract: Stake = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'stakeNFT'),
    stakeAddr,
  );

  const cycleLengthSeconds: string = yield stakeContract.methods.cycleLengthInSeconds().call();
  const periodLengthInCycles: string = yield stakeContract.methods.periodLengthInCycles().call();
  const currentCycle: string = yield stakeContract.methods.getCurrentCycle().call();
  const startFirstPeriod: string = yield stakeContract.methods.startTimestamp().call();
  const getPastPeriod = Math.ceil(Number(currentCycle) / Number(periodLengthInCycles)) - 1;
  const startCurrPeriodInCycles: number = getPastPeriod * Number(periodLengthInCycles);
  const startCurrentPeriod: number = Number(startFirstPeriod) + startCurrPeriodInCycles * Number(cycleLengthSeconds);

  return startCurrentPeriod + Number(cycleLengthSeconds) * Number(periodLengthInCycles);
}

function* setStakeTransaction(action: ReturnType<typeof StakingActions.stake.stakeBSPTRequest>) {
  const { address, amount, period, callBack } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  const priceWei: number = yield toWei(amount.toString());

  // @ts-ignore
  const stakeAddr: string = stakeBSPTAddress[network];

  // @ts-ignore
  const stakeContract = yield new web3.eth.Contract(getBlockchainABI(network, 'stakeBSPT'), stakeAddr);
  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const data = stakeContract.methods.deposit(priceWei, period, address);
    const { transactionHash } = yield makeTransaction({
      to: stakeAddr,
      from: address,
      data,
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        StakingActions.stake.stakeBSPTSuccess({
          hash: transactionHash,
        }),
      );
      yield analytics.sendEvent({
        category: 'BSPT',
        action: 'BSPT is staked',
        value: +amount,
      });
    }

    callBack();
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.stake.stakeBSPTFailure());
  }
}

export function* stakeNFTSaga(action: ReturnType<typeof StakingActions.stake.stakeNFTRequest>) {
  const { address, checkedTokens, callBack } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    // @ts-ignore
    const stakeAddr: string = stakeNFTAddress[network];

    const batch: any = [];
    let nonce: number = yield web3.eth.getTransactionCount(address);
    const gasPrice: number = yield web3.eth.getGasPrice();

    for (let i = 0; i < checkedTokens.length; i++) {
      // @ts-ignore
      const mintAddr: string = mintAddress[network];
      const isOldContract = checkedTokens[i].tokenAddress.toLowerCase() === mintAddr.toLowerCase();

      let tokenContract: any;

      if (isOldContract) {
        // @ts-ignore
        tokenContract = yield new web3.eth.Contract(getBlockchainABI(network, 'mint'), checkedTokens[i].tokenAddress);
      } else {
        // @ts-ignore
        tokenContract = yield new web3.eth.Contract(getBlockchainABI(network, 'token721'), checkedTokens[i].tokenAddress);
      }

      // @ts-ignore
      // const mintContract = yield new web3.eth.Contract(getBlockchainABI(network, 'mint'), checkedTokens[i].tokenAddress);
      // @ts-ignore
      const data = yield tokenContract.methods.safeTransferFrom(address, stakeAddr, checkedTokens[i].tokenId).encodeABI();
      // @ts-ignore
      const request = yield web3.eth.sendTransaction.request;
      // @ts-ignore
      const estimateGass = yield tokenContract.methods.safeTransferFrom(address, stakeAddr, checkedTokens[i].tokenId).estimateGas({ from: address });
      const params = {
        to: checkedTokens[i].tokenAddress,
        from: address,
        gas: estimateGass,
        nonce,
        gasPrice,
        data,
      };
      nonce += 1;
      // @ts-ignore
      yield batch.push({ request, params });
    }

    // @ts-ignore
    const transactionHash = yield makeBatchTransaction(batch);

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        StakingActions.stake.stakeNFTSuccess({
          hash: JSON.stringify(transactionHash),
        }),
      );
      yield analytics.sendEvent({
        category: 'NFT',
        action: 'NFT is staked',
      });

      callBack && callBack();
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.stake.stakeNFTFailure());
  }
}

export function* unStakeNFTSaga(action: ReturnType<typeof StakingActions.unstake.unStakeNFTRequest>) {
  const { address, checkedTokens } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  // @ts-ignore
  const stakeAddr: string = stakeNFTAddress[network];
  // @ts-ignore
  const stakeContract = yield new web3.eth.Contract(getBlockchainABI(network, 'stakeNFT'), stakeAddr);

  try {
    const data =
      checkedTokens.length === 1
        ? stakeContract.methods.unstakeNft(checkedTokens[0].tokenAddress, checkedTokens[0].tokenId).encodeABI()
        : stakeContract.methods
            .batchUnstakeNfts(
              checkedTokens.map((item: any) => item.tokenAddress),
              checkedTokens.map((item: any) => item.tokenId),
            )
            .encodeABI();

    const { transactionHash } = yield web3.eth.sendTransaction({
      to: stakeAddr,
      from: address,
      data,
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        StakingActions.unstake.unStakeNFTSuccess({
          hash: transactionHash,
        }),
      );
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.unstake.unStakeNFTFailure());
  }
}

export function* claimRewardsSaga(action: ReturnType<typeof StakingActions.claimRewards.claimRewardsRequest>) {
  const { address, type } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const stakeAddr = type === 'stakeBSPT' ? stakeBSPTAddress[network] : stakeNFTAddress[network];

  // @ts-ignore
  const stakeContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, type),
    stakeAddr,
  );

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    let data = null;

    if (type === 'stakeNFT') {
      const periodLengthSeconds: string = yield stakeContract.methods.periodLengthInCycles().call();

      data = stakeContract.methods.claimRewards(periodLengthSeconds).encodeABI();
    }
    if (type === 'stakeBSPT') {
      data = stakeContract.methods.claimRewards(address).encodeABI();
    }

    const { transactionHash } = yield web3.eth.sendTransaction({
      to: stakeAddr,
      from: address,
      data,
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        StakingActions.claimRewards.claimRewardsSuccess({
          hash: transactionHash,
        }),
      );
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.claimRewards.claimRewardsFailure());
  }
}

export function* stakePeriodSaga() {
  try {
    const { network }: NetworkStateInterface = yield select((state) => state.network);
    // @ts-ignore
    const stakeAddr: string = stakeNFTAddress[network];
    // @ts-ignore
    const stakeContract: Stake = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'stakeNFT'),
      stakeAddr,
    );

    const rewardsAddr: string = yield stakeContract.methods.rewardsTokenContract().call();
    // @ts-ignore
    const rewardsContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'reward'),
      rewardsAddr,
    );
    const period: number = yield call(checkPeriod, network);
    const balance: string = yield rewardsContract.methods.balanceOf(stakeAddr).call();

    yield put(
      StakingActions.stakePeriod.setStakePeriodSuccess({
        period: formatPeriod(period, balance),
      }),
    );
  } catch (error) {
    yield put(StakingActions.stakePeriod.setStakePeriodFailure({}));
  }
}

export function* stakedNFTHistorySaga(action: ReturnType<typeof StakingActions.stakedHistory.getStakedNFTHistoryRequest>) {
  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_NFT_HISTORY), {
      params: { tokenSource: 'BSC' },
    });

    if (data) {
      yield put(
        StakingActions.stakePeriod.setStakePeriodSuccess({
          period: formatPeriod(data.endPeriod, data.bsptBalance),
        }),
      );
      yield put(
        StakingActions.stakedHistory.getStakedNFTHistorySuccess({
          stakedHistory: [],
          totalNFTsInPool: data.totalNftInPool,
          totalInPool: data.totalNftInPool,
          totalPoolPower: data.totalPoolPower,
          yourTokensPower: data.yourTokensPower,
          yourRewardsForPeriod: data.yourRewardsForPeriod,
          yourRewards: data.yourRewards,
          currentAvailableReward: data.currentAvailableReward,
          claimIsAvailable: data.claimIsAvailable,
        }),
      );
    }
  } catch (error) {
    yield put(StakingActions.stakedHistory.getStakedNFTHistoryFailure());
  }
}

export function* stakedOldBSPTHistorySaga() {
  try {
    const {
      data: { data: stakedHistoryData },
    } = yield axios.get(getLink(endpoints.GET_BSPT_HISTORY), {
      params: { tokenSource: 'BSC', isOldStaking: true },
    });

    yield put(
      StakingActions.oldBsptStakingHistory.getOldStakedBSPTHistorySuccess({
        yourRewards: roundAmountToFixed(stakedHistoryData.yourRewards),
        yourLockedBSPT: roundAmountToFixed(stakedHistoryData.yourLockedBSPT),
        yourDeposits: stakedHistoryData.yourDeposits,
      }),
    );
  } catch (error) {
    yield put(StakingActions.oldBsptStakingHistory.getOldStakedBSPTHistoryFailure());
  }
}

export function* stakedBSPTHistorySaga(action: ReturnType<typeof StakingActions.stakedHistory.getStakedBSPTHistoryRequest>) {
  const { address } = action.payload;
  try {
    const {
      data: { data: stakedHistoryData },
    } = yield axios.get(getLink(endpoints.GET_BSPT_HISTORY), {
      params: { tokenSource: 'BSC' },
    });
    let stakedHistory: StakeHistoryLog[] = [];

    if (address) {
      const {
        data: { data },
      } = yield axios.get(getLink(endpoints.STAKE_BSPT_HISTORY, { wallet: address }), {
        params: { tokenSource: 'BSC' },
      });

      stakedHistory = yield all(Array.from(data, (log: StakeHistory) => call(getStakeHistoryLog, log)));
    }
    yield put(
      StakingActions.stakedHistory.getStakedBSPTHistorySuccess({
        stakedHistory,
        yourRewards: roundAmountToFixed(stakedHistoryData.yourRewards),
        totalLockedPool: roundAmountToFixed(stakedHistoryData.totalLockedPool),
        yourLockedBSPT: roundAmountToFixed(stakedHistoryData.yourLockedBSPT),
        yourBSPTBalance: +stakedHistoryData.yourBSPTBalance,
        yourDeposits: stakedHistoryData.yourDeposits,
        totalDistributed: roundAmountToFixed(stakedHistoryData.totalDistributed),
        totalSupply: roundAmountToFixed(stakedHistoryData.totalSupply),
      }),
    );
  } catch (error) {
    yield put(StakingActions.stakedHistory.getStakedBSPTHistoryFailure());
  }
}

export function* stakeBSPTSaga(action: ReturnType<typeof StakingActions.stake.stakeBSPTRequest>) {
  const { address, amount } = action.payload;

  // @ts-ignore
  const isApprove: boolean = yield call(checkApproveBSPTSaga, { payload: { address, amount } });
  if (!isApprove) {
    // @ts-ignore
    const approveHash: string = yield call(setApproveBSPTSaga, { payload: { address } });

    if (approveHash) {
      // @ts-ignore
      yield call(setStakeTransaction, { payload: action.payload });
    }
  } else {
    // @ts-ignore
    yield call(setStakeTransaction, { payload: action.payload });
  }
}

export function* claimOldNFTRewardsSaga(action: ReturnType<typeof StakingActions.clearOldStakings.claimOldNFTRewardsRequest>) {
  const { address, nftDeposits } = action.payload;
  const mappedNftDeposits = nftDeposits.map((item) => ({ tokenId: item.staking.tokenId, tokenAddress: item.staking.tokenAddress }));
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  const { nftStakeContract, nftStakeAddr } = yield getStakeContracts(network, true);

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const batch: any = [];
    let nonce: number = yield web3.eth.getTransactionCount(address);
    const gasPrice: number = yield web3.eth.getGasPrice();
    // @ts-ignore
    const request = yield web3.eth.sendTransaction.request;

    if (mappedNftDeposits && mappedNftDeposits.length) {
      const unstakeNFTData = nftStakeContract.methods
        .batchUnstakeNfts(
          mappedNftDeposits.map((item) => item.tokenAddress),
          mappedNftDeposits.map((item) => item.tokenId),
        )
        .encodeABI();
      const unstakeNFTEstimateGass: number = yield nftStakeContract.methods
        .batchUnstakeNfts(
          mappedNftDeposits.map((item) => item.tokenAddress),
          mappedNftDeposits.map((item) => item.tokenId),
        )
        .estimateGas({ from: address });

      const params = {
        to: nftStakeAddr,
        from: address,
        gas: unstakeNFTEstimateGass,
        nonce,
        gasPrice,
        data: unstakeNFTData,
      };
      nonce += 1;
      // @ts-ignore
      yield batch.push({ request, params });

      const periodLengthSeconds: string = yield nftStakeContract.methods.periodLengthInCycles().call();

      const claimNftdata = nftStakeContract.methods.claimRewards(periodLengthSeconds).encodeABI();
      const claimNftEstimateGass: number = yield nftStakeContract.methods.claimRewards(periodLengthSeconds).estimateGas({ from: address });
      const claimNftParams = {
        to: nftStakeAddr,
        from: address,
        gas: claimNftEstimateGass,
        nonce,
        gasPrice,
        data: claimNftdata,
      };
      nonce += 1;
      // @ts-ignore
      yield batch.push({ request, params: claimNftParams });
    }

    // @ts-ignore
    const transactionHash = yield makeBatchTransaction(batch);

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(StakingActions.clearOldStakings.claimOldNFTRewardsSuccess({ hash: transactionHash }));
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.clearOldStakings.claimOldNFTRewardsFailure({ errorMessage: "Can't claim old NFT rewards" }));
  }
}
export function* claimOldFanTokenRewardsSaga(action: ReturnType<typeof StakingActions.clearOldStakings.claimOldFTRewardsRequest>) {
  const { address, ftDeposits } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  const { ftStakeContract, ftStakeAddr } = yield getStakeContracts(network, true);

  try {
    const batch: any = [];
    let nonce: number = yield web3.eth.getTransactionCount(address);
    const gasPrice: number = yield web3.eth.getGasPrice();
    // @ts-ignore
    const request = yield web3.eth.sendTransaction.request;

    for (const [i, deposit] of ftDeposits.entries()) {
      if (deposit.aproxReward <= 0) continue;
      const data = ftStakeContract.methods.destroyLock(i).encodeABI();
      const estimateGass: number = yield ftStakeContract.methods.destroyLock(i).estimateGas({ from: address });
      const params = {
        to: ftStakeAddr,
        from: address,
        gas: estimateGass,
        nonce,
        gasPrice,
        data,
      };
      nonce += 1;
      // @ts-ignore
      yield batch.push({ request, params });
    }

    const isFtClaimAvailable = ftDeposits.reduce((acc, cur) => acc + Number(cur.reward), 0) > 0;
    if (isFtClaimAvailable) {
      const claimFtData = ftStakeContract.methods.bathClimeAndWithdraw().encodeABI();
      const claimFtEstimateGass: number = yield ftStakeContract.methods.bathClimeAndWithdraw().estimateGas({ from: address });
      const params = {
        to: ftStakeAddr,
        from: address,
        gas: claimFtEstimateGass,
        nonce,
        gasPrice,
        data: claimFtData,
      };
      yield batch.push({ request, params });
      nonce += 1;
    }

    // @ts-ignore
    const transactionHash: PromiseSettledResult<{ transactionHash: string }>[] = yield makeBatchWaitingTransaction(batch);

    if (!transactionHash.every((item) => item.status === 'fulfilled')) {
      yield put(StakingActions.clearOldStakings.claimOldFTRewardsFailure({ errorMessage: "Can't claim old FT rewards" }));
      return;
    }

    // @ts-ignore
    const hash = transactionHash.filter((item) => item.status === 'fulfilled').map((item) => item.value.transactionHash);
    yield put(StakingActions.clearOldStakings.claimOldFTRewardsSuccess({ hash }));
    yield put(StakingActions.oldRewards.getOldDepositsRewardsRequest({ address }));
  } catch (err) {
    yield put(StakingActions.clearOldStakings.claimOldFTRewardsFailure({ errorMessage: "Can't claim old FT rewards" }));
  }
}
export function* claimOldBSPTRewardsSaga(action: ReturnType<typeof StakingActions.clearOldStakings.claimOldBSPTRewardsRequest>) {
  const { address, bsptDeposits } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  const { bsptStakeContract, bsptStakeAddr } = yield getStakeContracts(network, true);

  try {
    const batch: any = [];
    let nonce: number = yield web3.eth.getTransactionCount(address);
    const gasPrice: number = yield web3.eth.getGasPrice();
    // @ts-ignore
    const request = yield web3.eth.sendTransaction.request;

    if (bsptDeposits.length) {
      const claimBsptData = bsptStakeContract.methods.claimRewards(address).encodeABI();
      const claimBsptEstimateGass: number = yield bsptStakeContract.methods.claimRewards(address).estimateGas({ from: address });
      const claimBsptParams = {
        to: bsptStakeAddr,
        from: address,
        gas: claimBsptEstimateGass,
        nonce,
        gasPrice,
        data: claimBsptData,
      };
      yield batch.push({ request, params: claimBsptParams });
      nonce += 1;

      for (const { depositId } of bsptDeposits) {
        /* 0 index was added because depositId itself it is an index in mapping from smart contract. 
        After first succeeded withdraw tx, all next withdraw txs will be failed due to not consistent data beetween contract state and transaction */

        const data = bsptStakeContract.methods.withdraw(bsptDeposits[0].depositId, address).encodeABI();
        const estimateGass: number = yield bsptStakeContract.methods.withdraw(bsptDeposits[0].depositId, address).estimateGas({ from: address });
        const params = {
          to: bsptStakeAddr,
          from: address,
          gas: estimateGass,
          nonce,
          gasPrice,
          data,
        };
        nonce += 1;
        // @ts-ignore
        yield batch.push({ request, params });
      }
      // @ts-ignore
      const transactionHash: PromiseSettledResult<{ transactionHash: string }>[] = yield makeBatchWaitingTransaction(batch);

      if (!transactionHash.every((item) => item.status === 'fulfilled')) {
        yield put(StakingActions.clearOldStakings.claimOldBSPTRewardsFailure({ errorMessage: "Can't claim old BSPT rewards" }));
        return;
      }

      // @ts-ignore
      const hash = transactionHash.filter((item) => item.status === 'fulfilled').map((item) => item.value.transactionHash);
      yield put(StakingActions.clearOldStakings.claimOldBSPTRewardsSuccess({ hash }));
      yield put(StakingActions.oldBsptStakingHistory.getOldStakedBSPTHistoryRequest({ address }));
    } else {
      yield put(StakingActions.clearOldStakings.claimOldBSPTRewardsFailure({ errorMessage: '' }));
    }
  } catch (error) {
    yield put(StakingActions.clearOldStakings.claimOldBSPTRewardsFailure({ errorMessage: "Can't claim old BSPT rewards" }));
  }
}

export function* unStakeBSPTSaga(action: ReturnType<typeof StakingActions.unstake.unStakeBSPTRequest>) {
  const { address, depositId, callBack } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const stakeAddr: string = stakeBSPTAddress[network];
  // @ts-ignore
  const stakeContract = yield new web3.eth.Contract(getBlockchainABI(network, 'stakeBSPT'), stakeAddr);

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const { transactionHash } = yield makeTransaction({
      to: stakeAddr,
      from: address,
      data: stakeContract.methods.withdraw(depositId, address),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        StakingActions.unstake.unStakeBSPTSuccess({
          hash: transactionHash,
        }),
      );
    }

    callBack();
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.unstake.unStakeBSPTFailure());
  }
}

export function* getOldDepositsRewardSaga() {
  try {
    const {
      data: {
        data: { reward, deposits },
      },
    } = yield axios.get(getLink(endpoints.GET_FT_HISTORY), {
      params: { tokenSource: 'BSC', isOldStaking: true },
    });

    yield put(StakingActions.oldRewards.getOldDepositsRewardsSuccess({ reward: reward.toString(), deposits }));
  } catch (error) {
    yield put(StakingActions.oldRewards.getOldDepositsRewardsFailure());
  }
}

export function* getDepositsRewardSaga(action: ReturnType<typeof StakingActions.rewards.getDepositsRewardRequest>) {
  try {
    const {
      data: {
        data: { reward, deposits },
      },
    } = yield axios.get(getLink(endpoints.GET_FT_HISTORY), {
      params: { tokenSource: 'BSC' },
    });

    yield put(StakingActions.rewards.getDepositsRewardSuccess({ reward: reward.toString(), deposits }));
  } catch (error) {
    yield put(StakingActions.rewards.getDepositsRewardFailure());
  }
}

export function* getFantokenRewardSaga(action: ReturnType<typeof StakingActions.rewards.getFantokenRewardRequest>) {
  const { fantokens } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  // @ts-ignore
  const stakeAddr: string = stakeFantokenAddress[network];
  // @ts-ignore
  const stakeContract = new web3.eth.Contract(getBlockchainABI(network, 'stakeFantoken'), stakeAddr);

  try {
    const rewards: any[] = [];

    for (let i = 0; i < fantokens.length; i++) {
      const amountWei: number = yield toWei(fantokens[i].amount.toString());
      rewards.push(stakeContract.methods.calcDividends(fantokens[i].tokenAddress, fantokens[i].period, amountWei).call);
    }
    // @ts-ignore
    const batchResult = yield makeBatchRequest(rewards);
    const reward: number = yield batchResult.reduce((acc: number, cur: any) => acc + +fromWei(cur.reword), 0);

    yield put(StakingActions.rewards.getFantokenRewardSuccess({ reward: reward.toString() }));
  } catch (error) {
    yield put(StakingActions.rewards.getFantokenRewardFailure());
  }
}

export function* stakeFantokenSaga(action: ReturnType<typeof StakingActions.stake.stakeFantokenRequest>) {
  const { address, fantokens, period } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  // @ts-ignore
  const stakeAddr: string = stakeFantokenAddress[network];
  // @ts-ignore
  const stakeContract = new web3.eth.Contract(getBlockchainABI(network, 'stakeFantoken'), stakeAddr);

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    let transactionHash: any;
    let totalAmount: number = 0;
    const ftLabel: string[] = [];

    const gasPrice: number = yield web3.eth.getGasPrice();
    if (fantokens.length === 1) {
      const amountWei: number = yield toWei(fantokens[0].amount.toString());
      totalAmount = fantokens[0].amount;
      ftLabel.push(fantokens[0].tokenSymbol);
      ({ transactionHash } = yield makeTransaction({
        to: stakeAddr,
        from: address,
        data: stakeContract.methods.deposit(fantokens[0].tokenAddress, amountWei, period, address),
      }));
    } else {
      const batch: any = [];
      let nonce: number = yield web3.eth.getTransactionCount(address);
      for (let i = 0; i < fantokens.length; i++) {
        const amountWei: number = yield toWei(fantokens[i].amount.toString());
        const estimateGass: number = yield stakeContract.methods.deposit(fantokens[i].tokenAddress, amountWei, period, address).estimateGas({ from: address });
        // @ts-ignore
        const request = yield web3.eth.sendTransaction.request;
        const params = {
          to: stakeAddr,
          from: address,
          gas: estimateGass,
          nonce,
          gasPrice,
          data: stakeContract.methods.deposit(fantokens[i].tokenAddress, amountWei, period, address).encodeABI(),
        };
        nonce += 1;
        // @ts-ignore
        yield batch.push({ request, params });
        totalAmount += fantokens[i].amount;
        ftLabel.push(fantokens[i].tokenSymbol);
      }

      // @ts-ignore
      transactionHash = yield makeBatchTransaction(batch);
    }

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        StakingActions.stake.stakeFantokenSuccess({
          hash: transactionHash,
        }),
      );
      yield analytics.sendEvent({
        category: 'FT',
        action: 'FT is staked',
        label: ftLabel.join(),
        value: totalAmount,
      });
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.stake.stakeFantokenFailure());
  }
}

export function* unStakeFantokenSaga(action: ReturnType<typeof StakingActions.unstake.unStakeFantokenRequest>) {
  const { depositId, address } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const stakeAddr: string = stakeFantokenAddress[network];
  // @ts-ignore
  const stakeContract = new web3.eth.Contract(getBlockchainABI(network, 'stakeFantoken'), stakeAddr);

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const { transactionHash } = yield makeTransaction({
      from: address,
      to: stakeAddr,
      data: stakeContract.methods.destroyLock(depositId),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        StakingActions.unstake.unStakeFantokenSuccess({
          hash: transactionHash,
        }),
      );
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.unstake.unStakeFantokenFailure());
  }
}

export function* claimFantokenRewardsSaga(action: ReturnType<typeof StakingActions.claimRewards.claimFantokenRewardsRequest>) {
  const { address /* , depositId */ } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  // @ts-ignore
  const stakeAddr: string = stakeFantokenAddress[network];
  // @ts-ignore
  const stakeContract = new web3.eth.Contract(getBlockchainABI(network, 'stakeFantoken'), stakeAddr);

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const { transactionHash } = yield makeTransaction({
      to: stakeAddr,
      from: address,
      data: stakeContract.methods.bathClimeAndWithdraw().encodeABI(),
      // data: stakeContract.methods.claimAndWithdraw(depositId).encodeABI(), // there is no depositId
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        StakingActions.claimRewards.claimFantokenRewardsSuccess({
          hash: transactionHash,
        }),
      );
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(StakingActions.claimRewards.claimFantokenRewardsFailure());
  }
}

export function* getAFactorsSaga(action: ReturnType<typeof StakingActions.ftStakeConfig.getAFactorsRequest>) {
  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_FT_STAKE_AFACTORS));
    if (data) {
      const aFactors: any = [];

      Object.entries(data).forEach((item) => {
        aFactors.push({ period: +item[0], apy: item[1] });
      });

      yield put(
        StakingActions.ftStakeConfig.getAFactorsSuccess({
          aFactors,
        }),
      );
    }
  } catch (error) {
    yield put(StakingActions.ftStakeConfig.getAFactorsFailure());
  }
}
