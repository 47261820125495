import axios from 'axios';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { StatsActions } from '../reducers/stats';

export function* getTokenMarketStateSaga(action: ReturnType<typeof StatsActions.tokenMarketState.getTokenMarketStateRequest>) {
	try {
		const {
			data: { data },
		} = yield axios.get(endpoints.GET_STATS_TOKEN_MARKET_STATE, { params: { days: 1 } });

		if (data) {
			yield put(StatsActions.tokenMarketState.getTokenMarketStateSuccess({ data }));
		}
	} catch (error) {
		yield put(StatsActions.tokenMarketState.getTokenMarketStateFailure());
	}
}

export function* bsptTopGainersSaga(action: ReturnType<typeof StatsActions.bsptTopGainers.getBsptTopGainersRequest>) {
	try {
		const {
			data: { data },
		} = yield axios.get(endpoints.GET_TOP_GAINERS, { params: { limit: 5 } });

		if (data) {
			yield put(StatsActions.bsptTopGainers.getBsptTopGainersSuccess({ data }));
		}
	} catch (error) {
		yield put(StatsActions.bsptTopGainers.getBsptTopGainersFailure());
	}
}
