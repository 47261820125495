import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MediaType } from 'entities/Media';
import { UploadMediaState } from './types';

const initialState: UploadMediaState = {
  coverImage: {
    link: null,
    loading: false,
    error: '',
  },
  avatar: {
    link: null,
    loading: false,
    error: '',
  },
  post: {
    link: null,
    loading: false,
    error: '',
  },
  aws: {
    link: '',
    loading: false,
    error: '',
  },
};

const { actions, reducer } = createSlice({
  name: 'media',
  initialState,
  reducers: {
    addMediaRequest: (state, { payload }: PayloadAction<{ type: MediaType; file: File }>) => {
      state[payload.type].loading = true;
    },
    addMediaSuccess: (
      state,
      { payload }: PayloadAction<{ type: MediaType; link: string | null }>,
    ) => {
      state[payload.type].loading = false;
      state[payload.type].link = payload.link;
    },
    addMediaFailure: (state, { payload }: PayloadAction<{ type: MediaType; error: string }>) => {
      state[payload.type].loading = false;
      state[payload.type].error = payload.error;
    },

    getPresignedLinkRequest: (
      state,
      { payload }: PayloadAction<{ type: MediaType; fileType: string; file: File; filename: string }>,
    ) => {
      state[payload.type].loading = true;
    },
    getPresignedLinkSuccess: (
      state,
      { payload }: PayloadAction<{ type: MediaType; link: string }>,
    ) => {
      state[payload.type].loading = false;
      state[payload.type].link = payload.link;
    },
    getPresignedLinkFailure: (
      state,
      { payload }: PayloadAction<{ type: MediaType; error: string }>,
    ) => {
      state[payload.type].loading = false;
      state[payload.type].error = payload.error;
    },

    uploadAwsLinkRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: MediaType;
        id: string | undefined;
        data: any;
      }>,
    ) => {
      state[payload.type].loading = true;
    },
    uploadAwsLinkSuccess: (state, { payload }: PayloadAction<{ type: MediaType }>) => {
      state[payload.type].loading = false;
    },
    uploadAwsLinkFailure: (
      state,
      { payload }: PayloadAction<{ type: MediaType; error: string }>,
    ) => {
      state[payload.type].loading = false;
      state[payload.type].error = payload.error;
    },
  },
});

export { actions as MediaActions };

export default reducer;
