import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    loadingContainer: {
      height: 40,
      textAlign: `right`,

      [theme.breakpoints.down('sm')]: {
        height: 32,
      },
    },

    connectWalletContainer: {
      '& .MuiButton-root': {
        padding: '4px 25px',
        [theme.breakpoints.up('lg')]: {
          padding: '12px 25px',
        },
      },
      [theme.breakpoints.down('lg')]: {
        justifySelf: 'flex-end',
        alignSelf: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        justifySelf: 'flex-end',
      },
    },

    progress: {
      height: '32px !important',
      width: '32px !important',
    },
  };
});
