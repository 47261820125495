import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: '100%',
    },
    input: {
      p: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: '30px',
      '& form': {
        backgroundColor: 'transparent',
      },
      background: 'rgba(255, 255, 255, 0.3)',
    },
    iconSearch: {
      margin: 8,
      color: '#ffffff',
    },
    inputSearch: {
      color: '#ffffff',
      width: '100%',
      border: 'none',
      backgroundColor: 'transparent',
      '& :hover': {
        border: 'none',
      },
    },
    searchPopper: {
      zIndex: 100,
      borderRadius: 10,
      height: 498,
      '& .MuiBox-root': {
        height: 498,
      },
    },
    boxSearchPepper: {
      padding: 0,
      borderRadius: 10,
      boxShadow:
        '0px 40px 80px rgba(0, 0, 0, 0.07), 0px 20.25px 34.875px rgba(0, 0, 0, 0.04725), 0px 8px 13px rgba(0, 0, 0, 0.035), 0px 1.75px 4.625px rgba(0, 0, 0, 0.02275)',
    },
    listGroup: {
      borderBottom: '1px solid #E0E0E0',
      color: '#828282',
      fontWeight: 600,
      fontSize: 16,
      padding: '20px 20px 10px',
    },
    listItemRoot: {
      width: '100%',
    },
    listItemButton: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    listItemName: {
      fontWeight: 600,
      fontSize: 16,
      color: '#333333',
    },
    cardActions: {
      position: 'absolute',
      padding: 0,
      bottom: 0,
      borderTop: '1px solid #E0E0E0',
      width: '100%',
      '& p': {
        color: '#333333',
        fontWeight: 600,
        fontSize: 16,
      },
    },
    empty: {
      paddingTop: 20,
      fontWeight: 500,
      fontSize: 16,
    },
  };
});
