import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FollowSubscribe } from 'entities/Account';
import { FollowingState } from './types';

const initialState: FollowingState = {
  list: [],
  deleted: [],
  loading: true,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'following',
  initialState,
  reducers: {
    getFollowingListRequest: (state, { payload }: PayloadAction<{ fanId: number }>) => {
      state.loading = true;
      state.error = false;
    },
    getFollowingListSuccess: (state, { payload }: PayloadAction<{ data: FollowSubscribe[] }>) => {
      state.list = payload.data;
      state.loading = false;
    },
    getFollowingListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    addFollowingRequest: (
      state,
      { payload }: PayloadAction<{ fanId: number; celebrityId: number }>,
    ) => {
      state.loading = true;
      state.error = false;
    },
    addFollowingSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    addFollowingFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    deleteFollowingRequest: (
      state,
      { payload }: PayloadAction<{ fanId: number; celebrityId: number }>,
    ) => {
      state.loading = true;
      state.error = false;
    },
    deleteFollowingSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    deleteFollowingFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as FollowingActions };

export default reducer;
