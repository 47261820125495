import axios from 'axios';
import * as endpoints from 'services/api';
import {all, call, put, select, take, race} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ROUTE_404 } from 'constants/routes';
import { HttpProviderBase } from 'web3-core-helpers';
import web3, {web3Eth} from 'helpers/getWeb3';
import { DaoActions } from '../reducers/dao';
import { NetworkStateInterface } from "../reducers/network/types";
import { getBlockchainABI } from "../../contracts";
import { getLink } from "../../helpers/routes";
import { blocksportDaoAddress, bsptTokenAddress } from "../../contracts/addresses";
import { AddressInterface } from "../../contracts/types";
import {VoterUser} from "../reducers/dao/types";
import {AlertActions} from "../reducers/alert";
import {sliceTruncateHash} from "../../helpers/address";
import {WalletState} from "../reducers/wallet/types";
import {NetworkActions} from "../reducers/network";

export function* getProposalSaga(action: ReturnType<typeof DaoActions.getProposalRequest>) {
	const { id } = action.payload;
	try {
		const {
			data: { data },
		} = yield axios.get(getLink(endpoints.GET_PROPOSAL, { id }), {});

		// if (data) {
		// const data = mockupData.find((item: any) => item.id === id);
		if (data) {
			yield put(DaoActions.getProposalSuccess({ data }));
		} else {
			yield put(push(ROUTE_404));
		}
		// }
	} catch (error) {
		console.log({ error });
		yield put(DaoActions.getProposalFailure());
		yield put(push(ROUTE_404));
	}
}

export function* getProposalsSaga(action: ReturnType<typeof DaoActions.getProposalsRequest>) {
	try {
		const {
			data: { data },
		} = yield axios.get(getLink(endpoints.GET_PROPOSALS), { params: action.payload });

		// if (data) {
		yield put(DaoActions.getProposalsSuccess({ data }));
		// }
	} catch (error) {
		console.log({ error });
		yield put(DaoActions.getProposalsFailure());
	}
}

export function* createProposal(action: ReturnType<typeof DaoActions.createProposalRequest>): any {
	const { dataHash } = action.payload;
	console.log('dataHash', dataHash);
	try {
		const { network }: NetworkStateInterface = yield select((state) => state.network);
		const { wallet } = yield select((state) => state.wallet);

		if (network !== 'eth' || !wallet.address) {
			const targetNetwork = process.env.REACT_APP_ENV === 'development' ? 5 : 1;
			yield put(AlertActions.showAlert({
				message: `Please switch to the ${targetNetwork === 1 ? 'Ethereum Mainnet' : 'Goerli Testnet'}.`,
				severity: 'warning',
			}));

			yield put(NetworkActions.changeMetamaskRequest({ networkKey: 'eth' }));

			const { success, failure } = yield race({
				success: take(NetworkActions.changeMetamaskSuccess),
				failure: take(NetworkActions.setUserRejected),
			});

			if (failure) {
				yield put(DaoActions.createProposalFailure());
				return;
			}

			if (success) {
				return yield call(createProposal, action);
			}
		}

		// @ts-ignore
		const daoAddress = blocksportDaoAddress[network];
		const daoContract: any = new web3.eth.Contract(
			getBlockchainABI(network, 'dao'),
			daoAddress,
		);

		const gasEstimate = yield call(
			daoContract.methods.createProposal(dataHash).estimateGas,
			{ from: wallet.address },
		);
		
		console.log('gasEstimate', gasEstimate);

		const gasPrice = yield call(web3.eth.getGasPrice);
		
		const transactionCost = web3.utils.fromWei((gasEstimate * Number(gasPrice)).toString(), 'ether');
		console.log('transactionCost', transactionCost);

		const { transactionHash } = yield web3.eth.sendTransaction({
			to: daoAddress,
			from: wallet.address,
			data: daoContract.methods.createProposal(dataHash).encodeABI(),
			gas: gasEstimate,
			gasPrice,
		});

		if (transactionHash) {
			yield put(DaoActions.createProposalSuccess({ data: transactionHash }));
			yield put(AlertActions.showAlert({ message: `Proposal created tx: ${sliceTruncateHash(transactionHash)}`, severity: 'success' }));
		}

	} catch (error: any) {
		yield put(AlertActions.showAlert({ message: error.message, severity: 'error' }));
		yield put(DaoActions.createProposalFailure());
	}
}

export function* getDaoParamsSaga(action: ReturnType<typeof DaoActions.getDaoParamsRequest>): Generator {
	try {
		const { network }: any = yield select((state) => state.network);
		const { wallet }: any = yield select((state) => state.wallet);
		// @ts-ignore
		const daoAddress = blocksportDaoAddress.eth;
		
		let daoContract: any;

		if (wallet.address && network === 'eth') {
			daoContract = new web3.eth.Contract(
				getBlockchainABI(network, 'dao'),
				daoAddress,
			);
		} else {
			daoContract = new web3Eth.eth.Contract(
				getBlockchainABI(network, 'dao'),
				daoAddress,
			);
		}

		const [votingPeriod, proposalThreshold, quorumFraction]: any = yield all([
			call([daoContract.methods.votingPeriod(), 'call']),
			call([daoContract.methods.proposalThreshold(), 'call']),
			call([daoContract.methods.quorumFraction(), 'call']),
		]);

		const data = { votingPeriod, proposalThreshold, quorumFraction };

		yield put(DaoActions.getDaoParamsSuccess({ data }));
	} catch (error) {
		yield put(DaoActions.getDaoParamsFailure());
	}
}

export function* voteProposalSaga(
	action: ReturnType<typeof DaoActions.voteProposalRequest>,
) {
	try {
		const { voter, proposalId, nonce } = action.payload;
		const timestamp =  Math.floor(Date.now() / 1000);

		const { network }: NetworkStateInterface = yield select((state) => state.network);

		const daoAddress = blocksportDaoAddress[network as keyof AddressInterface];
		const chainId: string = yield web3.eth.getChainId();

		const tokenAddress: string = bsptTokenAddress.eth;

		const msgParams = JSON.stringify({
			domain: {
				name: 'Blocksport DAO',
				version: '1',
				chainId,
				verifyingContract: daoAddress,
			},
			message: {
				voter,
				proposalId,
				timestamp,
				nonce,
			},
			primaryType: 'UpVote',
			types: {
				EIP712Domain: [
					{ name: 'name', type: 'string' },
					{ name: 'version', type: 'string' },
					{ name: 'chainId', type: 'uint256' },
					{ name: 'verifyingContract', type: 'address' },
				],
				UpVote: [
					{ name: 'voter', type: 'address' },
					{ name: 'proposalId', type: 'uint256' },
					{ name: 'timestamp', type: 'uint256' },
					{ name: 'nonce', type: 'uint256' },
				],
			},
		});

		const from: string[] = yield web3.eth.getAccounts();
		const params: string[] = [from[0], msgParams];
		const method = 'eth_signTypedData_v4';

		const signature: string = yield window.ethereum.request({
			method,
			params,
			from: from[0],
		});

		// @ts-ignore
		// const signature: any = yield call(() => new Promise((resolve, reject) => {
		// 	const provider = web3.currentProvider as HttpProviderBase;
		// 	if (!provider || typeof provider.send !== 'function') {
		// 		reject(new Error('Invalid or missing provider'));
		// 		return;
		// 	}
		// 	provider.send({
		// 		id: 1,
		// 		jsonrpc: "2.0",
		// 		method,
		// 		params,
		// 	}, (err, result) => err ? reject(err) : resolve(result));
		// }));

		if (!signature) {
			yield put(DaoActions.voteProposalFailure());
			return;
		}

		const { data } = yield call(axios.post, getLink(endpoints.POST_PROPOSAL_VOTE, { id: proposalId }), {
			voter,
			proposalId,
			timestamp,
			nonce,
			signature,
			chainId,
			tokenAddress,
		});

		yield put(DaoActions.voteProposalSuccess({ data: data.data } ));

	} catch (error) {
		console.log(error);
		yield put(DaoActions.voteProposalFailure());
	}
}

export function* getVotingInfoSaga(action: ReturnType<typeof DaoActions.getVotingInfoRequest>) {
	try {
		const { voter, proposalId } = action.payload;
		const { network }: NetworkStateInterface = yield select((state) => state.network);

		const { data } = yield call(axios.get, getLink(endpoints.GET_PROPOSAL_VOTING_INFO, { id: proposalId }), {
			params: { voter, network },
		});

		yield put(DaoActions.getVotingInfoSuccess({ data: data.data }));
	} catch (error) {
		console.log(error);
		yield put(DaoActions.getVotingInfoFailure());
	}
}

