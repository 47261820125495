// allowancesSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenAccessState, TransactionStates } from './types'

const initialState: TokenAccessState = {};

const { actions, reducer } = createSlice({
  name: 'erc20',
  initialState,
  reducers: {
    initAllowance: (state, { payload }: PayloadAction<{ tokenAddress: string; addressDestination: string }>) => {
      const { tokenAddress, addressDestination } = payload;
      if (!state[tokenAddress]) {
        state[tokenAddress] = {};
      }
      state[tokenAddress][addressDestination] = {
        initialAccessState: true,
        allowance: 0,
        allowanceStatus: TransactionStates.IDLE,
        approveHash: '',
        approveState: TransactionStates.IDLE,
        balance: 0,
        balanceState: TransactionStates.IDLE,
      };
    },
    getAllowanceRequest: (state, { payload }: PayloadAction<{ addressOwner: string; tokenAddress: string; addressDestination: string }>) => {
      const { tokenAddress, addressDestination } = payload;
      if (state[tokenAddress] && state[tokenAddress][addressDestination]) {
        state[tokenAddress][addressDestination].allowanceStatus = TransactionStates.PENDING;
      }
    },
    getAllowanceSuccess: (state, { payload }: PayloadAction<{ tokenAddress: string; addressDestination: string; allowance: number }>) => {
      const { tokenAddress, addressDestination, allowance } = payload;
      // console.log("payload", payload);
      // console.log("state[tokenAddress]", state[tokenAddress]);
      if (state[tokenAddress] && state[tokenAddress][addressDestination]) {
        state[tokenAddress][addressDestination] = {
          ...state[tokenAddress][addressDestination],
          allowance,
          allowanceStatus: TransactionStates.SUCCESS,
        };
      }
    },
    getAllowanceError: (state, { payload }: PayloadAction<{ tokenAddress: string; addressDestination: string; error: string }>) => {
      const { tokenAddress, addressDestination } = payload;
      if (state[tokenAddress] && state[tokenAddress][addressDestination]) {
        state[tokenAddress][addressDestination] = {
          ...state[tokenAddress][addressDestination],
          allowance: 0,
          allowanceStatus: TransactionStates.FAILED,
        };
      }
    },
    approveRequest: (state, { payload }: PayloadAction<{ addressOwner: string, tokenAddress: string; addressDestination: string; amount: number }>) => {
      const { tokenAddress, addressDestination } = payload;
      if (state[tokenAddress] && state[tokenAddress][addressDestination]) {
        state[tokenAddress][addressDestination].approveState = TransactionStates.PENDING;
      }
    },
    approveSuccess: (state, { payload }: PayloadAction<{ tokenAddress: string; addressDestination: string; hash: string }>) => {
      const { tokenAddress, addressDestination, hash } = payload;
      if (state[tokenAddress] && state[tokenAddress][addressDestination]) {
        state[tokenAddress][addressDestination] = {
          ...state[tokenAddress][addressDestination],
          approveState: TransactionStates.SUCCESS,
          approveHash: hash,
        };
      }
    },
    approveError: (state, { payload }: PayloadAction<{ tokenAddress: string; addressDestination: string; error: string }>) => {
      const { tokenAddress, addressDestination } = payload;
      if (state[tokenAddress] && state[tokenAddress][addressDestination]) {
        state[tokenAddress][addressDestination] = {
          ...state[tokenAddress][addressDestination],
          approveState: TransactionStates.FAILED,
        };
      }
    },
  },
});

export { actions as erc20Actions };

export default reducer;
