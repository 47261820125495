export const getInterest = (key: string) => {
	switch (key) {
		case 'SNG0':
		case 'RSB': {
			return 'red-star-fto';
		}
		case 'PTZT': {
			return 'partizan-fto';
		}
		case 'EMP': {
			return 'empoli-fto';
		}
		case 'SRDT': {
			return 'srdt-fto';
		}
		case 'TEAMBGR': {
			return 'tb-fto';
		}
		case 'DAO': {
			return 'dao';
		}
		default:
			return `${key.toLowerCase()}-fto`;
	}
};
