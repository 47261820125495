import {AddressInterface} from './types';

const DEV_ENV = process.env.REACT_APP_ENV === 'development';

export const bridgeDepositAddress: AddressInterface = DEV_ENV
    ? {
        eth: '0x720bE786f4013b5FCBD4bF650Bb1aE2B163e8138',
        bsc: '0xa485A3a8f38977B732A01F613fD6e7f599791645',
        polygon: '',
        whitebit: '',
    }
  : {
      eth: '0x53D8bC321dAFD48E6fa838EBcDeC928F1A73EFaa',
      bsc: '0x2F1Ce74466aF6Ce750e4040f4C73C6067F92D725',
      polygon: '',
        whitebit: '',
    };

export const bridgeHolderAddress: AddressInterface = DEV_ENV
  ? {
      eth: '0x6ed29615C90a49FF7292Cac8827f5b3B3239a254',
      bsc: '0xF325fDb6d51D6B1D697A8fe0c44F769d528272e1',
      polygon: '',
        whitebit: '',
    }
  : {
      eth: '0xAA923E6e4aC1e537cffD9260df957b665311Fa17',
      bsc: '0x96D9Cb3738338A767a1525B08Ae55ce39470736a',
      polygon: '',
        whitebit: '',
    };

export const mintAddress: AddressInterface = DEV_ENV
  ? {
      eth: '0x7dFe2f29D1d5fC5203e8B0b696C18D82b99C2565',
      bsc: '0x1907507f76b86Eb55fac82f6B92584DB6f5E49BC',
      polygon: '0x143145809B9538c8461bEa9618d46E9e289751c0',
      whitebit: '',
    }
  : {
      eth: '0x6debb461A3680A3d7aD607E289b1B226a62C04DB',
      bsc: '0xa865857BBc9fc8378a6C42D9a946c336F25681ec',
      polygon: '0x1c126ed8128c954c7d376afc87ba72877d243066',
      whitebit: '',
    };

export const auctionAddress: AddressInterface = DEV_ENV
    ? {
        eth: '0xd2a9bcd50FEcD00A6C23023323876c3adf7422A7',
        bsc: '0xE362bee9D1976AeCCEe36471D96701c05704C9a1',
        polygon: '0x3b2aEaB1e05166174C3EE1524468DacDf360977E',
        whitebit: '',
    }
    : {
        eth: '0x8d6D20E920C13B8113381FBb5ddbE5aD3a7E73A8',
        bsc: '0xf82502bBDcD5B0e46Ad693e6000d1180E2C8ed40',
        polygon: '0xD0E8af8E3fCC809a3cF6232237BA1578859c4879',
        whitebit: '',
    };

export const collectionAddress: AddressInterface = DEV_ENV
    ? {
        eth: '0xc685F977087Ed47F8bc11d1bD9279242ceb95F83',
        bsc: '0x5B24F1794Ac822605484950f430Aca8a43560340',
        polygon: '0x2EC28e7fd72B7fBF3Db33A8fb553794B07611Ff4',
        whitebit: '',
    }
    : {
        bsc: '0x6CC13Eeb5567A0a71194034B42A23901cCF0F435',
        eth: '0x92a49BA5c0f7002F5d2bc3a22fa514cA490b8EB8',
        polygon: '0x2EC28e7fd72B7fBF3Db33A8fb553794B07611Ff4',
        whitebit: '',
    };

export const auctionCollectionAddress: AddressInterface = DEV_ENV
    ? {
        eth: '0x94b93FC83a3E1cC1E19a4f1F55aCBFBC91557409',
        bsc: '0x23C900d10E8698ED29CdBd3c1BDd6235D056362F',
        polygon: '0xb72aec6D70F087E698983CD80d2d0484AEF0B65c',
        whitebit: '',
    }
    : {
        bsc: '0xaEE9437a26E38dd6Ffc0eFB2Ca3E1c34C1D0C64D',
        eth: '0x92a49BA5c0f7002F5d2bc3a22fa514cA490b8EB8',
        polygon: '0xb72aec6D70F087E698983CD80d2d0484AEF0B65c',
        whitebit: '',
    };

export const stakeNFTAddress: AddressInterface = DEV_ENV
    ? {
        bsc: '0x0aa625183b4938306568BfbA9803Ae059Dc443aE',
        eth: '0xd952659f0c429Fdf2361BB9195294231690fa290',
        polygon: '0xB0ec43bBf7a3922650230B78096EE5b44DBE4Fbd',
        whitebit: '',
    }
    : {
        bsc: '0x8a5c9402FDB06866E6d06133Bde4e0466d93c1Ec',
        eth: '0xd952659f0c429Fdf2361BB9195294231690fa290',
        polygon: '0xB0ec43bBf7a3922650230B78096EE5b44DBE4Fbd',
        whitebit: '',
    };

export const stakeBSPTAddress: AddressInterface = DEV_ENV
    ? {
        eth: '',
        bsc: '0x850D76dFe3f4EAF513f457BbEA43e843716b1432',
        polygon: '',
        whitebit: '',
      }
    : {
        bsc: '0x823c29734CF3A60C9a154A82d13c81fa3f7aD3F3',
        eth: '',
        polygon: '',
        whitebit: '',
      };
  
export const stakeBSPTAddressOld: AddressInterface = DEV_ENV
    ? {
        eth: '',
        bsc: '0xBF57dAf1Ce2d0B7c046f577514f1f2AEaB352ae2',
        polygon: '',
        whitebit: '',
    }
    : {
        bsc: '0x05aA5bd6e86d1c70Fac9180fEC3f7c2c64144B96',
        eth: '',
        polygon: '',
        whitebit: '',
    };

export const transferProxyAddress: AddressInterface = DEV_ENV
    ? {
        eth: '0x5a5186aa8405e72Cfb9Dae78b61b59732c92c0eA',
        bsc: '0x8157fa5fcaD3912f6Ef59f34912DC47D88aBcC3E',
        polygon: '',
        whitebit: '',
    }
    : {
        bsc: '0x10ca34190B8679B494a9C55191560188968632Fc',
        eth: '',
        polygon: '',
        whitebit: '',
    };
export const nftWhitelistAddresses = DEV_ENV
    ? ['0xcfda35b0ce38e77d0b9fbb8cfbcbd29f0238986f']
    : ['0x62fbbe6a63ea92d1be3c33b520eddcf857952b9b', '0x972d2647d8f564f1835bc585447da8e81ec35735', '0xc6df0a675c6e9ee89884aac9bd132691b4b5692e'];

export const whiteListAddress: AddressInterface = DEV_ENV
    ? {
        bsc: '0xbdE62B7c80B1c316Ed12Aa1d8994F9Ce99116235',
        eth: '',
        polygon: '',
        whitebit: '',
    }
    : {
        bsc: '0x7e2dECeD943C6D8C8348002F0D5F7Af0A4F3EE46',
        eth: '',
        polygon: '',
        whitebit: '',
    };

export const marketplaceAddress: AddressInterface = DEV_ENV
    ? {
        bsc: '0x522BE96695c17c54357D4599B48c0725E6FeeDf8',
        eth: '0xa696acB10B618247b70852F48a7b95A7F18f7518',
        polygon: '0x1219d8847CBB3Aa5DAB50F575aFc631048eC8171',
        whitebit: '0x7a16F2788c23aE5cFA8416cc9Ce38855EFc61Dc5',
    }
    : {
        bsc: '0x3ce5fD37c7fE73db2d6f4DFD9C995AA8B3a57E2e',
        eth: '0xAc347E1199F223De408f3e1b619FDA417d6f8225',
        polygon: '0x7f27085D25dEEde47B9c56d29e5CBB06093c62E0',
        whitebit: '0x005A1CC01701cbAB51eF4a3473f50a10388C13aD',
    };

export const token1155Address: AddressInterface = DEV_ENV
    ? {
        bsc: '0x36E5e30ccE43B1387AA684A039Cb3dd4610837fd',
        eth: '0xF76e263b30c1E6eEd2Ce839DC44e60e66fE17F0B',
        polygon: '0x19ABcaE3C97D08aa114994584023768581F95e21',
        whitebit: '0xEf2f9302aFa221599A096976d2d93677D65312A7',
    }
    : {
        bsc: '0x11518a7BAc6E8C316eEF4F9684915b1A23f4f50f',
        eth: '0x8307b0e9c3E18e7551609bd992FA488F12E272F4',
        polygon: '0x1ad0941CDbC1696D679F0ddFE4a517Ec4a41f29d',
        whitebit: '0xDe6f61b9f8ce89ae2D41E57bC4A8021263f7DD39',
    };

    export const token721Address: AddressInterface = DEV_ENV
    ? {
        bsc: '0x5544E44574c12F44F7b50669598F40a9e461d16d',
        eth: '0xeDB7e71fc80D7904aa5daE035C3bac64742432eD',
        polygon: '0x18F29CBFa9E31f801359E92fAe141a33e4b25C24',
        whitebit: '0xF76e263b30c1E6eEd2Ce839DC44e60e66fE17F0B',
    }
    : {
        bsc: '0x1ebA3b5652DF9e6D9c18C2Da00D6c3Ff2Efe738f',
        eth: '0x7E36Bb27fca477A3F395580893922880aCF29061',
        polygon: '0xAc347E1199F223De408f3e1b619FDA417d6f8225',
        whitebit: '0x6a5b5e9C04ca04DA31692364BED00E48D9e22a14',
    };

export const stakeFantokenAddress: AddressInterface = DEV_ENV
    ? {
        eth: '',
        bsc: '0x989f1518Ac9fb3904cf95aA89e8033650CFBfde5',
        // bsc: '0xfB50D472b7F3E1C3e0d64F7B99344a335056B485',
        polygon: '',
        whitebit: '',
    }
    : {
        bsc: '0xb4b9EE157689FD0721f230B0521a1e766A2F2c88',
        eth: '',
        polygon: '',
        whitebit: '',
    };
export const stakeFantokenAddressOld: AddressInterface = DEV_ENV
    ? {
        eth: '',
        bsc: '0xfB50D472b7F3E1C3e0d64F7B99344a335056B485',
        polygon: '',
        whitebit: '',
    }
    : {
        bsc: '0x86090c5A9D6dBf922A7D07e8A0c26D6a6DA1a88b',
        eth: '',
        polygon: '',
        whitebit: '',
    };

export const basketballPithAreaNftGameAddress: AddressInterface = DEV_ENV
    ? {
        eth: '',
        bsc: '0x2e3fedc7FFD52B8E9E98748F9bCe0E6802CA967D',
        polygon: '',
        whitebit: '',
    }
    : {
        bsc: '0x9A40f7e2CdFE5d3C02D5914f14142A053f156018',
        eth: '',
        polygon: '',
        whitebit: '',
    };

export const bsptTokenAddress: AddressInterface = DEV_ENV
    ? {
        eth: '0x119076bd87e67696b24f664C2E5b10D2b15Ab725',
        bsc: '0x6CdF5bA7e448770294C69a4607e1Dec2963C6fd4',
        polygon: '',
        whitebit: '',
    }
    : {
        eth: '0xa350da05405cc204e551c4eed19c3039646528d5',
        bsc: '0x74F996A2ED8B1370C2f12ce9D040a1d9c9254E6e',
        polygon: '',
        whitebit: '',
    }

export const blocksportDaoAddress: AddressInterface = DEV_ENV
    ? {
        eth: '0x156ed612384e99b919EE73b41D8C47337F2703b4',
        bsc: '',
        polygon: '',
        whitebit: '',
    } : {
        eth: '0xa8484853A0D35Fe07d02fE31708A925c8105aB3B',
        bsc: '',
        polygon: '',
        whitebit: '',
    };

export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const currencyNativeToken = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const emptyAddress = '0x0000000000000000000000000000000000000000';
