import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmailActions } from 'store/reducers/email';

import { Dialog, DialogContent, IconButton, Button, Typography, Box, Container, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CloseIcon, ShapeIcon } from 'icons';

import { Form, Formik } from 'formik';
import { FormTextField } from 'shared/components/Form';
import DisplayOn from 'shared/components/DisplayOn';

import { useDialogStyles } from 'shared/dialogs/styles';

import { validationSchema } from './lib';

interface Message {
	name: string;
	email: string;
	about: string;
}

const ApplicationForm = () => {
	const dispatch = useDispatch();

	const {
		joinEmail: { loading, sent: joinEmailSent, joinModalIsOpen },
	} = useSelector((state) => state.email);

	const handleClose = () => {
		dispatch(EmailActions.joinEmail.toggleJoinModal({ open: false }));
	};

	const { classes: dialogClasses, cx } = useDialogStyles();
	const theme = useTheme();

	const onSubmit = (values: any) => {
		dispatch(
			EmailActions.joinEmail.sendJoinEmailRequest({
				...values,
			}),
		);
	};

	useEffect(() => {
		joinEmailSent && handleClose();
	}, [joinEmailSent]);

	const getInitialValues = ({ name = '', email = '', about = '' }: Message) => ({
		name,
		email,
		about,
	});

	return (
		<Dialog open={joinModalIsOpen} onClose={handleClose} fullWidth maxWidth="md" className={cx(dialogClasses.appForm, { loading })}>
			<IconButton onClick={handleClose} className={dialogClasses.closeBtn} size="large">
				<CloseIcon />
			</IconButton>
			<Typography variant="h3" className={dialogClasses.appFormTitle}>
				Blocksport Application Form
			</Typography>
			<DialogContent sx={{ paddingTop: 0 }}>
				<Container>
					{loading && (
						<Box display="flex" justifyContent="center" alignItems="center" className={dialogClasses.appFormLoader}>
							<CircularProgress />
						</Box>
					)}
					{!loading && (
						<>
							<DisplayOn media={theme.breakpoints.up('sm')}>
								<Typography variant="subtitle1" className={cx(dialogClasses.appFormIntro, 'df')}>
									Fill out this form if you would like to be a <ShapeIcon />
									<span>Creator at our platform!</span>
								</Typography>
							</DisplayOn>
							<DisplayOn media={theme.breakpoints.between('xs', 'sm')}>
								<Typography variant="subtitle1" className={dialogClasses.appFormIntro}>
									Fill out this form if you would like to be a Creator at our platform!
								</Typography>
							</DisplayOn>
							<Typography variant="subtitle1" className={dialogClasses.appFormIntro}>
								Try to mention as many important details as possible that will help us decide faster.
							</Typography>
							<Box mt={5} className={dialogClasses.appFormBox}>
								<Formik initialValues={getInitialValues({} as Message)} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
									{({ dirty, isValid, values, setFieldValue }) => {
										return (
											<Form>
												<Box mb={5}>
													<Typography variant="h3" className={dialogClasses.appFormLabel}>
														Full name or name of your club / team *
													</Typography>
													<FormTextField istouched={0} name="name" placeholder="Full Name" fullWidth />
												</Box>
												<Box mb={5}>
													<Typography variant="h3" className={dialogClasses.appFormLabel}>
														Contact email *
													</Typography>
													<FormTextField istouched={0} name="email" type="email" placeholder="Email" fullWidth />
												</Box>
												<Box mb={5}>
													<Typography variant="h3" className={dialogClasses.appFormLabel}>
														Tell us about yourself or your club / team *
													</Typography>
													<FormTextField
														istouched={0}
														name="about"
														placeholder="Tell us everything you believe might be helpful a creator on Blocksport "
														fullWidth
														multiline
														maxRows={3}
													/>
												</Box>
												<Box mb={1} display="flex" justifyContent="center">
													<Button type="submit" variant="contained" disabled={!dirty || !isValid} fullWidth className={dialogClasses.appFormSubmit}>
														Submit
													</Button>
												</Box>
											</Form>
										);
									}}
								</Formik>
							</Box>
						</>
					)}
				</Container>
			</DialogContent>
		</Dialog>
	);
};

export default ApplicationForm;
