import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { SubscriberActions } from '../reducers/subscriber';

export function* getSubscriberListSaga(
  action: ReturnType<typeof SubscriberActions.getSubscriberListRequest>,
) {
  const { celebrityId } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.SUBSCRIBERS, { celebrityId }));
    yield put(SubscriberActions.getSubscriberListSuccess({ data }));
  } catch {
    yield put(SubscriberActions.getSubscriberListFailure());
  }
}
