import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DepositState } from './types';

const initialState: DepositState = {
	token: null,
	loading: false,
	error: false,
	saving: false,
	saved: false,
	whitelisting: false,
	whitelisted: false,
	errorMessage: '',
};

const { actions, reducer } = createSlice({
	name: 'deposit',
	initialState,
	reducers: {
		getNFTRequest: (state, { payload }: PayloadAction<{ address: string; contract: string; tokenId: number }>) => {
			state.loading = true;
			state.error = false;
		},
		getNFTSuccess: (state, { payload }: PayloadAction<any>) => {
			state.loading = false;
			state.error = false;
			state.token = payload.data;
		},
		getNFTFailure: (state, { payload }: PayloadAction<{ error: string }>) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = payload.error;
		},

		saveNFTRequest: (state, { payload }: PayloadAction<any>) => {
			state.saving = true;
			state.error = false;
			state.saved = false;
		},
		saveNFTSuccess: (state, { payload }: PayloadAction<any>) => {
			state.saved = true;
			state.saving = false;
		},
		saveNFTFailure: (state) => {
			state.saving = false;
		},

		whitelistRequest: (state, { payload }: PayloadAction<any>) => {
			state.whitelisting = true;
			state.error = false;
			state.whitelisted = false;
			state.saved = false;
		},
		whitelistSuccess: (state, { payload }: PayloadAction<any>) => {
			state.whitelisting = false;
			state.error = false;
			state.whitelisted = true;
		},
		whitelistFailure: (state) => {
			state.whitelisting = false;
			state.error = true;
		},

		cancelDeposit: (state) => {
			state.token = null;
			state.loading = false;
			state.error = false;
			state.saving = false;
			state.saved = false;
			state.whitelisting = false;
			state.whitelisted = false;
		},
	},
});

export { actions as DepositActions };

export default reducer;
