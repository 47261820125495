import { Theme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {},
    balanceBox: {
      '& .MuiButtonBase-root': {
        padding: `8px 24px`,
        whiteSpace: 'nowrap',
        background: 'rgba(255, 255, 255, 0.3)',
        borderRadius: 30,
      },
    },
    profileMenu: {
      '& .MuiMenuItem-root': {
        minHeight: 'auto',
        fontWeight: 600,
        padding: '12px 16px',
        minWidth: 200,
        '&:hover': {
          backgroundColor: 'rgba(56, 137, 250, 0.1)',
        },
      },
      '& .MuiAvatar-root': {
        marginRight: 20,
      },
      '& .MuiListItemIcon-root': {
        marginRight: 20,
        minWidth: 'auto',

        '& svg': {
          width: 20,
          height: 20,
          color: '#3889FA',
        },
      },
    },
  };
});
