import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
	.use(Backend)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// https://www.i18next.com/how-to/add-or-load-translations
	// .use(
	// 	resourcesToBackend((language, namespace, callback) => {
	// 		import(`/locales/${language}/${namespace}.json`)
	// 			.then((resources) => {
	// 				callback(null, resources);
	// 			})
	// 			.catch((error) => {
	// 				callback(error, null);
	// 			});
	// 	}),
	// )
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: 'en',
		debug: process.env.NODE_ENV === 'development',
		// keySeparator: false,
		// keySeparator: '.',
		keySeparator: '#',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;
