import { put } from 'redux-saga/effects';
import { MediaActions } from 'store/reducers/media';
import axios from 'axios';
import { getLink } from 'helpers/routes';
import { GET_PRESIGNED_LINK, ADD_MEDIA, PUT_TOKEN } from 'services/api';

export function* addMediaSaga(action: ReturnType<typeof MediaActions.addMediaRequest>) {
  const { file, type } = action.payload;

  const formData = new FormData();
  formData.append('file', file);

  try {
    const {
      data: { data: link },
    } = yield axios.post(ADD_MEDIA, formData);
    yield put(MediaActions.addMediaSuccess({ type, link }));
  } catch (error) {
    yield put(MediaActions.addMediaFailure({ type, error: (error as any).response.data.error }));
  }
}

export function* getPresignedLinkSaga(action: ReturnType<typeof MediaActions.getPresignedLinkRequest>) {
  const { type, fileType, file, filename } = action.payload;

  try {
    const {
      data: {
        data: { url, fields },
      },
    } = yield axios.get(GET_PRESIGNED_LINK, { params: { format: fileType, filename } });
    if (url && fields) {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => formData.append(key, value as string));
      formData.append('file', file);

      yield axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: '' },
      });

      yield put(MediaActions.getPresignedLinkSuccess({ type: 'aws', link: `${url}/${fields.key}` }));
    }
  } catch (error) {
    yield put(MediaActions.getPresignedLinkFailure({ type, error: (error as any).response.data.error }));
  }
}

export function* uploadAwsLinkSaga(action: ReturnType<typeof MediaActions.uploadAwsLinkRequest>) {
  const { type, id, data } = action.payload;

  try {
    yield axios.put(getLink(PUT_TOKEN, { id, tokenSource: data.blockchain.toUpperCase() }), {
      ...data,
    });
  } catch (error) {
    yield put(MediaActions.uploadAwsLinkFailure({ type, error: (error as any).response.data.error }));
  }
}
