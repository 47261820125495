import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  loader: {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    height: 'calc(100vh - 260px)',
  },
}));

export default function Loader() {
  const { classes } = useStyles();

  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );
}
