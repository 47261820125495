import React from 'react';

import { RouteProps, Route as ReactRoute, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { BannedIcon } from 'icons';
import { Layout, LayoutProps } from '../Layout';
import InfoCard from '../Cards/Info';

export default function Route(props: RouteProps & Omit<LayoutProps, 'children'>) {
  const { fullwidth, component, ...rest } = props;
  // Selectors
  const { account } = useSelector((state) => state.account);

  const location = useLocation();

  const handleContactUs = () => {
    window.open('mailto:email@example.com');
  };

  const isProfile = /^\/profile\/[0-9a-z]*|\/profile$/.test(location.pathname);
  const isDaoDetails = /^\/dao\/\d+$/.test(location.pathname);

  return (
    <Layout fullwidth={fullwidth} isProfile={ isProfile || isDaoDetails }>
      {account && account.status === 'banned' ? (
        <Box display="grid" justifyItems="center" alignItems="center">
          <InfoCard onClick={handleContactUs} buttonTitle="Contact Us" title="Your Account has been banned!" icon={<BannedIcon />}>
            <Typography variant="subtitle1">
              Your Blocksport profile was disabled for violating out terms. Contacts us to restore an access to your profile.
            </Typography>
          </InfoCard>
        </Box>
      ) : (
        <ReactRoute {...rest} render={() => <>{component}</>} />
      )}
    </Layout>
  );
}
