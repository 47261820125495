import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { mineShaft } from 'constants/colors';

import { NotificationActions } from 'store/reducers/notification';

const styles = () => ({
  btn: { color: mineShaft },
});

interface SnackbarProps {
  classes?: any;
}

let displayed: string[] = [];

const SnackbarNotify: React.FC<SnackbarProps> = (props: SnackbarProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // Selectors
  const { list: notifications } = useSelector((state) => state.notification);

  const { classes } = props;

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const closeAction = (key: number) => {
    return (
      <IconButton onClick={() => closeSnackbar(key)} className={classes.btn} size="large">
        <CloseIcon />
      </IconButton>
    );
  };

  useEffect(() => {
    notifications.forEach((notification: any) => {
      if (displayed.includes(notification.key)) return;
      enqueueSnackbar(notification.message, {
        ...notification.options,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: closeAction,
        onExited() {
          dispatch(NotificationActions.removeNotification(notification.key));
        },
      });
      storeDisplayed(notification.key);
    });
  }, [displayed, notifications, closeSnackbar, enqueueSnackbar]);

  return null;
};

// @ts-ignore
export default withStyles(SnackbarNotify, styles);
