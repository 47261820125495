import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';

interface FormTextFieldProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  istouched?: number;
  endAdornment?: React.ReactNode;
}

export default function FormTextField(props: FormTextFieldProps) {
  const { name, istouched } = props;
  const [field, { error, touched }] = useField(name);

  return <TextField {...field} {...props} error={istouched === 1 ? touched && !!error : !!error} helperText={istouched === 1 ? touched && error : error} />;
}

FormTextField.defaultProps = {
  istouched: 1,
};
