import {
  bscTestnetNode,
  bscMainnetNode,
  bscTestnetUrl,
  bscMainnetUrl,
  ethInfuraTestnetNode,
  ethAlchemyMainnetNode,
  ethAlchemyTestnetNode,
  ethTestnetUrl,
  ethMainnetUrl,
  polyTestnetUrl,
  polyTestnetNode,
  polyMainnetUrl,
  polyMainnetNode,
  goerliTestnetUrl, wbtMainnetNode, wbtTestnetNode, wbtTestnetNodeRpc, wbtMainnetNodeRpc,
} from './url';

const isDevEnv = process.env.REACT_APP_ENV === 'development';

const ethAlchemyNode = isDevEnv ? ethAlchemyTestnetNode : ethAlchemyMainnetNode;

export const networkConstants = {
  NETWORKS: {
    1: {
      // ETH mainnet chainID value
      network: 'eth',
      currency: 'ETH',
      link: 'https://etherscan.io/',
    },
    5: {
      // ETH testnet chainID value
      network: 'eth',
      currency: 'ETH',
      link: 'https://goerli.etherscan.io/',
    },
    56: {
      // BSC mainnet chainID value
      network: 'bsc',
      currency: 'BNB',
      name: 'BNB',
      link: 'https://bscscan.com/',
    },
    97: {
      // BSC testnet chainID value
      network: 'bsc',
      currency: 'BNB',
      name: 'BNB',
      link: 'https://testnet.bscscan.com/',
    },
    137: {
      // POLYGON mainnet chainID value
      network: 'polygon',
      currency: 'MATIC',
      name: 'POLYGON',
      link: 'https://polygonscan.com/',
    },
    80001: {
      // POLYGON testnet chainID value
      network: 'polygon',
      currency: 'MATIC',
      name: 'POLYGON',
      link: 'https://mumbai.polygonscan.com/',
    },
    1875: {
      // WHITEBIT main net chainID value
      network: 'whitebit',
      currency: 'WBT',
      name: 'WB',
      link: wbtMainnetNode,
    },
    2625: {
      // WHITEBIT test net chainID value
      network: 'whitebit',
      currency: 'WBT',
      name: 'WB',
      link: wbtTestnetNode,
    },
  },
  NETWORK_CONFIGS: {
    bsc: {
      name: 'BNB',
      symbol: isDevEnv ? 'tBNB' : 'BNB',
      chainId: isDevEnv ? '0x61' : '0x38',
      chainName: isDevEnv ? 'Binance Testnet Smart Chain' : 'Binance Smart Chain',
      decimals: 18,
      blockExplorerUrls: isDevEnv ? [bscTestnetUrl] : [bscMainnetUrl],
      rpcUrls: isDevEnv ? [bscTestnetNode] : [bscMainnetNode],
    },
    eth: {
      name: isDevEnv ? 'Goerli Test Network' : 'Ethereum Network',
      symbol: 'ETH',
      chainId: isDevEnv ? '0x5' : '0x1',
      chainName: isDevEnv ? 'Goerli Testnet' : 'Ethereum Mainnet',
      decimals: 18,
      blockExplorerUrls: isDevEnv ? [ethTestnetUrl] : [ethMainnetUrl],
      rpcUrls: isDevEnv ? [`${goerliTestnetUrl}`] : [`${ethAlchemyNode}${process.env.REACT_APP_ALCHEMY_PROJECT_ID}`], // Также убедитесь, что у вас есть правильный URL для Goerli
    },
    polygon: {
      name: isDevEnv ? 'MATIC Mumbai' : 'MATIC',
      symbol: 'MATIC',
      chainId: isDevEnv ? '0x13881' : '0x89',
      chainName: isDevEnv ? 'Matic Mumbai Testnet' : 'Matic',
      decimals: 18,
      blockExplorerUrls: isDevEnv ? [polyTestnetUrl] : [polyMainnetUrl],
      rpcUrls: isDevEnv ? [polyTestnetNode] : [polyMainnetNode],
    },
    whitebit: {
      name: isDevEnv ? 'Whitechain Test Network' : 'Whitechain Network',
      symbol: 'WBT',
      chainId: isDevEnv ? '0xa41' : '0x753',
      chainName: isDevEnv ? 'Whitechain Testnet' : 'Whitechain Network',
      decimals: 18,
      blockExplorerUrls: isDevEnv ? [wbtTestnetNode] : [wbtMainnetNode],
      rpcUrls: isDevEnv ? [wbtTestnetNodeRpc] : [wbtMainnetNodeRpc],
    },
  },
};
