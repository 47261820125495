import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MyWalletActions } from 'store/reducers/mywallet';
import { Menu, ButtonBase, Box, IconButton, Typography } from '@mui/material';
import Avatar from 'shared/components/Avatar';
import { ProfileIcon } from 'icons';
import ProfileMenu from '../ProfileMenu';
import { useStyles } from './styles';

export default function Profile() {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Selectors
  const { account } = useSelector((state) => state.account);
  const { wallet } = useSelector((state) => state.wallet);
  const { networkCurrency } = useSelector((state) => state.network);

  // Constants
  const avatar = account?.avatar ?? null;

  const handleMyWallet = () => {
    dispatch(MyWalletActions.toggleMyWallet());
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Box mr={3} flexGrow={1} className={classes.balanceBox}>
          <ButtonBase onClick={handleMyWallet}>
            <Typography variant="h4">
              {wallet.balance} {networkCurrency}
            </Typography>
          </ButtonBase>
        </Box>
        <Box>
          <IconButton onClick={handleClick}>
            <Avatar src={avatar} fallbackicon={<ProfileIcon />} />
          </IconButton>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.profileMenu}
        hideBackdrop
        keepMounted
      >
        <ProfileMenu />
      </Menu>
    </div>
  );
}
