import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FollowSubscribe, User } from 'entities/Account';
import { ProfileState } from './types';

const initialState: ProfileState = {
  profile: null,
  followings: [],
  followers: [],
  subscribings: [],
  subscribers: [],
  loading: true,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setInitial: (state) => {
      state.profile = null;
      state.followings = [];
      state.followers = [];
      state.subscribings = [];
      state.subscribers = [];
      state.loading = true;
      state.error = false;
    },
    getProfileRequest: (state, { payload }: PayloadAction<{ id: number | string }>) => {
      state.loading = true;
      state.error = false;
    },
    getProfileSuccess: (state, { payload }: PayloadAction<User>) => {
      state.loading = false;
      state.profile = payload;
    },
    getProfileFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getProfileFollowingsRequest: (state, { payload }: PayloadAction<{ id: number | string }>) => {
      state.loading = true;
      state.error = false;
    },
    getProfileFollowingsSuccess: (state, { payload }: PayloadAction<FollowSubscribe[]>) => {
      state.loading = false;
      state.followings = payload;
    },
    getProfileFollowingsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getProfileFollowersRequest: (state, { payload }: PayloadAction<{ id: number | string }>) => {
      state.loading = true;
      state.error = false;
    },
    getProfileFollowersSuccess: (state, { payload }: PayloadAction<FollowSubscribe[]>) => {
      state.loading = false;
      state.followers = payload;
    },
    getProfileFollowersFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getProfileSubscribingsRequest: (state, { payload }: PayloadAction<{ id: number | string }>) => {
      state.loading = true;
      state.error = false;
    },
    getProfileSubscribingsSuccess: (state, { payload }: PayloadAction<FollowSubscribe[]>) => {
      state.loading = false;
      state.subscribings = payload;
    },
    getProfileSubscribingsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getProfileSubscribersRequest: (state, { payload }: PayloadAction<{ id: number | string }>) => {
      state.loading = true;
      state.error = false;
    },
    getProfileSubscribersSuccess: (state, { payload }: PayloadAction<FollowSubscribe[]>) => {
      state.loading = false;
      state.subscribers = payload;
    },
    getProfileSubscribersFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getProfileByNicknameRequest: (state, { payload }: PayloadAction<{ nickname: string }>) => {
      state.loading = true;
      state.error = false;
    },
    getProfileByNicknameSuccess: (state, { payload }: PayloadAction<User>) => {
      state.loading = false;
      state.profile = payload;
    },
    getProfileByNicknameFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as ProfileActions };

export default reducer;
