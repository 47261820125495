export const pitchGameABI = [
  { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'gameId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'claimed', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'totalReward', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'tokenRewardsAsset', type: 'address' },
    ],
    name: 'ClaimedRewards',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'gameId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'eventName', type: 'string' },
      {
        components: [
          { internalType: 'string', name: 'eventName', type: 'string' },
          { internalType: 'enum IBasketballPithAreaNftGame.CompetitionSide', name: 'competitionSide', type: 'uint8' },
          { internalType: 'uint256', name: 'mintTokenForEachArea', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenRewardsPerPoint', type: 'uint256' },
          { internalType: 'address', name: 'tokenRewardsAsset', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'reservePricePerToken', type: 'uint256' },
          { internalType: 'uint256', name: 'startSellingAt', type: 'uint256' },
          { internalType: 'uint256', name: 'endSellingAt', type: 'uint256' },
          { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
          { internalType: 'uint64[]', name: 'goals', type: 'uint64[]' },
          { internalType: 'address', name: 'creator', type: 'address' },
          { internalType: 'bool', name: 'finalized', type: 'bool' },
          { internalType: 'bool', name: 'distributeRewards', type: 'bool' },
        ],
        indexed: false,
        internalType: 'struct IBasketballPithAreaNftGame.Game',
        name: '_params',
        type: 'tuple',
      },
    ],
    name: 'GameCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'gameId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'gameCreator', type: 'address' },
    ],
    name: 'GameUpdated',
    type: 'event',
  },
  { anonymous: false, inputs: [{ indexed: false, internalType: 'uint8', name: 'version', type: 'uint8' }], name: 'Initialized', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'gameId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'buyFor', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'quantityToBuy', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'currency', type: 'address' },
      { indexed: false, internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
    ],
    name: 'PurchaseToken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32' },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  { inputs: [], name: 'DEFAULT_ADMIN_ROLE', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function' },
  { inputs: [], name: 'OPERATOR_ROLE', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: '_gameId', type: 'uint256' },
      { internalType: 'uint64[]', name: '_goals', type: 'uint64[]' },
    ],
    name: 'calculateRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_gameId', type: 'uint256' },
      { internalType: 'address', name: '_claimed', type: 'address' },
    ],
    name: 'claimedRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
    name: 'getGame',
    outputs: [
      {
        components: [
          { internalType: 'string', name: 'eventName', type: 'string' },
          { internalType: 'enum IBasketballPithAreaNftGame.CompetitionSide', name: 'competitionSide', type: 'uint8' },
          { internalType: 'uint256', name: 'mintTokenForEachArea', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenRewardsPerPoint', type: 'uint256' },
          { internalType: 'address', name: 'tokenRewardsAsset', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'reservePricePerToken', type: 'uint256' },
          { internalType: 'uint256', name: 'startSellingAt', type: 'uint256' },
          { internalType: 'uint256', name: 'endSellingAt', type: 'uint256' },
          { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
          { internalType: 'uint64[]', name: 'goals', type: 'uint64[]' },
          { internalType: 'address', name: 'creator', type: 'address' },
          { internalType: 'bool', name: 'finalized', type: 'bool' },
          { internalType: 'bool', name: 'distributeRewards', type: 'bool' },
        ],
        internalType: 'struct IBasketballPithAreaNftGame.Game',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_gameId', type: 'uint256' }],
    name: 'getRemainingTokensForGame',
    outputs: [{ internalType: 'uint256', name: 'totalRestTokens', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'getRoleMember',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleMemberCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'gameId', type: 'uint256' }],
    name: 'getTokenIds',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_defaultAdmin', type: 'address' },
      { internalType: 'address', name: '_nativeTokenWrapper', type: 'address' },
      { internalType: 'address', name: '_nftContractAddress', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'string', name: 'eventName', type: 'string' },
          { internalType: 'string[]', name: 'uris', type: 'string[]' },
          { internalType: 'enum IBasketballPithAreaNftGame.CompetitionSide', name: 'competitionSide', type: 'uint8' },
          { internalType: 'uint256', name: 'mintTokenForEachArea', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenRewardsPerPoint', type: 'uint256' },
          { internalType: 'address', name: 'tokenRewardsAsset', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'reservePricePerToken', type: 'uint256' },
          { internalType: 'uint256', name: 'startSellingAt', type: 'uint256' },
          { internalType: 'uint256', name: 'endSellingAt', type: 'uint256' },
        ],
        internalType: 'struct IBasketballPithAreaNftGame.GameParameters',
        name: '_params',
        type: 'tuple',
      },
    ],
    name: 'initializeGame',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nftContract',
    outputs: [{ internalType: 'contract IBasketballPithAreaNftLight', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_gameId', type: 'uint256' },
      { internalType: 'address', name: '_buyFor', type: 'address' },
      { internalType: 'uint256', name: '_quantityToBuy', type: 'uint256' },
      { internalType: 'address', name: '_currency', type: 'address' },
      { internalType: 'uint256', name: '_totalPrice', type: 'uint256' },
    ],
    name: 'purchaseToken',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'rewards',
    outputs: [
      { internalType: 'uint256', name: 'gameId', type: 'uint256' },
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint256', name: 'totalReward', type: 'uint256' },
      { internalType: 'bool', name: 'claimed', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_gameId', type: 'uint256' },
      { internalType: 'string', name: '_eventName', type: 'string' },
      { internalType: 'enum IBasketballPithAreaNftGame.CompetitionSide', name: '_competitionSide', type: 'uint8' },
      { internalType: 'uint256', name: '_tokenRewardsPerPoint', type: 'uint256' },
      { internalType: 'address', name: '_tokenRewardsAsset', type: 'address' },
      { internalType: 'address', name: '_currencyToAccept', type: 'address' },
      { internalType: 'uint256', name: '_reservePricePerToken', type: 'uint256' },
      { internalType: 'uint256', name: '_startSellingAt', type: 'uint256' },
      { internalType: 'uint256', name: '_endSellingAt', type: 'uint256' },
    ],
    name: 'updateGame',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
