const DEV_ENV = process.env.REACT_APP_ENV === 'development';

export const availableFTO: any = {
	RSB: {
		tokenImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/balance-coins.png',
		balanceImage: 'https://s3.eu-central-1.amazonaws.com/nft.prod.blocksport.io/fantoken-images-to-aws/fantoken-images/fantoken.png',
		title: 'Red Star Belgrade RLK',
		tokenAddress: DEV_ENV ? '0x43a217C620EF800A2755D322e0C8b3cf86aF1465' : '0x2D255400F3d85ebF06968616A0ebe393edAa2949',
		Link: '/fan-token/RSBRLK',
		prefix: '$',
		nickname: 'RSBRLK',
		status: 'tba',
	},
	PTZT: {
		tokenImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/PartizanBC/BalancePTZTImage.png',
		balanceImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/PartizanBC/PTZTTokenImage.png',
		title: 'Partizan FTO',
		tokenAddress: DEV_ENV ? '0x503728FC123802f2a4F8FF2FBd48c4668088D034' : '0xa2d20e93A8BF2bA6328378dC213592c77c74bF9A',
		Link: '/fan-token/PTZT',
		prefix: '$',
		nickname: 'PartizanBC',
		status: 'live',
	},
	EMP: {
		tokenImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/EmpoliFC/BalanceEMPImage.png',
		balanceImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/EmpoliFC/EMPTokenImage.png',
		title: 'Empoli FC',
		tokenAddress: DEV_ENV ? '0x35A1BA43E141A2874834095BBBe7E462c0437717' : '0x0c575e2856DfdeDfF6CbA72fA3117f0ffDc870Df',
		Link: '/fan-token/EMP',
		prefix: '$',
		nickname: 'EmpoliCalcio',
		status: 'live',
	},
	SRDT: {
		tokenImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/RedDevils/reddevils-two-tokens.png',
		balanceImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/RedDevils/reddevils-transaction-token.png',
		title: 'Salford Red Devils',
		tokenAddress: DEV_ENV ? '0xA8eF1Bc1AF20477Ec654A3126a66DA7A5A36e3a5' : '0x51A339E0997e2c5a039e5f0959980A69C54653Db',
		Link: '/fan-token/SRDT',
		prefix: '',
		nickname: 'SalfordRedDevils',
		status: 'tba',
	},
	TEAMBGR: {
		tokenImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/BFU/bfu-two-tokens.png',
		balanceImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/BFU/bfu-transaction-token.png',
		title: 'Team Bulgaria',
		tokenAddress: DEV_ENV ? '0xA837aEcA0E0baD09e7aae9243E1012e6C5aAa28f' : '0x6Ec121dea69BEC3548AaA95EFE069B3CE98f6472',
		Link: '/fan-token/TEAMBGR',
		prefix: '#',
	},
	KRUT: {
		tokenImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/KRUT/krut-two-tokens.png',
		balanceImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/KRUT/krut-transaction-token.png',
		title: 'Kenya Rugby Union',
		tokenAddress: DEV_ENV ? '0x89E0c97d8F054D5098c9770F62AE75609896Ce93' : '0xFd9270De89bde201F0cC00fAAe40dF40B22157fE',
		Link: '/fan-token/KRUT',
		prefix: '$',
	},
	SAHA: {
		tokenImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/SAHA/two-tokens.png',
		balanceImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/SAHA/transaction-token.png',
		title: 'SA Hockey Association',
		tokenAddress: DEV_ENV ? '0x3bC181B8e64c58f8e8B0A4Ad9D2b340Bc1b5B4cF' : '0x7168Dc293463f0c622D066f5a6B64CF8f8937F64',
		Link: '/fan-token/SAHA',
		prefix: '$',
		nickname: 'SAHA_NFT',
		status: 'tba',
	},
	// UCFC: {
	// 	tokenImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/SAHA/two-tokens.png',
	// 	balanceImage: 'https://prod-blocksport-nft-data.s3.eu-central-1.amazonaws.com/site-fantokens/SAHA/transaction-token.png',
	// 	title: 'United City FC Fan Token',
	// 	tokenAddress: DEV_ENV ? '0x3bC181B8e64c58f8e8B0A4Ad9D2b340Bc1b5B4cF' : '0x7168Dc293463f0c622D066f5a6B64CF8f8937F64',
	// 	Link: '/fan-token/UCFC',
	// 	prefix: '$',
	// 	nickname: 'UCFC',
	// 	status: 'tba',
	// },
};
