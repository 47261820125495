import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MoonpayState } from './types';

const initialState: MoonpayState = {
  moonpayURL: '',
  loading: false,
  error: false,
  isOpen: false,
};

const { actions, reducer } = createSlice({
  name: 'moonpay',
  initialState,
  reducers: {
    signMoonpayURLRequest: (state, action: PayloadAction<{ originUrl: string }>) => {
      state.loading = true;
      state.error = false;
    },
    signMoonpayURLSuccess: (state, action: PayloadAction<{ link: string }>) => {
      state.loading = false;
      state.moonpayURL = action.payload.link;
    },
    signMoonpayURLFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    toggleMoonpayModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpen = payload;
    },
  },
});

export { actions as MoonpayActions };
export default reducer;
