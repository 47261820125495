import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		Editor: {
			'& .editorClassName': {
				minHeight: 325,
				border: '1px solid #E6E6E6',
				padding: '30px 30px 0',
			},
		},
	};
});
