import moment from 'moment';
import { format, parseISO } from 'date-fns';
import { BSPTOption } from 'entities/Options';

export const formatPeriod = (period: number, balance?: string | number) => {
  const isClaimRewards = balance ? balance !== '0' : '0';

  if (period) {
    const currentTime = new Date().getTime();

    const distanceToDate = new Date(period * 1000).getTime() - currentTime;

    const days: string | number = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
    let hours: string | number = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes: string | number = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));

    const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    if (numbersToAddZeroTo.includes(hours)) {
      hours = `0${hours}`;
    }

    if (numbersToAddZeroTo.includes(minutes)) {
      minutes = `0${minutes}`;
    }

    return {
      period,
      balance,
      isClaimRewards,
      days: days > 0 ? days : 0,
      hours: hours > 0 ? hours : 0,
      minutes: minutes > 0 ? minutes : 0,
    };
  }
};

export const formatTimer = (endTime: number | null) => {
  let timer = null;

  if (endTime) {
    const calcEndTime = moment.unix(endTime).format('YYYY/MM/DD HH:mm:ss');
    const calcStartTime = moment(new Date()).format('YYYY/MM/DD HH:mm:ss');
    const endTimer = moment.unix(endTime).format('YYYY/MM/DD HH:mm');

    const duration = moment.duration(moment(calcEndTime).diff(moment(calcStartTime)));

    const hours = Math.floor(+duration.asHours());
    const minutes = +duration.minutes();
    const seconds = +duration.seconds();
    const endDate = moment(calcEndTime).format('MMMM DD YYYY');

    timer = { endTime: endTimer, endDate, hours, minutes, seconds, isClaim: false };

    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      timer = { endTime: endTimer, endDate, hours: 0, minutes: 0, seconds: 0, isClaim: true };
    }
  }

  return timer;
};

export const formatDate = (date: string) => {
  const data = moment(date).format('MMMM D, YYYY');
  const time = moment(date).format('h:mma');

  return `${data} at ${time}`;
};

export const roundPriceToFixed = (number: number, decimals: number = 5) => {
  return parseFloat(number.toFixed(decimals)).toString();
};

export const formatFromTimestamp = (timestamp: string) => {
  const data = moment(Number(timestamp), 'X').format('MMMM D, YYYY');
  const time = moment(Number(timestamp), 'X').format('h:mma');

  return `${data} at ${time}`;
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatBSPTOptions = (options: BSPTOption[]) => {
  return options.map((bspt: BSPTOption) => {
    return {
      label: `${bspt.amount} BSPT`,
      value: `${bspt.depositId}`,
    };
  });
};

export const formatUnlistOptions = (options: any[], address: string) => {
  if (options.length === 0 || address === '') return;
  const filterd = options.filter((item) => item.ownerWallet.toLowerCase() === address.toLowerCase());
  return filterd.length > 0
    ? filterd.map((seller: any) => {
        return {
          label: `${seller.tokenAmount}`,
          value: `${seller.auctionId}`,
        };
      })
    : [];
};

export const roundAmountToFixed = (amount: number) => {
  return parseFloat(Number(amount).toFixed(2));
};

export const getCurrencySymbol = (currency: string) => {
  let symbol;
  switch (currency.toLowerCase()) {
    case 'usd':
      symbol = '$';
      break;
    case 'eur':
      symbol = '€';
      break;
    case 'gbp':
      symbol = '£';
      break;
    case 'bgn':
      symbol = 'BGN';
      break;
    case 'chf':
      symbol = 'CHF';
      break;
    case 'uah':
      symbol = '₴';
      break;
    default:
      symbol = '$';
  }
  return symbol;
};

export const format2LongDate = (date: string) => {
  return format(parseISO(date), 'PPpp');
};

export const formatPrice = (price: number, currency: string) => {
  const locale = navigator.language;
  return price.toLocaleString(locale, {
    style: 'currency',
    currency,
  });
};

export const toLocalDateTimeString = (date: Date) => {
  const locale = navigator.language;
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false } as const;
  return date.toLocaleDateString(locale, options);
};

export const toLocalDateString = (date: Date) => {
  const locale = navigator.language;
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' } as const;
  return date.toLocaleDateString(locale, options);
};

export const hexToDec = (hex: string) => {
  return parseInt(hex, 16);
};
