import { combineReducers, Slice } from '@reduxjs/toolkit';

export function combineSliceReducers<T extends Record<string, Slice>>(slices: T) {
  return combineReducers(
    Object.entries(slices).reduce(
      (combinedReducer, [name, { reducer }]) => ({
        ...combinedReducer,
        [name]: reducer,
      }),
      {},
    ) as { [K in keyof T]: T[K]['reducer'] },
  );
}

export function combineSliceActions<T extends Record<string, Slice>>(slices: T) {
  return Object.entries(slices).reduce(
    (combinedActions, [name, { actions }]) => ({
      ...combinedActions,
      [name]: actions,
    }),
    {},
  ) as { [K in keyof T]: T[K]['actions'] };
}
