export const stakeNFTABI = [
  {
    inputs: [
      { internalType: 'uint32', name: 'cycleLengthInSeconds_', type: 'uint32' },
      { internalType: 'uint16', name: 'periodLengthInCycles_', type: 'uint16' },
      {
        internalType: 'contract IERC1155721Transferrable',
        name: 'whitelistedNftContract_',
        type: 'address',
      },
      { internalType: 'contract IERC20', name: 'rewardsTokenContract_', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { anonymous: false, inputs: [], name: 'Disabled', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'staker', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'startCycle', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'stakerStake', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'globalStake', type: 'uint256' },
    ],
    name: 'HistoriesUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'staker', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'cycle', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'weight', type: 'uint256' },
    ],
    name: 'NftStaked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'staker', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'cycle', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'weight', type: 'uint256' },
    ],
    name: 'NftUnstaked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'staker', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'cycle', type: 'uint256' },
      { indexed: false, internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      { indexed: false, internalType: 'uint256[]', name: 'weights', type: 'uint256[]' },
    ],
    name: 'NftsBatchStaked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'staker', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'cycle', type: 'uint256' },
      { indexed: false, internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
      { indexed: false, internalType: 'uint256[]', name: 'weights', type: 'uint256[]' },
    ],
    name: 'NftsBatchUnstaked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'startPeriod', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'endPeriod', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'rewardsPerCycle', type: 'uint256' },
    ],
    name: 'RewardsAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'staker', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'cycle', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'startPeriod', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'periods', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'RewardsClaimed',
    type: 'event',
  },
  { anonymous: false, inputs: [], name: 'Started', type: 'event' },
  {
    inputs: [
      { internalType: 'uint16', name: 'startPeriod', type: 'uint16' },
      { internalType: 'uint16', name: 'endPeriod', type: 'uint16' },
      { internalType: 'uint256', name: 'rewardsPerCycle', type: 'uint256' },
    ],
    name: 'addRewardsForPeriods',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' }],
    name: 'batchUnstakeNfts',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint16', name: 'maxPeriods', type: 'uint16' }],
    name: 'claimRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'cycleLengthInSeconds',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'disable', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'enabled',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint16', name: 'maxPeriods', type: 'uint16' }],
    name: 'estimateRewards',
    outputs: [
      { internalType: 'uint16', name: 'startPeriod', type: 'uint16' },
      { internalType: 'uint16', name: 'periods', type: 'uint16' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCurrentCycle',
    outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCurrentPeriod',
    outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'globalHistory',
    outputs: [
      { internalType: 'uint128', name: 'stake', type: 'uint128' },
      { internalType: 'uint128', name: 'startCycle', type: 'uint128' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'lastGlobalSnapshotIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'staker', type: 'address' }],
    name: 'lastStakerSnapshotIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'nextClaims',
    outputs: [
      { internalType: 'uint16', name: 'period', type: 'uint16' },
      { internalType: 'uint64', name: 'globalSnapshotIndex', type: 'uint64' },
      { internalType: 'uint64', name: 'stakerSnapshotIndex', type: 'uint64' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'uint256', name: 'id', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'operator', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
      { internalType: 'bytes[]', name: '', type: 'bytes[]' },
    ],
    name: 'onERC721ReceivedBatch',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'periodLengthInCycles',
    outputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'rewardsSchedule',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'rewardsTokenContract',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'stakerHistories',
    outputs: [
      { internalType: 'uint128', name: 'stake', type: 'uint128' },
      { internalType: 'uint128', name: 'startCycle', type: 'uint128' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'start', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'startTimestamp',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'tokenInfos',
    outputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'uint64', name: 'weight', type: 'uint64' },
      { internalType: 'uint16', name: 'depositCycle', type: 'uint16' },
      { internalType: 'uint16', name: 'withdrawCycle', type: 'uint16' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalRewardsPool',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'unstakeNft',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'whitelistedNftContract',
    outputs: [{ internalType: 'contract IERC1155721Transferrable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint16', name: 'cycle', type: 'uint16' },
      { internalType: 'int256', name: 'globalSnapshotIndex', type: 'int256' },
    ],
    name: 'withdrawLostCycleRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'withdrawRewardsPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'withdrawnLostCycles',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
];
