import { rgba } from 'polished';

export const white = '#FFFFFF';
export const black = '#000000';

// Primary
export const royalBlue = '#4884e8';
export const mineShaft = '#292929';
export const athensGray = '#e7f0ff';
export const cinderella = '#fbe0d9';
export const hawkesBlue = '#e9edfe';
export const malibu = '#76B8FF';
export const anzac = '#E0AA40';
export const bilbao = '#14731d';
export const monza = '#DC0020';
export const darkerGray = '#252525';
export const gray = '#8d929b';

// Secondary
export const logan = '#b3abcb';
export const waterLoo = '#465e88';
export const alto = '#d4d4d4';
export const mercury = '#e9e9e9';
export const silver = '#c9c9c9';
export const jumbo = '#767579';
export const catskillWhite = '#f5f7fa';
export const mandysPink = '#f6c6bc';
export const surf = '#baddc3';
export const athensGray2 = '#e3e7ed';
export const lightTangelo = '#FFAA80';

// Custom
export const yankeesBlue = '#132640';
export const sunray = '#DEBC5A';
export const darkSunray = '#B29236';
export const oldSilver = '#828282';
export const darkCharcoal = '#333333';
export const bsRed = '#FE6450';
export const bsBlue = '#3889FA';
export const mariner = '#2B7ED9';

export const gradientStart = '#408FFF';
export const gradientEnd = '#F224D0';
export const buttonGradient = `linear-gradient(90deg, ${gradientStart} 0%, ${gradientEnd} 100%)`;

const colors = {
  primary: mineShaft,
  secondary: royalBlue,
  background: athensGray,
  primaryButtonGradient: buttonGradient,

  // Profile
  profileBackground: white,
  profileBorder: logan,

  // Buttons
  containedPrimaryBtnDisabledBg: rgba(royalBlue, 0.4),
  containedPrimaryBtnDisabledText: white,
  containedSecondaryBtnDisabledBg: rgba(white, 0.4),
  containedSecondaryBtnDisabledText: white,
  containedBtnText: white,
  containedBtnHoverBg: rgba(royalBlue, 0.9),
  primaryBtnBg: rgba(royalBlue, 0.95),
  primaryBtnHoverBg: rgba(royalBlue, 0.9),
  secondaryBtnBg: white,
  secondaryBtnText: royalBlue,
  secondaryBtnHoverBg: rgba(white, 0.9),
  secondaryLargeBtnHoverText: royalBlue,
  outlinedBtnDisabledBorder: rgba(royalBlue, 0.4),
  outlinedBtnDisabledText: rgba(royalBlue, 0.6),
  outlinedPrimaryBtnText: royalBlue,
  outlinedPrimaryBtnBorder: royalBlue,
  outlinedPrimaryBtnHoverBg: royalBlue,
  outlinedPrimaryBtnHoverBorder: royalBlue,
  outlinedPrimaryBtnHoverText: white,
  outlinedSecondaryBtnHoverBg: royalBlue,
  outlinedSecondaryBtnHoverText: white,
  outlinedSecondaryBtnDisabledBorder: mercury,
  outlinedSecondaryBtnDisabledText: silver,

  // Divider
  backgroundColor: alto,

  // Upload
  borderUpload: rgba(logan, 0.5),
  borderUploadError: rgba(monza, 0.5),
  borderUploadRight: rgba(logan, 0.5),

  // Card
  borderCard: alto,
  boxShadow: rgba(jumbo, 0.15),

  // Stepper
  completeLabel: waterLoo,
  activeLabel: mineShaft,
  label: mineShaft,

  // Form
  inputBorder: rgba(logan, 0.6),
  inputPlaceholder: waterLoo,
  inputBorderHover: logan,
  inputBorderFocused: logan,
  inputBorderError: monza,
  inputHelperError: monza,
  inputDisabledBorder: mercury,
  inputDisabledText: mercury,
  selectOption: mineShaft,
  selectOptionHover: royalBlue,
  inputOutlinedColor: royalBlue,
  inputOutlinedBorder: royalBlue,
  inputOutlinedBorderHover: royalBlue,
};

export default colors;
