import { put, select } from 'redux-saga/effects';
import {
  erc20Actions,
} from '../reducers/erc20';
import { NetworkStateInterface } from "../reducers/network/types";
import web3 from "../../helpers/getWeb3";
import { getBlockchainABI } from "../../contracts";
import { fromWei, toWei } from "../../helpers/web3";
import { AlertActions } from '../reducers/alert'
import { sliceTruncateHash } from '../../helpers/address'

type ContractType = {
  methods: {
    balanceOf: (address: string) => { call: () => Promise<string> };
    allowance: (address: string, address2: string) => { call: () => Promise<boolean> };
    approve: (address: string, amount: string) => { call: () => Promise<boolean>
      encodeABI(): string;
    };
  };
};

export function* getAllowanceSaga(action: any) {
  const { addressOwner, tokenAddress, addressDestination } = action.payload;
  try {
    const { network }: NetworkStateInterface = yield select((state) => state.network);

    const tokenContract: ContractType = yield new web3.eth.Contract(getBlockchainABI(network, 'stakeBSPT'), tokenAddress);
    const result: string = yield tokenContract.methods.allowance(addressOwner, addressDestination).call();
    const allowance = +fromWei(result);
    yield put(erc20Actions.getAllowanceSuccess({ tokenAddress, addressDestination, allowance }));
  } catch (error) {
    // @ts-ignore
    yield put(erc20Actions.getAllowanceError({ tokenAddress, addressDestination, error: error.message  }));
  }
}

export function* approveSaga(action: any) {
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  const { addressOwner, tokenAddress, addressDestination, amount } = action.payload;

  const amountWei = toWei(amount.toString());

  try {
    const tokenContract: ContractType = yield new web3.eth.Contract(getBlockchainABI(network, 'stakeBSPT'), tokenAddress);
    const gasPrice:number = yield web3.eth.getGasPrice();

    const { transactionHash } = yield web3.eth.sendTransaction({
      to: tokenAddress,
      from: addressOwner,
      gasPrice,
      data: tokenContract.methods.approve(addressDestination, amountWei).encodeABI(),
    });

    yield put(erc20Actions.approveSuccess({ tokenAddress, addressDestination, hash: transactionHash }));
    yield put(erc20Actions.getAllowanceRequest({ addressOwner, tokenAddress, addressDestination }));
    yield put(AlertActions.showAlert({ message: `Approve success tx: ${sliceTruncateHash(transactionHash)}`, severity: 'success' }));

  } catch (error: any) {
    yield put(AlertActions.showAlert({ message: error.message, severity: 'error' }));
    yield put(erc20Actions.approveError({ tokenAddress, addressDestination, error: error.message  }));
  }
}
