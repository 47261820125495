import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { white, lightTangelo } from 'constants/colors';

export const useStyles = makeStyles()((theme: Theme) => {
	return {
		root: {},
		block: {
			flexDirection: 'column',

			[theme.breakpoints.up('lg')]: {
				flexDirection: 'row',
			},
		},
		input: {
			width: '100%',
			paddingBottom: 32,
			position: 'relative',

			'& .MuiInputBase-input': {
				margin: 0,
				padding: '12px 19px',

				fontWeight: 500,
				fontSize: '1.6rem',
				lineHeight: '2.6rem',

				background: 'transparent',
				borderBottom: '1px solid #FFFFFF',
				color: white,
				marginLeft: 15,
				marginRight: 15,
				paddingLeft: 0,
				textAlign: 'center',

				'&::placeholder': {
					color: 'inherit',
				},
				'&.Mui-error input': {
					fontSize: '1.6rem',
				},
			},
			'& .MuiInput-root': {
				'&:before, &:after': {
					display: 'none',
				},
			},
			'& .MuiOutlinedInput-root': {
				'&:hover': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: white,
					},
				},
				'&.Mui-error .MuiOutlinedInput-notchedOutline': {
					borderColor: `${lightTangelo}!important`,
				},
			},

			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: `${white}!important`,
				borderRadius: 30,
			},

			'& .MuiFormHelperText-root': {
				position: 'absolute',
				bottom: -32,
				left: 0,

				'&.Mui-error': {
					fontWeight: 400,
					fontSize: 18,
					lineHeight: '32px',
					letterSpacing: '0.02em',
					color: lightTangelo,
				},
			},

			[theme.breakpoints.up('sm')]: {},
			[theme.breakpoints.up('lg')]: {
				marginRight: theme.spacing(4),

				'& .MuiInputBase-input': {
					borderBottom: 0,
					background: white,
					color: '#828282',
					borderRadius: 30,
					marginLeft: 0,
					marginRight: 0,
					paddingLeft: 19,
					textAlign: 'left',
				},
			},
		},
		action: {
			'& .MuiButton-containedPrimary': {
				width: '100%',
				maxHeight: 42,
				minWidth: 180,
			},

			[theme.breakpoints.up('lg')]: {
				'& .MuiButton-containedPrimary': {
					width: 162,
					maxHeight: 50,
				},
			},
		},
	};
});
