import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FollowSubscribe } from 'entities/Account';
import { SubscribingState } from './types';

const initialState: SubscribingState = {
  list: [],
  loading: true,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'subscribing',
  initialState,
  reducers: {
    getSubscribingListRequest: (state, { payload }: PayloadAction<{ fanId: number }>) => {
      state.loading = true;
      state.error = false;
    },
    getSubscribingListSuccess: (state, { payload }: PayloadAction<{ data: FollowSubscribe[] }>) => {
      state.list = payload.data;
      state.loading = false;
    },
    getSubscribingListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    addSubscribingRequest: (
      state,
      { payload }: PayloadAction<{ fanId: number; celebrityId: number }>,
    ) => {
      state.loading = true;
      state.error = false;
    },
    addSubscribingSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    addSubscribingFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    deleteSubscribingRequest: (
      state,
      { payload }: PayloadAction<{ fanId: number; celebrityId: number }>,
    ) => {
      state.loading = true;
      state.error = false;
    },
    deleteSubscribingSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    deleteSubscribingFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    resetSubscribingList: (state) => {
      state.list = []
    },
  },
});

export { actions as SubscribingActions };

export default reducer;
