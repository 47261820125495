import { put } from 'redux-saga/effects';
import { MetaMaskActions } from 'store/reducers/metamask';

declare const window: any;

export function* checkMetaMaskSaga() {
  const role: string = yield localStorage.getItem('role');
  if (!role) {
    yield localStorage.setItem('role', 'fan');
  }
  yield put(MetaMaskActions.setIsMetamask(!!window.ethereum));
}
