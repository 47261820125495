import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPFSState } from './types';
import { UploadData, UploadFile } from '../../../entities/IPFS';

const initialState: IPFSState = {
  loading: false,
  error: false,
  path: '',
  fileHash: '',
  dataHash: '',
  uploadProgress: null,
};

const { actions, reducer } = createSlice({
  name: 'ipfs',
  initialState,
  reducers: {
    setInitial: (state) => {
      state.loading = false;
      state.error = false;
      state.path = '';
      state.fileHash = '';
      state.dataHash = '';
      state.uploadProgress = null;
    },

    uploadFileRequest: (state, { payload }: PayloadAction<UploadFile>) => {
      state.loading = true;
      state.error = false;
    },
    uploadFileSuccess: (state, { payload: { hash, path } }: PayloadAction<{ hash: string; path: string }>) => {
      state.fileHash = hash;
      state.path = path;
      state.loading = false;
      state.error = false;
    },
    uploadFileFailure: (state) => {
      state.error = true;
      state.loading = false;
    },

    setUploadProgress: (state, { payload: { progress } }: PayloadAction<{ progress: number }>) => {
      state.uploadProgress = progress;
    },

    uploadDataRequest: (state, { payload }: PayloadAction<UploadData>) => {
      state.loading = true;
      state.error = false;
    },

    uploadDataSuccess: (state, { payload: { dataHash } }: PayloadAction<{ dataHash: string }>) => {
      state.dataHash = dataHash;
      state.loading = false;
      state.error = false;
    },
    uploadDataFailure: (state) => {
      state.error = true;
    },
  },
});

export { actions as IPFSActions };

export default reducer;
