import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put, select } from 'redux-saga/effects';
import { FeedActions } from '../reducers/feed';

export function* getPostsSaga(action: ReturnType<typeof FeedActions.getPostsRequest>) {
  const { celebrityId, query, append } = action.payload;

  try {
    const {
      data: {
        data,
        meta: { total },
      },
    } = yield axios.get(getLink(endpoints.GET_POSTS, { celebrityId }), {
      params: query,
    });
    yield put(FeedActions.getPostsSuccess({ posts: data, total, append }));
  } catch {
    yield put(FeedActions.getPostsFailure());
  }
}

export function* addPostSaga(action: ReturnType<typeof FeedActions.addPostRequest>) {
  const { data } = action.payload;
  const selector = (state: AppState) => state.account;
  const { account }: ReturnType<typeof selector> = yield select(selector);

  if (account?.id) {
    try {
      const { data: response } = yield axios.post(
        getLink(endpoints.GET_POSTS, { celebrityId: account.id }),
        data,
      );
      yield put(FeedActions.addPostSuccess(response.data));
    } catch {
      yield put(FeedActions.addPostFailure());
    }
  }
}

export function* editPostSaga(action: ReturnType<typeof FeedActions.editPostRequest>) {
  const { id, data } = action.payload;
  const selector = (state: AppState) => state.account;
  const { account }: ReturnType<typeof selector> = yield select(selector);

  if (account?.id) {
    try {
      const { data: response } = yield axios.put(
        getLink(endpoints.GET_POST, { celebrityId: account.id, id }),
        data,
      );
      yield put(FeedActions.editPostSuccess(response.data));
    } catch {
      yield put(FeedActions.editPostFailure());
    }
  }
}

export function* deletePostSaga(action: ReturnType<typeof FeedActions.deletePostRequest>) {
  const { id } = action.payload;
  const selector = (state: AppState) => state.account;
  const { account }: ReturnType<typeof selector> = yield select(selector);

  if (account?.id) {
    try {
      yield axios.delete(getLink(endpoints.GET_POST, { celebrityId: account.id, id }));
      yield put(FeedActions.deletePostSuccess({ id }));
    } catch {
      yield put(FeedActions.deletePostFailure());
    }
  }
}
