import React from 'react';

import { RouteProps } from 'react-router-dom';

import Route from 'shared/components/Route';

export interface PublicRouteProps {
  children: React.ReactElement;
  component: any;
  fullwidth?: boolean;
}

export default function PublicRoute(props: RouteProps & Omit<PublicRouteProps, 'children'>) {
  const { component: Component, ...rest } = props;

  return <Route {...rest} component={Component} />;
}

PublicRoute.defaultProps = {
  fullwidth: false,
};
