import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { RouteProps } from 'react-router-dom';

import Loader from 'shared/components/Loader';

import PrivateRoute from '../routes/components/PrivateRoute';
import PublicRoute from '../routes/components/PublicRoute';

export interface PrivateRouteProps {
  component: any;
  children: React.ReactElement;
  roles?: string[];
  protection?: boolean;
  fullwidth?: boolean;
  allowedForAnonymouse?: boolean;
}

const loader = <Loader />;

function LayoutRoute({ roles, protection, component: Component, fullwidth, ...rest }: RouteProps & Omit<PrivateRouteProps, 'children'>) {
  const {
    network: { loading },
  } = useSelector((state) => state);

  const Structure = (
    <Suspense fallback={loader}>
      <Component />
    </Suspense>
  );

  if (!loading) {
    return protection ? (
      <PrivateRoute {...rest} roles={roles} fullwidth={fullwidth} component={Structure} />
    ) : (
      <PublicRoute {...rest} fullwidth={fullwidth} component={Structure} />
    );
  }
  return loader;
}

LayoutRoute.defaultProps = {
  protection: false,
  fullwidth: false,
  roles: [],
};

export default LayoutRoute;
