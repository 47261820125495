export const auctionABI = [
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'primaryBlocksportFeeBasisPoints', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'secondaryBlocksportFeeBasisPoints', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'secondaryCreatorFeeBasisPoints', type: 'uint256' },
    ],
    name: 'MarketFeesUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'reason', type: 'string' },
    ],
    name: 'ReserveAuctionBannedByAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'bidder', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'endTime', type: 'uint256' },
    ],
    name: 'ReserveAuctionBidPlaced',
    type: 'event',
  },
  { anonymous: false, inputs: [{ indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' }], name: 'ReserveAuctionCanceled', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'reason', type: 'string' },
    ],
    name: 'ReserveAuctionCanceledByAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'minPercentIncrementInBasisPoints', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'maxBidIncrementRequirement', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'duration', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'extensionDuration', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'goLiveDate', type: 'uint256' },
    ],
    name: 'ReserveAuctionConfigUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'seller', type: 'address' },
      { indexed: true, internalType: 'address', name: 'nftContract', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'duration', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'extensionDuration', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'reservePrice', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
    ],
    name: 'ReserveAuctionCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'seller', type: 'address' },
      { indexed: true, internalType: 'address', name: 'bidder', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'blsFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'creatorFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'ownerRev', type: 'uint256' },
    ],
    name: 'ReserveAuctionFinalized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' }],
    name: 'ReserveAuctionUnbannedByAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'reservePrice', type: 'uint256' },
    ],
    name: 'ReserveAuctionUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'string', name: 'reason', type: 'string' },
    ],
    name: 'TokenBannedByAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'TokenUnbannedByAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'WithdrawPending',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'Withdrawal',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: 'nftContract', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'string', name: 'reason', type: 'string' },
    ],
    name: 'adminBanToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { internalType: 'string', name: 'reason', type: 'string' },
    ],
    name: 'adminCancelReserveAuction',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'nftContract', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'adminUnbanToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'minPercentIncrementInBasisPoints', type: 'uint256' },
      { internalType: 'uint256', name: 'duration', type: 'uint256' },
      { internalType: 'uint256', name: 'primaryblsFeeBasisPoints', type: 'uint256' },
      { internalType: 'uint256', name: 'secondaryblsFeeBasisPoints', type: 'uint256' },
      { internalType: 'uint256', name: 'secondaryCreatorFeeBasisPoints', type: 'uint256' },
    ],
    name: 'adminUpdateConfig',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'bannedTokens',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'auctionId', type: 'uint256' }],
    name: 'cancelReserveAuction',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'nftContract', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'uint256', name: 'reservePrice', type: 'uint256' },
    ],
    name: 'createReserveAuction',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'auctionId', type: 'uint256' }],
    name: 'finalizeReserveAuction',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getBlocksportTreasury',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getFeeConfig',
    outputs: [
      { internalType: 'uint256', name: 'primaryBlocksportFeeBasisPoints', type: 'uint256' },
      { internalType: 'uint256', name: 'secondaryBlocksportFeeBasisPoints', type: 'uint256' },
      { internalType: 'uint256', name: 'secondaryCreatorFeeBasisPoints', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'nftContract', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'uint256', name: 'price', type: 'uint256' },
    ],
    name: 'getFees',
    outputs: [
      { internalType: 'uint256', name: 'blocksportFee', type: 'uint256' },
      { internalType: 'uint256', name: 'creatorSecondaryFee', type: 'uint256' },
      { internalType: 'uint256', name: 'ownerRev', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'nftContract', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'getIsPrimary',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'auctionId', type: 'uint256' }],
    name: 'getMinBidAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getPendingWithdrawal',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'auctionId', type: 'uint256' }],
    name: 'getReserveAuction',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'nftContract', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'address payable', name: 'seller', type: 'address' },
          { internalType: 'uint256', name: 'duration', type: 'uint256' },
          { internalType: 'uint256', name: 'extensionDuration', type: 'uint256' },
          { internalType: 'uint256', name: 'endTime', type: 'uint256' },
          { internalType: 'address payable', name: 'bidder', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        internalType: 'struct NFTMarketReserveAuction.ReserveAuction',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getReserveAuctionConfig',
    outputs: [
      { internalType: 'uint256', name: 'minPercentIncrementInBasisPoints', type: 'uint256' },
      { internalType: 'uint256', name: 'duration', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'nftContract', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'getReserveAuctionIdFor',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address payable', name: 'treasury', type: 'address' }],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [{ internalType: 'uint256', name: 'auctionId', type: 'uint256' }], name: 'placeBid', outputs: [], stateMutability: 'payable', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { internalType: 'uint256', name: 'reservePrice', type: 'uint256' },
    ],
    name: 'updateReserveAuction',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'withdraw', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'address payable', name: 'user', type: 'address' }],
    name: 'withdrawFor',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
