export const bscTestnetNode =
  'https://bsc-testnet-rpc.publicnode.com' ||
  'https://bsc-testnet.public.blastapi.io/' ||
  'https://data-seed-prebsc-1-s3.binance.org:8545' ||
  'https://data-seed-prebsc-1-s1.binance.org:8545' ||
  'https://data-seed-prebsc-2-s1.binance.org:8545' ||
  'https://data-seed-prebsc-1-s2.binance.org:8545' ||
  'https://data-seed-prebsc-2-s2.binance.org:8545' ||
  'https://data-seed-prebsc-2-s3.binance.org:8545';

export const polyTestnetNode = 'https://polygon-mumbai-pokt.nodies.app' || 'https://rpc-mumbai.maticvigil.com' || 'https://rpc.maticvigil.com';

export const bscMainnetNode = 'https://bsc-dataseed.binance.org' || 'https://bsc-dataseed1.binance.org' || 'https://bsc-dataseed1.ninicoin.io';

export const polyMainnetNode =
  'https://polygon-rpc.com' ||
  'https://rpc-mainnet.matic.network' ||
  'https://matic-mainnet.chainstacklabs.com' ||
  'https://rpc-mainnet.maticvigil.com' ||
  'https://rpc-mainnet.matic.quiknode.pro' ||
  'https://matic-mainnet-full-rpc.bwarelabs.com';

export const bscTestnetUrl = 'https://testnet.bscscan.com';
export const bscMainnetUrl = 'https://bscscan.com';

export const polyTestnetUrl = 'https://mumbai.polygonscan.com/';

export const ethTestnetUrl = 'https://goerli.etherscan.io/';
export const ethMainnetUrl = 'https://eth.public-rpc.com';
export const polyMainnetUrl = 'https://polygonscan.com/';

export const ethInfuraTestnetNode = 'https://rinkeby.infura.io/v3/';
export const ethAlchemyTestnetNode = 'https://eth-rinkeby.alchemyapi.io/v2/';
export const goerliTestnetUrl = 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
export const ethAlchemyMainnetNode = 'https://eth-mainnet.alchemyapi.io/v2/';

export const ethTestnetNode = 'https://rpc.sepolia.org/';

export const wbtTestnetNodeRpc = 'https://rpc-testnet.whitebit.network';
export const wbtMainnetNodeRpc = 'https://rpc.whitebit.network';

export const wbtTestnetNode = 'https://testnet.whitechain.io';
export const wbtMainnetNode = 'https://explorer.whitechain.io';

export const veriffSearchParam = 'veriffDocsSubmitted';
