import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListTokenAction, MintTokenAction, DetailTokenAction, Token } from 'entities/Token';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import { TokenState, StepState, ListedTokensState, ProfileTokensState, TransactionState, ListState, BidsState, MintedTokensState } from './types';

const token = {
  step: createSlice({
    name: 'token/step',
    initialState: {
      step: 0,
      isCreating: false,
    } as StepState,
    reducers: {
      setStep: (state, { payload }: PayloadAction<number>) => {
        state.step = payload;
      },
      setIsCreating: (state, { payload }: PayloadAction<boolean>) => {
        state.isCreating = payload;
      },
    },
  }),
  mint: createSlice({
    name: 'token/mint',
    initialState: {
      loading: false,
      error: false,
      hash: '',
      tokenId: '',
    } as TransactionState,
    reducers: {
      mintSingleRequest: (state, { payload }: PayloadAction<MintTokenAction>) => {
        state.loading = true;
        state.error = false;
      },
      mintSingleSuccess: (state, { payload: { hash, tokenId } }: PayloadAction<{ hash: string; tokenId: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
        state.tokenId = tokenId;
      },
      minSingleFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      mintCollectionRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      mintCollectionSuccess: (state, { payload: { hash, tokenId } }: PayloadAction<{ hash: string; tokenId: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
        state.tokenId = tokenId;
      },
      mintCollectionFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      setMintInitial: (state) => {
        state.loading = false;
        state.error = false;
        state.error = false;
        state.hash = '';
        state.tokenId = '';
      },
    },
  }),
  list: createSlice({
    name: 'token/list',
    initialState: {
      loading: { list: false, unlist: false },
      error: false,
      hash: '',
    } as ListState,
    reducers: {
      listSingleRequest: (state, { payload }: PayloadAction<ListTokenAction>) => {
        state.loading.list = true;
        state.error = false;
      },
      listSingleSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading.list = false;
        state.error = false;
        state.hash = hash;
      },
      listSingleFailure: (state) => {
        state.error = true;
        state.loading.list = false;
      },

      listCollectionRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading.list = true;
        state.error = false;
      },
      listCollectionSuccess: (state) => {
        state.loading.list = false;
        state.error = false;
      },
      listCollectionFailure: (state) => {
        state.error = true;
        state.loading.list = false;
      },

      unListTokenRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading.unlist = true;
        state.error = false;
      },
      unListTokenSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading.unlist = false;
        state.error = false;
        state.hash = hash;
      },
      unListTokenFailure: (state) => {
        state.error = true;
        state.loading.unlist = false;
      },

      unListCollectionRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading.unlist = true;
        state.error = false;
      },
      unListCollectionSuccess: (state) => {
        state.loading.unlist = false;
        state.error = false;
      },
      unListCollectionFailure: (state) => {
        state.error = true;
        state.loading.unlist = false;
      },
      winModalCloseRequest: (state, action: PayloadAction<{ nftId: number | string; userId: number; isCelebrity: boolean; tokenSource: string }>) => {},
      winModalCloseError: (state) => {
        state.error = true;
      },

      setListInitial: (state) => {
        state.loading.list = false;
        state.loading.unlist = false;
        state.error = false;
        state.error = false;
        state.hash = '';
      },
    },
  }),
  detail: createSlice({
    name: 'token/details',
    initialState: {
      data: null,
      buynowPrices: null,
      loading: { data: false, update: false, buyoutprice: false },
      error: false,
    } as TokenState,
    reducers: {
      getDetailRequest: (state, { payload }: PayloadAction<DetailTokenAction>) => {
        state.loading.data = true;
        state.error = false;
      },
      getDetailSuccess: (state, { payload: { data } }: PayloadAction<{ data: Token }>) => {
        state.loading.data = false;
        state.error = false;
        state.data = data;
      },
      getDetailFailure: (state) => {
        state.error = true;
        state.loading.data = false;
      },

      updatePriceRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading.update = true;
        state.error = false;
      },
      updatePriceSuccess: (state) => {
        state.loading.update = false;
        state.error = false;
      },
      updatePriceFailure: (state) => {
        state.error = true;
        state.loading.update = false;
      },
      resetToken: (state) => {
        state.data = null;
      },

      getDetailByIdRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading.data = true;
        state.error = false;
      },

      getBuyoutPriceRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading.buyoutprice = true;
        state.error = false;
      },
      getBuyoutPriceSuccess: (state, { payload: { prices } }: PayloadAction<{ prices: any }>) => {
        state.loading.buyoutprice = false;
        state.error = false;
        state.buynowPrices = prices;
      },
      getBuyoutPriceFailure: (state) => {
        state.error = true;
        state.loading.buyoutprice = false;
      },
    },
  }),
  profileTokens: createSlice({
    name: 'token/profileTokens',
    initialState: {
      profileTokens: [],
      loading: false,
      error: false,
    } as ProfileTokensState,
    reducers: {
      getProfileTokensRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getProfileTokensSuccess: (state, { payload: { tokens } }: PayloadAction<{ tokens: Token[] }>) => {
        state.loading = false;
        state.error = false;
        state.profileTokens = tokens;
      },
      getProfileTokensFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      getProfileTokensInit: (state) => {
        state.profileTokens = [];
        state.error = true;
        state.loading = false;
      },
    },
  }),
  mintedTokens: createSlice({
    name: 'token/mintedTokens',
    initialState: {
      mintedTokens: [],
      loading: false,
      error: false,
    } as MintedTokensState,
    reducers: {
      getMintedTokensRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getMintedTokensSuccess: (state, { payload: { tokens } }: PayloadAction<{ tokens: Token[] }>) => {
        state.loading = false;
        state.error = false;
        state.mintedTokens = tokens;
      },
      getMintedTokensFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      getMintedTokensInit: (state) => {
        state.mintedTokens = [];
        state.error = true;
        state.loading = false;
      },
    },
  }),
  hotTokens: createSlice({
    name: 'token/hotTokens',
    initialState: {
      hotTokens: [],
      loading: false,
      error: false,
    } as any,
    reducers: {
      getHotTokensRequest: (state) => {
        state.loading = true;
        state.error = false;
      },
      getHotTokensSuccess: (state, { payload: { tokens } }: PayloadAction<{ tokens: Token[] }>) => {
        state.loading = false;
        state.error = false;
        state.hotTokens = tokens;
      },
      getHotTokensFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  latestTokens: createSlice({
    name: 'token/latestTokens',
    initialState: {
      latestTokens: [],
      loading: false,
      error: false,
    } as any,
    reducers: {
      getLatestTokensRequest: (state) => {
        state.loading = true;
        state.error = false;
      },
      getLatestTokensSuccess: (state, { payload: { tokens } }: PayloadAction<{ tokens: Token[] }>) => {
        state.loading = false;
        state.error = false;
        state.latestTokens = tokens;
      },
      getLatestTokensFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  listedTokens: createSlice({
    name: 'token/listedTokens',
    initialState: {
      listedTokens: [],
      total: null,
      loading: false,
      error: false,
      nextPageExist: false,
    } as ListedTokensState,
    reducers: {
      getListedTokensRequest: (state, { payload }: PayloadAction<{ query?: any; clean?: boolean } | undefined>) => {
        state.loading = true;
        state.error = false;
      },
      getListedTokensSuccess: (
        state,
        { payload: { tokens, countTokens, nextPageExist } }: PayloadAction<{ tokens: Token[]; countTokens: number; nextPageExist: boolean }>,
      ) => {
        state.loading = false;
        state.error = false;
        state.listedTokens = tokens;
        state.total = countTokens;
        state.nextPageExist = nextPageExist;
      },
      getListedTokensFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      clearTokens: (state) => {
        state.listedTokens = [];
      },
    },
  }),
  bids: createSlice({
    name: 'token/bids',
    initialState: {
      bids: [],
      loading: false,
      error: false,
      hash: '',
    } as BidsState,
    reducers: {
      getBidsRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getBidsSuccess: (state, { payload: { data } }: PayloadAction<any>) => {
        state.loading = false;
        state.error = false;
        state.bids = data;
      },
      getBidsFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      placeBidRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      placeBidSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      placeBidFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  orders: createSlice({
    name: 'token/orders',
    initialState: {
      hash: '',
      loading: false,
      error: false,
    } as any,
    reducers: {
      orderTransactionRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      orderTransactionSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      orderTransactionFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  claim: createSlice({
    name: 'token/claim',
    initialState: {
      loading: false,
      error: false,
      hash: '',
    } as TransactionState,
    reducers: {
      claimRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      claimSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      claimFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  settle: createSlice({
    name: 'token/settle',
    initialState: {
      loading: false,
      error: false,
      hash: '',
    } as TransactionState,
    reducers: {
      settleRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      settleSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      settleFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  burn: createSlice({
    name: 'token/burn',
    initialState: {
      loading: false,
      error: false,
      hash: '',
    } as TransactionState,
    reducers: {
      burnRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      burnSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      burnFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
      setInitial: (state) => {
        state.loading = false;
        state.error = false;
        state.hash = '';
      },
    },
  }),
  history: createSlice({
    name: 'token/history',
    initialState: {
      loading: false,
      error: false,
      data: [],
    } as any,
    reducers: {
      historyRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      historySuccess: (state, { payload: { data } }: PayloadAction<{ data: any[] }>) => {
        state.loading = false;
        state.error = false;
        state.data = data;
      },
      historyFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  buy: createSlice({
    name: 'token/buy',
    initialState: {
      hash: '',
      buyerSign: null,
      loading: { sign: true },
      error: { sign: false },
    } as any,
    reducers: {
      buyerSignRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      buyerSignSuccess: (state, { payload: { sign } }: PayloadAction<any>) => {
        state.loading = false;
        state.error = false;
        state.buyerSign = sign;
        state.hash = sign;
      },
      buyerSignFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
};

const actions = combineSliceActions(token);

export { actions as TokenActions };

export default combineSliceReducers(token);
