import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TwitterState } from './types';

const initialState: TwitterState = {
  loading: false,
  error: false,
  oauthToken: null,
  data: null,
};

const { actions, reducer } = createSlice({
  name: 'twitter',
  initialState,
  reducers: {
    oathTokenRequest: (state) => {
      state.loading = true;
      state.error = false;
    },
    oathTokenSuccess: (state, { payload: { oauthToken } }: PayloadAction<{ oauthToken: string }>) => {
      state.oauthToken = oauthToken;
      // state.oauthTokenSecret = oauthTokenSecret;
      state.loading = false;
      state.error = false;
    },
    oathTokenFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    connectRequest: (state, { payload }: PayloadAction<{ oauthToken: string; oauthVerifier: string }>) => {
      state.loading = true;
      state.error = false;
    },
    connectSuccess: (state, { payload: { data } }) => {
      state.loading = false;
      state.error = false;
    },
    connectFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    disconnectRequest: (state) => {
      state.loading = true;
      state.error = false;
    },
    disconnectSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    disconnectFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as TwitterActions };

export default reducer;
