import React, { CSSProperties } from 'react';
import { useField } from 'formik';
import Select, { SelectProps } from '../../Select';

interface FormSelectProps extends Omit<SelectProps, 'name'> {
  name: string;
  fullWidth?: boolean;
  type?: string;
  styleProps?: CSSProperties;
  placeholder?: string;
  value?: any;
  onChange?: (event: any) => void;
}

export default function FormSelect(props: FormSelectProps) {
  const { name, styleProps, fullWidth, type } = props;
  const [field, { error, touched }] = useField(name);

  return <Select {...field} {...props} fullWidth={fullWidth} type={type} error={touched && !!error} helperText={touched && error} styleProps={styleProps} />;
}
