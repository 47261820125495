import axios from 'axios';
import { getLink } from 'helpers/routes';
import { put } from "redux-saga/effects";
import * as endpoints from "../../services/api";
import { SearchActions } from "../reducers/search";

export function* getSearchListRequestSaga(action: ReturnType<typeof SearchActions.getSearchRequest>) {
    const searchQuery = action.payload;
    try {
        const { data } = yield axios.get(getLink(endpoints.SEARCH_GET_SEARCH_LIST, { searchQuery }));
        yield put(SearchActions.getSearchSuccess(data));
    } catch (e) {
        yield put(SearchActions.getSearchFailed);
    }
}
