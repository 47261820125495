import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAnalytics from 'hooks/useAnalytics';
import CookiesBanner from 'shared/components/CookiesBanner';
import ScrollToTop from 'shared/components/ScrollToTop';
import SnackbarNotify from 'shared/snackbar/Notify';
import ChangeNetwork from 'shared/dialogs/ChangeNetwork';
import SEO from 'shared/components/Seo';
import MyWalletModal from 'shared/dialogs/MyWallet';
import MoonPayDialog from 'shared/dialogs/MoonPay';
import ConnectWallet from 'shared/dialogs/ConnectWallet';
import ApplicationForm from 'shared/dialogs/ApplicationForm';
import ThankYou from 'shared/dialogs/ThankYou';
import Support from 'shared/components/Support';

import { PopupsActions } from 'store/reducers/popups';
import { NetworkActions } from 'store/reducers/network';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Routes from './routes';

import './App.scss';
import CustomAlert from './shared/components/CustomAlert/CustomAlert'

declare global {
  interface Window {
    zE: any;
  }
}

function App() {
  useAnalytics();

  const dispatch = useDispatch();

  const {
    popups: { changeNetwork, networkToChange },
  } = useSelector((state) => state);

  return (
    <div className="app">
      <CustomAlert />
      <SEO />
      <CookiesBanner />
      <ScrollToTop />
      <Routes />
      <SnackbarNotify />
      <ConnectWallet />
      <MyWalletModal />

      <MoonPayDialog />
      <Support />

      <ApplicationForm />
      <ThankYou text="Our team will contact you with further details after reviewing your application form." />

      <ChangeNetwork
        open={changeNetwork}
        handleClose={() => dispatch(PopupsActions.setChangeNetwork({ changeNetwork: false, networkToChange: 'ETH' }))}
        handleButton={() => {
          dispatch(NetworkActions.changeMetamaskRequest({ networkKey: networkToChange }));
        }}
        targetNetwork={networkToChange.toUpperCase()}
      />
    </div>
  );
}

export default App;
