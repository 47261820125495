import React from 'react';

import Helmet, { HelmetProps } from 'react-helmet';

type SeoProps = {
	description?: string;
} & HelmetProps;

const SEO = (props: SeoProps) => {
	const { title, description = '', children } = props;
	const metaDescription = description || 'Shaping the future of sports';

	return (
		<>
			<Helmet title={title} defaultTitle="Blocksport" titleTemplate="%s - Blocksport" />
			<Helmet>
				<meta name="description" content={metaDescription} />
				{children}
			</Helmet>
		</>
	);
};

export default SEO;
