import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { CloseIcon } from 'icons';
import React, { useEffect, useRef } from 'react';
import PrivacyPolicy from 'scenes/PrivacyPolicy';
import { useDialogStyles } from 'shared/dialogs/styles';

interface PrivacyDialogProps {
  open: boolean;
  onClose: () => void;
}

const PrivacyDialog = (props: PrivacyDialogProps) => {
  const { open, onClose } = props;

  const { classes: dialogClasses } = useDialogStyles();

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        // @ts-ignore
        descriptionElement?.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={dialogClasses.pageDialog}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="scroll-dialog-title">
        <IconButton onClick={onClose} className={dialogClasses.closeBtn} size="large">
          <CloseIcon className={dialogClasses.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <PrivacyPolicy />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PrivacyDialog;
