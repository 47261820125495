import { call, takeEvery } from 'redux-saga/effects';

// Actions
import { NetworkActions } from 'store/reducers/network';
import { MoonpayActions } from 'store/reducers/moonpay';
import { IPFSActions } from '../reducers/ipfs';
import { TokenActions } from '../reducers/token';
import { ApproveActions } from '../reducers/approve';
import { StakingActions } from '../reducers/staking';
import { CelebrityActions } from '../reducers/celebrity';
import { AccountActions } from '../reducers/account';
import { WalletActions } from '../reducers/wallet';
import { MediaActions } from '../reducers/media';
import { FeedActions } from '../reducers/feed';
import { ProfileActions } from '../reducers/profile';
import { FollowingActions } from '../reducers/following';
import { SubscribingActions } from '../reducers/subscribing';
import { FollowerActions } from '../reducers/follower';
import { SubscriberActions } from '../reducers/subscriber';
import { InvitationActions } from '../reducers/invitation';
import { EmailActions } from '../reducers/email';
import { FanTokenActions } from '../reducers/fantoken';
import { StripeActions } from '../reducers/stripe';
import { DepositActions } from '../reducers/deposit';
import { MyWalletActions } from '../reducers/mywallet';
import { SearchActions } from '../reducers/search';
import { FiltersActions } from '../reducers/filters';
import { TwitterActions } from '../reducers/twitter';
import { MexcActions } from '../reducers/mexc';
import { GameActions } from '../reducers/games';
import { BridgeActions } from '../reducers/bridge';
import { erc20Actions } from '../reducers/erc20';
import { DaoActions } from '../reducers/dao';
import { WhitePayActions } from '../reducers/whitePay';
import { StatsActions } from '../reducers/stats';

// Saga functions
import { uploadDataSaga, uploadFileSaga } from './ipfs';

import {
  mintSingleSaga,
  mintCollectionSaga,
  profileTokensSaga,
  listedTokensSaga,
  detailTokenSaga,
  listSingleSaga,
  placeBidSaga,
  claimSaga,
  settleSaga,
  updatePriceTokenSaga,
  unListTokenSaga,
  getBidsSaga,
  burnTokenSaga,
  tokenHistorySaga,
  hotTokensSaga,
  latestTokensSaga,
  listCollectionSaga,
  unListCollectionSaga,
  winModalClose,
  orderTransactionSaga,
  buyerSignSaga,
  getDetailByIdSaga,
  getBuyoutPriceSaga,
  getMintedTokensSaga,
} from './token';
import { checkApproveSaga, setApproveSaga, checkApproveBSPTSaga, setApproveBSPTSaga, checkApproveFantokenSaga, setApproveFantokenSaga } from './approve';
import {
  unStakeNFTSaga,
  stakedNFTHistorySaga,
  stakeNFTSaga,
  claimRewardsSaga,
  stakePeriodSaga,
  stakedBSPTHistorySaga,
  stakeBSPTSaga,
  unStakeBSPTSaga,
  getFantokenRewardSaga,
  stakeFantokenSaga,
  unStakeFantokenSaga,
  claimFantokenRewardsSaga,
  getDepositsRewardSaga,
  getOldDepositsRewardSaga,
  getAFactorsSaga,
  stakedOldBSPTHistorySaga,
  claimOldBSPTRewardsSaga,
  claimOldFanTokenRewardsSaga,
  claimOldNFTRewardsSaga,
} from './staking';

import { getAccountWalletSaga, setRoleSaga, updateAccountSaga, addAccountSaga } from './account';
import { connectWalletSaga, disconnectWalletSaga, setWalletSaga, authCheckLocalStorageSaga, getWalletSaga, updateWalletSaga } from './wallet';
import { addMediaSaga, getPresignedLinkSaga, uploadAwsLinkSaga } from './media';
import { checkMetaMaskSaga } from './metamask';
import { getCelebritiesListSaga } from './celebrity';
import { addPostSaga, deletePostSaga, editPostSaga, getPostsSaga } from './feed';
import {
  getProfileByNicknameSaga,
  getProfileFollowersSaga,
  getProfileFollowingsSaga,
  getProfileSaga,
  getProfileSubscribersSaga,
  getProfileSubscribingsSaga,
} from './profile';
import { addFollowingSaga, deleteFollowingSaga, getFollowingListSaga } from './following';
import { addSubscribingSaga, deleteSubscribingSaga, getSubscribingListSaga } from './subscribing';
import { getFollowerListSaga } from './follower';
import { getSubscriberListSaga } from './subscriber';
import { getInvitationSaga } from './invitation';
import { changeNetworkSaga, setNetworkSaga } from './network';
import { signMoonpayURLSaga } from './moonpay';
import { sendJoinEmailSaga, subscribeEmailSaga, sendVerifyRequestSaga, validateCodeRequestSaga } from './email';
import { getTokenListRequestSaga, getTokenRequestSaga, getTransactionFeeSaga, getAccountBalanceSaga, getAccountTransactionsSaga, sendRecieptSaga } from './fantoken';
import { initFTOPaymentSaga, initNFTPaymentSaga, initGamePaymentSaga } from './stripe';
import { getNFTSaga, saveNFTSaga, whitelistSaga } from './deposit';
import { getTransactionListRequestSaga, getBalancesSaga, getBsptBalance } from './mywallet';
import { getSearchListRequestSaga } from './search';
import { changeFiltersSaga } from './filters';
import { requestOathToken, requestConnect, disconnect } from './twitter';
import { getRateSaga } from './mexc';
import {
  getGamesListSaga,
  getGameSaga,
  purchaseTokenSaga,
  claimGameRewardsSaga,
  getCurrentGameSaga,
  checkGameRewardsSaga,
  watchForTransferNftCompletion,
  claimRewardsFiatSaga,
} from './games';
import { getProposalsSaga, getProposalSaga, createProposal, getDaoParamsSaga, voteProposalSaga, getVotingInfoSaga } from './dao';
import * as bridgeSagas from './bridge';
import * as erc20Sagas from './erc20';

import { initWhitePaymentSaga, initTokenPaymentSaga } from './whitePay';
import { KycActions } from '../reducers/kyc';
import { getVerificationsByUserIdSaga } from './kyc';
import { getTokenMarketStateSaga, bsptTopGainersSaga } from './stats';

export default function* rootSaga() {
  // Dao
  yield takeEvery(DaoActions.getProposalsRequest, getProposalsSaga);
  yield takeEvery(DaoActions.getProposalRequest, getProposalSaga);
  yield takeEvery(DaoActions.createProposalRequest, createProposal);
  yield takeEvery(DaoActions.getDaoParamsRequest, getDaoParamsSaga);
  yield takeEvery(DaoActions.voteProposalRequest, voteProposalSaga);
  yield takeEvery(DaoActions.getVotingInfoRequest, getVotingInfoSaga);

  // Bridge
  yield takeEvery(BridgeActions.getBSPTBalancesRequest, bridgeSagas.getBSPTBalancesSaga);
  yield takeEvery(BridgeActions.getBSPTFeeRequest, bridgeSagas.getBSPTFeeSaga);
  yield takeEvery(BridgeActions.getBSPTAvailableBalanceRequest, bridgeSagas.getBSPTAvailableBalanceSaga);
  yield takeEvery(BridgeActions.depositRequest, bridgeSagas.depositSaga);
  yield takeEvery(BridgeActions.transferStatusRequest, bridgeSagas.watchForTransferCompletion);

  yield takeEvery(erc20Actions.getAllowanceRequest, erc20Sagas.getAllowanceSaga);
  yield takeEvery(erc20Actions.approveRequest, erc20Sagas.approveSaga);

  // Account
  yield takeEvery(AccountActions.getAccountWalletRequest, getAccountWalletSaga);
  yield takeEvery(AccountActions.setRoleRequest, setRoleSaga);
  yield takeEvery(AccountActions.updateAccountRequest, updateAccountSaga);
  yield takeEvery(AccountActions.addAccountRequest, addAccountSaga);

  // Profile
  yield takeEvery(ProfileActions.getProfileRequest, getProfileSaga);
  yield takeEvery(ProfileActions.getProfileFollowersRequest, getProfileFollowersSaga);
  yield takeEvery(ProfileActions.getProfileFollowingsRequest, getProfileFollowingsSaga);
  yield takeEvery(ProfileActions.getProfileSubscribersRequest, getProfileSubscribersSaga);
  yield takeEvery(ProfileActions.getProfileSubscribingsRequest, getProfileSubscribingsSaga);
  yield takeEvery(ProfileActions.getProfileByNicknameRequest, getProfileByNicknameSaga);

  // Wallet
  yield takeEvery(WalletActions.disconnectWallet, disconnectWalletSaga);
  yield takeEvery(WalletActions.connectWallet, connectWalletSaga);
  yield takeEvery(WalletActions.setWalletRequest, setWalletSaga);
  yield takeEvery(WalletActions.getWalletRequest, getWalletSaga);
  yield takeEvery(WalletActions.updateWalletRequest, updateWalletSaga);

  // IPFS
  yield takeEvery(IPFSActions.uploadFileRequest, uploadFileSaga);
  yield takeEvery(IPFSActions.uploadDataRequest, uploadDataSaga);

  // Approve
  yield takeEvery(ApproveActions.setApproveRequest, setApproveSaga); // new contract
  yield takeEvery(ApproveActions.checkApproveRequest, checkApproveSaga); // new contract
  yield takeEvery(ApproveActions.checkApproveBSPTRequest, checkApproveBSPTSaga);
  yield takeEvery(ApproveActions.setApproveBSPTRequest, setApproveBSPTSaga);
  yield takeEvery(ApproveActions.checkApproveFantokenRequest, checkApproveFantokenSaga);
  yield takeEvery(ApproveActions.setApproveFantokenRequest, setApproveFantokenSaga);

  // Token
  yield takeEvery(TokenActions.mint.mintSingleRequest, mintSingleSaga); // new contract
  yield takeEvery(TokenActions.mint.mintCollectionRequest, mintCollectionSaga); // new contract
  yield takeEvery(TokenActions.profileTokens.getProfileTokensRequest, profileTokensSaga);
  yield takeEvery(TokenActions.listedTokens.getListedTokensRequest, listedTokensSaga);
  yield takeEvery(TokenActions.detail.getDetailRequest, detailTokenSaga);
  yield takeEvery(TokenActions.detail.updatePriceRequest, updatePriceTokenSaga); // new contract
  yield takeEvery(TokenActions.list.listSingleRequest, listSingleSaga); // new contract
  yield takeEvery(TokenActions.list.unListTokenRequest, unListTokenSaga); // new contract
  yield takeEvery(TokenActions.bids.placeBidRequest, placeBidSaga); // new contract
  yield takeEvery(TokenActions.list.unListCollectionRequest, unListCollectionSaga); // new contract
  yield takeEvery(TokenActions.list.winModalCloseRequest, winModalClose);
  yield takeEvery(TokenActions.bids.getBidsRequest, getBidsSaga);
  yield takeEvery(TokenActions.claim.claimRequest, claimSaga); // new contract
  yield takeEvery(TokenActions.settle.settleRequest, settleSaga); // new contract
  yield takeEvery(TokenActions.burn.burnRequest, burnTokenSaga); // new contract
  yield takeEvery(TokenActions.history.historyRequest, tokenHistorySaga);
  yield takeEvery(TokenActions.hotTokens.getHotTokensRequest, hotTokensSaga);
  yield takeEvery(TokenActions.latestTokens.getLatestTokensRequest, latestTokensSaga);
  yield takeEvery(TokenActions.list.listCollectionRequest, listCollectionSaga); // new contract
  yield takeEvery(TokenActions.buy.buyerSignRequest, buyerSignSaga); // new contract
  yield takeEvery(TokenActions.detail.getDetailByIdRequest, getDetailByIdSaga);
  yield takeEvery(TokenActions.detail.getBuyoutPriceRequest, getBuyoutPriceSaga);
  yield takeEvery(TokenActions.mintedTokens.getMintedTokensRequest, getMintedTokensSaga);

  // Orders
  yield takeEvery(TokenActions.orders.orderTransactionRequest, orderTransactionSaga);

  // Staking
  yield takeEvery(StakingActions.stake.stakeNFTRequest, stakeNFTSaga);
  yield takeEvery(StakingActions.unstake.unStakeNFTRequest, unStakeNFTSaga);
  yield takeEvery(StakingActions.stake.stakeBSPTRequest, stakeBSPTSaga);
  yield takeEvery(StakingActions.unstake.unStakeBSPTRequest, unStakeBSPTSaga);
  yield takeEvery(StakingActions.stakedHistory.getStakedNFTHistoryRequest, stakedNFTHistorySaga);
  yield takeEvery(StakingActions.claimRewards.claimRewardsRequest, claimRewardsSaga);
  yield takeEvery(StakingActions.stakePeriod.setStakePeriodRequest, stakePeriodSaga);
  yield takeEvery(StakingActions.stakedHistory.getStakedBSPTHistoryRequest, stakedBSPTHistorySaga);
  yield takeEvery(StakingActions.oldBsptStakingHistory.getOldStakedBSPTHistoryRequest, stakedOldBSPTHistorySaga);
  yield takeEvery(StakingActions.rewards.getFantokenRewardRequest, getFantokenRewardSaga);
  yield takeEvery(StakingActions.stake.stakeFantokenRequest, stakeFantokenSaga);
  yield takeEvery(StakingActions.unstake.unStakeFantokenRequest, unStakeFantokenSaga);
  yield takeEvery(StakingActions.claimRewards.claimFantokenRewardsRequest, claimFantokenRewardsSaga);
  yield takeEvery(StakingActions.rewards.getDepositsRewardRequest, getDepositsRewardSaga);
  yield takeEvery(StakingActions.oldRewards.getOldDepositsRewardsRequest, getOldDepositsRewardSaga);
  yield takeEvery(StakingActions.ftStakeConfig.getAFactorsRequest, getAFactorsSaga);
  yield takeEvery(StakingActions.clearOldStakings.claimOldBSPTRewardsRequest, claimOldBSPTRewardsSaga);
  yield takeEvery(StakingActions.clearOldStakings.claimOldFTRewardsRequest, claimOldFanTokenRewardsSaga);
  yield takeEvery(StakingActions.clearOldStakings.claimOldNFTRewardsRequest, claimOldNFTRewardsSaga);

  // Upload
  yield takeEvery(MediaActions.addMediaRequest, addMediaSaga);
  yield takeEvery(MediaActions.getPresignedLinkRequest, getPresignedLinkSaga);
  yield takeEvery(MediaActions.uploadAwsLinkRequest, uploadAwsLinkSaga);

  // Celebrity
  yield takeEvery(CelebrityActions.getCelebritiesListRequest, getCelebritiesListSaga);

  // Feed
  yield takeEvery(FeedActions.getPostsRequest, getPostsSaga);
  yield takeEvery(FeedActions.addPostRequest, addPostSaga);
  yield takeEvery(FeedActions.editPostRequest, editPostSaga);
  yield takeEvery(FeedActions.deletePostRequest, deletePostSaga);

  // Following/Follower
  yield takeEvery(FollowingActions.getFollowingListRequest, getFollowingListSaga);
  yield takeEvery(FollowingActions.addFollowingRequest, addFollowingSaga);
  yield takeEvery(FollowingActions.deleteFollowingRequest, deleteFollowingSaga);
  yield takeEvery(FollowerActions.getFollowerListRequest, getFollowerListSaga);

  // Subscribing/Subscriber
  yield takeEvery(SubscribingActions.getSubscribingListRequest, getSubscribingListSaga);
  yield takeEvery(SubscribingActions.addSubscribingRequest, addSubscribingSaga);
  yield takeEvery(SubscribingActions.deleteSubscribingRequest, deleteSubscribingSaga);
  yield takeEvery(SubscriberActions.getSubscriberListRequest, getSubscriberListSaga);

  // Invitation
  yield takeEvery(InvitationActions.getInvitationRequest, getInvitationSaga);

  // Network
  yield takeEvery(NetworkActions.changeMetamaskRequest, changeNetworkSaga);

  // Moonpay
  yield takeEvery(MoonpayActions.signMoonpayURLRequest, signMoonpayURLSaga);

  // Email
  yield takeEvery(EmailActions.joinEmail.sendJoinEmailRequest, sendJoinEmailSaga);
  yield takeEvery(EmailActions.subscribeEmail.sendRequest, subscribeEmailSaga);
  yield takeEvery(EmailActions.verifyEmail.sendVerifyRequest, sendVerifyRequestSaga);
  yield takeEvery(EmailActions.verifyEmail.validateCodeRequest, validateCodeRequestSaga);

  // FanToken
  yield takeEvery(FanTokenActions.getTokenListRequest, getTokenListRequestSaga);
  yield takeEvery(FanTokenActions.getTokenRequest, getTokenRequestSaga);
  yield takeEvery(FanTokenActions.getTransactionFeeRequest, getTransactionFeeSaga);
  yield takeEvery(FanTokenActions.getAccountBalanceRequest, getAccountBalanceSaga);
  yield takeEvery(FanTokenActions.getAccountTransactionsRequest, getAccountTransactionsSaga);
  yield takeEvery(FanTokenActions.sendRecieptRequest, sendRecieptSaga);

  // MyWallet
  yield takeEvery(MyWalletActions.getTransactionListRequest, getTransactionListRequestSaga);
  yield takeEvery(MyWalletActions.getBalancesRequest, getBalancesSaga);
  yield takeEvery(MyWalletActions.getBsptBalanceRequest, getBsptBalance);

  // Stripe
  yield takeEvery(StripeActions.fto.initPaymentRequest, initFTOPaymentSaga);
  yield takeEvery(StripeActions.nft.initPaymentRequest, initNFTPaymentSaga);
  yield takeEvery(StripeActions.game.initPaymentRequest, initGamePaymentSaga);

  // Deposit
  yield takeEvery(DepositActions.getNFTRequest, getNFTSaga);
  yield takeEvery(DepositActions.saveNFTRequest, saveNFTSaga);
  yield takeEvery(DepositActions.whitelistRequest, whitelistSaga);

  // Search
  yield takeEvery(SearchActions.getSearchRequest, getSearchListRequestSaga);

  // Filter
  yield takeEvery(FiltersActions.marketplace.changeFilters, changeFiltersSaga);

  // Twitter
  yield takeEvery(TwitterActions.oathTokenRequest, requestOathToken);
  yield takeEvery(TwitterActions.connectRequest, requestConnect);
  yield takeEvery(TwitterActions.disconnectRequest, disconnect);

  // Mexc
  yield takeEvery(MexcActions.rate.getRateRequest, getRateSaga);

  // Games
  yield takeEvery(GameActions.getGamesListRequest, getGamesListSaga);
  yield takeEvery(GameActions.getGameRequest, getGameSaga);
  yield takeEvery(GameActions.purchaseTokenRequest, purchaseTokenSaga);
  yield takeEvery(GameActions.claimRewardsRequest, claimGameRewardsSaga);
  yield takeEvery(GameActions.getCurrentGameRequest, getCurrentGameSaga);
  yield takeEvery(GameActions.checkGameRewardsRequest, checkGameRewardsSaga);
  yield takeEvery(GameActions.transferStatusRequest, watchForTransferNftCompletion);
  yield takeEvery(GameActions.claimRewardsFiatRequest, claimRewardsFiatSaga);

  // Stats
  yield takeEvery(StatsActions.tokenMarketState.getTokenMarketStateRequest, getTokenMarketStateSaga);
  yield takeEvery(StatsActions.bsptTopGainers.getBsptTopGainersRequest, bsptTopGainersSaga);

  yield call(setNetworkSaga);
  yield call(checkMetaMaskSaga);
  yield call(authCheckLocalStorageSaga);

  // Whitepay
  yield takeEvery(WhitePayActions.initPaymentRequest, initWhitePaymentSaga);
  yield takeEvery(WhitePayActions.initTokenPaymentRequest, initTokenPaymentSaga);

  // Kyc
  yield takeEvery(KycActions.getVerificationsByUserIdRequest, getVerificationsByUserIdSaga);
}
