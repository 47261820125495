import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FanTokenState } from './types';

const initialState: FanTokenState = {
  list: {},
  status: {},
  loading: false,
  error: false,
  balance: null,
  transactions: [],
  fanWalletIsOpen: false,
  fantokenModalIsOpen: false,
  recieptSent: false,
  loadingBalance: false,
};

const { actions, reducer } = createSlice({
  name: 'fantoken',
  initialState,
  reducers: {
    getTokenListRequest: (state) => {
      state.loading = true;
      state.error = false;
    },
    getTokenListSuccess: (state, { payload }: PayloadAction<{ data: [] }>) => {
      // state.list = payload.data;
      state.loading = false;
      state.error = false;
    },
    getTokenListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    getTokenRequest: (state, { payload }: PayloadAction<{ tokenSymbol: string }>) => {
      state.loading = true;
      state.error = false;
    },
    getTokenSuccess: (state, { payload }: PayloadAction<any>) => {
      state.list = { ...state.list, [payload.data.tokenSymbol]: { ...state.list[payload.data.tokenSymbol], ...payload.data } };
      state.loading = false;
      state.error = false;
    },
    getTokenFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    setFanTokenServiceStatus: (state, { payload }: PayloadAction<{ serviceAvailable: boolean; tokenSymbol: string }>) => {
      state.status = { ...state.status, [payload.tokenSymbol]: payload.serviceAvailable };
    },

    getTransactionFeeRequest: (state, { payload }: PayloadAction<{ tokenSymbol: string }>) => {
      state.loading = true;
      state.error = false;
    },
    getTransactionFeeSuccess: (state, { payload }: PayloadAction<any>) => {
      state.list = { ...state.list, [payload.tokenSymbol]: { ...state.list[payload.tokenSymbol], ...{ transactionFee: payload } } };
      state.loading = false;
      state.error = false;
    },
    getTransactionFeeFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    getAccountBalanceRequest: (state, { payload }: PayloadAction<{ address: string }>) => {
      state.loadingBalance = true;
      state.error = false;
    },
    getAccountBalanceSuccess: (state, { payload }: PayloadAction<{ data: any }>) => {
      state.balance = payload.data;
      state.loadingBalance = false;
      state.error = false;
    },
    getAccountBalanceFailure: (state) => {
      state.loadingBalance = false;
      state.error = true;
    },

    getAccountTransactionsRequest: (state, { payload }: PayloadAction<{ address: string }>) => {
      state.loading = true;
      state.error = false;
    },
    getAccountTransactionsSuccess: (state, { payload }: PayloadAction<{ data: any }>) => {
      state.transactions = payload.data;
      state.loading = false;
      state.error = false;
    },
    getAccountTransactionsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    toggleFanWallet: (state) => {
      state.fanWalletIsOpen = !state.fanWalletIsOpen;
    },
    closeFanWallet: (state) => {
      state.fanWalletIsOpen = false;
    },
    toggleFantokenModal: (state, { payload }: PayloadAction<{ open: boolean }>) => {
      state.fantokenModalIsOpen = payload.open;
    },

    sendRecieptRequest: (state, { payload }: PayloadAction<{ paymentIntentId: string; receiptEmail: string }>) => {
      state.loading = true;
      state.error = false;
      state.recieptSent = false;
    },
    sendRecieptSuccess: (state) => {
      state.loading = false;
      state.error = false;
      state.recieptSent = true;
    },
    sendRecieptFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as FanTokenActions };

export default reducer;
