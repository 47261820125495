import { call, put, select } from 'redux-saga/effects';
import web3, {web3Bsc} from 'helpers/getWeb3';
import { token1155Address } from 'contracts/addresses';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import axios from 'axios';
import analytics from 'analytics';
import { push } from 'connected-react-router';
import { ROUTE_PROFILE_DETAILS } from 'constants/routes';
import { FollowingActions } from '../reducers/following';
import { SubscribingActions } from '../reducers/subscribing';
import { FollowerActions } from '../reducers/follower';
import { SubscriberActions } from '../reducers/subscriber';
import { AccountActions } from '../reducers/account';
import { WalletActions } from '../reducers/wallet';
import { NotificationActions } from '../reducers/notification';
import { setLocalSaga } from './wallet';
import { NetworkStateInterface } from '../reducers/network/types';
import { getBlockchainABI } from '../../contracts';

export function* setRoleSaga(action: ReturnType<typeof AccountActions.setRoleRequest>) {
  const { address } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const addr: string = token1155Address[network];
  // @ts-ignore
  const mintContract = yield new web3.eth.Contract(getBlockchainABI(network, 'token1155'), addr);

  try {
    if (address) {
      const isCelebrity: boolean = yield mintContract
          .methods
          .hasRole('0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6', address)
          .call();

      const role = isCelebrity ? 'celebrity' : 'fan';

      yield call(setLocalSaga, true, role);

      yield put(AccountActions.setRoleSuccess(role));
    }
  } catch (error) {
    yield put(AccountActions.setRoleFailure());
  }
}

export function* getAccountWalletSaga(action: ReturnType<typeof AccountActions.getAccountWalletRequest>) {
  const { address } = action.payload;
  const url = getLink(endpoints.GET_ME_WALLET, { wallet: address });
  const token: string = yield localStorage.getItem('token');

  try {
    const {
      data: { data },
    } = yield axios.get(url);

    if (data) {
      yield call(setLocalSaga, true, data.role, data.id, token);
    }
    yield put(AccountActions.getAccountWalletSuccess(data));
    yield put(AccountActions.setRoleRequest({ address }));

    // Analytics Event
    yield analytics.set({ userId: data.id });
    yield analytics.sendEvent({
      category: 'Wallet',
      action: 'Wallet connected',
    });

    if (data?.role === 'fan') {
      yield put(FollowingActions.getFollowingListRequest({ fanId: data.id }));
      yield put(SubscribingActions.getSubscribingListRequest({ fanId: data.id }));
    } else if (data?.role === 'celebrity') {
      yield put(FollowerActions.getFollowerListRequest({ celebrityId: data.id }));
      yield put(SubscriberActions.getSubscriberListRequest({ celebrityId: data.id }));
    }
  } catch (error) {
    yield put(AccountActions.getAccountWalletFailure());
    yield put(WalletActions.disconnectWallet({}));
  }
}

export function* updateAccountSaga(action: ReturnType<typeof AccountActions.updateAccountRequest>) {
  const userId: string = yield localStorage.getItem('userId');
  const role: string = yield localStorage.getItem('role');
  const connected = JSON.parse(localStorage.getItem('connected') as string);

  if (userId && connected) {
    const url = getLink(endpoints.UPDATE_ME, { role, id: userId });

    try {
      const {
        data: { data },
      } = yield axios.put(url, action.payload);

      if (data) {
        yield put(AccountActions.updateAccountSuccess(data));
        yield put(push(ROUTE_PROFILE_DETAILS));
      }
    } catch (error) {
      yield put(AccountActions.updateAccountFailure());
      yield put(
        NotificationActions.pushNotification({
          message: (error as any).response.data.error,
          options: {
            variant: 'error',
          },
        }),
      );
    }
  }
}

export function* addAccountSaga(action: ReturnType<typeof AccountActions.addAccountRequest>) {
  const { data: payloadData, callback } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.post(endpoints.ADD_PROFILE, { ...payloadData });

    if (data) {
      if (payloadData.role === 'celebrity') {
        yield axios.post(getLink(endpoints.ADD_ROLE_CELEBRITY, { id: data.id }));
      }

      yield put(AccountActions.addAccountSuccess(data));

      yield call(setLocalSaga, true, data.role, data.id);

      callback && callback();
    }
  } catch (error) {
    yield put(AccountActions.addAccountFailure());
    yield put(
      NotificationActions.pushNotification({
        message: (error as any).response.data.error,
        options: {
          variant: 'error',
        },
      }),
    );
  }
}
