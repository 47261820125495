import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Token, DepositFormatInfo } from 'entities/Token';
import { StakeHistoryLog } from 'entities/History';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import {
  StakedHistoryState,
  StakeState,
  StakedPoolInfoState,
  CheckedTokenState,
  RewardsState,
  BSPTStakingPayload,
  OldBSPTStakingState,
  OldBSPTStakingPayload,
  ClaimOldRewardsState,
} from './types';

const initialBSPTStakingHistoryState: BSPTStakingPayload = {
  stakedHistory: [],
  yourRewards: 0,
  totalLockedPool: 0,
  yourLockedBSPT: 0,
  yourBSPTBalance: 0,
  totalDistributed: 0,
  totalSupply: 0,
  yourDeposits: [],
};
const initialHistoryState = {
  currentAvailableReward: 0,
  totalPoolPower: 0,
  totalNFTsInPool: 0,
  yourTokensPower: 0,
  yourRewardsForPeriod: 0,
  loading: false,
  error: false,
  claimIsAvailable: false,
  totalInPool: 0,
  ...initialBSPTStakingHistoryState,
} as StakedHistoryState;

const initialClaimOldRewardsState: ClaimOldRewardsState = {
  loading: false,
  error: false,
  hash: [],
  errorMessage: '',
};

const staking = {
  stakedTokens: createSlice({
    name: 'staking/stakedTokens',
    initialState: {
      stakedTokens: [],
    } as any,
    reducers: {
      setStakedTokens: (state, { payload: { tokens } }: PayloadAction<any>) => {
        state.stakedTokens = tokens;
      },
    },
  }),
  unstakedTokens: createSlice({
    name: 'staking/unstakedTokens',
    initialState: {
      unstakedTokens: [],
    } as any,
    reducers: {
      setUnstakedTokens: (state, { payload: { tokens } }: PayloadAction<any>) => {
        state.unstakedTokens = tokens;
      },
    },
  }),
  oldBsptStakingHistory: createSlice({
    name: 'staking/oldBsptStakingHistory',
    initialState: {
      loading: false,
      error: false,
      yourBSPTBalance: 0,
      yourDeposits: [],
      yourRewards: 0,
      yourLockedBSPT: 0,
    } as OldBSPTStakingState,
    reducers: {
      getOldStakedBSPTHistoryRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getOldStakedBSPTHistorySuccess: (state, { payload: { yourRewards, yourLockedBSPT, yourDeposits } }: PayloadAction<OldBSPTStakingPayload>) => {
        state.loading = false;
        state.error = false;
        state.yourDeposits = yourDeposits;
        state.yourRewards = yourRewards;
        state.yourLockedBSPT = yourLockedBSPT;
      },
      getOldStakedBSPTHistoryFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
      setInitialOldStakedBSPTHistory: (state) => {
        state.loading = false;
        state.error = false;
        state.yourDeposits = [];
        state.yourRewards = 0;
        state.yourLockedBSPT = 0;
      },
    },
  }),
  stakedHistory: createSlice({
    name: 'staking/stakedHistory',
    initialState: initialHistoryState,
    reducers: {
      getStakedNFTHistoryRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getStakedNFTHistorySuccess: (
        state,
        {
          payload: {
            stakedHistory,
            currentAvailableReward,
            totalPoolPower,
            yourTokensPower,
            yourRewards,
            totalNFTsInPool,
            yourRewardsForPeriod,
            totalInPool,
            claimIsAvailable,
          },
        }: PayloadAction<{
          stakedHistory: Token[];
          yourRewardsForPeriod: number;
          currentAvailableReward: number;
          totalPoolPower: number;
          yourTokensPower: number;
          totalInPool: number;
          yourRewards: number;
          totalNFTsInPool: number;
          claimIsAvailable: boolean;
        }>,
      ) => {
        state.loading = false;
        state.error = false;
        state.totalInPool = totalInPool;
        state.yourRewardsForPeriod = yourRewardsForPeriod;
        state.totalNFTsInPool = totalNFTsInPool;
        state.yourTokensPower = yourTokensPower;
        state.stakedHistory = stakedHistory;
        state.totalPoolPower = totalPoolPower;
        state.yourRewards = yourRewards;
        state.currentAvailableReward = currentAvailableReward;
        state.claimIsAvailable = claimIsAvailable;
      },
      getStakedNFTHistoryFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      getStakedBSPTHistoryRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getStakedBSPTHistorySuccess: (
        state,
        {
          payload: { yourRewards, totalLockedPool, yourLockedBSPT, stakedHistory, yourBSPTBalance, yourDeposits, totalDistributed, totalSupply },
        }: PayloadAction<BSPTStakingPayload>,
      ) => {
        state.loading = false;
        state.error = false;
        state.totalSupply = totalSupply;
        state.yourDeposits = yourDeposits;
        state.totalDistributed = totalDistributed;
        state.yourBSPTBalance = yourBSPTBalance;
        state.stakedHistory = stakedHistory;
        state.yourRewards = yourRewards;
        state.totalLockedPool = totalLockedPool;
        state.yourLockedBSPT = yourLockedBSPT;
      },
      getStakedBSPTHistoryFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  stakedPoolInfo: createSlice({
    name: 'staking/stakedPoolInfo',
    initialState: {
      nftsInPool: 0,
      totalPoolPower: 0,
      error: false,
    } as StakedPoolInfoState,
    reducers: {
      getStakedPoolInfoRequest: (state, { payload }: PayloadAction<any>) => {
        state.error = false;
      },
      getStakedPoolInfoSuccess: (state, { payload: { nftsInPool, totalPoolPower } }: PayloadAction<{ nftsInPool: number; totalPoolPower: number }>) => {
        state.error = false;
        state.nftsInPool = nftsInPool;
        state.totalPoolPower = totalPoolPower;
      },
      getStakedPoolInfoFailure: (state) => {
        state.error = true;
      },

      getStakedPoolInfoInit: (state) => {
        state.nftsInPool = 0;
        state.totalPoolPower = 0;
        state.error = false;
      },
    },
  }),
  claimRewards: createSlice({
    name: 'staking/claimRewards',
    initialState: {
      loading: false,
      error: false,
      hash: '',
    } as any,
    reducers: {
      claimRewardsRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      claimRewardsSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      claimRewardsFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      claimFantokenRewardsRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      claimFantokenRewardsSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      claimFantokenRewardsFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  stakePeriod: createSlice({
    name: 'staking/stakePeriod',
    initialState: {
      period: null,
      loading: false,
      error: false,
    } as any,
    reducers: {
      setStakePeriodRequest: (state) => {
        state.loading = true;
        state.error = false;
      },
      setStakePeriodSuccess: (state, { payload: { period } }: PayloadAction<{ period: any }>) => {
        state.loading = false;
        state.error = false;
        state.period = period;
      },
      setStakePeriodFailure: (state, { payload: { period } }: PayloadAction<any>) => {
        state.loading = false;
        state.error = true;
      },
    },
  }),
  stake: createSlice({
    name: 'staking/stake',
    initialState: {
      loading: false,
      error: false,
      hash: '',
      checkedTokens: [],
    } as StakeState,
    reducers: {
      stakeBSPTRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      stakeBSPTSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      stakeBSPTFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      stakeNFTRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      stakeNFTSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      stakeNFTFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      setStakeInitial: (state) => {
        state.loading = false;
        state.error = false;
        state.hash = '';
      },

      stakeFantokenRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      stakeFantokenSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      stakeFantokenFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  /** Unstake and Claim rewards from old stakings without KYC user whitelist */
  clearOldStakings: createSlice({
    name: 'staking/clearOldStakings',
    initialState: {
      bspt: initialClaimOldRewardsState,
      ft: initialClaimOldRewardsState,
      nft: initialClaimOldRewardsState,
    } as { bspt: ClaimOldRewardsState; ft: ClaimOldRewardsState; nft: ClaimOldRewardsState },
    reducers: {
      setInitialClaimOldRewardsState: (state) => {
        state.bspt = initialClaimOldRewardsState;
        state.ft = initialClaimOldRewardsState;
        state.nft = initialClaimOldRewardsState;
      },
      claimOldBSPTRewardsRequest: (state, { payload }: PayloadAction<{ address: string; bsptDeposits: DepositFormatInfo[] }>) => {
        state.bspt.loading = true;
        state.bspt.error = false;
      },
      claimOldBSPTRewardsSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string[] }>) => {
        state.bspt.loading = false;
        state.bspt.error = false;
        state.bspt.hash = hash;
      },
      claimOldBSPTRewardsFailure: (state, { payload: { errorMessage } }: PayloadAction<{ errorMessage: string }>) => {
        state.bspt.error = true;
        state.bspt.loading = false;
        state.bspt.errorMessage = errorMessage;
      },
      claimOldFTRewardsRequest: (state, { payload }: PayloadAction<{ address: string; ftDeposits: any[] }>) => {
        state.ft.loading = true;
        state.ft.error = false;
      },
      claimOldFTRewardsSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string[] }>) => {
        state.ft.loading = false;
        state.ft.error = false;
        state.ft.hash = hash;
      },
      claimOldFTRewardsFailure: (state, { payload: { errorMessage } }: PayloadAction<{ errorMessage: string }>) => {
        state.ft.error = true;
        state.ft.loading = false;
        state.ft.errorMessage = errorMessage;
      },
      claimOldNFTRewardsRequest: (state, { payload }: PayloadAction<{ address: string; nftDeposits: Token[] }>) => {
        state.nft.loading = true;
        state.nft.error = false;
      },
      claimOldNFTRewardsSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string[] }>) => {
        state.nft.loading = false;
        state.nft.error = false;
        state.nft.hash = hash;
      },
      claimOldNFTRewardsFailure: (state, { payload: { errorMessage } }: PayloadAction<{ errorMessage: string }>) => {
        state.nft.error = true;
        state.nft.loading = false;
        state.nft.errorMessage = errorMessage;
      },
    },
  }),
  unstake: createSlice({
    name: 'staking/unstake',
    initialState: {
      loading: false,
      error: false,
      hash: '',
      checkedTokens: [],
    } as StakeState,
    reducers: {
      unStakeBSPTRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      unStakeBSPTSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      unStakeBSPTFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
      unStakeNFTRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      unStakeNFTSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      unStakeNFTFailure: (state) => {
        state.error = true;
        state.loading = false;
      },

      setUnStakeInitial: (state) => {
        state.loading = false;
        state.error = false;
        state.hash = '';
      },

      unStakeFantokenRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      unStakeFantokenSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
        state.loading = false;
        state.error = false;
        state.hash = hash;
      },
      unStakeFantokenFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
  checkedTokens: createSlice({
    name: 'staking/checkedTokens',
    initialState: {
      checkedTokens: [],
    } as CheckedTokenState,
    reducers: {
      checkTokenRequest: (state, { payload }: PayloadAction<any>) => {
        const { tokenId, tokenAddress, nonplatform } = payload;
        const index = state.checkedTokens.findIndex((object: any) => object.tokenId === tokenId);
        if (index === -1) {
          state.checkedTokens = [...state.checkedTokens, { tokenId, tokenAddress, nonplatform }];
        }
      },
      unCheckTokenRequest: (state, { payload: { tokenId } }: PayloadAction<{ tokenId: any }>) => {
        const index = state.checkedTokens.findIndex((object: any) => object.tokenId === tokenId);
        if (index !== -1) {
          state.checkedTokens.splice(index, 1);
        }
        state.checkedTokens = [...state.checkedTokens];
      },
      checkAllTokensRequest: (state, { payload: { checkedTokens } }: PayloadAction<{ checkedTokens: any }>) => {
        checkedTokens.forEach((item: any) => {
          const index = state.checkedTokens.findIndex((object: any) => object.tokenId === item.tokenId);
          if (index === -1) {
            state.checkedTokens = [...state.checkedTokens, item];
          }
        });
      },
      setCheckedInitial: (state) => {
        state.checkedTokens = [];
      },
    },
  }),
  oldRewards: createSlice({
    name: 'staking/oldRewards',
    initialState: {
      loading: false,
      error: false,
      depositReward: '0',
      deposits: [],
    } as Omit<RewardsState, 'fantokenReward'>,
    reducers: {
      getOldDepositsRewardsRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getOldDepositsRewardsSuccess: (state, { payload: { reward, deposits } }: PayloadAction<{ reward: string; deposits: any[] }>) => {
        state.loading = false;
        state.error = false;
        state.depositReward = reward;
        state.deposits = deposits;
      },
      getOldDepositsRewardsFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
      setInitialOldDepositsRewards: (state) => {
        state.loading = false;
        state.error = false;
        state.depositReward = '0';
        state.deposits = [];
      },
    },
  }),
  rewards: createSlice({
    name: 'staking/rewards',
    initialState: {
      loading: false,
      error: false,
      fantokenReward: '0',
      depositReward: '0',
      deposits: [],
    } as RewardsState,
    reducers: {
      getDepositsRewardRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getDepositsRewardSuccess: (state, { payload: { reward, deposits } }: PayloadAction<{ reward: string; deposits: any[] }>) => {
        state.loading = false;
        state.error = false;
        state.depositReward = reward;
        state.deposits = deposits;
      },
      getDepositsRewardFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
      getFantokenRewardRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getFantokenRewardSuccess: (state, { payload: { reward } }: PayloadAction<{ reward: string }>) => {
        state.loading = false;
        state.error = false;
        state.fantokenReward = reward;
      },
      getFantokenRewardFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
      setInitial: (state) => {
        state.loading = false;
        state.error = false;
        state.fantokenReward = '0';
      },
    },
  }),
  ftStakeConfig: createSlice({
    name: 'staking/ftStakeConfig',
    initialState: {
      loading: true,
      error: false,
      aFactors: [],
    } as any,
    reducers: {
      getAFactorsRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.error = false;
      },
      getAFactorsSuccess: (state, { payload: { aFactors } }: PayloadAction<any>) => {
        state.loading = false;
        state.error = false;
        state.aFactors = aFactors;
      },
      getAFactorsFailure: (state) => {
        state.error = true;
        state.loading = false;
      },
    },
  }),
};

const actions = combineSliceActions(staking);

export { actions as StakingActions };

export default combineSliceReducers(staking);
