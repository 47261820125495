export const sliceAddress = (address: string) => `@${address.slice(0, 4)}...${address.slice(-4)}`;

export const checkNickName = (nickName: string) => {
  if (nickName && nickName.startsWith('0x')) {
    return sliceAddress(nickName);
  }
  return `@${nickName}`;
};

export const truncateAddress = (address: string) => {
  if (!address) return 'No Account';
  const match = address.match(/^(0x[a-zA-Z0-9]{16})[a-zA-Z0-9]+([a-zA-Z0-9]{3})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export function sliceTruncateHash(hash: any, startLength: number = 6, endLength: number = 4) {
  if (hash) {
    const start = hash.slice(0, startLength);
    const end = hash.slice(-endLength);
    return `${start}...${end}`;
  }
  return '';
}
