import React from 'react';
import { Typography, Box, Paper, Link, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { toLocalDateTimeString, formatPrice } from 'helpers/format';
import { fromWei } from 'helpers/web3';
import { blockChainCurrency } from 'helpers/blockchain';
import { availableFTO } from 'constants/fantokens';
import { useStyles } from '../../styles';

const Transaction = (props: any) => {
	const { amount, type, explorerLink, status, dateOperation, createdAt, eventName, token, value, currency, blockchain } = props;
	const { classes } = useStyles();

	const Status = () => {
		if (['abandoned', 'zombie', 'failure'].includes(status)) {
			return <CancelIcon color="error" />;
		}
		if (status === 'success' || !type) {
			return <CheckCircleIcon color="success" />;
		}
		return <CircularProgress size={20} />;
	};

	const Price = () => {
		if (['nft_buy_now', 'nft_buy_now_multitoken', 'fantoken_transfer'].includes(type)) {
			return <>{formatPrice(amount / 100, currency)}</>;
		}

		return <>{`${fromWei(value.toString())} ${blockChainCurrency(blockchain)}`}</>;
	};

	const TransactionType = () => {
		if (type === 'fantoken_transfer') {
			const [tokenSymbol, fto]: [tokenSymbol: string | null, fto: any] = Object.entries(availableFTO).find((item: any) => item[0] === token) || [null, null];

			return (
				<Box mr={1} display="flex" alignItems="center" className="fto">
					<img src={fto.balanceImage} alt="Balance FanTokens" />
					<Box display="flex" flexDirection="column" alignItems="flex-start" flexGrow={1}>
						<Typography variant="caption" color="gray">
							{`${fto.prefix}${tokenSymbol}`}
						</Typography>
						<Typography variant="h5">{value}</Typography>
					</Box>
				</Box>
			);
		}

		if (['nft_buy_now', 'nft_buy_now_multitoken'].includes(type)) {
			return <Typography variant="h5">Purchase NFT</Typography>;
		}

		if (eventName === 'NewOffer') {
			return <Typography variant="h5">Bid</Typography>;
		}

		if (eventName) {
			return <Typography variant="h5">{eventName}</Typography>;
		}

		return <>X</>;
	};

	return (
		<Paper variant="outlined" elevation={0} className={classes.transaction}>
			<Link href={explorerLink} target="_blank" rel="noopener" underline="none">
				<Box display="flex" alignItems="center" justifyContent="flex-start">
					<Box className="status">
						<Status />
					</Box>
					<Box flexGrow={1} className="transactionType">
						<TransactionType />
					</Box>
					<Box display="flex" flexDirection="column" alignItems="flex-end" className="priceanddate">
						<Typography variant="h4">
							<Price />
						</Typography>
						<Box display="flex" alignItems="center">
							<OpenInNewIcon />
							<Typography variant="caption" color="gray" sx={{ marginLeft: '10px' }}>
								{toLocalDateTimeString(new Date(dateOperation || createdAt))}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Link>
		</Paper>
	);
};

export default Transaction;
