import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import { StatsState } from './types';

const initialState: StatsState = {
  loading: false,
  error: false,
  data: null,
};

const stats = {
  tokenMarketState: createSlice({
    name: 'stats/tokenMarketState',
    initialState: { ...initialState },
    reducers: {
      getTokenMarketStateRequest: (state) => {
        state.loading = true;
        state.error = false;
      },
      getTokenMarketStateSuccess: (state, { payload }: PayloadAction<{ data: any }>) => {
        state.loading = false;
        state.data = payload.data;
      },
      getTokenMarketStateFailure: (state) => {
        state.loading = false;
        state.error = true;
      },
    },
  }),
  bsptTopGainers: createSlice({
    name: 'stats/bsptTopGainers',
    initialState: { ...initialState },
    reducers: {
      getBsptTopGainersRequest: (state) => {
        state.loading = true;
        state.error = false;
      },
      getBsptTopGainersSuccess: (state, { payload }: PayloadAction<{ data: any }>) => {
        state.loading = false;
        state.data = payload.data;
      },
      getBsptTopGainersFailure: (state) => {
        state.loading = false;
        state.error = true;
      },
    },
  }),
};

const actions = combineSliceActions(stats);

export { actions as StatsActions };

export default combineSliceReducers(stats);
