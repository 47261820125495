import React, { useState } from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { UserAltIcon } from 'icons';

const CustomizedAvatar = styled((props: any) => {
  const { fallbackicon = <UserAltIcon /> } = props;

  const [fallback, setFallback] = useState<boolean>(!props.src);

  return (
    <MuiAvatar
      {...props}
      imgProps={{
        onError: () => {
          setFallback(true);
        },
      }}
    >
      {fallback && fallbackicon}
    </MuiAvatar>
  );
})`
  background-color: white;
  & > .MuiAvatar-fallback {
    display: none;
  }
  & svg {
    width: 100%;
  }
`;

export default function Avatar(props: any) {
  return <CustomizedAvatar {...props} />;
}
