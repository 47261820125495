import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { ROUTE_404 } from 'constants/routes';
import { ProfileActions } from 'store/reducers/profile';

export function* getProfileSaga(action: ReturnType<typeof ProfileActions.getProfileRequest>) {
  const { id } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_PROFILE, { id }));

    yield put(ProfileActions.getProfileSuccess(data));

    if (data.role === 'celebrity') {
      yield put(ProfileActions.getProfileFollowersRequest({ id: data.id }));
      yield put(ProfileActions.getProfileSubscribersRequest({ id: data.id }));
    } else {
      yield put(ProfileActions.getProfileFollowingsRequest({ id: data.id }));
      yield put(ProfileActions.getProfileSubscribingsRequest({ id: data.id }));
    }
  } catch (error) {
    yield put(ProfileActions.getProfileFailure());
    yield put(push(ROUTE_404));
  }
}

export function* getProfileByNicknameSaga(action: ReturnType<typeof ProfileActions.getProfileByNicknameRequest>) {
  try {
    const { nickname } = action.payload;
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_PROFILE_BY_NICKNAME, { nickname }));

    yield put(ProfileActions.getProfileByNicknameSuccess(data));

    if (data.role === 'celebrity') {
      yield put(ProfileActions.getProfileFollowersRequest({ id: data.id }));
      yield put(ProfileActions.getProfileSubscribersRequest({ id: data.id }));
    } else {
      yield put(ProfileActions.getProfileFollowingsRequest({ id: data.id }));
      yield put(ProfileActions.getProfileSubscribingsRequest({ id: data.id }));
    }
  } catch (e) {
    yield put(ProfileActions.getProfileByNicknameFailure());
    yield put(push(ROUTE_404));
  }
}

export function* getProfileFollowersSaga(action: ReturnType<typeof ProfileActions.getProfileFollowersRequest>) {
  const { id } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.FOLLOWERS, { celebrityId: id }));

    yield put(ProfileActions.getProfileFollowersSuccess(data));
  } catch {
    yield put(ProfileActions.getProfileFollowersFailure());
  }
}

export function* getProfileFollowingsSaga(action: ReturnType<typeof ProfileActions.getProfileFollowingsRequest>) {
  const { id } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.FOLLOWINGS, { fanId: id }));
    yield put(ProfileActions.getProfileFollowingsSuccess(data));
  } catch {
    yield put(ProfileActions.getProfileFollowingsFailure());
  }
}

export function* getProfileSubscribersSaga(action: ReturnType<typeof ProfileActions.getProfileSubscribersRequest>) {
  const { id } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.SUBSCRIBERS, { celebrityId: id }));
    yield put(ProfileActions.getProfileSubscribersSuccess(data));
  } catch {
    yield put(ProfileActions.getProfileSubscribersFailure());
  }
}

export function* getProfileSubscribingsSaga(action: ReturnType<typeof ProfileActions.getProfileSubscribingsRequest>) {
  const { id } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.SUBSCRIBINGS, { fanId: id }));
    yield put(ProfileActions.getProfileSubscribingsSuccess(data));
  } catch {
    yield put(ProfileActions.getProfileSubscribingsFailure());
  }
}
