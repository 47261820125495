import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { royalBlue, mineShaft, waterLoo } from 'constants/colors';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    wrapper: {
      paddingRight: 20,
      '& a': {
        color: royalBlue,
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 40px',
      },
    },
    name: {
      color: mineShaft,
      textAlign: 'center',
    },
    date: {
      marginTop: '25px',
      color: waterLoo,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    ul: {
      marginTop: '8px',
      paddingLeft: '18px',
      '& h4': {
        marginLeft: '13px',
      },
    },
    item: {
      color: royalBlue,
    },
    ulLeters: {
      marginTop: '8px',
      paddingLeft: '18px',
      '& h4': {
        marginLeft: '13px',
      },
    },
    itemLeters: {
      color: mineShaft,
      fontWeight: 'bold',
      fontSize: '18px',
    },
    text: {
      color: mineShaft,
      marginTop: '16px',
      fontWeight: 'normal',
      lineHeight: 1.78,
    },
    textBig: {
      marginTop: '32px',
      color: mineShaft,
      fontWeight: 'normal',
      lineHeight: 1.78,
    },
    caption: {
      lineHeight: 1.25,
      color: mineShaft,
      marginTop: '32px',
    },
  };
});
