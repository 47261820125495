import React from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, Redirect } from 'react-router-dom';

import * as routePaths from 'constants/routes';
import { checkRoles } from 'helpers/routes';

import Route from 'shared/components/Route';

export interface PrivateRouteProps {
  children: React.ReactElement;
  component: any;
  roles?: string[];
  fullwidth?: boolean;
  allowedForAnonymouse?: boolean;
}

function PrivateRoute({ roles, component: Component, allowedForAnonymouse, ...rest }: RouteProps & Omit<PrivateRouteProps, 'children'>) {
  const {
    account: { role },
    wallet: {
      wallet: { address },
    },
  } = useSelector((state) => state);

  const connected = JSON.parse(localStorage.getItem('connected') as string);
  const isAccessPage = roles ? checkRoles(roles, role) : false;

  // restrict anonymouse users
  if (!allowedForAnonymouse && !address && !connected) {
    return <Redirect to={routePaths.ROUTE_404} />;
  }

  // restrict by role
  if (address && connected && !isAccessPage) {
    return <Redirect to={routePaths.ROUTE_404} />;
  }

  return <Route {...rest} component={Component} />;
}

PrivateRoute.defaultProps = {
  fullwidth: false,
  roles: [],
  allowedForAnonymouse: false,
};

export default PrivateRoute;
