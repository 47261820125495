
export interface TokenAccessState {
  [tokenAddress: string]: {
    [addressDestination: string]: AllowanceInfo;
  };
}

export enum TransactionStates {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface AllowanceInfo {
  initialAccessState: boolean;

  allowance: number;
  allowanceStatus: TransactionStates;

  approveHash: string;
  approveState: TransactionStates;

  balance: number;
  balanceState: TransactionStates;
}


