import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { SubscribingActions } from '../reducers/subscribing';

export function* getSubscribingListSaga(
  action: ReturnType<typeof SubscribingActions.getSubscribingListRequest>,
) {
  const { fanId } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.SUBSCRIBINGS, { fanId }));
    yield put(SubscribingActions.getSubscribingListSuccess({ data }));
  } catch {
    yield put(SubscribingActions.getSubscribingListFailure());
  }
}

export function* addSubscribingSaga(
  action: ReturnType<typeof SubscribingActions.addSubscribingRequest>,
) {
  const { fanId, celebrityId } = action.payload;

  try {
    yield axios.post(getLink(endpoints.SUBSCRIBINGS, { fanId }), [celebrityId]);

    yield put(SubscribingActions.addSubscribingSuccess());
    yield put(SubscribingActions.getSubscribingListRequest({ fanId }));
  } catch {
    yield put(SubscribingActions.getSubscribingListFailure());
  }
}

export function* deleteSubscribingSaga(
  action: ReturnType<typeof SubscribingActions.deleteSubscribingRequest>,
) {
  const { fanId, celebrityId } = action.payload;

  try {
    yield axios.delete(getLink(endpoints.SUBSCRIBINGS, { fanId }), { data: [celebrityId] });

    yield put(SubscribingActions.deleteSubscribingSuccess());
    yield put(SubscribingActions.getSubscribingListRequest({ fanId }));
  } catch {
    yield put(SubscribingActions.deleteSubscribingFailure());
  }
}
