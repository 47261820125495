import web3 from 'helpers/getWeb3';
import { ethers } from 'ethers';

// export const createERCDepositData = (tokenAmountOrID: string, lenRecipientAddress: string, recipientAddress: string) => {
//   return `0x${web3.utils.toHex(tokenAmountOrID).substr(2)}${web3.utils.toHex(lenRecipientAddress).substr(2)}${recipientAddress.substr(2)}`;
// };

// const toHex = (covertThis: any, padding: number) => {
//   console.log('typeof covertThis', typeof covertThis);
//   console.log('ethers.utils.hexlify(covertThis)', web3.utils.toHex(covertThis));
//   return ethers.utils.hexZeroPad(web3.utils.toHex(covertThis), padding);
// };

const toHex = (covertThis: number | bigint | ethers.utils.BytesLike | ethers.utils.Hexable, padding: number) => {
  return ethers.utils.hexZeroPad(ethers.utils.hexlify(covertThis), padding);
};

export const createERCDepositData = (tokenAmountOrID: any, lenRecipientAddress: any, recipientAddress: string) => {
  const amount = ethers.utils.parseUnits(tokenAmountOrID.toString(), 18);
  return `0x${toHex(amount, 32).substr(2)}${toHex(lenRecipientAddress, 32).substr(2)}${recipientAddress.substr(2)}`;
};

// export const createERCDepositData = (tokenAmountOrID: any, lenRecipientAddress: number, recipientAddress: string): string => {
//   return `0x${toHex(tokenAmountOrID, 32).substr(2)}${toHex(lenRecipientAddress, 32).substr(2)}${recipientAddress.substr(2)}`;
// };

export const createResourceID = (contractAddress: string, chainID: number) => {
  return toHex(contractAddress + toHex(chainID, 1).substr(2), 32);
};

export const fromWei = (value: string) => {
  return web3.utils.fromWei(value, 'ether');
};

export const toWei = (value: string) => {
  return web3.utils.toWei(value, 'ether');
};

export const makeBatchRequest = (callsRequests: any) => {
  const batch = new web3.BatchRequest();

  const promises = callsRequests.map((callItem: any) => {
    return new Promise((res, rej) => {
      const req = callItem.request((err: any, data: any) => {
        if (err) rej(err);
        else res(data);
      });
      batch.add(req);
    });
  });
  batch.execute();

  return Promise.all(promises);
};

export const makeBatchTransaction = (callsRequests: any) => {
  const batch = new web3.BatchRequest();

  const promises = callsRequests.map((callItem: any) => {
    return new Promise((res, rej) => {
      const req = callItem.request(callItem.params, (error: any, transactionHash: any) => {
        if (error) rej(error);
        else {
          res({ transactionHash });
        }
      });
      batch.add(req);
    });
  });
  batch.execute();

  return Promise.all(promises);
};

export const makeBatchWaitingTransaction = (callsRequests: any) => {
  const batch = new web3.BatchRequest();

  const receiptPromises = callsRequests.map((callItem: any) => {
    return new Promise((res, rej) => {
      const req = callItem.request(callItem.params, async (error: any, transactionHash: any) => {
        if (error) rej(error);
        else {
          await waitForTransactionReceipt(transactionHash).catch(() => null);
          res({ transactionHash });
        }
      });
      batch.add(req);
    });
  });
  batch.execute();

  return Promise.allSettled<{ transactionHash: string }>(receiptPromises);
};

const waitForTransactionReceipt = async (transactionHash: string, maxAttempts = 10, interval = 2000) => {
  let attempts = 0;
  let receipt = null;

  while (attempts < maxAttempts && receipt === null) {
    // eslint-disable-next-line no-await-in-loop
    receipt = await web3.eth.getTransactionReceipt(transactionHash).catch(console.error);
    attempts += 1;
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => setTimeout(resolve, interval));
  }

  if (receipt === null) {
    throw new Error('Transaction receipt not received within the specified time.');
  }

  return receipt;
};

export const makeTransaction = async ({ from, to, data, nonce, value }: { from: string; to: string; data: any; nonce?: number; value?: string }) => {
  const nonceValue = nonce || (await web3.eth.getTransactionCount(from));
  const gasPrice = await web3.eth.getGasPrice();
  const gasEstimate = await data.estimateGas({ from, value });
  return web3.eth.sendTransaction({ from, to, data: data.encodeABI(), gas: gasEstimate, gasPrice, nonce: nonceValue, value });
};
