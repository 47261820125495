import React from 'react';
import { Dialog, DialogContent, IconButton, DialogActions, Link, Typography, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { CloseIcon } from 'icons';
import { Link as RouteLink } from 'react-router-dom';
import { useDialogStyles } from '../styles';

interface MetaMaskProps {
  open: boolean;
  error: boolean;
  loading: boolean;
  isMetaMask: boolean;
  handleClose(e?: any): void;
  handleButton(e?: any): void;
}

const MetaMaskDialog = (props: MetaMaskProps) => {
  const { open, loading, error, isMetaMask, handleClose, handleButton } = props;

  const { classes: dialogClasses } = useDialogStyles();

  let title = <Typography variant="h3">Install MetaMask</Typography>;
  let description = <Typography variant="subtitle1">Install MetaMask to connect to BlockSport</Typography>;
  let button = "Go to MetaMask's website";

  if (isMetaMask) {
    title = <Typography variant="h2">It’s time to connect your wallet</Typography>;
    description = (
      <Typography variant="subtitle1">
        By connecting your wallet, you agree to our{' '}
        <RouteLink to="/terms">
          <Link color="secondary" underline="hover">
            <Box display="inline" onClick={handleClose}>
              Terms of Service
            </Box>{' '}
          </Link>
        </RouteLink>
        and our{' '}
        <RouteLink to="/privacy">
          <Link color="secondary" underline="hover">
            <Box display="inline" onClick={handleClose}>
              Privacy Policy
            </Box>
          </Link>
        </RouteLink>
        .
      </Typography>
    );
    button = 'MetaMask';
  }

  if (error) {
    title = <Typography variant="h3">Signature rejected</Typography>;
    description = <Typography variant="subtitle1">Please sign the message in MetaMask to continue.</Typography>;
    button = 'Retry';
  }

  return (
    <Dialog open={open} onClose={handleClose} className={dialogClasses.ConnectRoot} maxWidth="sm">
      <Box>
        <IconButton onClick={handleClose} className={dialogClasses.closeBtn} size="large">
          <CloseIcon />
        </IconButton>
        <Box mb={5} mt={5}>
          <DialogContent className={dialogClasses.content}>
            {title}
            <Box mt={3} />
            {description}
          </DialogContent>
        </Box>
        <DialogActions className={dialogClasses.actions}>
          <LoadingButton
            fullWidth={!isMetaMask}
            onClick={handleButton}
            variant="contained"
            className={dialogClasses.actionBtn}
            loadingIndicator="Connecting…"
            loading={loading}
          >
            {button}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default MetaMaskDialog;
