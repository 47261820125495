import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, IconButton, Typography, Divider } from '@mui/material';

import { CloseIcon } from 'icons';
import { getMoonPayBuyWidgetUrl } from 'helpers/links';
import { MoonpayActions } from 'store/reducers/moonpay';
import { useStyles } from './styles';
import { useDialogStyles } from '../styles';
import Loader from '../../components/Loader/Loader';

const MoonPayDialog = () => {
  const dispatch = useDispatch();
  const { classes: dialogClasses } = useDialogStyles();
  const { classes } = useStyles();

  const {
    wallet: { address },
  } = useSelector((state) => state.wallet);

  const { isOpen: open } = useSelector((state) => state.moonpay);

  const { moonpayURL, loading } = useSelector((state) => state.moonpay);

  const onClose = () => {
    dispatch(MoonpayActions.toggleMoonpayModal(false));
  };

  useEffect(() => {
    if (open) {
      dispatch(
        MoonpayActions.signMoonpayURLRequest({
          originUrl: getMoonPayBuyWidgetUrl(address),
        }),
      );
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <IconButton onClick={onClose} className={dialogClasses.closeBtn} size="large">
        <CloseIcon />
      </IconButton>
      <Typography variant="h3" className={dialogClasses.dividerTitle}>
        Add funds
      </Typography>
      <Divider className={dialogClasses.divider} />
      <DialogContent className={classes.moonPayContent}>
        {!loading ? <iframe title="MoonPay" className={classes.moonPay} src={moonpayURL || getMoonPayBuyWidgetUrl(address)} /> : <Loader />}
      </DialogContent>
    </Dialog>
  );
};

export default MoonPayDialog;
