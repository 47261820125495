export const ROUTE_HOME = '/';

export const ROUTE_MARKETPLACE = '/marketplace';

export const ROUTE_FAN_TOKEN = '/fan-token';

export const ROUTE_FAN_TOKEN_TEAM = '/fan-token/:nickname';

export const ROUTE_COLLECTIBLES_DETAILS = '/collectibles/:id/:tokenSource';

export const ROUTE_CELEBRITIES = '/celebrities';

export const ROUTE_CREATE_TOKEN = '/create-token';

export const ROUTE_EDIT_TOKEN = '/edit-token/:id/:tokenSource';

export const ROUTE_PROFILE_DETAILS = '/profile';

export const ROUTE_PROFILE_EDIT = '/profile-edit';

export const ROUTE_PROFILE_VIEW = '/profile/:nickname';

export const ROUTE_BIDS = '/bids';

export const ROUTE_PLACE_BID = '/place-bid/:id/:tokenSource';

export const ROUTE_404 = '/404';

export const ROUTE_INVITE = '/invite';

export const ROUTE_ABOUT = '/about';

export const ROUTE_PRIVACY = '/privacy';

export const ROUTE_PLAY_EARN = '/play-and-earn';
export const ROUTE_STAKE_FANTOKENS = '/play-and-earn/fantoken-stake';

export const ROUTE_HELP = '/help';

export const ROUTE_TERM = '/terms';

export const ROUTE_COOKIES_POLICY = '/cookies-policy';

export const DEPOSIT_NFT = '/deposit-nft';

export const ROUTE_INSTANT_PURCHASE = '/instant-purchase/:id/:tokenSource';
export const ROUTE_INSTANT_PURCHASE_MULTI = '/instant-purchase/:id/:sellerId/:tokenSource';

export const ROUTE_CONNECT = '/connect';

export const ROUTE_GAME_OWN_YOUR_PITCH = '/play-and-earn/own-your-pitch/partizan';
export const ROUTE_GAME_OWN_YOUR_PITCH_NBA = '/play-and-earn/own-your-pitch';
export const ROUTE_GAME_OWN_YOUR_PITCH_PURCHASE = '/play-and-earn/own-your-pitch/purchase/:eventId';

export const ROUTE_BSPT_BRIDGE = '/bspt-bridge';

export const ROUTE_DAO = '/dao';
export const ROUTE_DAO_PROPOSAL = '/dao/:id';
export const ROUTE_DAO_NEW = '/dao/create';

export const WHITE_PAY_ORDER_FAILURE = '/whitepay/order/failure';
export const WHITE_PAY_ORDER_SUCCESS = '/whitepay/order/success';
