import { put } from 'redux-saga/effects';
import { NetworkActions } from 'store/reducers/network';
import { PopupsActions } from 'store/reducers/popups';
import { networkConstants } from 'constants/network';
import { AlertActions } from '../reducers/alert';

declare const window: any;

export function* setNetworkSaga() {
  try {
    if (window.ethereum) {
      const chainId: string = yield window.ethereum.request({
        method: 'eth_chainId',
      });

      // @ts-ignore
      if (networkConstants.NETWORKS[+chainId]) {
        const {
          network: net,
          currency,
          link,
          // @ts-ignore
        } = networkConstants.NETWORKS[+chainId];

        yield put(NetworkActions.setCurrentNetwork({ network: net, currency, link, loading: false }));
      } else {
        yield put(NetworkActions.setCurrentNetwork({ network: 'eth', currency: 'ETH', link: '', loading: false }));
      }
    } else {
      yield put(NetworkActions.setCurrentNetwork({ network: 'eth', currency: 'ETH', link: '', loading: false }));
    }
  } catch (err: any) {
    yield put(AlertActions.showAlert({ message: err.message, severity: 'error' }));
  }
}

export function* changeNetworkSaga({ payload }: ReturnType<typeof NetworkActions.changeMetamaskRequest>) {
  const { networkKey } = payload;

  // @ts-ignore
  const network = networkConstants.NETWORK_CONFIGS[networkKey.toLowerCase()];

  const { chainId, chainName, rpcUrls, name, symbol, decimals, blockExplorerUrls } = network;

  try {
    yield window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
    yield put(NetworkActions.changeMetamaskSuccess());

    yield put(PopupsActions.setChangeNetwork({ changeNetwork: false, networkToChange: networkKey }));
  } catch (err: any) {
    // yield put(AlertActions.showAlert({ message: err.message, severity: 'error' }));
    // This error code indicates that a User rejected the request.
    if (err.code === 4001) {
      yield put(NetworkActions.setUserRejected());
    }
    // This error code indicates that the chain has not been added to MetaMask
    if ([4902, -32603].includes(err.code)) {
      yield window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainName,
            chainId,
            nativeCurrency: {
              name,
              symbol,
              decimals,
            },
            blockExplorerUrls,
            rpcUrls,
          },
        ],
      });
      yield put(NetworkActions.changeMetamaskSuccess());
    }
  }
}
