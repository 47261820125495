import React from 'react';
import { createTheme } from '@mui/material/styles';
import { white, darkCharcoal, oldSilver, bsBlue } from 'constants/colors';

const colors = {
	primary: darkCharcoal,
	secondary: oldSilver,

	// Buttons
	primaryBtnBg: 'linear-gradient(90deg, #3889FA 0%, #0942C9 100%)',
	primaryGradientBtnBg: 'linear-gradient(90deg, #408FFF 0%, #F224D0 100%)',
	primaryBtnHoverBg: `linear-gradient(90deg, ${bsBlue} 0%, ${bsBlue} 100%)`,
	containedPrimaryBtnDisabledBg: 'linear-gradient(90deg, #3889FA 0%, #0942C9 100%)',
	containedPrimaryBtnDisabledText: white,
	// containedSecondaryBtnDisabledBg: rgba(white, 0.4),
	// containedSecondaryBtnDisabledText: white,
	containedBtnText: white,
	// containedBtnHoverBg: 'linear-gradient(90deg, #3889FA 0%, #0942C9 100%)',
	// secondaryBtnBg: white,
	// secondaryBtnText: royalBlue,
	// secondaryBtnHoverBg: rgba(white, 0.9),
	// secondaryLargeBtnHoverText: royalBlue,
	// outlinedBtnDisabledBorder: rgba(royalBlue, 0.4),
	// outlinedBtnDisabledText: rgba(royalBlue, 0.6),
	// outlinedPrimaryBtnText: royalBlue,
	// outlinedPrimaryBtnBorder: royalBlue,
	// outlinedPrimaryBtnHoverBg: royalBlue,
	// outlinedPrimaryBtnHoverBorder: royalBlue,
	// outlinedPrimaryBtnHoverText: white,
	// outlinedSecondaryBtnHoverBg: royalBlue,
	// outlinedSecondaryBtnHoverText: white,
	// outlinedSecondaryBtnDisabledBorder: mercury,
	// outlinedSecondaryBtnDisabledText: silver,
};

declare module '@mui/material/styles' {
	interface TypographyVariants {
		big: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		big?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		big: true;
	}
}

const Blocksport = createTheme({
	typography: {
		fontFamily: "'Montserrat', sans-serif",
		htmlFontSize: 10,
		allVariants: {
			color: colors.primary,
		},
		big: {
			fontFamily: "'Big Shoulders Display', sans-serif",
			fontOpticalSizing: 'auto',
			fontWeight: 800,
			fontStyle: 'normal',
		},
	},
	palette: {
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.secondary,
		},
	},
	components: {
		MuiContainer: {
			styleOverrides: {
				maxWidthLg: ({ theme }) => ({
					[theme.breakpoints.up('lg')]: {
						maxWidth: '1290px',
					},
				}),
			},
		},
		MuiTypography: {
			styleOverrides: {
				h1: {
					fontSize: '3.6rem',
					fontWeight: 600,
					lineHeight: '4.4rem',
				},
				h2: {
					fontSize: '2.4rem',
					fontWeight: 600,
					lineHeight: '3.2rem',
				},
				h3: {
					fontSize: '2rem',
					fontWeight: 600,
					lineHeight: '3rem',
				},
				h4: {
					fontSize: '1.6rem',
					fontWeight: 600,
					lineHeight: '2.6rem',
				},
				h5: {
					fontSize: '1.4rem',
					fontWeight: 600,
					lineHeight: '1.7rem',
				},
				subtitle1: {
					fontSize: '1.6rem',
					fontWeight: 500,
					lineHeight: '2.6rem',
				},
				subtitle2: {
					fontSize: '1.4rem',
					fontWeight: 500,
				},
				body1: {
					fontSize: '1.6rem',
					lineHeight: '2.4rem',
				},
				body2: {
					fontSize: '1.4rem',
				},
				caption: {
					fontSize: '1.2rem',
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: 'transparent',
					'&.Mui-focused': {
						backgroundColor: 'transparent',
					},
					'&:hover': {
						backgroundColor: 'transparent',
					},
					'& input': {
						paddingLeft: 0,
						paddingBottom: '11px',
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'& input': {
						'&.Mui-disabled': {
							WebkitTextFillColor: 'inherit',
						},
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				text: {
					whiteSpace: 'nowrap',
				},
				root: {
					borderRadius: 30,
					fontWeight: 600,
					padding: '13px 44px',
					textTransform: 'capitalize',
					fontSize: '1.6rem',
					lineHeight: '2.4rem',
					whiteSpace: 'nowrap',
				},
				contained: {
					boxShadow: 'none',
					background: colors.primaryBtnBg,
					color: colors.containedBtnText,
					'&:active': {
						boxShadow: 'none',
						background: colors.primaryBtnHoverBg,
					},
					'&:hover': {
						background: colors.primaryBtnHoverBg,
						boxShadow: 'none',
					},
					'&.Mui-disabled': {
						backgroundColor: colors.containedPrimaryBtnDisabledBg,
						color: colors.containedPrimaryBtnDisabledText,
						opacity: 0.5,
					},
				},
				outlined: {
					fontFamily: 'Prompt, sans-serif',
					fontWeight: 500,
					fontSize: 16,
					outline: 'none',
					position: 'relative',
					zIndex: 1,
					borderRadius: 30,
					padding: '9px 26px',
					background: 'linear-gradient(114.49deg, #3889FA 1.31%, #0942C9 100%)',
					cursor: 'pointer',
					backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #3889FA, #0942C9)',
					WebkitBackgroundClip: 'text',
					border: '2px solid transparent',
					backgroundOrigin: 'border-box',
					backgroundClip: 'content-box, border-box',
					boxShadow: '2px 1000px 1px #fff inset',
					color: '#0942C9',

					'&:before': {
						content: '""',
						position: 'absolute',
						left: 2,
						right: 2,
						top: 2,
						bottom: 2,
						borderRadius: 30,
						backgroundColor: 'white',
						zIndex: -1,
						transition: '200ms',
					},
					'&:after': {
						content: 'attr(data)',
						fontSize: '16px',
						background: 'linear-gradient(114.49deg, #3889FA 1.31%, #0942C9 100%)',
						WebkitBackgroundClip: 'text',
						color: 'transparent',
						transition: '200ms',
					},

					'&:hover': {
						color: '#3889FA',
						border: '2px solid #3889FA',
					},

					'&.Mui-disabled': {
						opacity: 0.4,
						color: '#0942C9',
						'&:after': {
							display: 'none',
						},
					},
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgba(26, 26, 26, 0.7)',
				},
				invisible: {
					backgroundColor: 'transparent',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 10,
				},
				elevation1: {
					boxShadow: `0px 20.25px 34.875px rgba(0, 0, 0, 0.04725), 0px 8px 13px rgba(0, 0, 0, 0.035)`,
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				bar: {
					background: 'linear-gradient(90deg, #3788F9 0%, #0A44CA 100%)',
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: 6,

					'&.MuiChip-filledWarning': {
						backgroundColor: '#F2994A',
					},
					'&.MuiChip-filledPrimary': {
						backgroundColor: '#3889FA',
					},
					'&.MuiChip-filledDefault': {
						backgroundColor: '#828282',
					},
					'&.MuiChip-sizeMedium': {
						height: 28,
					},
				},
				label: {
					fontWeight: 700,
					fontSize: '1.4rem',
					lineHeight: '24px',
					letterSpacing: '0.02em',
					color: '#FFFFFF',
					padding: '2px 10px',
				},
				icon: {
					'&.MuiChip-iconMedium': {
						width: 11,
						marginRight: 2,
						marginLeft: 10,
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontweight: 400,
					fontSize: '1.2rem',
					lineHeight: '15px',
					color: '#FFFFFF',
					background: '#828282',
					borderRadius: 10,
					padding: 8,
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					borderRadius: 10,
					padding: '8px 16px',
					fontSize: '1.4rem',
					fontFamily: "'Montserrat', sans-serif",
					// lineHeight: '2.4rem',
					// letterSpacing: '0.02em',
					color: '#333333',
					backgroundColor: '#FFFFFF',
					fontWeight: 600,
				},
				// message: {
				// 	padding: 0,
				// },
				// icon: {
				// 	marginRight: 8,
				// },
			},
		},
	},
});

export default Blocksport;

export const digitsFont = createTheme({
	typography: {
		fontFamily: `'digital-7regular'`,
	},
});
