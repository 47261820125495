import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { royalBlue, mineShaft, waterLoo } from 'constants/colors';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    wrapper: {
      padding: '0 12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& a': {
        color: royalBlue,
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 40px',
      },
    },
    name: {
      color: mineShaft,
      textAlign: 'center',
      marginBottom: 25,
    },
    date: {
      color: waterLoo,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    ul: {
      marginTop: '8px',
      paddingLeft: '18px',
    },
    item: {
      color: royalBlue,
    },
    text: {
      color: mineShaft,
      marginTop: 16,
      fontWeight: 'normal',
      lineHeight: 1.78,
    },
    textTop: {
      color: mineShaft,
      marginTop: 40,
      fontWeight: 'normal',
      lineHeight: 1.78,
    },
    textBig: {
      marginTop: 32,
      color: mineShaft,
      fontWeight: 'normal',
      lineHeight: 1.78,
    },
    caption: {
      lineHeight: 1.25,
      color: mineShaft,
      marginTop: 32,
      marginBottom: 16,
      fontSize: 32,
    },
    block: {},
    ulLeters: {
      paddingLeft: '18px',
      '& h4': {
        marginLeft: '13px',
      },
      marginBottom: 0,
    },
    itemLeters: {
      color: mineShaft,
      fontWeight: 'bold',
      fontSize: '18px',
    },
  };
});
