import axios, { AxiosError } from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { FanToken, FanTokenPrice } from 'entities/Fantoken';
import { FanTokenActions } from '../reducers/fantoken';
import { NotificationActions } from '../reducers/notification';

export function* getTokenListRequestSaga() {
	try {
		const { data } = yield axios.get(endpoints.FAN_TOKEN_GET_TOKEN_LIST);
		yield put(FanTokenActions.getTokenListSuccess({ data: data.reduce((acc: [], cur: FanToken) => ({ ...acc, [cur.symbol]: { ...cur } }), {}) }));
	} catch (error) {
		yield put(FanTokenActions.getTokenListFailure());
	}
}

export function* getTokenRequestSaga(action: ReturnType<typeof FanTokenActions.getTokenRequest>) {
	const { tokenSymbol } = action.payload;

	try {
		const { data } = yield axios.get(getLink(endpoints.FAN_TOKEN_GET_TOKEN, { tokenSymbol }));
		const price = data.prices.find((item: FanTokenPrice) => item.isDefault === true) || data.prices[0];
		yield put(FanTokenActions.getTokenSuccess({ data: { ...data, price } }));
	} catch (error) {
		yield put(FanTokenActions.getTokenFailure());
		const err = error as AxiosError;
		if (err.response?.status === 404) {
			yield put(FanTokenActions.setFanTokenServiceStatus({ ...{ tokenSymbol }, serviceAvailable: false }));
		}
	}
}

export function* getTransactionFeeSaga(action: ReturnType<typeof FanTokenActions.getTransactionFeeRequest>) {
	const { tokenSymbol } = action.payload;

	try {
		const { data } = yield axios.get(getLink(endpoints.FAN_TOKEN_GET_TRANSACTION_FEE, { tokenSymbol }));
		yield put(FanTokenActions.getTransactionFeeSuccess({ ...{ tokenSymbol }, ...data }));
	} catch (error) {
		yield put(FanTokenActions.getTransactionFeeFailure());
	}
}

export function* getAccountBalanceSaga(action: ReturnType<typeof FanTokenActions.getAccountBalanceRequest>) {
	const { address } = action.payload;

	try {
		const { data } = yield axios.get(getLink(endpoints.FAN_TOKEN_GET_ACCOUNT_BALANCE, { address }));
		yield put(FanTokenActions.getAccountBalanceSuccess({ data }));
	} catch (error) {
		yield put(FanTokenActions.getAccountBalanceFailure());
	}
}

export function* getAccountTransactionsSaga(action: ReturnType<typeof FanTokenActions.getAccountTransactionsRequest>) {
	const { address } = action.payload;

	try {
		const { data } = yield axios.get(getLink(endpoints.FAN_TOKEN_GET_ACCOUNT_TRANSACTIONS, { address }));
		yield put(FanTokenActions.getAccountTransactionsSuccess({ data }));
	} catch (error) {
		yield put(FanTokenActions.getAccountTransactionsFailure());
	}
}

export function* sendRecieptSaga(action: ReturnType<typeof FanTokenActions.sendRecieptRequest>) {
	const { paymentIntentId, receiptEmail } = action.payload;

	try {
		yield axios.patch(getLink(endpoints.FAN_TOKEN_SEND_RECIEPT, { paymentIntentId }), { receiptEmail });
		yield put(FanTokenActions.sendRecieptSuccess());
		yield put(
			NotificationActions.pushNotification({
				message: 'Receipt was sent',
				options: {
					variant: 'success',
				},
			}),
		);
	} catch (error) {
		yield put(FanTokenActions.sendRecieptFailure());
		yield put(
			NotificationActions.pushNotification({
				message: 'Error sending reciept',
				options: {
					variant: 'error',
				},
			}),
		);
	}
}
