import React, { ReactNode, CSSProperties } from 'react';
import { Box, FormControl, FormHelperText, MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';
// import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ISelectOption } from 'entities/Options';
import { CheckIcon, ExpandMoreIcon } from 'icons';
import { useStyles } from './styles';

type SelectPropsDef = {
  fullWidth?: boolean;
  helperText?: ReactNode;
  options: ISelectOption[];
  styleProps?: CSSProperties;
  type?: string;
  error?: boolean;
};

export type SelectProps = MuiSelectProps & SelectPropsDef;

const Select = (props: SelectProps) => {
  const { options, value, helperText, fullWidth, error, styleProps, placeholder, type, ...rest } = props;
  const { classes, cx } = useStyles({ customStile: { ...styleProps } });

  return (
    <FormControl fullWidth={fullWidth}>
      <MuiSelect
        value={value}
        error={error}
        variant={type === 'standard' ? 'standard' : 'outlined'}
        fullWidth
        displayEmpty
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          hideBackdrop: false,
          classes: {
            paper: classes.menuPaper,
          },
          MenuListProps: {
            classes: {
              root: classes.menuListRoot,
            },
          },
        }}
        className={cx({ [classes.customStile]: (styleProps?.width && true) || false }, 'MuiCustomSelect')}
        classes={{
          icon: type === 'period' ? classes.periodIcon : classes.standardIcon,
          select: classes.select,
        }}
        renderValue={(val) => {
          if (!val) {
            return <span className={classes.placeholder}>{placeholder}</span>;
          }
          const selectedOption = options.find((option) => option.value === val);
          return selectedOption ? (
            <Box className={cx(classes.menuItemContent, { [classes.menuItemContentPeriod]: type === 'period' })}>
              {selectedOption.icon}
              <span>{selectedOption.label}</span>
            </Box>
          ) : (
            <span>{`${val}`}</span>
          );
        }}
        {...rest}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              classes={{
                root: classes.menuItemRoot,
                selected: classes.menuItemSelected,
              }}
            >
              <Box
                className={cx(classes.menuItemContent, {
                  [classes.menuItemContentOffset]: !option.icon,
                })}
              >
                {option.icon}
                <span>{option.label}</span>
              </Box>
              {option.value === value && <CheckIcon width={24} height={24} />}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {/* <div className={cx({ [classes.periodBg]: type === 'period' })} /> */}
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Select.defaultProps = {
  type: 'standard',
  fullWidth: false,
  styleProps: { width: '100%', height: 32, margin: '16px 0 0', padding: '4px 0', textAlign: 'left' },
};

export default Select;
