import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { ProfileActions } from 'store/reducers/profile';
import { FollowingActions } from '../reducers/following';

export function* getFollowingListSaga(
  action: ReturnType<typeof FollowingActions.getFollowingListRequest>,
) {
  const { fanId } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.FOLLOWINGS, { fanId }));

    yield put(FollowingActions.getFollowingListSuccess({ data }));
  } catch {
    yield put(FollowingActions.getFollowingListFailure());
  }
}

export function* addFollowingSaga(action: ReturnType<typeof FollowingActions.addFollowingRequest>) {
  const { fanId, celebrityId } = action.payload;

  try {
    yield axios.post(getLink(endpoints.FOLLOWINGS, { fanId }), [celebrityId]);

    yield put(FollowingActions.addFollowingSuccess());
    yield put(FollowingActions.getFollowingListRequest({ fanId }));
    yield put(ProfileActions.getProfileFollowersRequest({ id: celebrityId }));
  } catch {
    yield put(FollowingActions.getFollowingListFailure());
  }
}

export function* deleteFollowingSaga(
  action: ReturnType<typeof FollowingActions.deleteFollowingRequest>,
) {
  const { fanId, celebrityId } = action.payload;

  try {
    yield axios.delete(getLink(endpoints.FOLLOWINGS, { fanId }), { data: [celebrityId] });

    yield put(FollowingActions.deleteFollowingSuccess());
    yield put(FollowingActions.getFollowingListRequest({ fanId }));

    yield put(ProfileActions.getProfileFollowersRequest({ id: celebrityId }));
  } catch {
    yield put(FollowingActions.deleteFollowingFailure());
  }
}
