import { royalBlue } from 'constants/colors';
import { bscTestnetUrl, bscMainnetUrl, ethMainnetUrl, ethTestnetUrl, polyTestnetUrl, polyMainnetUrl, wbtTestnetNode, wbtMainnetNode } from 'constants/url';
import { isMobile } from 'helpers/isMobile';
import { v4 as uuidv4 } from 'uuid';

export function getImageFullLink(link: string) {
  // /static/media - for imported assets
  if (link.startsWith('http') || link.startsWith('/static/media')) return link;
  return `${process.env.REACT_APP_BASE_API}/media/${link}`;
}

export function getIPFSLink(link: string) {
  return link.replace('ipfs://', 'https://ipfs.io/');
}

export function getProfileLink(address: string) {
  return `${window.origin}/profile/${address}`;
}

export function getAddressLink(address: string, network: string) {
  let urlBsc = `${bscTestnetUrl}/address/`;
  let urlEth = `${ethTestnetUrl}/address/`;
  let urlPolygon = `${polyTestnetUrl}/address/`;
  let urlWhitechain = `${wbtTestnetNode}/address/`;

  if (process.env.REACT_APP_ENV === 'production') {
    urlBsc = `${bscMainnetUrl}/address/`;
    urlEth = `${ethMainnetUrl}/address/`;
    urlPolygon = `${polyMainnetUrl}/address/`;
    urlWhitechain = `${wbtMainnetNode}/address/`;
  }

  let url = '';

  switch (network.toLowerCase()) {
    case 'bsc':
      url = urlBsc;
      break;
    case 'polygon':
      url = urlPolygon;
      break;
    case 'whitebit':
      url = urlWhitechain;
      break;
    default:
      url = urlEth;
  }

  return `${url}${address}`;
}

export function getMoonPayBuyWidgetUrl(address: string) {
  const url = `${process.env.REACT_APP_MOONPAY_URL}?apiKey=${process.env.REACT_APP_MOONPAY_API_KEY}&walletAddress=${address}&colorCode=${encodeURIComponent(royalBlue)}`;

  return url;
}

export function getPostlink(postId: string, address: string) {
  return `${window.origin}/profile/${address}#${postId}`;
}

export function getMetamaskWebsiteUrl() {
  return isMobile() ? `https://metamask.app.link/dapp/${window.location.href}` : 'https://metamask.io/download.html';
}

export const generateFilename = () => {
  return uuidv4();
};
