import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 580,
      padding: 32,
      borderRadius: 8,
      border: `1px solid ${colors.profileBorder}`,
      backgroundColor: colors.profileBackground,
      textAlign: 'center',
    },
    title: {
      margin: '24px 0 16px',
    },
    btn: {
      marginTop: 32,
      width: 188,
    },
  };
});
