import React from 'react';
import SEO from 'shared/components/Seo';
import { Box, Typography, Container } from '@mui/material';

import { useStyles } from './styles';

function TermsAndConditions() {
  const { classes } = useStyles();

  return (
    <>
      <SEO title="Terms and Conditions" />
      <Container>
        <Box className={classes.wrapper}>
          <Typography variant="h1" className={classes.name}>
            Terms & Conditions
          </Typography>
          <Typography variant="body2" className={classes.date}>
            Effective date: 01/10/2021
          </Typography>
          <Box className={classes.block}>
            <Typography variant="h4" className={classes.textTop}>
              This Terms & Conditions (hereinafter referred to as the <strong>Agreement</strong>), shall constitute contractual relationship between BLOCKSPORT AG
              (hereinafter referred to as the <strong>Service Provider or Blocksport</strong>) with its registered office at Dammstrasse 16, 6300 Zug, Switzerland, and
              You (hereinafter referred to as the <strong>User</strong>), collectively referred to as the <strong>Parties</strong>, and each separately – the{' '}
              <strong>Party</strong>.{' '}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Terms & Definitions
            </Typography>
            <Typography variant="h4" className={classes.text}>
              {' '}
              The Parties agree that the following terms used in the text of this Agreement shall have the following definitions.
            </Typography>
            <ol type="a" className={classes.ulLeters}>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  Application means mobile applications or any other application software built for any other platform under the title “Blocksport” or any other title
                  designed as an online marketplace technology providing its users, including the User, possibility to create, purchase, sell or exchange the Products via
                  auction or within any other procedure defined by the Service Provider.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  Website means the website of the Service Provider under the domain name “
                  <a href={`${window.location.host}/`} target="_blank" rel="noreferrer">
                    {`${window.location.host}/`}
                  </a>
                  ” located on the Internet at the following link:{' '}
                  <a href={`${window.location.origin}/`} target="_blank" rel="noreferrer">
                    {`${window.location.origin}/`}
                  </a>
                  , through which, in particular, the Application is accessible to the Service Provider’s users, including the User, and through which the Service
                  Provider delivers the Services to its users, including the User.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  Exclusive Rights mean the scope of all exclusive intellectual property and any other similar or related rights owned by the Service Provider, which
                  includes, in particular, all without exception exclusive rights to the possession, use and disposal in any way of the Objects, any parts and/or elements
                  thereof, as well as to prohibit and prevent in any way the use of the Objects, any parts and/or elements thereof by other persons.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  License means a written permission to use the Application and Website.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  License Territory means country of residence and/or location of the User.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  Objects mean the Application, Website, any technology, trademarks, inventions, designs, copyright objects, logos, graphical or other materials or
                  objects, including any parts and/or elements thereof, which under applicable law are or may be construed as intellectual property or any other similar
                  or related objects, accessed through Blocksport’s Services, or in any other way with the direct or indirect involvement or under the control of
                  Blocksport.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  Products mean any digital and virtual assets, including without limitation non-fungible tokens (NFTs) and Fan Tokens.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  Effective Date means the date the User accepts the terms and conditions of this Agreement in one or more ways referred to in Paragraph 2 of this
                  Agreement.
                </Typography>
              </li>
            </ol>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Contracting
            </Typography>
            <Typography variant="h4" className={classes.text}>
              By accessing and/or using the Services hereunder, including without limitation by creating a profile or account within Blocksport’s system, the User
              acknowledges and expressly and unambiguously agrees to the terms and conditions set forth by this Agreement, any annexes hereto and contracting as well as
              to the Privacy Notice, Cookie Policy and any other documents referred to herein.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Agreement shall be deemed to have been executed upon the moment the User accepts this Agreement which may be performed, in particular, by:
            </Typography>
            <ol type="a" className={classes.ulLeters}>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  commencement of actual use of the Services;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  the User’s registration on the Website, in the Application or within any other Blocksports’s system, including by creating an account or profile
                  therein;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  putting an electronic signature in any form permitted by applicable law;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  performing by the User any other actions testifying to the effect that the User has accepted the terms and conditions of this Agreement.
                </Typography>
              </li>
            </ol>
            <Typography variant="h4" className={classes.text}>
              The Effective Date shall be calculated upon performance by the User of any of the actions specified in this Paragraph, which took place first.{' '}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Scope of the agreement
            </Typography>
            <Typography variant="h4" className={classes.text}>
              This Agreement shall regulate the provision by the Service Provider to the User of a certain set of services. Under this Paragraph and other terms of the
              Agreement, the Service Provider shall render to the User the following services (hereinafter referred to as the Services):
            </Typography>
            <ol type="a" className={classes.ulLeters}>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  providing the User access to use the Application, Website, including, but not limited to, by granting access to information and another content posted
                  or available in the Application or on the Website, as well as providing the User access to create, purchase, sell or exchange the Products via auction
                  or within any other procedure defined by the Service Provider.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  providing technical support of the Application and Website to the extent necessary for its proper functioning and use by the User.
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  providing the User with any other services related to the services specified in this Paragraph, both referred to in the Agreement and those not directly
                  specified in the Agreement, but by virtue of their nature arising out of the legal relationship between the Service Provider and the User developed on
                  the basis of the Agreement, and are necessary for the provision of the services directly referred to herein.
                </Typography>
              </li>
            </ol>
            <Typography variant="h4" className={classes.text}>
              The list of the Services defined in this Paragraph is exhaustive and is not subject to extended interpretation. The Service Provider may, at his own
              discretion, unilaterally amend the list of the Services specified in this Paragraph, subject to prior notice to the User in the manner referred to in
              Section 2 of Paragraph 4 of this Agreement.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Services specified in this Paragraph may be provided both free of charge and for a fee. The Service Provider shall be entitled at any time to
              unilaterally change these terms, in particular make the Services exclusively fee-based, subject to prior notice to the User in the manner referred to in
              Section 2 of Paragraph 4 of this Agreement.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Services specified in this Paragraph at the discretion of the Service Provider may be provided both together and separately from each other, as well as
              whether upon the condition of the obligatory registration of the User in the Application, on the Website or within any other information system of the
              Service Provider and setting up of a user account in the Application, on the Website or within any other information system of the Service Provider or
              without such the condition.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Service Provider may render the Services specified in this Paragraph in person or engage for such purposes any third parties contracted with the Service
              Provider or related thereto in any other form. The Service Provider shall not require any User’s consent to engage any third party to render the Services
              hereunder.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Services are delivered to the User on an “as-is” basis and without warranties of any kind, expressed or implied, guarantees or conditions with regard to
              such use of the Services, ie the Service Provider delivers the Services in their present condition and the User accepts these Services with all defects that
              are obvious at the time of providing the Services, as well as those that may be exposed later.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Services specified in Section 3 (b) of this Paragraph shall be rendered provided the Service Provider has an appropriate technological capacity.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Failure by the User to perform the obligations hereunder shall be reason for the Service Provider to withhold the Services as well as cancel obligations
              provided for herein, in particular by restricting the User's access to the Services, Application or Website, as well as any parts and/or elements thereof.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Payment & Settlements
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Service Provider may charge the User fees for accessing and using the Services hereunder, in particular by withdrawing the commission fee for any
              transactions performed by users, including the User, while using the Services. Some of the Services may also be charged on a subscription basis, inter alia
              payable upfront. The relevant fees may be set and charged for all the Services provided for herein as well as any part thereof.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Service Provider at his own discretion may set the amount to be charged for the Services specified herein by publishing the relevant information in the
              Application or Website, by providing links in the Application or on the Website to the sources through which the relevant information can be easily accessed
              by the User, including, but not limited to, by email notification and in any other way which allows an unlimited number of persons, including the User, to
              get acquainted with the amount to be charged for the Services hereunder. The Service Provider hereunder shall use only those contact details of the User
              that are provided by the latter in execution of the terms envisaged in Paragraph 7 of the Agreement.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Unless otherwise provided for by applicable law, the fees set by the Service Provider for the Services specified herein, shall exclude any taxes, fees,
              other mandatory payments, except for value added tax, if any, expenses related to bank charges, currency exchange/purchase/sale and conversion fees as well
              as any other additional payments and expenses that may occur when paying for the Services hereunder. Unless otherwise provided for by applicable law, the
              User is solely responsible for paying all the charges referred to in this Section, except for value added tax, if any. All taxes and fees payable by the
              Service Provider in relation to the income/profit received by the Service Provider for the provision of the Services specified herein, as well as other
              indirect taxes shall be executed pursuant to applicable law.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              To access and use the Services, the User must provide one or more payment methods suggested by the Service Provider. The information as to the payment
              methods shall be communicated to the User as provided for in Section 2 of this Paragraph. The Service Provider engages third party payment providers and
              uses their services to process and/or accept the payments due to the Service Provider hereunder.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              If technically possible, the User may change/update the selected methods of payment for the Services through the User's personal account or profile in the
              Application or Website or within another information system of the Service Provider, if any, and/or in any other way specified by the Service Provider in
              the manner set out in Section 2 of this Paragraph. The Service Provider may also change/update the methods of payment for the Services, and following such
              the change/update payments for the Services shall be made subject to the changed/updated methods of payment. In the latter instance, should the
              changed/updated payment methods differ from the previously used payment method, the User must choose and specify a new method of payment for the Services in
              the manner specified above in this Section. In the aftermath of any change or update of payment methods specified in this Section, the Service Provider is
              entitled to keep charging the applicable payment methods.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Any payments made by the User to the Service Provider for the Services hereunder or providing access thereto, are non-refundable, unless otherwise expressly
              determined by the Service Provider or provided for by applicable law, including, but not limited to, the Service Provider shall not refund money for
              partially used or unused Services, including for the Services provided on a subscription or advance-payment basis. The User may at any time cancel the
              fee-based Services, in particular those payable upfront. Should this be the case, the User shall continue to enjoy access to these Services prior the end of
              the billing period, if any, and following the end of the billing period, if any, the Service Provider may deactivate the profile or personal account of the
              User, if any, in the Application or on the Website or within any other information system of the Service Provider.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Payment for the Services hereunder shall be made during the entire term of the Agreement and/or provision of the Services, including without limitation for
              every transaction performed by the Service Provider’s users, including the User. The terms and periods of payments for the Services shall be determined by
              the Service Provider in the manner provided for in Section 2 of this Paragraph.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              All commissions, other one-time and periodical payments made or due to be made hereunder, shall constitute the total value of this Agreement.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Any payments hereunder shall be made by wire transfer or in any other form not prohibited by applicable law, including without limitation ETH and fiat
              payments over payment gateways, upon obligatory observance of currency, financial and fiscal laws.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Intellectual property
            </Typography>
            <Typography variant="h4" className={classes.text}>
              ll Exclusive Rights, shall be owned exclusively by the Service Provider, whereby the Service Provider while providing the Services hereunder may at his own
              discretion use the Objects, as well as any other objects, including any parts and/or elements thereof created by the Service Provider while providing the
              Services referred to herein.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              To provide the Services referred to herein, the Service Provider grants the User a non-exclusive, limited and revocable License applicable to the License
              Territory. The License applies only to the Application and Website, and may be revoked at any time by the Service Provider. The License can be both on a
              paid basis or free of charge. Should the License is fee-paying, the fee for the License may be included in the Services fee provided that the Services are
              also fee-paying, or be charged separately under the conditions the Service Provider deems appropriate. The License hereunder is issued to the User for the
              term of this Agreement.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The rights granted under the License may be used by the User only for personal, non-commercial purposes. Personal, non-commercial use of the rights under
              the License also means, in particular, the use of such the rights for personal educational purposes. Compliance with this provision shall be regarded as
              conditio sine qua non. The User may not in any way transfer, grant any permissions/licenses/sublicenses to use the rights granted under the License, to
              third parties, including, but not limited to, by entering into partnership, license or any other agreement, except as provided for herein or by consent of
              the Service Provider. Violation by the User of the provisions provided for in this Paragraph shall constitute a material breach of the Agreement.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The User hereunder is also strictly prohibited to:
            </Typography>
            <ol type="a" className={classes.ulLeters}>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  issue or grant permissions/licenses/sublicenses for the use of the Objects, including any parts and/or elements thereof and/or any rights thereto, to
                  third parties;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  sell or assign the Objects, including any parts and/or elements thereof and/or any rights thereto;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  export or import the Objects, including any parts and/or elements thereof and/or any rights thereto;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  transfer, transmit or distribute in whatever form and by whatever medium the Objects, including any parts and/or elements thereof and/or any rights
                  thereto;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  make any modification in the Objects, including any parts and/or elements thereof, make any changes thereto or otherwise disintegrate them;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  perform engineering analysis of the Application or Website, including any parts and/or elements thereof, so as to reproduce, decompile, decode,
                  disintegrate the Application’s or Website’s security system, restore or attempt to restore the source code or protocols of the Application or Website or
                  any parts and/or elements and/or functionalities thereof;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  use the Objects, including any parts and/or elements thereof, as part of any commercial products and/or services;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  otherwise violate the Exclusive Rights.
                </Typography>
              </li>
            </ol>
            <Typography variant="h4" className={classes.text}>
              Any transactions as to the Products performed by the users, including the User, while using the Services, does not entail any transfer (assignment) of any
              intellectual property or similar/related rights to any objects such the Products contain or embody.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Service Provider shall retain any and all rights not expressly granted or transferred hereunder to the User.{' '}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Representations & warranties
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The User hereby represents and warrants that:
            </Typography>
            <ol type="a" className={classes.ulLeters}>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  signing the Agreement by the User in no way infringes the rights, freedoms and legitimate interests of third parties;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  the User has the necessary and sufficient legal capacity and authority to enter into the Agreement;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  the conclusion of this Agreement does not in any way violate his rights and legitimate interests, as well as does not in any way restrict his legal
                  capacity;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  the Service Provider’s ownership of any rights claimed or specified in this Agreement, shall be unconditionally recognized by the User and not be
                  challenged by him in a court or in any other manner;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  the User shall not violate any absolute rights of the Service Provider, including, but not limited to, the Exclusive Rights;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  all information, regardless of the form (format) thereof, provided to the Service Provider in accordance with the terms of the Agreement, is accurate
                  and true;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  during the use of the Services hereunder the User will comply with all applicable laws necessary to use the Services, including the laws of the country
                  where the User is a resident;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  during the use of the Services hereunder the User will not participate in any fraudulent or false suggestion actions.
                </Typography>
              </li>
            </ol>
            <Typography variant="h4" className={classes.text}>
              The Parties hereby acknowledge and agree that:
            </Typography>
            <ol type="a" className={classes.ulLeters}>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  this Agreement is not an agreement on the transfer of ownership of the Exclusive Rights, which implies that the Service Provider reserves the ownership
                  right to such the Exclusive Rights;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  this Agreement is not a partnership Agreement and that this Agreement does not create an employment or labour relationship governed by labour law;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  the Service Provider only provides access to the platform and facilitates the transactions between the users who participate in purchase, sale or
                  exchange of the products in the auction or otherwise, and is not a party to any agreement between them as well as not a broker, financial institution,
                  creditor or any other similar entity.
                </Typography>
              </li>
            </ol>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Personal data
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The use by the User of the Services hereunder is also subject to the Service Provider’s Privacy Notice (hereinafter referred to as the Policy) adopted and
              published by the Service Provider in the Application or on the Website.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              To use the Services hereunder, in particular to have access thereto, and perform other obligations of the Service Provider defined herein, the User must
              provide/transfer to the Service Provider a certain amount of personal data. The list of such personal data shall be determined by the Policy and privacy
              request. The User must also provide to the Service Provider any other reliable information necessary for the proper provision of the Services.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The User hereby gives his consent to the processing of his personal data in accordance with the Policy. To comply by the Service Provider with the Agreement
              and statutory requirements of applicable law, including, but not limited to, the General Data Protection Regulation adopted by the European Parliament and
              the Council of the European Union, in particular as regards to meeting by the Service Provider the requirements as to taking appropriate measures for the
              storage and protection of such personal data and other information provided to the Service Provider, the User also consents to the transfer of his personal
              data and other information provided for in this Paragraph, to third parties, including non-residents of European Union or those outside thereof
              (cross-border processing/transfer of personal data/other information).{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              By consenting to the processing of personal data, accessing to/accepting this Agreement in the manner described herein and within the limits permitted by
              applicable law, gaining access to or continuing to use the Services, in particular by registering or creating an account or profile in the Application or on
              the Website or within another information system of the Service Provider, the User shall automatically be deemed to have consented to the processing of his
              personal data in the manner described in the Policy. The Service Provider, where applicable, may request the User to provide a separate consent to the
              processing of his personal data in the manner prescribed by the Policy.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The User hereby acknowledges that he has acquainted himself with the Policy and is duly notified of the amount of personal data being collected and
              processed by the Service Provider.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              To comply with this Agreement, as well as to prevent violations of the rights and interests of the User and/or third parties, the Service Provider in the
              course of his activities as to personal data protection shall be guided by all applicable laws, in particular the requirements of the General Data
              Protection Regulation adopted by the European Parliament and the Council of the European Union. When processing personal data and/or determining the
              purposes for which personal data are processed, the Service Provider shall take all necessary measures and actions, including, but not limited to, by
              developing and implementing appropriate documents, in particular agreements with third parties, creating and implementing appropriate technical capabilities
              aimed at protecting such personal data, as well as preventing unauthorized access to such personal data and/or illegal use thereof by third parties.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Enforcement actions
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The User may notify the Service Provider of the fact of violation of his rights and/or legitimate interests by the Service Provider, other users or third
              parties, if such violation occurred during the provision of the Services and/or using the Application or Website. To exercise the right provided for in this
              Paragraph, the User shall notify in free format the Service Provider of the relevant fact of violation by email or otherwise using the contact details of
              the Service Provider referred to herein. When the notification received, the Service Provider, when applicable, may take reasonable measures targeted at
              restoring the violated rights and/or legitimate interests of the User who sent the notification.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Should the User violate any terms of this Agreement, any appendixes hereto or any other rules and documents referred to in this Agreement, the Service
              Provider may take against the User, in particular, the following enforcement actions:
            </Typography>
            <ol type="a" className={classes.ulLeters}>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  lock or restrict the User's access to the Services, Application or Website, including any parts and/or elements thereof, for any period specified by the
                  Service Provider;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  lock, restrict or otherwise interfere with the User's transmission of information or any other content performed through the Application or Website;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  lock or restrict in any other way the User's access to his personal account or profile in the Application or on the Website or within any other
                  information system operated by the Service Provider, if any.
                </Typography>
              </li>
            </ol>
            <Typography variant="h4" className={classes.text}>
              The list of the enforcement actions defined in this Paragraph is not exhaustive and the Service Provider for his part may take any other enforcement actions
              he deems appropriate provided the adoption of such measures assists or is necessary for protection of his or third parties’ rights and legitimate interests.{' '}
            </Typography>
            <Typography variant="h4" className={classes.text}>
              To ensure the compliance by the Parties with this Agreement, the Service Provider reserves a right to monitor any transactions, any content or information
              performed, stored, transmitted and/or otherwise processed through the Application, Website or in any other way in the process of providing the Services
              and/or performing the Agreement.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Liability
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The User shall be liable for any damages caused by the breach of the provisions and obligations hereunder and reimburse Blocksport for all the damages
              caused by such the breach, except as provided for in this Agreement.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Violation by the User of any provision of this Agreement, any appendixes hereto or other rules and documents referred to herein shall be reasonable cause
              for Blocksport to take against the User the enforcement actions envisaged by Section 3 (a), Section 4 (b) and Section 5 (c) of Paragraph 8 of this
              Agreement.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Except for the cases when the actions or omissions of Blocksport are an intentional breach of the obligations hereunder, the latter shall not be liable for:
            </Typography>
            <ol type="a" className={classes.ulLeters}>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  problems with the operation of the Application and Website caused by technical problems;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  any losses (both direct and lost profits) as a result of the User's use of the Application, Website or any of their services as well as any parts and/or
                  elements thereof;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  for the actions of other users who use the Application, Website or any of their services as well as any parts and/or elements thereof;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  failure to perform or improper performance of obligations to grant access to use the Application, Website or technical support due to technical or force
                  majeure circumstances;
                </Typography>
              </li>
              <li className={classes.itemLeters}>
                <Typography variant="h4" className={classes.text}>
                  for any damage caused by the use of the Services hereunder, in particular damage caused by non-compliance with Blocksport’s instructions.
                </Typography>
              </li>
            </ol>
            <Typography variant="h4" className={classes.text}>
              In all other cases the Parties shall be liable pursuant to applicable law. Neither Party shall be liable for non-performance or improper performance of
              obligations under this Agreement, should such non-performance or improper performance is the result of force majeure, namely: floods, fires, natural
              disasters, epidemics, decisions of state bodies, lockdowns and quarantines, changes in the applicable law and other circumstances recognized as force
              majeure circumstances arisen beyond the will and control of the Parties and which the Parties could not have foreseen and/or prevented by reasonable
              measures. In the event of the circumstances specified in this Paragraph, the Party in respect of which such circumstances apply, shall notify another Party
              thereof in writing in 10 (ten) working days upon the occurrence of such circumstances. In this case, the due date for fulfillment by the Parties of
              commitments hereunder shall be proportionally postponed for the period over which the said circumstances exist.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Statutory compliance
            </Typography>
            <Typography variant="h4" className={classes.text}>
              To comply with the requirements provided for by applicable law, the Parties shall obtain/receive from competent authorities, local governments and other
              competent bodies and entities all necessary permits, licenses and approvals required by applicable law, or necessary for the proper provision of the
              Services hereunder.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Parties shall assist each other in the course of complying with the requirements of applicable law, including with regard to taxation, as well as
              submitting financial and other reports to the relevant regulatory and revenue bodies, including, but not limited to, by providing the relevant information,
              drawing-up and signing the necessary documents.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Entire agreement
            </Typography>
            <Typography variant="h4" className={classes.text}>
              This Agreement incorporates all agreements between the Parties and documents by reference and is the entire agreement between the Parties in regard to the
              scope of this Agreement. This Agreement supersedes all prior or contemporaneous agreements, undertakings, representations or communications between the
              Parties, whether verbal or written, in regard to the scope of this Agreement. The Parties shall not be bound by any provision, condition or term that is
              different from or in addition to the provisions, conditions or terms of this Agreement.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Governing law & disputes
            </Typography>
            <Typography variant="h4" className={classes.text}>
              This Agreement and relations arising out of the Agreement or in relation hereto shall be governed by and construed in accordance with the substantive laws
              of Switzerland. Any disputes over this Agreement or in relation hereto shall be resolved subject to the substantive laws of Switzerland and the jurisdiction
              and venue of such action shall be vested solely before the ordinary courts of the Canton of (insert the Canton of Company’s registration).
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Survival & inconsistency
            </Typography>
            <Typography variant="h4" className={classes.text}>
              If for any reason any provision of this Agreement shall be held to be invalid, illegal, or unenforceable, the validity, legality and enforceability of other
              provisions hereof shall remain unaffected. If the provisions of this Agreement are inconsistent with the provisions contained in Policy, any other rules or
              document referred to in this Agreement, the provisions contained herein shall control to the extend necessary to resolve such inconsistency.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Communication
            </Typography>
            <Typography variant="h4" className={classes.text}>
              When the User wishes to contact Blocksport as to the issues related to this Agreement, the User shall first use the means of electronic communication, in
              particular by email notification or otherwise defined by Blocksport. That notwithstanding, the User may use any other means of communication with
              Blocksport, in particular by advance written notice to Blocksport, only if Blocksport does not respond within 10 (ten) calendar days upon submitting an
              appropriate notification or request by electronic means of communication. When Blocksport wishes to contact the User or notify him as to the issues related
              to this Agreement, Blocksport may use any means of communication not prohibited by applicable law, including, but not limited to, Blocksport may contact the
              User or send him a message and/or information by posting this message and/or information in the Application or on the Website or sending an appropriate
              message to the User’s email address. Under all circumstances any communication between the User and Blocksport may be performed exclusively with the use of
              the contact details specified herein and contact information of the User provided by the latter to Blocksport in compliance with Paragraph 7 of the
              Agreement.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Amendment & termination
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Blocksport may at any time to unilaterally alter, amend or otherwise modify this Agreement, any appendixes hereto or documents referenced in this Agreement
              or any appendixes hereto. Blocksport may also establish additional terms and conditions for the provision of the Services to the User without altering the
              provisions of this Agreement. Such alterations, additional terms and conditions must be published in the Application or on the Website or otherwise
              communicated to the User in the manner specified in Section 2 of Paragraph 4 of this Agreement, including, but not limited to, by email notification. The
              alterations, additional terms and conditions referred to in this Paragraph shall form an integral part of this Agreement and be binding on the User if they
              are published in the Application of on the Website or otherwise communicated to the User in the manner specified in Section 2 of Paragraph 4 of this
              Agreement.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Blocksport may at his own discretion refuse to provide the Services and unilaterally terminate this Agreement upon prior notice of the User by publishing
              the relevant information in the Application or on the Website or communicating the relevant information to the User in any other manner specified in Section
              2 of Paragraph 4 of this Agreement, including, but not limited to, by email notification. Should this happen, the Agreement shall be considered terminated
              on the day of publication of the information specified in this Section in the Application or on the Website or sending the relevant information to the User
              in any other manner prescribed by this Section. Unless stated otherwise, in all other cases the Agreement is terminated pursuant to applicable law.{' '}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Assignment
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Blocksport hereunder may assign any of his rights and obligations provided for herein to third parties. To exercise this right, Blocksport does not require
              the consent of the User. That being the case, Blocksport undertakes to publish the above information in the Application or on the Website or otherwise
              communicate the relevant information to the User in the manner specified in Section 2 of Paragraph 4 of this Agreement, including, but not limited to, by
              email notification. The User for his part may not assign any of his rights and obligations under this Agreement to third parties without the written consent
              of Blocksport.{' '}
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Miscellaneous
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Blocksport does not monitor or review the content of third parties’ websites and services accessible through the use of the Services, Application or Website
              even if these websites and Services are linked to from them. This Agreement does not create any third-party beneficiary rights in any individual or entity
              that is not a party to this Agreement.
            </Typography>
            <Typography variant="h4" className={classes.text}>
              The Agreement is available to anyone on the Website by the link referred to in Section 3 (b) of Paragraph 1 of the Agreement.
            </Typography>
          </Box>
          <Box className={classes.block}>
            <Typography variant="h2" className={classes.caption}>
              Contact details of the service provider
            </Typography>
            <Typography variant="h4" className={classes.text}>
              Loсation: Dammstrasse 16, 6300 Zug, Switzerland <br /> Email: info@blocksport.io{' '}
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default TermsAndConditions;
