import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'entities/Account';
import { Post } from 'entities/Post';
import { FeedState } from './types';

const initialState: FeedState = {
  posts: [],
  total: 0,
  loading: false,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    getPostsRequest: (
      state,
      {
        payload,
      }: PayloadAction<{ celebrityId: User['id']; query: FeedState['query']; append?: boolean }>,
    ) => {
      state.query = payload.query;
      state.loading = true;
      state.error = false;
    },
    getPostsSuccess: (
      state,
      { payload }: PayloadAction<Pick<FeedState, 'posts' | 'total'> & { append?: boolean }>,
    ) => {
      state.loading = false;
      state.posts = payload.append ? state.posts.concat(payload.posts) : payload.posts;
      state.total = payload.total;
    },
    getPostsFailure: (state) => {
      state.error = true;
    },

    addPostRequest: (state, { payload }: PayloadAction<{ data: Pick<Post, 'body' | 'media'> }>) => {
      state.loading = true;
      state.error = false;
    },
    addPostSuccess: (state, { payload }: PayloadAction<Post>) => {
      state.loading = false;
      state.posts.unshift(payload);
      state.total += 1;
    },
    addPostFailure: (state) => {
      state.error = true;
    },

    editPostRequest: (
      state,
      { payload }: PayloadAction<{ id: Post['id']; data: Pick<Post, 'body' | 'media'> }>,
    ) => {
      state.loading = true;
      state.error = false;
    },
    editPostSuccess: (state, { payload }: PayloadAction<Post>) => {
      state.loading = false;
      state.posts.splice(
        state.posts.findIndex((post) => post.id === payload.id),
        1,
        payload,
      );
    },
    editPostFailure: (state) => {
      state.error = true;
    },

    deletePostRequest: (state, { payload }: PayloadAction<Pick<Post, 'id'>>) => {
      state.loading = true;
      state.error = false;
    },
    deletePostSuccess: (state, { payload }: PayloadAction<Pick<Post, 'id'>>) => {
      state.loading = false;
      state.posts.splice(
        state.posts.findIndex((post) => post.id === payload.id),
        1,
      );
      state.total -= 1;
    },
    deletePostFailure: (state) => {
      state.error = true;
    },
  },
});

export { actions as FeedActions };

export default reducer;
