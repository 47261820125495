import axios from 'axios';
import analytics from 'analytics';
import { ethers } from 'ethers';
import * as endpoints from 'services/api';
import { delay, call, put, all, select } from 'redux-saga/effects';
import web3 from 'helpers/getWeb3';
import { makeTransaction, toWei } from 'helpers/web3';
import { Token, DetailParamsURL, StakeInfo } from 'entities/Token';
import {
  auctionAddress,
  mintAddress,
  auctionCollectionAddress,
  emptyAddress,
  token721Address,
  token1155Address,
  marketplaceAddress,
  MAX_INT,
  currencyNativeToken,
} from 'contracts/addresses';

import { getLink } from 'helpers/routes';
import { formatTimer, formatPeriod } from 'helpers/format';

import { TokenActions } from '../reducers/token';
import { TransactionActions } from '../reducers/transaction';
import { StakingActions } from '../reducers/staking';
import { NetworkStateInterface } from '../reducers/network/types';

import { getWalletSaga } from './wallet';

import { getBlockchainABI } from '../../contracts';

function* getInfoIPFS(dataHash: string) {
  try {
    const { data } = yield axios.get(`https://gateway.pinata.cloud/ipfs/${dataHash}`, {
      timeout: 3000,
    });

    return {
      ...data,
      image: data.image.replace('ipfs://', 'https://ipfs.io/'),
      uri: `https://gateway.pinata.cloud/ipfs/${dataHash}`,
    };
  } catch (error) {
    return null;
  }
}

function* getTokenId(txHash: string) {
  try {
    const { logs } = yield web3.eth.getTransactionReceipt(txHash);
    // const lastLog = logs.length - 2;
    const lastLog = logs.length - 1;
    const lastTopic = logs[logs.length - 1].topics.length - 1;
    const hex: string = logs[lastLog].topics[lastTopic];
    const id: number = yield web3.utils.hexToNumber(hex);

    return id.toString();
  } catch (error) {
    return null;
  }
}

function* getTokens(data: any, index: number, isStakeInfo: boolean) {
  const item = data[index];
  const image = item.imageAws ? item.imageAws : item.image;
  const stakeInfo: StakeInfo =
    isStakeInfo && item.staking
      ? { ...item.staking, stakePeriod: formatPeriod(item.staking.stakePeriod) }
      : {
          depositCycle: '0',
          owner: emptyAddress,
          weight: '0',
          withdrawCycle: '0',
          currentCycle: '0',
          onStake: false,
          power: '0',
          stakePeriod: { period: 0, isClaimRewards: false, balance: 0, days: 0, hours: 0, minutes: 0 },
          tokenId: '0',
          tokenSource: 'ETH',
        };

  let token: Token;
  let ipfsInfo: Token | null = null;

  if (item.ipfs && item.id && item.title) {
    ipfsInfo = yield call(getInfoIPFS, item.ipfs);
  }

  if (ipfsInfo) {
    token = {
      ...item,
      ...ipfsInfo,
      image,
      staking: stakeInfo,
      timer: formatTimer(item.timer),
      timerSeconds: item.timer,
    };
  } else {
    token = {
      ...item,
      image,
      staking: stakeInfo,
      timer: formatTimer(item.timer),
      timerSeconds: item.timer,
    };
  }

  return token;
}

export function* detailTokenSaga(action: ReturnType<typeof TokenActions.detail.getDetailRequest>) {
  const { idToken, account, tokenSource } = action.payload;

  let paramsURL = { nftId: idToken };
  let url = endpoints.GET_INFO_NFT;

  if (account) {
    paramsURL = { ...paramsURL, userId: account.id } as DetailParamsURL;
    url = endpoints.GET_INFO_ACC_NFT;
  }

  try {
    const {
      data: { data: responseDetailInfo },
    } = yield axios.get(getLink(url, paramsURL), {
      params: { tokenSource },
    });

    if (responseDetailInfo) {
      const image = responseDetailInfo.imageAws ? responseDetailInfo.imageAws : responseDetailInfo.image;

      if (responseDetailInfo.sellers) {
        for (const seller of responseDetailInfo.sellers) {
          seller.price = ethers.utils.formatEther(seller.price.toString());
        }
      }

      if (
        responseDetailInfo.status === 'reservePrice' &&
        !['0', '1.157920892373162e+59'].includes(responseDetailInfo.buyoutPricePerToken) &&
        responseDetailInfo.tblAuctionId
      ) {
        yield put(TokenActions.detail.getBuyoutPriceRequest({ auctionId: responseDetailInfo.tblAuctionId }));
      }

      yield put(
        TokenActions.detail.getDetailSuccess({
          data: {
            ...responseDetailInfo,
            image,
            timer: formatTimer(responseDetailInfo.timer),
            timerSeconds: responseDetailInfo.timer,
            staking: responseDetailInfo.staking
              ? { ...responseDetailInfo.staking, stakePeriod: formatPeriod(responseDetailInfo.staking.stakePeriod) }
              : {
                  depositCycle: '0',
                  owner: emptyAddress,
                  weight: '0',
                  withdrawCycle: '0',
                  currentCycle: '0',
                  onStake: false,
                  power: '0',
                  stakePeriod: { period: 0, isClaimRewards: false, balance: 0, days: 0, hours: 0, minutes: 0 },
                  tokenId: '0',
                  tokenSource: 'ETH',
                },
          },
        }),
      );
    }
  } catch (error) {
    yield put(TokenActions.detail.getDetailFailure());
  }
}

export function* mintSingleSaga(action: ReturnType<typeof TokenActions.mint.mintSingleRequest>) {
  const { address, dataHash, exclusive, callBack } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const mintAddr: string = token721Address[network];

  // @ts-ignore
  const mintContract = yield new web3.eth.Contract(getBlockchainABI(network, 'token721'), mintAddr);

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const { transactionHash } = yield makeTransaction({
      to: mintAddr,
      from: address,
      data: mintContract.methods.mint(address, dataHash, exclusive),
    });

    if (transactionHash) {
      // @fixme: tokenId is null
      const tokenId: string = yield getTokenId(transactionHash);

      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        TokenActions.mint.mintSingleSuccess({
          tokenId,
          hash: transactionHash,
        }),
      );

      yield call(getWalletSaga);
      callBack();
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.mint.minSingleFailure());
  }
}

export function* listSingleSaga(action: ReturnType<typeof TokenActions.list.listSingleRequest>) {
  const { address, idToken, reservePrice, tokenAddress, callBack, buyoutPrice } = action.payload;

  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const marketplaceAddr: string = marketplaceAddress[network];

  // @ts-ignore
  const marketplaceContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'marketplace'),
    marketplaceAddr,
  );

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const priceWei: number = yield toWei(reservePrice.toString());
    const buyoutPriceWei: number = yield buyoutPrice === 0 ? 0 : toWei(buyoutPrice.toString());

    const listingParams = {
      assetContract: tokenAddress,
      tokenId: idToken,
      startTime: 0,
      secondsUntilEndTime: 0,
      quantityToList: 1,
      currencyToAccept: currencyNativeToken, // native token
      reservePricePerToken: priceWei,
      buyoutPricePerToken: buyoutPriceWei,
      listingType: 1, // 0 - Direct , 1 - Auction
    };

    const { transactionHash } = yield makeTransaction({
      to: marketplaceAddr,
      from: address,
      data: marketplaceContract.methods.createListing(Object.values(listingParams)),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        TokenActions.list.listSingleSuccess({
          hash: transactionHash,
        }),
      );

      yield call(getWalletSaga);
      callBack();
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.list.listSingleFailure());
  }
}

export function* placeBidSaga(action: ReturnType<typeof TokenActions.bids.placeBidRequest>) {
  const { auctionId, address, bid, marketplaceAddr } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);
  const { account }: { account: any } = yield select((state) => state.account);

  // @ts-ignore
  const isOldContract = marketplaceAddr.toLowerCase() === auctionAddress[network].toLowerCase();

  let marketplaceContract;

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  if (isOldContract) {
    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'auction'),
      marketplaceAddr,
    );
  } else {
    // @ts-ignore
    // marketplaceAddr = marketplaceAddress[network];
    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'marketplace'),
      marketplaceAddr,
    );
  }

  try {
    const price: string = yield toWei(bid.toString());
    let transactionHash: string;

    if (isOldContract) {
      yield marketplaceContract.methods.getReserveAuction(auctionId).call();

      ({ transactionHash } = yield makeTransaction({
        to: marketplaceAddr,
        value: price,
        from: address,
        data: marketplaceContract.methods.placeBid(auctionId),
      }));
    } else {
      ({ transactionHash } = yield makeTransaction({
        to: marketplaceAddr,
        from: address,
        value: price,
        data: marketplaceContract.methods.offer(auctionId, 1, currencyNativeToken, price, Date.now().toString(), account.wallet),
      }));
    }

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        TokenActions.bids.placeBidSuccess({
          hash: transactionHash,
        }),
      );
      // Analytics Event
      yield analytics.sendEvent({
        category: 'Bid',
        action: 'Bid placed',
        value: +price,
      });
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.bids.placeBidFailure());
  }
}

export function* claimSaga(action: ReturnType<typeof TokenActions.claim.claimRequest>) {
  const { auctionId, address, tokenAddress } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const mintAddr: string = mintAddress[network];
  const isOldContract = tokenAddress.toLowerCase() === mintAddr.toLowerCase();

  let marketplaceAddr: string;
  let marketplaceContract;

  if (isOldContract) {
    // @ts-ignore
    marketplaceAddr = auctionAddress[network];

    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'auction'),
      marketplaceAddr,
    );
  } else {
    // @ts-ignore
    marketplaceAddr = marketplaceAddress[network];

    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'marketplace'),
      marketplaceAddr,
    );
  }

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    let transactionHash: string;

    if (isOldContract) {
      yield marketplaceContract.methods.finalizeReserveAuction(auctionId).call();

      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.finalizeReserveAuction(auctionId).encodeABI(),
      }));
    } else {
      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.closeAuction(auctionId, address).encodeABI(),
      }));
    }

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        TokenActions.claim.claimSuccess({
          hash: transactionHash,
        }),
      );
      yield analytics.sendEvent({
        category: 'Claim',
        action: 'Claim complete',
      });
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.claim.claimFailure());
  }
}

export function* settleSaga(action: ReturnType<typeof TokenActions.settle.settleRequest>) {
  const { auctionId, address, tokenAddress } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const mintAddr: string = mintAddress[network];
  const isOldContract = tokenAddress.toLowerCase() === mintAddr.toLowerCase();

  let marketplaceAddr: string;
  let marketplaceContract;

  if (isOldContract) {
    // @ts-ignore
    marketplaceAddr = auctionAddress[network];
    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'auction'),
      marketplaceAddr,
    );
  } else {
    // @ts-ignore
    marketplaceAddr = marketplaceAddress[network];
    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'marketplace'),
      marketplaceAddr,
    );
  }

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    let transactionHash: string;

    if (isOldContract) {
      yield marketplaceContract.methods.finalizeReserveAuction(auctionId).call();

      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.finalizeReserveAuction(auctionId).encodeABI(),
      }));
    } else {
      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.closeAuction(auctionId, address).encodeABI(),
      }));
    }

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        TokenActions.settle.settleSuccess({
          hash: transactionHash,
        }),
      );
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.settle.settleFailure());
  }
}

export function* unListTokenSaga(action: ReturnType<typeof TokenActions.list.unListTokenRequest>) {
  const { auctionId, address, tokenAddress, callBack = null } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const mintAddr: string = mintAddress[network];
  const isOldContract = tokenAddress.toLowerCase() === mintAddr.toLowerCase();

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  let marketplaceAddr: string;
  let marketplaceContract;

  if (isOldContract) {
    // @ts-ignore
    marketplaceAddr = auctionAddress[network];
    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'auction'),
      marketplaceAddr,
    );
  } else {
    // @ts-ignore
    marketplaceAddr = marketplaceAddress[network];
    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'marketplace'),
      marketplaceAddr,
    );
  }
  try {
    let transactionHash: string;

    if (isOldContract) {
      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.cancelReserveAuction(auctionId).encodeABI(),
      }));
    } else {
      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.closeAuction(auctionId, address).encodeABI(),
      }));
    }

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        TokenActions.list.unListTokenSuccess({
          hash: transactionHash,
        }),
      );

      callBack && callBack();
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.list.unListTokenFailure());
  }
}

export function* updatePriceTokenSaga(action: ReturnType<typeof TokenActions.detail.updatePriceRequest>) {
  const { address, auctionId, reservePrice, callBack, tokenAddress, buyoutPrice } = action.payload;
  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const mintAddr: string = mintAddress[network];
  const isOldContract = tokenAddress.toLowerCase() === mintAddr.toLowerCase();

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  let marketplaceAddr: string;
  let marketplaceContract;

  if (isOldContract) {
    // @ts-ignore
    marketplaceAddr = auctionAddress[network];

    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'auction'),
      marketplaceAddr,
    );
  } else {
    // @ts-ignore
    marketplaceAddr = marketplaceAddress[network];

    // @ts-ignore
    marketplaceContract = yield new web3.eth.Contract(
      // @ts-ignore
      getBlockchainABI(network, 'marketplace'),
      marketplaceAddr,
    );
  }

  try {
    let transactionHash: string;
    const price: string = yield toWei(reservePrice.toString());
    const buyoutPriceWei: number = yield buyoutPrice === 0 ? 0 : toWei(buyoutPrice.toString());

    if (isOldContract) {
      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.updateReserveAuction(auctionId, price).encodeABI(),
      }));
    } else {
      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.updateListing(auctionId, 1, price, buyoutPriceWei, currencyNativeToken, 0, 0).encodeABI(),
      }));
    }

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(TokenActions.detail.updatePriceSuccess());

      callBack();
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.detail.updatePriceFailure());
  }
}

export function* getBidsSaga(action: ReturnType<typeof TokenActions.bids.getBidsRequest>) {
  const { profileId } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_BIDS, { profileId }));

    const bids: Token[] = yield all(Array.from({ length: data.length }, (token, index) => call(getTokens, data, index, false)));

    yield put(
      TokenActions.bids.getBidsSuccess({
        data: bids,
      }),
    );
  } catch (error) {
    yield put(TokenActions.bids.getBidsFailure());
  }
}

export function* tokenHistorySaga(action: ReturnType<typeof TokenActions.history.historyRequest>) {
  const { idToken, celebrityId, tokenSource, isCollectible } = action.payload;

  let url = endpoints.TOKEN_HISTORY;
  let paramsURL = { idToken, celebrityId };

  if (isCollectible) {
    url = endpoints.COLLECTION_HISTORY;
    // @ts-ignore
    paramsURL = { idToken };
  }

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(url, paramsURL), {
      params: { tokenSource },
    });

    if (isCollectible) {
      for (const history of data) {
        if (history.buyoutPricePerToken) {
          // history.price = ethers.utils.formatEther(history.price.toString());
          history.price = history.buyoutPricePerToken;
        }
      }
    }

    if (data) {
      yield put(TokenActions.history.historySuccess({ data }));
    }
  } catch (error) {
    yield put(TokenActions.history.historyFailure());
  }
}

export function* burnTokenSaga(action: ReturnType<typeof TokenActions.burn.burnRequest>) {
  const { idToken, address, collection, quantity, callback, tokenAddress } = action.payload;

  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const mintAddr: string = mintAddress[network];
  const isOldContract = !collection && tokenAddress.toLowerCase() === mintAddr.toLowerCase();

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  let marketplaceAddr: string;
  let marketplaceContract: any;
  let transactionHash: string;

  try {
    if (collection) {
      // @ts-ignore
      marketplaceAddr = token1155Address[network];
      // @ts-ignore
      marketplaceContract = yield new web3.eth.Contract(getBlockchainABI(network, 'token1155'), mintAddr);

      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.burn(address, idToken, quantity).encodeABI(),
      }));
    } else if (isOldContract) {
      // that for handle single tokens on an old contract
      marketplaceAddr = mintAddr;
      // @ts-ignore
      marketplaceContract = yield new web3.eth.Contract(getBlockchainABI(network, 'mint'), mintAddr);
      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.burn(idToken).encodeABI(),
      }));
    } else {
      // @ts-ignore
      marketplaceAddr = token721Address[network];
      // @ts-ignore
      marketplaceContract = yield new web3.eth.Contract(getBlockchainABI(network, 'token721'), mintAddr);

      ({ transactionHash } = yield web3.eth.sendTransaction({
        to: marketplaceAddr,
        from: address,
        data: marketplaceContract.methods.burn(idToken).encodeABI(),
      }));
    }

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        TokenActions.burn.burnSuccess({
          hash: transactionHash,
        }),
      );
      callback && callback();
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.burn.burnFailure());
  }
}

export function* listCollectionSaga(action: ReturnType<typeof TokenActions.list.listCollectionRequest>) {
  const { reqData, callBack } = action.payload;

  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const marketplaceAddr: string = marketplaceAddress[network];
  // @ts-ignore
  const сollectionAddr: string = token1155Address[network];

  // @ts-ignore
  const marketplaceContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'marketplace'),
    marketplaceAddr,
  );

  // const priceForAllTokens = Number(reqData.tokenPrice) * Number(reqData.tokenAmount);
  // const parsePrice = parseFloat(priceForAllTokens.toFixed(10));
  // const convertPriceForEach = ethers.utils.parseEther(reqData.tokenPrice.toString()).toString();
  // const convertPriceForAll = ethers.utils.parseEther(parsePrice.toString()).toString();

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const priceWei: number = yield toWei(reqData.tokenPrice.toString());

    const listingParams = {
      assetContract: сollectionAddr,
      tokenId: reqData.tokenId,
      startTime: 0,
      secondsUntilEndTime: 0,
      quantityToList: reqData.tokenAmount,
      currencyToAccept: currencyNativeToken, // native token
      reservePricePerToken: 0, // ? todo need check
      buyoutPricePerToken: priceWei, // @dev for direction need pass buyoutPricePerToken
      listingType: 0, // 0 - Direct, 1 - Auction
    };

    const { transactionHash } = yield web3.eth.sendTransaction({
      to: marketplaceAddr,
      from: reqData.ownerWallet,
      data: marketplaceContract.methods.createListing(Object.values(listingParams)).encodeABI(),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(TokenActions.list.listCollectionSuccess());

      callBack();
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.list.listCollectionFailure());
  }
}

export function* mintCollectionSaga(action: ReturnType<typeof TokenActions.mint.mintCollectionRequest>) {
  const { address, dataHash, amount, callBack } = action.payload;

  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const collectionAddr: string = token1155Address[network];

  // @ts-ignore
  const collectionContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'token1155'),
    collectionAddr,
  );

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const { transactionHash } = yield web3.eth.sendTransaction({
      to: collectionAddr,
      from: address,
      data: collectionContract.methods.mintTo(address, MAX_INT, dataHash, amount.toString(), []).encodeABI(),
    });

    if (transactionHash) {
      const tokenId: string = yield getTokenId(transactionHash);

      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(
        TokenActions.mint.mintCollectionSuccess({
          hash: transactionHash,
          tokenId,
        }),
      );

      yield call(getWalletSaga);
      callBack();
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.mint.mintCollectionFailure());
  }
}

export function* profileTokensSaga(action: ReturnType<typeof TokenActions.profileTokens.getProfileTokensRequest>) {
  const { id, filters } = action.payload;

  try {
    const params = filters ? { condition: filters } : {};
    // const { network }: NetworkStateInterface = yield select((state) => state.network);
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_PROFILE_NFT, { id }), { params });
    const tokens: Token[] = yield all(Array.from({ length: data.length }, (token, index) => call(getTokens, data, index, true)));

    yield put(
      TokenActions.profileTokens.getProfileTokensSuccess({
        tokens: tokens.filter((item: Token) => item && item.username !== emptyAddress && item.id && item.title),
      }),
    );

    yield put(
      StakingActions.stakedTokens.setStakedTokens({
        tokens: tokens.filter(
          (item: Token) => item && item.username !== emptyAddress && item.id && item.title && !item.collection && item.staking?.onStake && item.blockchain === 'BSC', // network.toUpperCase(),
        ),
      }),
    );
    yield put(
      StakingActions.unstakedTokens.setUnstakedTokens({
        tokens: tokens.filter(
          (item: Token) =>
            item &&
            item.username !== emptyAddress &&
            item.id &&
            item.title &&
            !item.collection &&
            !item.staking?.onStake &&
            (item.staking?.depositCycle ? +item.staking?.depositCycle >= +item.staking?.withdrawCycle : true) &&
            // +item.staking?.currentCycle !== +item.staking?.depositCycle &&
            // +item.staking?.withdrawCycle !== +item.staking?.currentCycle &&
            item.blockchain === 'BSC' && // network.toUpperCase() &&
            item.status !== 'notconfirmed',
        ),
      }),
    );
  } catch (error) {
    yield put(TokenActions.profileTokens.getProfileTokensFailure());
  }
}

export function* getMintedTokensSaga(action: ReturnType<typeof TokenActions.mintedTokens.getMintedTokensRequest>) {
  const { celebrityId, filters } = action.payload;

  try {
    const params = filters ? { condition: filters } : {};

    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_CELEBRITY_TOKENS_MINTED, { celebrityId }), { params });

    const tokens: Token[] = yield all(Array.from({ length: data.length }, (token, index) => call(getTokens, data, index, true)));

    yield put(
      TokenActions.mintedTokens.getMintedTokensSuccess({
        tokens: tokens.filter((item: Token) => item && item.username !== emptyAddress && item.id && item.title),
      }),
    );
  } catch (error) {
    yield put(TokenActions.mintedTokens.getMintedTokensFailure());
  }
}

export function* listedTokensSaga(action: ReturnType<typeof TokenActions.listedTokens.getListedTokensRequest>) {
  // @ts-ignore: possibly undefined
  const { limit, offset, ...allParams } = action.payload.query;

  try {
    const {
      data: {
        data,
        meta: { total },
      },
    } = yield axios.get(getLink(endpoints.GET_AUCTION_LISTED), {
      params: { ...allParams, limit, offset },
    });

    const tokens: Token[] = yield all(Array.from({ length: data.length }, (token, index) => call(getTokens, data, index, false)));

    const {
      listedTokens: { listedTokens },
    } = yield select((state) => state.token);

    const mergedTokens: Token[] = action.payload?.clean ? [] : [...listedTokens];

    [...tokens].filter((item) => {
      if (item.id && item.title) {
        mergedTokens.push(item);
      }
      return mergedTokens;
    });

    yield put(
      TokenActions.listedTokens.getListedTokensSuccess({
        tokens: mergedTokens,
        countTokens: total,
        nextPageExist: mergedTokens.length < total,
      }),
    );
  } catch (error) {
    yield put(TokenActions.listedTokens.getListedTokensFailure());
  }
}

export function* hotTokensSaga() {
  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_HOT));

    const tokens: Token[] = yield all(Array.from({ length: data.length }, (token, index) => call(getTokens, data, index, false)));

    yield put(
      TokenActions.hotTokens.getHotTokensSuccess({
        tokens: tokens.filter((item) => item.id && item.title),
      }),
    );
  } catch (error) {
    yield put(TokenActions.hotTokens.getHotTokensFailure());
  }
}

export function* latestTokensSaga() {
  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_LATEST));

    const tokens: Token[] = yield all(Array.from({ length: data.length }, (token, index) => call(getTokens, data, index, false)));

    yield put(
      TokenActions.latestTokens.getLatestTokensSuccess({
        tokens: tokens.filter((item) => item.id && item.title),
      }),
    );
  } catch (error) {
    yield put(TokenActions.latestTokens.getLatestTokensFailure());
  }
}

export function* unListCollectionSaga(action: ReturnType<typeof TokenActions.list.unListCollectionRequest>) {
  const { address, auctionId, callBack } = action.payload;

  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const auctionAddr: string = marketplaceAddress[network];

  // @ts-ignore
  const auctionContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'marketplace'),
    auctionAddr,
  );

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  try {
    const { transactionHash } = yield web3.eth.sendTransaction({
      to: auctionAddr,
      from: address,
      data: auctionContract.methods.cancelDirectListing(auctionId).encodeABI(),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(TokenActions.list.unListCollectionSuccess());

      callBack();
    }
  } catch (error) {
    yield put(TokenActions.list.unListCollectionFailure());
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
  }
}

export function* winModalClose(action: ReturnType<typeof TokenActions.list.winModalCloseRequest>) {
  try {
    const { userId, nftId, isCelebrity, tokenSource } = action.payload;

    if (isCelebrity) {
      yield axios.post(
        getLink(endpoints.WIN_MODAL_OPEN_CELEBRITY, {
          celebrityId: userId,
          id: nftId,
          tokenSource,
        }),
      );
    } else {
      yield axios.post(
        getLink(endpoints.WIN_MODAL_OPEN_FAN, {
          fanId: userId,
          nftId,
        }),
        {
          tokenSource,
        },
      );
    }
  } catch (error) {
    yield put(TokenActions.list.winModalCloseError());
  }
}

export function* buyerSignSaga(action: ReturnType<typeof TokenActions.buy.buyerSignRequest>) {
  const { reqData } = action.payload;

  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const auctionCollectionAddr: string = marketplaceAddress[network];
  // @ts-ignore
  // const сollectionAddr: string = token1155Address[network];

  // @ts-ignore
  const auctionContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'marketplace'),
    auctionCollectionAddr,
  );

  try {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

    const convertPrice = ethers.utils.parseEther(reqData.price.toString()).toString();

    const buyParams = {
      listingId: reqData.listingId,
      buyFor: reqData.buyerWallet,
      quantityToBuy: reqData.tokenAmount,
      currency: currencyNativeToken,
      totalPrice: convertPrice,
    };

    yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

    const { transactionHash } = yield web3.eth.sendTransaction({
      value: +convertPrice,
      to: auctionCollectionAddr,
      from: reqData.buyerWallet,
      data: auctionContract.methods.buy(buyParams.listingId, buyParams.buyFor, buyParams.quantityToBuy, buyParams.currency, buyParams.totalPrice).encodeABI(),
    });

    if (transactionHash) {
      yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      yield put(TokenActions.buy.buyerSignSuccess({ sign: transactionHash }));
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.buy.buyerSignFailure());
  }
}

export function* orderTransactionSaga(action: ReturnType<typeof TokenActions.orders.orderTransactionRequest>) {
  const { reqData } = action.payload;

  const { network }: NetworkStateInterface = yield select((state) => state.network);

  // @ts-ignore
  const auctionCollectionAddr: string = auctionCollectionAddress[network];

  // @ts-ignore
  const auctionCollectionContract = yield new web3.eth.Contract(
    // @ts-ignore
    getBlockchainABI(network, 'auctionCollection'),
    auctionCollectionAddr,
  );

  yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

  const { sellerSign, buyerSign } = reqData;

  try {
    const { transactionHash, blockNumber } = yield web3.eth.sendTransaction({
      value: +buyerSign.data.message.makeAsset.value,
      to: auctionCollectionAddr,
      from: buyerSign.data.message.maker,
      data: auctionCollectionContract.methods
        .matchOrders(
          [
            sellerSign.data.message.maker, // seller maker
            [[sellerSign.data.message.makeAsset.assetType.assetClass, sellerSign.data.message.makeAsset.assetType.data], sellerSign.data.message.makeAsset.value],
            sellerSign.data.message.taker, // 0x
            [[sellerSign.data.message.takeAsset.assetType.assetClass, sellerSign.data.message.takeAsset.assetType.data], sellerSign.data.message.takeAsset.value],
            sellerSign.data.message.salt,
            0, // always 0
            0, // always 0
            sellerSign.data.message.dataType,
            sellerSign.data.message.data,
          ],
          sellerSign.sig,
          [
            buyerSign.data.message.maker, // buyer maker
            [[buyerSign.data.message.makeAsset.assetType.assetClass, buyerSign.data.message.makeAsset.assetType.data], buyerSign.data.message.makeAsset.value],
            buyerSign.data.message.taker, // seller taker
            [[buyerSign.data.message.takeAsset.assetType.assetClass, buyerSign.data.message.takeAsset.assetType.data], buyerSign.data.message.takeAsset.value],
            buyerSign.data.message.salt,
            0,
            0,
            buyerSign.data.message.dataType,
            buyerSign.data.message.data,
          ],
          buyerSign.sig,
        )
        .encodeABI(),
    });

    if (transactionHash) {
      yield put(
        TokenActions.orders.orderTransactionSuccess({
          hash: transactionHash,
        }),
      );

      yield delay(5000);

      const { data } = yield axios.post(endpoints.POST_COLLECTION_AUCTION, {
        transactionHash,
        blockNumber,
        auctionId: sellerSign.auctionId,
        from: sellerSign.data.message.maker,
        to: buyerSign.data.message.maker,
        amount: +buyerSign.data.message.takeAsset.value,
        tokenSource: network.toUpperCase(),
        tokenId: sellerSign.tokenId,
      });

      if (data) {
        yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
      }
    }
  } catch (error) {
    yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
    yield put(TokenActions.orders.orderTransactionFailure());
  }
}

export function* getDetailByIdSaga(action: ReturnType<typeof TokenActions.detail.getDetailByIdRequest>) {
  const { nftId, nftAddress } = action.payload;

  try {
    const {
      data: { data: responseDetailInfo },
    } = yield axios.get(getLink(endpoints.GET_INFO_NFT_BY_ADDRESS, { nftId, nftAddress }));

    if (responseDetailInfo) {
      const image = responseDetailInfo.imageAws ? responseDetailInfo.imageAws : responseDetailInfo.image;

      if (responseDetailInfo.sellers) {
        for (const seller of responseDetailInfo.sellers) {
          seller.price = ethers.utils.formatEther(seller.price.toString());
        }
      }

      yield put(
        TokenActions.detail.getDetailSuccess({
          data: {
            ...responseDetailInfo,
            image,
            timer: formatTimer(responseDetailInfo.timer),
            timerSeconds: responseDetailInfo.timer,
            staking: responseDetailInfo.staking
              ? { ...responseDetailInfo.staking, stakePeriod: formatPeriod(responseDetailInfo.staking.stakePeriod) }
              : {
                  depositCycle: '0',
                  owner: emptyAddress,
                  weight: '0',
                  withdrawCycle: '0',
                  currentCycle: '0',
                  onStake: false,
                  power: '0',
                  stakePeriod: { period: 0, isClaimRewards: false, balance: 0, days: 0, hours: 0, minutes: 0 },
                  tokenId: '0',
                  tokenSource: 'ETH',
                },
          },
        }),
      );
    } else {
      throw new Error('Wrong answer');
    }
  } catch (error) {
    yield put(TokenActions.detail.getDetailFailure());
  }
}

export function* getBuyoutPriceSaga(action: ReturnType<typeof TokenActions.detail.getBuyoutPriceRequest>) {
  const { auctionId } = action.payload;

  try {
    const {
      data: {
        data: { prices },
      },
    } = yield axios.get(getLink(endpoints.GET_TOKEN_BUYOUT_PRICE, { auctionId }));

    if (prices) {
      yield put(TokenActions.detail.getBuyoutPriceSuccess({ prices }));
    }
  } catch (error) {
    yield put(TokenActions.detail.getBuyoutPriceFailure());
  }
}
