import React from 'react';
import { IconButton, Typography, Box, Paper, Stack } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { CoinsIcon } from 'icons';
import { availableFTO } from 'constants/fantokens';
import { getAddressLink } from 'helpers/links';

import { useStyles } from '../../styles';

const Fantoken = ({ tokenSymbol, balance }: { tokenSymbol: string; balance: string }) => {
	const { classes } = useStyles();
	return (
		<Box mb={1}>
			<Paper variant="outlined" className={classes.ftobalance}>
				<Box display="flex">
					<Box mr={2} className={classes.imgBox} display="flex" alignItems="center">
						<img src={availableFTO[tokenSymbol].balanceImage} alt="Balance FanTokens" />
					</Box>
					<Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" flexGrow={1}>
						<Typography variant="caption" sx={{ color: '#828282' }}>{`${availableFTO[tokenSymbol].title}`}</Typography>
						<Typography variant="h4">{`${balance} ${availableFTO[tokenSymbol].prefix}${tokenSymbol}`}</Typography>
					</Box>
					<Box display="flex" className={classes.ftoactions}>
						<Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
							<IconButton aria-label="link" href={availableFTO[tokenSymbol].Link}>
								<CoinsIcon className={classes.iconsControl} />
							</IconButton>
							<IconButton aria-label="link" target="_blank" href={getAddressLink(availableFTO[tokenSymbol].tokenAddress, 'bsc')} rel="noopener">
								<LaunchIcon className={classes.iconsControl} />
							</IconButton>
						</Stack>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

export default Fantoken;
