import { put } from 'redux-saga/effects';
import axios from 'axios';
import { SIGN_MOONPAY } from 'services/api';
import { getLink } from 'helpers/routes';
import { MoonpayActions } from '../reducers/moonpay';

export function* signMoonpayURLSaga(
  action: ReturnType<typeof MoonpayActions.signMoonpayURLRequest>,
) {
  try {
    const { originUrl } = action.payload;
    const { data } = yield axios.post(getLink(SIGN_MOONPAY), { originUrl });

    yield put(MoonpayActions.signMoonpayURLSuccess({ link: data.data }))
  } catch (err) {
    yield put(MoonpayActions.signMoonpayURLFailure())
  }
}