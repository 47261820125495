import { bscTestnetUrl, bscMainnetUrl, ethMainnetUrl, ethTestnetUrl, polyTestnetUrl, polyMainnetUrl, wbtTestnetNode, wbtMainnetNode } from '../constants/url';

export const getNetworkChainId = (blockchain: string) => {
  switch (blockchain?.toLowerCase()) {
    case 'eth':
      return process.env.REACT_APP_ENV === 'production' ? 1 : 5;
    case 'bsc':
      return process.env.REACT_APP_ENV === 'production' ? 56 : 97;
    case 'polygon':
      return process.env.REACT_APP_ENV === 'production' ? 137 : 80001;
    case 'whitebit':
      return process.env.REACT_APP_ENV === 'production' ? 1875 : 2625;
  }
};

export const blockChainCurrency = (chain?: string | number) => {
  switch (chain?.toString().toLowerCase()) {
    case 'bsc':
    case '56':
    case '97':
      return 'BNB';
    case 'polygon':
    case '137':
    case '80001':
      return 'MATIC';
    case 'whitebit':
    case '1875':
    case '2625':
      return 'WBT';
    default:
      return 'ETH';
  }
};

export const handleViewHash = (hash: string, blockchain?: string) => {
  const bscUrl = process.env.REACT_APP_ENV === 'production' ? bscMainnetUrl : bscTestnetUrl;
  const ethUrl = process.env.REACT_APP_ENV === 'production' ? ethMainnetUrl : ethTestnetUrl;
  const polyUrl = process.env.REACT_APP_ENV === 'production' ? polyMainnetUrl : polyTestnetUrl;
  const whitebitUrl = process.env.REACT_APP_ENV === 'production' ? wbtMainnetNode : wbtTestnetNode;

  switch (blockchain?.toLowerCase()) {
    case 'eth':
      window.open(`${ethUrl}/tx/${hash}`, '_blank');
      break;
    case 'bsc':
      window.open(`${bscUrl}/tx/${hash}`, '_blank');
      break;
    case 'polygon':
      window.open(`${polyUrl}/tx/${hash}`, '_blank');
      break;
    case 'whitebit':
      window.open(`${whitebitUrl}/tx/${hash}`, '_blank');
      break;
    default:
      return null;
  }
};

export const blockChainServiceLabel = (blockchain?: string) => {
  switch (blockchain?.toLowerCase()) {
    case 'eth':
      return 'Etherscan';
    case 'bsc':
      return 'BSCscan';
    case 'polygon':
      return 'POLYscan';
    case 'whitebit':
      return 'Whitechain';
    default:
      return null;
  }
};

export const formatChainName = (chain?: string | number) => {
  switch (chain?.toString().toLowerCase()) {
    case 'whitebit':
    case '1875':
    case '2625':
      return 'Whitechain';
    default:
      return chain?.toString();
  }
};
