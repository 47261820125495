import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { white, bsBlue } from 'constants/colors';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    wrapper: {
      background: white,
      padding: '8px',
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      width: '100%',
      zIndex: 9999,
      bottom: 0,

      '& .MuiTypography-root': {
        color: '#828282',
        '& ~ .MuiTypography-root': {
          marginTop: theme.spacing(2),
        },
        '& a': {
          color: bsBlue,
        },
      },
      [theme.breakpoints.up('sm')]: {
        padding: '16px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '40px 180px',
      },
    },

    footer: {
      marginTop: 24,
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.between('xs', 'md')]: {
        justifyContent: 'space-between',
      },
    },
    btn: {
      [theme.breakpoints.up('sm')]: {
        width: 180,
        marginLeft: 24,
      },
    },
  };
});
