import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyWalletState } from './types';

const initialState: MyWalletState = {
  loading: false,
  error: false,
  transactions: [],
  totalTransactions: 0,
  myWalletIsOpen: false,
  myWalletModalIsOpen: false,
  loadingBalance: false,
  bsptBalance: 0,
  balances: [],
};

const { actions, reducer } = createSlice({
  name: 'mywallet',
  initialState,
  reducers: {
    getTransactionListRequest: (state, { payload }: PayloadAction<{ address: string /* tokenSource: string; pagination: any */ }>) => {
      state.loading = true;
      state.error = false;
    },
    getTransactionListSuccess: (state, { payload }: PayloadAction<{ data: []; meta: any }>) => {
      state.transactions = payload.data;
      state.totalTransactions = payload.meta.total;
      state.loading = false;
      state.error = false;
    },
    getTransactionListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    toggleMyWallet: (state) => {
      state.myWalletIsOpen = !state.myWalletIsOpen;
    },
    closeMyWallet: (state) => {
      state.myWalletIsOpen = false;
    },
    toggleMyWalletModal: (state, { payload }: PayloadAction<{ open: boolean }>) => {
      state.myWalletModalIsOpen = payload.open;
    },
    getBsptBalanceRequest: (state) => {
      state.loadingBalance = true;
      state.error = false;
    },
    getBsptBalanceSuccess: (state, { payload }: PayloadAction<any>) => {
      state.bsptBalance = payload;
      state.loadingBalance = false;
    },
    getBsptBalanceFailure: (state) => {
      state.loadingBalance = false;
      state.error = true;
    },
    getBalancesRequest: (state) => {
      state.loadingBalance = true;
      state.error = false;
    },
    getBalancesSuccess: (state, { payload }: PayloadAction<any>) => {
      state.balances = payload.balances;
      state.loadingBalance = false;
    },
    getBalancesFailure: (state) => {
      state.loadingBalance = false;
      state.error = true;
    },
  },
});

export { actions as MyWalletActions };

export default reducer;
