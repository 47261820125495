import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<any>()((theme: Theme, props) => {
  return {
    root: {
      height: props.height,
      margin: props.margin,
      padding: props.padding,
      textAlign: props.TextAlign,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 'bold',
      '&.Miu-focused': {
        backgroundColor: theme.palette.common.white,
      },
    },
    customStile: {
      ...props.customStile,
      paddingBottom: 12,
      paddingTop: 12,
      height: 'auto',
    },
    select: {
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    standardIcon: {
      right: 10,
      fill: theme.palette.secondary.main,
      zIndex: 1,
    },
    periodIcon: {
      top: 14,
      right: 13,
      fill: theme.palette.common.white,
      zIndex: 1,
    },
    periodBg: {
      position: 'absolute',
      right: 0,
      height: '50px',
      width: '50px',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '0 10px 10px 0',
      pointerEvents: 'none',
    },
    menuPaper: {
      maxHeight: 185,
      padding: '16px 16px 16px 4px',
      borderRadius: 8,
      boxShadow: '0 -4px 22px 0 rgba(118, 117, 121, 0.15)',
    },
    menuListRoot: {
      display: 'grid',
      gridGap: 16,
    },
    menuItemRoot: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 14,
      lineHeight: 1.29,
      fontWeight: 'bold',
      padding: '3px 0',
      backgroundColor: `${theme.palette.common.white} !important`,

      '&:hover': {
        color: theme.palette.secondary.main,
        backgroundColor: `${theme.palette.common.white} !important`,
      },
    },
    menuItemSelected: {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    menuItemContent: {
      display: 'grid',
      gridTemplateColumns: 'min-content min-content',
      alignItems: 'center',
      gridGap: 16,

      fontWeight: 500,
      fontSize: '1.6rem',
      lineHeight: '26px',
      color: '#333333',
    },
    menuItemContentPeriod: {
      display: 'block',
      paddingRight: 45,
    },
    menuItemContentOffset: {
      paddingLeft: 20,
    },
    placeholder: {
      fontWeight: 500,
      fontSize: '1.6rem',
      lineHeight: '26px',
      color: '#828282',
    },
  };
});
