import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Invitation } from 'entities/Invitation';
import { InvitationState } from './types';

const initialState: InvitationState = {
  loading: true,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'invitation',
  initialState,
  reducers: {
    getInvitationRequest: (
      state,
      { payload }: PayloadAction<Pick<Invitation, 'invitationToken'>>,
    ) => {
      state.loading = true;
      state.error = false;
    },
    getInvitationSuccess: (state, { payload }: PayloadAction<{ data: Invitation }>) => {
      state.data = payload.data;
      state.loading = false;
    },
    getInvitationFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as InvitationActions };

export default reducer;
