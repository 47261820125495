import React from 'react';
import { withStyles } from 'tss-react/mui';
import { SnackbarProvider as SnackbarProviderBase } from 'notistack';
import { surf, anzac, malibu, mandysPink, mineShaft } from 'constants/colors';

const styles = () => ({
  message: {
    fontSize: 16,
    fontWeight: 500,
    color: mineShaft,
  },
  variantSuccess: {
    backgroundColor: surf,
  },
  variantError: {
    backgroundColor: mandysPink,
  },
  variantWarning: {
    backgroundColor: anzac,
  },
  variantInfo: { backgroundColor: malibu },
});

const snackbarProvider = (props: any) => {
  return <SnackbarProviderBase {...props} />;
};

export default React.memo(withStyles(snackbarProvider, styles));
