import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from 'helpers/history';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';

// Reducers
import popups from './reducers/popups';
import ipfs from './reducers/ipfs';
import token from './reducers/token';
import approve from './reducers/approve';
import staking from './reducers/staking';
import transaction from './reducers/transaction';
import media from './reducers/media';
import metamask from './reducers/metamask';
import account from './reducers/account';
import wallet from './reducers/wallet';
import celebrity from './reducers/celebrity';
import notification from './reducers/notification';
import feed from './reducers/feed';
import profile from './reducers/profile';
import following from './reducers/following';
import subscribing from './reducers/subscribing';
import follower from './reducers/follower';
import subscriber from './reducers/subscriber';
import invitation from './reducers/invitation';
import network from './reducers/network';
import moonpay from './reducers/moonpay';
import filters from './reducers/filters';
import email from './reducers/email';
import fantoken from './reducers/fantoken';
import mywallet from './reducers/mywallet';
import stripe from './reducers/stripe';
import deposit from './reducers/deposit';
import search from './reducers/search';
import twitter from './reducers/twitter';
import mexc from './reducers/mexc';
import games from './reducers/games';
import bridge from './reducers/bridge';
import erc20 from './reducers/erc20';
import alert from './reducers/alert';
import dao from './reducers/dao';
import whitePay from './reducers/whitePay';
import stats from './reducers/stats';
import kyc from './reducers/kyc';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  router: connectRouter(history),
  transaction,
  staking,
  approve,
  popups,
  ipfs,
  token,
  media,
  metamask,
  account,
  wallet,
  celebrity,
  notification,
  feed,
  profile,
  following,
  subscribing,
  follower,
  subscriber,
  invitation,
  network,
  moonpay,
  filters,
  email,
  fantoken,
  mywallet,
  stripe,
  deposit,
  search,
  twitter,
  mexc,
  games,
  bridge,
  erc20,
  alert,
  dao,
  whitePay,
  stats,
  kyc,
});

declare global {
  interface AppState extends ReturnType<typeof rootReducer> {}

  type AppSelector<T = unknown> = (state: AppState) => T;
}

// With this, `useSelector(state => ...)` automatically infers `state` param as `AppState`
declare module 'react-redux' {
  interface DefaultRootState extends AppState {}
}

const composeEnhancers =
  process.env.NODE_ENV === 'development' && typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const configureStore = () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))));
  sagaMiddleware.run(rootSaga);

  return store;
};

export type AppState = ReturnType<typeof rootReducer>;

export default configureStore;
