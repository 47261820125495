import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import { EmailState } from './types';

const initialState: EmailState = {
  loading: false,
  error: false,
  sent: false,
};

const email = {
  joinEmail: createSlice({
    name: 'email/joinEmail',
    initialState: { ...initialState, joinModalIsOpen: false, thankuModalIsOpen: false },
    reducers: {
      sendJoinEmailRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.sent = false;
        state.error = false;
      },
      sendJoinEmailSuccess: (state) => {
        state.loading = false;
        state.sent = true;
        state.thankuModalIsOpen = true;
      },
      sendJoinEmailFailure: (state) => {
        state.loading = false;
        state.error = true;
        state.sent = false;
      },
      sendJoinEmailSetNotified: (state) => {
        state.loading = false;
        state.error = false;
        state.sent = false;
      },
      toggleJoinModal: (state, { payload }: PayloadAction<{ open: boolean }>) => {
        state.joinModalIsOpen = payload.open;
      },
      toggleThankUModal: (state, { payload }: PayloadAction<{ open: boolean }>) => {
        state.thankuModalIsOpen = payload.open;
      },
    },
  }),

  subscribeEmail: createSlice({
    name: 'email/subscribeEmail',
    initialState,
    reducers: {
      sendRequest: (state, { payload }: PayloadAction<any>) => {
        state.loading = true;
        state.sent = false;
        state.error = false;
      },
      sendSuccess: (state) => {
        state.loading = false;
        state.sent = true;
      },
      sendFailure: (state) => {
        state.loading = false;
        state.error = true;
        state.sent = false;
      },
      setNotified: (state) => {
        state.loading = false;
        state.error = false;
        state.sent = false;
      },
    },
  }),

  verifyEmail: createSlice({
    name: 'email/verifyEmail',
    initialState: { loading: false, error: false, codeSent: false, verified: false, errorMessage: '' },
    reducers: {
      sendVerifyRequest: (state, { payload }: PayloadAction<{ email: string }>) => {
        state.loading = true;
        state.error = false;
      },
      sendVerifySuccess: (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.codeSent = true;
      },
      sendVerifyFailure: (state, { payload }: PayloadAction<{ error: string }>) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = payload.error;
      },
      validateCodeRequest: (state, { payload }: PayloadAction<{ verificationCode: string }>) => {
        state.loading = true;
        state.error = false;
      },
      validateCodeSuccess: (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.verified = true;
      },
      validateCodeFailure: (state, { payload }: PayloadAction<{ error: string }>) => {
        state.loading = false;
        state.error = true;
        state.errorMessage = payload.error;
      },
    },
  }),
};

const actions = combineSliceActions(email);

export { actions as EmailActions };

export default combineSliceReducers(email);
