import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WhitePayState } from './types';

const stripePaymentInitialState: WhitePayState = {
  paid: false,
  loading: false,
  success: false,
  error: false,
  purchase: null,
  paymentIntent: null,
  message: '',
  acquiringUrl: null,
  idempotencyKey: '',
};

const { actions, reducer } = createSlice({
  name: 'whitePay',
  initialState: stripePaymentInitialState,
  reducers: {
    initPaymentRequest: (state, { payload }: PayloadAction<{ eventId: number; quantity: number }>) => {
      state.loading = true;
      state.error = false;
    },

    initPaymentSuccess: (state, { payload }: PayloadAction<{ acquiringUrl: string; idempotencyKey: string }>) => {
      state.loading = false;
      state.error = false;
      state.acquiringUrl = payload.acquiringUrl;
      state.idempotencyKey = payload.idempotencyKey;
    },
    initPaymentFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    initTokenPaymentRequest: (state, { payload }: PayloadAction<{ auctionId: number; quantity: number }>) => {
      state.loading = true;
      state.error = false;
    },
    initTokenPaymentSuccess: (state, { payload }: PayloadAction<{ acquiringUrl: string; idempotencyKey: string }>) => {
      state.loading = false;
      state.error = false;
      state.acquiringUrl = payload.acquiringUrl;
      state.idempotencyKey = payload.idempotencyKey;
    },
    initTokenPaymentFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    paymentStart: (state) => {
      state.loading = true;
      state.error = false;
    },
    paymentComplete: (state) => {
      state.paid = true;
      state.loading = false;
    },
    paymentSuccess: (state, { payload }: PayloadAction<any>) => {
      state.paid = true;
      state.success = true;
      state.paymentIntent = payload.paymentIntent;
    },
    paymentError: (state, { payload }: PayloadAction<{ message: string | undefined }>) => {
      state.paid = false;
      state.error = true;
      state.message = payload.message;
      state.loading = false;
    },
    setInitial: (state) => {
      state.paid = false;
      state.loading = false;
      state.success = false;
      state.error = false;
      state.message = '';
      state.paymentIntent = null;
    },
  },
});

export { actions as WhitePayActions, reducer as whitePayReducer };

export default reducer;
