import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'entities/Notifications';
import { NotificationState } from './types';

const initialState: NotificationState = {
  list: [],
};

const { actions, reducer } = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification: (state, { payload }: PayloadAction<Omit<Notification, 'key'>>) => {
      state.list.push({ ...payload, key: new Date().getTime() + Math.random() });
    },
    removeNotification: (state, { payload }: PayloadAction<Notification['key']>) => {
      state.list = state.list.filter((item) => item.key !== payload);
    },
  },
});

export { actions as NotificationActions };

export default reducer;
