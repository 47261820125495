import axios from 'axios';
import * as endpoints from 'services/api';
import { eventChannel, END } from 'redux-saga';
import { put, call, take } from 'redux-saga/effects';

import { IPFSActions } from '../reducers/ipfs';

const pinataKeys = {
  pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
  pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
};

function uploadAttach(url: string, formData: any) {
  return eventChannel((emitter) => {
    const config = {
      headers: {
        ...pinataKeys,
        Authorization: '',
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => emitter(Math.floor((progressEvent.loaded * 100) / progressEvent.total)),
    };

    axios.post(url, formData, config).then(({ data }) => {
      emitter(data);
      emitter(END);
    });

    return () => {};
  });
}

export function* uploadFileSaga(action: ReturnType<typeof IPFSActions.uploadFileRequest>) {
  const { name, file, callBack } = action.payload;

  const url = endpoints.PIN_IPFS_FILE;

  const formData = new FormData();
  const pinataOptions = JSON.stringify({
    wrapWithDirectory: true,
  });
  const pinataMetadata = JSON.stringify({
    name,
  })

  formData.append('file', file);
  formData.append('pinataOptions', pinataOptions);
  formData.append('pinataMetadata', pinataMetadata);

  // @ts-ignore
  const channel = yield call(uploadAttach, url, formData);
  while (channel) {
    try {
      // @ts-ignore
      const data = yield take(channel);
      if (data.IpfsHash) {
        yield put(
          IPFSActions.uploadFileSuccess({
            hash: data.IpfsHash,
            path: `https://ipfs.io/ipfs/${data.IpfsHash}/${name}`,
          }),
        );

        callBack();
      } else {
        yield put(
          IPFSActions.setUploadProgress({
            progress: data,
          }),
        );
      }
    } catch (error) {
      yield put(IPFSActions.uploadFileFailure());
    }
  }
}

export function* uploadDataSaga(action: ReturnType<typeof IPFSActions.uploadDataRequest>) {
  const nft = action.payload;

  const url = endpoints.PIN_IPFS_DATA;

  try {
    const { data } = yield axios.post(
      url,
      { pinataContent: { ...nft, name:nft.title }, pinataMetadata: { name: nft.title } },
      {
        headers: {
          Authorization: '',
          ...pinataKeys,
        },
      },
    );

    yield put(
      IPFSActions.uploadDataSuccess({
        dataHash: data.IpfsHash,
      }),
    );
  } catch (error) {
    yield put(IPFSActions.uploadDataFailure());
  }
}
