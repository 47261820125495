import ReactGA from 'react-ga4';

const isDev = process.env.REACT_APP_ENV === 'development';
const trackingID = process.env.REACT_APP_GA;

const init = () => {
  if (trackingID) {
    ReactGA.initialize(trackingID, { testMode: isDev });
  }
};

const sendEvent = (payload: any) => {
  if (trackingID) {
    ReactGA.event(payload);
  }
};

const sendPageview = (path: string) => {
  if (trackingID) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
};

const set = (payload: any) => {
  if (trackingID) {
    ReactGA.set(payload);
  }
};

export default {
  init,
  sendEvent,
  sendPageview,
  set,
};
