import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { bsBlue } from 'constants/colors';

export const useStyles = makeStyles()((theme: Theme) => {
  // const headerHeight = 60;

  return {
    backdrop: {
      backgroundColor: 'rgba(0,0,0,.6)',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 100,
    },
    loader: {
      height: '100%',
      zIndex: 101,
    },
    loaderProgress: {
      color: '#3889FA',
    },
    textLoading: {
      color: '#FFFFFF',
      fontWeight: 600,
      fontSize: 16,
    },
    dialogTitle: {
      fontSize: '23px',
      padding: ' 24px 16px 20px 30px',
      fontWeight: '600',
    },
    dialogContent: {
      padding: '0',
      height: '550px',
      width: '456px',
      overflow: 'hidden',

      '& .MuiContainer-root': {
        paddingLeft: '32px',
        paddingRight: '32px',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    bsptBalance: {
      backgroundColor: '#F2F2F2',
      borderRadius: '10px',
      padding: '16px 12px',
    },
    disableIcon: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#828282',
      padding: 0,
      marginTop: 20,
      marginRight: 20,
    },
    tabList: {
      backgroundColor: '#F2F2F2',
      borderRadius: '30px',
      marginBottom: '24px',
      minHeight: '28px',
      '& .MuiButtonBase-root': {
        textTransform: 'none',
        fontWeight: '500',
        fontSize: '12px',
        color: '#333333',
        padding: 0,
        minHeight: '28px',
      },
      '& .MuiButtonBase-root.Mui-selected': {
        backgroundColor: '#3889FA',
        borderRadius: '30px',
        color: '#FFFFFF',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    totalBalance: {
      color: '#828282',
      fontSize: '12px',
    },
    balance: {
      fontWeight: '600',
      color: '#333333',
      fontSize: '16px',
    },
    tabPanel: {
      padding: 0,
      height: '80%',
      '& .MuiContainer-root': {
        padding: 0,
      },
    },
    iconsControl: {
      width: 18,
      height: 18,
    },
    dialogActions: {
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      padding: '20px',
      width: '100%',
      margin: 0,
      left: 0,
      background: '#FFF',
    },
    buttonAction: {
      borderRadius: '100rem',
      width: '100%',
      margin: 0,
      fontSize: '16px',
      color: '#0942C9',
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #3889FA, #0942C9);',
      WebkitBackgroundClip: 'text',
      border: '2px solid transparent',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      boxShadow: '2px 1000px 1px #fff inset',
    },
    tabPanelAddressPaper: {
      borderRadius: '10px',
      boxShadow: 'none',
      border: '1px solid #F2F2F2',
      '& .MuiTypography-root': {
        fontWeight: 600,
        fontSize: '14px',
        padding: '11px 15px',
      },
      '& svg': {
        width: 14,
        height: 14,
      },
    },
    copied: {
      borderColor: '#219653',
    },
    copiedText: {
      color: '#219653',
      marginTop: 45,
      position: 'absolute',
    },
    balanceArea: {
      height: 60,
      backgroundColor: '#F2F2F2 !important',
      borderRadius: '10px',
      boxShadow: 'none',
      '& .MuiSvgIcon-root': {
        color: '#3889FA',
      },
      marginBottom: 8,
    },
    emptyText: {
      fontSize: '16px',
      fontWeight: 500,
      padding: '0',
      color: '#828282',
    },
    // FT
    ftobalance: {
      padding: '12px 12px 10px 12px',
    },
    imgBox: {
      '& img': {
        width: 'auto',
        height: 36,
      },
    },
    ftoactions: {
      marginRight: '-9px',
      '& svg': {
        color: '#3889FA',
      },
    },

    balancesWrapper: {
      overflowY: 'scroll',
      height: '100%',
      paddingBottom: 100,
      paddingRight: '5px',

      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-track': {
        WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
      },
    },
    transactions: {
      minHeight: 472,
      overflowY: 'scroll',
      height: '100%',
      paddingBottom: 0,
      paddingRight: '5px',

      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-track': {
        WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
      },
    },
    transaction: {
      padding: '12px 14px 12px  16px',
      background: '#F2F2F2',
      borderRadius: 10,
      borderColor: '#F2F2F2',

      '& .status': {
        display: 'inline-flex',

        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
        '& .MuiCircularProgress-root': {
          color: bsBlue,
        },
      },
      '& .fto': {
        height: 32,
        '& img': {
          objectFit: 'contain',
          height: '100%',
          marginRight: '10px',
        },
      },
      '& .transactionType': {
        paddingLeft: '12px',
      },
      '& .priceanddate': {
        '& .MuiSvgIcon-root': {
          width: 18,
          height: 18,
          color: bsBlue,
        },
      },
    },
  };
});
