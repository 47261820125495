import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KycState } from './types';

const initialState: KycState = {
  loading: false,
  error: false,
  errorMessage: '',
  kycVerifications: [],
  verificationRedirectUrl: null,
};

const { actions, reducer } = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    createVerificationSessionRequest: (state) => {
      state.loading = true;
      state.error = false;
    },
    createVerificationSessionSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    createVerificationSessionFailure: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = payload.error;
    },

    getVerificationsByUserIdRequest: (state, { payload }: PayloadAction<{ userId: number }>) => {
      state.loading = true;
      state.error = false;
    },
    getVerificationsByUserIdSuccess: (state, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.error = false;
      state.kycVerifications = payload.data;
    },
    getVerificationsByUserIdFailure: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = payload.error;
    },
  },
});

export { actions as KycActions };

export default reducer;
