import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { css } from '@emotion/css';
import { Tooltip } from 'entities/Tooltip';

import { Logo } from 'icons';

import * as routePaths from 'constants/routes';

import { WalletActions } from 'store/reducers/wallet';

import DisplayOn from 'shared/components/DisplayOn';

import Search from './Search';

import { useStyles, HeaderTheme } from './styles';

import GetHeaderProfile from './GetHeaderProfile';
import MobileHeader from './MobileHeader';
import Menu from './Menu';

export interface HeaderProps {
  isProfile?: boolean;
}

export default function Header(props: HeaderProps) {
  const { classes } = useStyles();

  const { isProfile } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();

  // Selectors
  const {
    wallet: { wallet, error: walletError, loading: walletLoading },
    account: {
      errors: { account: accountError },
      loading: { role: loadingRole, account: loadingAccount },
    },
  } = useSelector((state) => state);

  // State
  const [bannerTooltip, setBannerTooltip] = useState<Tooltip>({ open: false, message: '' });
  const [headerType, setHeaderType] = useState<string>('blue');
  const [headerTypeDesktop, setHeaderTypeDesktop] = useState<string>('blue');
  const [scrolled, setScrolled] = useState<boolean>(window.pageYOffset > 0);

  // Constants
  const errors = walletError || accountError;
  const loading = loadingRole || loadingAccount || walletLoading;

  useEffect(() => {
    if (bannerTooltip) {
      const timer = setTimeout(() => {
        setBannerTooltip({ ...bannerTooltip, open: false });
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [bannerTooltip]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isProfile) {
      setHeaderTypeDesktop('transparent');
      return;
    }
    if (['/fan-token/KRUT', '/fan-token/SAHA', '/fan-token/UCFC'].includes(location.pathname)) {
      setHeaderTypeDesktop('transparent');
      return;
    }
    if ([routePaths.ROUTE_GAME_OWN_YOUR_PITCH, routePaths.ROUTE_GAME_OWN_YOUR_PITCH_NBA].includes(location.pathname)) {
      setHeaderTypeDesktop('black');
      return;
    }
    setHeaderTypeDesktop('blue');
  }, [isProfile, location.pathname]);

  useEffect(() => {
    if (headerType === 'transparent' && scrolled) {
      setHeaderType('blue');
    }
    if (!scrolled && isProfile) {
      setHeaderType('transparent');
    }
    if (!scrolled && ['/fan-token/KRUT', '/fan-token/SAHA', '/fan-token/UCFC'].includes(location.pathname)) {
      setHeaderType('transparent');
    }
  }, [scrolled]);

  const handleScroll = () => {
    setScrolled(window.pageYOffset > 0);
  };

  const handleShowMetaMask = () => {
    dispatch(WalletActions.openModal());
  };

  const handleConnect = () => {
    dispatch(WalletActions.openModal());
  };

  const handleDisconnect = () => {
    dispatch(WalletActions.disconnectWallet({}));
    history.push(routePaths.ROUTE_HOME);
  };

  return (
    <>
      <DisplayOn media={theme.breakpoints.between('xs', 'lg')}>
        <MobileHeader
          bannerTooltip={bannerTooltip}
          setBannerTooltip={setBannerTooltip}
          walletLoading={loading}
          walletAddress={wallet.address}
          handleShowMetaMask={handleShowMetaMask}
          errors={errors}
          handleConnect={handleConnect}
          handleDisconnect={handleDisconnect}
          isProfile={isProfile}
          headerStyle={HeaderTheme[headerType] as React.CSSProperties}
        />
      </DisplayOn>
      <DisplayOn media={theme.breakpoints.up('lg')}>
        <header className={classes.root} style={HeaderTheme[headerTypeDesktop] as React.CSSProperties}>
          <Box display="flex" alignItems="center" className={css({ height: '100%' })}>
            <Box mr={8} className={classes.logoBox}>
              <NavLink to={routePaths.ROUTE_HOME}>
                <Logo />
              </NavLink>
            </Box>
            <Box flexGrow={1} mr={7}>
              <Search />
            </Box>

            <Box mr={3} className={classes.linksBox}>
              <Menu setOpen={null} />
            </Box>
            <Box className={classes.profileBox}>
              <GetHeaderProfile walletLoading={loading} walletAddress={wallet.address} handleShowMetaMask={handleShowMetaMask} errors={errors} />
            </Box>
          </Box>
        </header>
      </DisplayOn>
    </>
  );
}
