import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import { Purchase } from 'entities/Fantoken';
import { StripeFTOState, StripePaymentState } from './types';

const initialState: StripeFTOState = {
  loading: false,
  error: false,
  stripeClientSecret: null,
  paid: false,
  success: false,
  purchase: null,
  paymentIntentId: null,
};

const stripePaymentInitialState: StripePaymentState = {
  paid: false,
  loading: false,
  success: false,
  error: false,
  message: '',
  paymentIntent: null,
};

const stripe = {
  payment: createSlice({
    name: 'stripe/payment',
    initialState: stripePaymentInitialState,
    reducers: {
      paymentStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      paymentComplete: (state) => {
        state.paid = true;
        state.loading = false;
      },
      paymentSuccess: (state, { payload }: PayloadAction<any>) => {
        state.paid = true;
        state.success = true;
        state.paymentIntent = payload.paymentIntent;
      },
      paymentError: (state, { payload }: PayloadAction<{ message: string | undefined }>) => {
        state.paid = false;
        state.error = true;
        state.message = payload.message;
        state.loading = false;
      },
      setInitial: (state) => {
        state.paid = false;
        state.loading = false;
        state.success = false;
        state.error = false;
        state.message = '';
        state.paymentIntent = null;
      },
    },
  }),
  fto: createSlice({
    name: 'stripe/fto',
    initialState,
    reducers: {
      initPaymentRequest: (state, { payload }: PayloadAction<{ tokenSymbol: string; address: string; purchase: Purchase }>) => {
        state.loading = true;
        state.error = false;
        state.paid = false;
        state.purchase = payload.purchase;
      },
      initPaymentSuccess: (state, { payload }: PayloadAction<{ clientSecret: string; paymentIntentId: string }>) => {
        state.stripeClientSecret = payload.clientSecret;
        state.paymentIntentId = payload.paymentIntentId;
        state.loading = false;
        state.error = false;
      },
      initPaymentFailure: (state, { payload }: PayloadAction<{ error: any }>) => {
        state.loading = false;
        state.error = true;
      },
    },
  }),
  nft: createSlice({
    name: 'stripe/nft',
    initialState: { loading: false, error: false, stripeClientSecret: '', paymentIntentId: '', initialized: false },
    reducers: {
      initPaymentRequest: (state, { payload }: PayloadAction<{ auctionId: string; quantity: number }>) => {
        state.loading = true;
        state.error = false;
        state.initialized = false;
      },
      initPaymentSuccess: (state, { payload }: PayloadAction<{ clientSecret: string; paymentIntentId: string }>) => {
        state.stripeClientSecret = payload.clientSecret;
        state.paymentIntentId = payload.paymentIntentId;
        state.loading = false;
        state.error = false;
        state.initialized = true;
      },
      initPaymentFailure: (state, { payload }: PayloadAction<{ error: any }>) => {
        state.loading = false;
        state.error = true;
      },
    },
  }),
  game: createSlice({
    name: 'stripe/game',
    initialState: { loading: false, error: false, stripeClientSecret: '', paymentIntentId: '', initialized: false },
    reducers: {
      initPaymentRequest: (state, { payload }: PayloadAction<{ eventId: string; quantity: number }>) => {
        state.loading = true;
        state.error = false;
        state.initialized = false;
      },
      initPaymentSuccess: (state, { payload }: PayloadAction<{ clientSecret: string; paymentIntentId: string }>) => {
        state.stripeClientSecret = payload.clientSecret;
        state.paymentIntentId = payload.paymentIntentId;
        state.loading = false;
        state.error = false;
        state.initialized = true;
      },
      initPaymentFailure: (state, { payload }: PayloadAction<{ error: any }>) => {
        state.loading = false;
        state.error = true;
      },
    },
  }),
};

const actions = combineSliceActions(stripe);

export { actions as StripeActions };

export default combineSliceReducers(stripe);
