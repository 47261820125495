import React, { useState } from 'react';
import { getLink } from 'helpers/routes';
import { Grid, Box, List, ListItem, Typography, Container, Stack, useMediaQuery, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FacebookIcon, MediumIcon, TelegramIcon, XIcon, LinkedInIcon, InstagramIcon } from 'icons';
import SubscriptionForm from 'shared/components/SubscriptionForm';
import TermsConditionsDialog from 'shared/dialogs/TermsConditions/TermsConditions';
import PrivacyDialog from 'shared/dialogs/Privacy/Privacy';
import { ROUTE_BSPT_BRIDGE, ROUTE_DAO, ROUTE_CELEBRITIES } from 'constants/routes';
import { useStyles } from './styles';

const socials = [
	{ link: 'https://www.linkedin.com/company/blocksport', icon: <LinkedInIcon /> },
	{ link: 'https://www.facebook.com/blocksportdotio/', icon: <FacebookIcon /> },
	{ link: 'https://www.instagram.com/blocksportdotio/', icon: <InstagramIcon /> },
	{ link: 'https://twitter.com/blocksportdotio', icon: <XIcon /> },
	{ link: 'https://t.me/Blocksport_official ', icon: <TelegramIcon /> },
	{ link: 'https://blocksport.medium.com/', icon: <MediumIcon /> },
];

const Footer = () => {
	const { classes, cx } = useStyles();
	const theme = useTheme();

	const [privacyModal, setPrivacyModal] = useState<boolean>(false);
	const [termsModal, setTermsModal] = useState<boolean>(false);

	const links = [
		{ title: 'Blog', path: 'https://blocksport.io/our-news/', target: '_blank', rel: 'noopener' },
		{ title: 'Сookie Policy', path: getLink('/cookies-policy'), target: '_self' },
		{ title: 'Help', path: getLink('/help'), target: '_self' },
		{ title: 'Terms & Conditions', onClick: () => setTermsModal(true), path: getLink('/terms'), popup: true },
		{ title: 'About', path: 'https://blocksport.io/team/', target: '_blank', rel: 'noopener' },
		{ title: '$BSPT bridge', path: getLink(ROUTE_BSPT_BRIDGE), target: '_self' },
		{ title: 'Privacy', onClick: () => setPrivacyModal(true), path: getLink('/privacy'), popup: true },
		{ title: 'Roadmap DAO', path: getLink(ROUTE_DAO), target: '_self' },
		{ title: 'Creators', path: getLink(ROUTE_CELEBRITIES), target: '_self' },
	];

	return (
		<>
			<footer className={classes.root}>
				<Container>
					<Grid container>
						<Grid item xs={12}>
							<Box mb={{ xs: 2, sm: 3, lg: 6 }}>
								<Typography variant="h2" className={classes.joinText}>
									Join the Community
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} lg={6}>
							<Box mb={2}>
								<SubscriptionForm />
							</Box>
						</Grid>
						<Grid item lg={1} />
						<Grid item xs={12} lg={5}>
							<Box mt={{ xs: 4, lg: 0 }} className={classes.linksBox}>
								<Stack direction="row">
									{socials.map((item) => (
										<ListItem key={item.link}>
											<a target="_blank" href={item.link} rel="noreferrer">
												{item.icon}
											</a>
										</ListItem>
									))}
								</Stack>
							</Box>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} lg={6}>
							<Box mt={{ xs: 4, lg: 0 }}>
								<Typography variant="subtitle1" component="p">
									{`Blocksport is a Swiss SportsTech. We build web3-ready platform solutions for the professional sports and entertainment industry to enable NFT and Fan
Token use cases for their fan community. Our solutions open untapped revenue streams and strengthen the community engagement.`}
								</Typography>
							</Box>
						</Grid>
						<Grid item lg={1} />
						<Grid item xs={12} lg={5}>
							<Box mt={{ xs: 4, lg: 0 }} className={classes.linksContainer}>
								<List>
									{links.map((item) =>
										item.popup ? (
											<ListItem key={item.path} onClick={item.onClick}>
												<Typography>{item.title}</Typography>
											</ListItem>
										) : (
											<ListItem key={item.path}>
												<Link href={item.path} target={item.target} underline="none" rel={item.rel}>
													{item.title}
												</Link>
											</ListItem>
										),
									)}
								</List>
							</Box>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Box mt={{ xs: 4, lg: 7 }}>
								<Typography
									variant="h4"
									className={cx(classes.copyright, { mobile: useMediaQuery(theme.breakpoints.between('xs', 'lg')) })}
								>{`Copyright Blocksport.io ${new Date().getFullYear()}`}</Typography>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</footer>

			<PrivacyDialog open={privacyModal} onClose={() => setPrivacyModal(false)} />
			<TermsConditionsDialog open={termsModal} onClose={() => setTermsModal(false)} />
		</>
	);
};

export default Footer;
