import React, { ReactNode } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './styles';

interface CardProps {
  icon: ReactNode;
  title: string;
  children?: ReactNode;
  buttonTitle: string;
  onClick?: () => void;
}

export default function InfoCard(props: CardProps) {
  const { icon, title, buttonTitle, children, onClick } = props;

  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      {icon}
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      {children}
      <Button onClick={onClick} variant="contained" color="primary" className={classes.btn}>
        {buttonTitle}
      </Button>
    </Box>
  );
}
