import axios from 'axios'
import { all, call, delay, put, select } from 'redux-saga/effects'
import { createERCDepositData, createResourceID, fromWei, toWei } from 'helpers/web3'
import web3, { web3Bsc, web3Eth } from 'helpers/getWeb3'
import { getBlockchainABI } from 'contracts'
import { bridgeDepositAddress, bridgeHolderAddress, bsptTokenAddress } from 'contracts/addresses'
import { NetworkStateInterface } from '../reducers/network/types'
import { TransactionActions } from '../reducers/transaction'
import { BridgeActions } from '../reducers/bridge'
import * as endpoints from '../../services/api'
import { getLink } from '../../helpers/routes'
import { AlertActions } from '../reducers/alert';
import { sliceTruncateHash } from '../../helpers/address'

type ContractType = {
	methods: {
		balanceOf: (address: string) => { call: () => Promise<string> };
		allowance: (address: string, address2: string) => { call: () => Promise<boolean> };
		approve: (address: string, amount: string) => { call: () => Promise<boolean> };
		_fee(): any;
	};
};

export function* watchForTransferCompletion(action: ReturnType<typeof BridgeActions.transferStatusRequest>) {
	const { transactionHash } = action.payload;
	while (true) {
		yield delay(20000);
		try {
			const {
				data: { data },
			} = yield axios.get(getLink(endpoints.GET_EXCHANGE_STATUS, { transactionHash }));

			if (data.status === 'SUCCESS') {
				yield put(BridgeActions.transferStatusSuccess({
					status: 'SUCCESS',
					transactionHashBridgePayout: data.transactionHashBridgePayout,
					destinationChainId: data.destinationChainId,
				}));
				yield put(AlertActions.showAlert({ message: `Transfer success tx: ${sliceTruncateHash(data.transactionHashBridgePayout)}`, severity: 'success' }));
				break;
			}
		} catch (error: any) {
			if (error.response && error.response.status === 404) {
				yield put(BridgeActions.transferStatusFailure());
				break;
			}
			yield put(BridgeActions.transferStatusFailure());
			break;
		}
	}
}

export function* depositSaga(action: ReturnType<typeof BridgeActions.depositRequest>) {
	const { address, amount, targetChainId, originChainId, fee } = action.payload;

	yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));
	const { network }: NetworkStateInterface = yield select((state) => state.network);
	// @ts-ignore
	const bridgeDepositAddr: string = bridgeDepositAddress[network];

	const bridgeContract: any = new web3.eth.Contract(
		getBlockchainABI(network, 'bridge'),
		bridgeDepositAddr,
	);

	// @ts-ignore
	const tokenAddress: string = bsptTokenAddress[network];

	try {
		const depositData: string = createERCDepositData(amount, 20, address);

		const resourceID = createResourceID(tokenAddress, originChainId);

		const gasPrice: string = yield call(web3.eth.getGasPrice);

		const { transactionHash } = yield web3.eth.sendTransaction({
			to: bridgeDepositAddr,
			from: address,
			data: bridgeContract.methods.deposit(targetChainId, resourceID, depositData).encodeABI(),
			value: toWei(fee.toString()),
			gasPrice,
		});

		if (transactionHash) {
			yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
			yield put(BridgeActions.depositSuccess({ transactionHash, chainId: targetChainId }));
			yield put(AlertActions.showAlert({ message: `Deposit success tx: ${sliceTruncateHash(transactionHash)}`, severity: 'success' }));
		}
	} catch (error: any) {
		yield put(AlertActions.showAlert({ message: error.message, severity: 'error' }));
		yield put(BridgeActions.depositFailure());
	}
}

export function* getBSPTBalancesSaga(action: ReturnType<typeof BridgeActions.getBSPTBalancesRequest>): Generator<any, void, any> {
	const { address, networks } = action.payload;

	try {
		const { network }: NetworkStateInterface = yield select((state) => state.network);
		
		console.log('network', network);

		const getBalanceFromNetwork = function* (web3Instance: any, net: string): Generator<any, Promise<string>, any> {
			// @ts-ignore
			const tokenAddress: string = bsptTokenAddress[net];
			const tokenContract: ContractType = yield new web3Instance.eth.Contract(getBlockchainABI(net, null), tokenAddress);
			return yield tokenContract.methods.balanceOf(address).call();
		};

		const balances: string[] = yield all(
			networks.map((net: string) => {
				let web3Connected = web3;
				if (net === 'eth' && net !== network) {
					web3Connected = web3Eth;
				} else if (net === 'bsc' && net !== network) {
					web3Connected = web3Bsc;
				}

				switch (net) {
					case 'eth':
						return call(getBalanceFromNetwork, web3Connected, net);
					case 'bsc':
						return call(getBalanceFromNetwork, web3Connected, net);
					default:
						return null;
				}
			}),
		);

		for (let i = 0; i < networks.length; i++) {
			yield put(BridgeActions.getBSPTBalancesSuccess({ balance: Math.floor(+fromWei(balances[i])), network: networks[i] }));
		}
	} catch (error) {
		// console.log({ error });
		yield put(BridgeActions.getBSPTBalancesFailure());
	}
}

export function* getBSPTFeeSaga(action: ReturnType<typeof BridgeActions.getBSPTFeeRequest>): Generator<any, void, any> {
	const { networks } = action.payload;

	try {
		const { network }: NetworkStateInterface = yield select((state) => state.network);

		const getFeeFromNetwork = function* (web3Instance: any, net: string): Generator<any, number, any> {
			// @ts-ignore
			const bridgeDepositAddr: string = bridgeDepositAddress[net];
			const contract: ContractType = yield new web3Instance.eth.Contract(getBlockchainABI(net, 'bridge'), bridgeDepositAddr);
			return yield contract.methods._fee().call();
		};

		const balances: string[] = yield all(
			networks.map((net: string) => {
				let web3Connected = web3;
				if (net === 'eth')  { /// && net !== network) {
					web3Connected = web3Eth;
				} else if (net === 'bsc') {
					web3Connected = web3Bsc;
				}

				switch (net) {
					case 'eth':
						return call(getFeeFromNetwork, web3Connected, net);
					case 'bsc':
						return call(getFeeFromNetwork, web3Connected, net);
					default:
						return null;
				}
			}),
		);

		for (let i = 0; i < networks.length; i++) {
			yield put(BridgeActions.getBSPTFeeSuccess({ fee: (+fromWei(balances[i])), network: networks[i] }));
		}
	} catch (error) {
		// console.log({ error });
		yield put(BridgeActions.getBSPTFeeFailure());
	}
}

export function* getBSPTAvailableBalanceSaga(action: ReturnType<typeof BridgeActions.getBSPTAvailableBalanceRequest>): Generator<any, void, any> {
	const { networks } = action.payload;

	try {
		const { network }: NetworkStateInterface = yield select((state) => state.network);

		const getBalanceFromNetwork = function* (web3Instance: any, net: string): Generator<any, Promise<string>, any> {
			// @ts-ignore
			const tokenAddress: string = bsptTokenAddress[net];
			// @ts-ignore
			const holderAddress = bridgeHolderAddress[net];
			const tokenContract: ContractType = yield new web3Instance.eth.Contract(getBlockchainABI(net, 'stakeBSPT'), tokenAddress);

			return yield tokenContract.methods.balanceOf(holderAddress).call();
		};

		const balances: string[] = yield all(
			networks.map((net: string) => {

				let web3Connected = web3;
				if (net === 'eth') {
					web3Connected = web3Eth;
				} else if (net === 'bsc') {
					web3Connected = web3Bsc;
				}

				switch (net) {
					case 'eth':
						return call(getBalanceFromNetwork, web3Connected, net);
					case 'bsc':
						return call(getBalanceFromNetwork, web3Connected, net);
					default:
						return null;
				}
			}),
		);

		for (let i = 0; i < networks.length; i++) {
			yield put(BridgeActions.getBSPTAvailableBalanceSuccess({ availableBalance: Math.floor(+fromWei(balances[i])), network: networks[i] }));
		}
	} catch (error) {
		// console.log({ error });
		yield put(BridgeActions.getBSPTAvailableBalanceFailure());
	}
}
