import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { InvitationActions } from 'store/reducers/invitation';

export function* getInvitationSaga(
  action: ReturnType<typeof InvitationActions.getInvitationRequest>,
) {
  const { invitationToken } = action.payload;

  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.INVITATION, { invitationToken }));
    yield put(InvitationActions.getInvitationSuccess({ data }));
  } catch {
    yield put(InvitationActions.getInvitationFailure());
  }
}
