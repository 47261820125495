import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import { FiltersInterface } from 'scenes/Collectibles/scenes/List/types';
import { initFiltersState } from './types';

const initialState: FiltersInterface = {
  filters: { ...initFiltersState },
};

const filters = {
  marketplace: createSlice({
    name: 'filters/marketplace',
    initialState,
    reducers: {
      changeFilters: (state, { payload }: PayloadAction<any>) => {
        state.filters = payload.filters;
      },
      clearFilters: (state) => {
        state.filters = { ...initFiltersState };
      },
    },
  }),
};

const actions = combineSliceActions(filters);

export { actions as FiltersActions };

export default combineSliceReducers(filters);
