import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApproveState } from './types';

const initialState: ApproveState = {
  isApprove: false,
  loading: false,
  error: false,
  hash: '',
  fantokenApproves: {},
};

const { actions, reducer } = createSlice({
  name: 'approve',
  initialState,
  reducers: {
    setApproveInitial: (state) => {
      state.hash = '';
      state.isApprove = false;
    },

    checkApproveRequest: (state, { payload }: PayloadAction<{ tokenAddress: string; ownerAddress: string; approveAddress: string; type?: string }>) => {
      state.isApprove = null; // false;
    },

    checkApproveSuccess: (state, { payload: { isApproved } }: PayloadAction<{ isApproved: boolean }>) => {
      state.isApprove = isApproved;
    },

    checkApproveFailure: (state) => {
      state.isApprove = false;
    },

    setApproveRequest: (state, { payload }: PayloadAction<{ ownerAddress: string; approveAddress: string; type?: string; tokenAddress: string }>) => {
      state.loading = true;
      state.error = false;
    },
    setApproveSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
      state.loading = false;
      state.error = false;
      state.hash = hash;
    },
    setApproveFailure: (state) => {
      state.error = true;
      state.loading = false;
    },
     setApproveReset: (state) => {
      state.isApprove = null;
    },


    checkApproveBSPTRequest: (state, { payload }: PayloadAction<{ address: string; amount: string }>) => {
      state.isApprove = false;
    },

    checkApproveBSPTSuccess: (state, { payload: { isApprove } }: PayloadAction<{ isApprove: boolean }>) => {
      state.isApprove = isApprove;
    },

    checkApproveBSPTFailure: (state) => {
      state.isApprove = false;
    },

    setApproveBSPTRequest: (state, { payload }: PayloadAction<{ address: string }>) => {
      state.loading = true;
      state.error = false;
    },
    setApproveBSPTSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
      state.loading = false;
      state.error = false;
      state.hash = hash;
    },
    setApproveBSPTFailure: (state) => {
      state.error = true;
      state.loading = false;
    },

    checkApproveFantokenRequest: (state, { payload }: PayloadAction<{ address: string; amount: string; tokenAddress: string }>) => {
      state.fantokenApproves = {};
      state.loading = true;
      state.error = false;
    },

    checkApproveFantokenSuccess: (state, { payload: { approve } }: PayloadAction<{ approve: any }>) => {
      state.fantokenApproves = { ...state.fantokenApproves, ...approve };
      state.loading = false;
      state.error = false;
    },

    checkApproveFantokenFailure: (state) => {
      state.fantokenApproves = {};
      state.error = true;
      state.loading = false;
    },

    setApproveFantokenRequest: (state, { payload }: PayloadAction<{ address: string; tokenAddress: string }>) => {
      state.loading = true;
      state.error = false;
    },
    setApproveFantokenSuccess: (state, { payload: { hash, approve } }: PayloadAction<{ hash: string; approve: any }>) => {
      state.loading = false;
      state.error = false;
      state.hash = hash;
      state.fantokenApproves = { ...state.fantokenApproves, ...approve };
    },
    setApproveFantokenFailure: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export { actions as ApproveActions };

export default reducer;
