import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';

import { WalletActions } from 'store/reducers/wallet';
import { MoonpayActions } from 'store/reducers/moonpay';
import { IPFSActions } from 'store/reducers/ipfs';
import { TokenActions } from 'store/reducers/token';
import { EmailActions } from 'store/reducers/email';

import * as routePaths from 'constants/routes';
import { getLink } from 'helpers/routes';

import { MenuItem, ListItemIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Avatar from 'shared/components/Avatar';
import DisplayOn from 'shared/components/DisplayOn';
import { DisconnectIcon, SupportIcon, CoinsIcon, DepositNFTIcon, BidsIcon, StarBlueIcon } from 'icons';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import {ROUTE_DAO} from "constants/routes";

const ProfileMenu = (props: any) => {
	const { handleClick } = props;
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();

	const { account, role } = useSelector((state) => state.account);

	const avatar = account?.avatar ?? null;
	const isCelebrity = role === 'celebrity';

	const handleCreateToken = () => {
		if (account) {
			history.push(routePaths.ROUTE_CREATE_TOKEN);

			dispatch(TokenActions.step.setStep(0));
			dispatch(TokenActions.step.setIsCreating(true));
			dispatch(IPFSActions.setInitial());
		}
	};

	const handleJoin = () => {
		dispatch(EmailActions.joinEmail.toggleJoinModal({ open: true }));
	};

	const handleAddFunds = () => {
		dispatch(MoonpayActions.toggleMoonpayModal(true));
	};

	const handleSupport = () => {
		ZendeskAPI('webWidget', 'updateSettings', {
			webWidget: {
				chat: {
					suppress: true,
				},
			},
		});
		ZendeskAPI('webWidget', 'open');
	};

	const handleDisconnect = () => {
		dispatch(WalletActions.disconnectWallet({}));
		history.push(routePaths.ROUTE_HOME);
	};

	return (
		<>
			<DisplayOn media={theme.breakpoints.up('lg')}>
				<MenuItem component={Link} to={getLink(routePaths.ROUTE_PROFILE_DETAILS)}>
					<Avatar src={avatar} sx={{ width: 24, height: 24 }} />
					View Profile
				</MenuItem>
			</DisplayOn>

			{isCelebrity && (
				<MenuItem onClick={handleCreateToken}>
					<DisplayOn media={theme.breakpoints.up('lg')}>
						<ListItemIcon>
							<AddCircleIcon />
						</ListItemIcon>
					</DisplayOn>
					Create NFT
				</MenuItem>
			)}

			{!isCelebrity && (
				<MenuItem onClick={handleJoin}>
					<DisplayOn media={theme.breakpoints.up('lg')}>
						<ListItemIcon>
							<StarBlueIcon />
						</ListItemIcon>
					</DisplayOn>
					Join as celebrity
				</MenuItem>
			)}

			{process.env.REACT_APP_ENV === 'development' && (
				<MenuItem component={Link} to={getLink(routePaths.DEPOSIT_NFT)} onClick={() => handleClick && handleClick()}>
					<DisplayOn media={theme.breakpoints.up('lg')}>
						<ListItemIcon>
							<DepositNFTIcon />
						</ListItemIcon>
					</DisplayOn>
					Deposit NFT
				</MenuItem>
			)}

			<MenuItem onClick={handleAddFunds}>
				<DisplayOn media={theme.breakpoints.up('lg')}>
					<ListItemIcon>
						<CoinsIcon />
					</ListItemIcon>
				</DisplayOn>
				Add funds
			</MenuItem>

			{!isCelebrity && (
				<MenuItem component={Link} to={getLink(routePaths.ROUTE_BIDS)} onClick={() => handleClick && handleClick()}>
					<DisplayOn media={theme.breakpoints.up('lg')}>
						<ListItemIcon>
							<BidsIcon />
						</ListItemIcon>
					</DisplayOn>
					Bids
				</MenuItem>
			)}

			<MenuItem component={Link} to={getLink(routePaths.ROUTE_BSPT_BRIDGE)}>
				<DisplayOn media={theme.breakpoints.up('lg')}>
					<ListItemIcon>
						<ImportExportIcon />
					</ListItemIcon>
				</DisplayOn>
				$BSPT bridge
			</MenuItem>

			<MenuItem component={Link} to={getLink(routePaths.ROUTE_PLAY_EARN)} onClick={() => handleClick && handleClick()}>
				<DisplayOn media={theme.breakpoints.up('lg')}>
					<ListItemIcon>
						<CoinsIcon />
					</ListItemIcon>
				</DisplayOn>
				Staking
			</MenuItem>

			<MenuItem component={Link} to={getLink(routePaths.ROUTE_DAO)}>
				<DisplayOn media={theme.breakpoints.up('lg')}>
					<ListItemIcon>
						<HowToVoteIcon />
					</ListItemIcon>
				</DisplayOn>
				DAO
			</MenuItem>

			<MenuItem onClick={handleSupport}>
				<DisplayOn media={theme.breakpoints.up('lg')}>
					<ListItemIcon>
						<SupportIcon />
					</ListItemIcon>
				</DisplayOn>
				Support
			</MenuItem>

			<DisplayOn media={theme.breakpoints.up('lg')}>
				<MenuItem onClick={handleDisconnect}>
					<ListItemIcon>
						<DisconnectIcon />
					</ListItemIcon>
					Disconnect
				</MenuItem>
			</DisplayOn>
		</>
	);
};

export default ProfileMenu;
