import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkStateInterface } from './types';

const initialState: NetworkStateInterface = {
  network: '',
  networkCurrency: '',
  link: '',
  loading: true,
  rejected: false,
};

const { actions, reducer } = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setCurrentNetwork: (state, { payload }: PayloadAction<{ network: string; currency: string; link: string; loading: boolean }>) => {
      state.network = payload.network;
      state.networkCurrency = payload.currency;
      state.link = payload.link;
      state.loading = payload.loading;
    },
    changeMetamaskRequest: (state, { payload }: PayloadAction<{ networkKey: string }>) => {
      state.rejected = false;
    },
    setUserRejected: (state) => {
      state.rejected = true;
    },
    changeMetamaskSuccess: (state) => {
      state.loading = false;
    },
  },
});

export { actions as NetworkActions };

export default reducer;
