import axios from 'axios';
import Web3 from 'web3';
import { subMonths, getUnixTime } from 'date-fns';
import { networkConstants } from 'constants/network';
import { getLink } from 'helpers/routes';
import { fromWei } from 'helpers/web3';
import { put, select } from 'redux-saga/effects';
import * as endpoints from '../../services/api';
import { MyWalletActions } from '../reducers/mywallet';

export function* getTransactionListRequestSaga(action: ReturnType<typeof MyWalletActions.getTransactionListRequest>) {
  const { address } = action.payload;

  const params = {
    fromDate: getUnixTime(subMonths(new Date(), 3)), // last 3 months
    toDate: getUnixTime(new Date()),
  };

  try {
    const { data } = yield axios.get(getLink(endpoints.GET_WALLET_TRANSACTIONS, { address }), { params });

    yield put(MyWalletActions.getTransactionListSuccess(data));
  } catch (error) {
    yield put(MyWalletActions.getTransactionListFailure());
  }
}

export function* getBalancesSaga(action: ReturnType<typeof MyWalletActions.getBalancesRequest>) {
  const {
    wallet: {
      wallet: { address, chainId: walletChainId, balance: walletBalance },
    },
    network: { network },
  } = yield select((state) => state);

  const networks = Object.keys(networkConstants.NETWORK_CONFIGS).filter((item) => item !== network); // remove current network from array
  const balances = [{ chainId: walletChainId, balance: walletBalance, network }];

  try {
    for (let i = 0; i < networks.length; i++) {
      // @ts-ignore
      const balance = yield getBalance(address, networks[i]);
      if (balance) {
        balances.push(balance);
      }
    }
    yield put(MyWalletActions.getBalancesSuccess({ balances }));
  } catch (error) {
    yield put(MyWalletActions.getBalancesFailure());
  }
}

export function* getBsptBalance(){
  const {
    wallet: {
      wallet: { address },
    },
  } = yield select((state) => state);

  try{
    const {data} = yield axios.get(endpoints.GET_WALLET_BSPT_BALANCE, { params: { address} });
    yield put(MyWalletActions.getBsptBalanceSuccess(data));
  }catch(err){
    yield put(MyWalletActions.getBsptBalanceFailure());
  }
}

function* getBalance(address: string, network: string) {
  try {
    // @ts-ignore
    const provider = networkConstants.NETWORK_CONFIGS[network].rpcUrls[0];
    const web3Provider = new Web3.providers.HttpProvider(provider);
    const web3local = new Web3(web3Provider);

    const balance: string = yield web3local.eth.getBalance(address);
    const chainId: string = yield web3local.eth.getChainId();
    const weiValue: string = yield fromWei(balance);

    if (balance) {
      return { chainId, balance: Math.round(+weiValue * 1e4) / 1e4, network };
    }
  } catch (error) {
    // console.log({ error });
  }
}
