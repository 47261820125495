import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { MexcActions } from '../reducers/mexc';

export function* getRateSaga(action: ReturnType<typeof MexcActions.rate.getRateRequest>) {
	const { symbol } = action.payload;

	try {
		const {
			data: { data },
		} = yield axios.get(getLink(endpoints.GET_MEXC_RATE), { params: { symbol } });

		if (data) {
			yield put(MexcActions.rate.getRateSuccess({ rate: { [symbol]: { ...data } } }));
		}
	} catch (error) {
		yield put(MexcActions.rate.getRateFailure());
	}
}
