import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TwitterUser, User } from 'entities/Account'
import { AccountState } from './types';

const initialState: AccountState = {
  account: null,
  // twitter: null,
  role: 'fan',
  loading: { role: false, account: false },
  errors: { update: false, account: false, createAccount: false },
};

const { actions, reducer } = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setRoleRequest: (state, { payload }: PayloadAction<{ address: string }>) => {
      state.loading.role = true;
      state.errors.account = false;
    },
    setRoleSuccess: (state, { payload }: PayloadAction<AccountState['role']>) => {
      state.role = payload;
      state.loading.role = false;
      state.errors.account = false;
    },
    setRoleFailure: (state) => {
      state.loading.role = false;
      state.errors.account = true;
    },
    getAccountWalletRequest: (state, { payload }: PayloadAction<{ address: string }>) => {
      state.loading.account = true;
      state.errors.account = false;
    },
    getAccountWalletSuccess: (state, { payload }: PayloadAction<AccountState['account']>) => {
      state.account = payload;
      state.loading.account = false;
      state.errors.account = false;
    },
    getAccountWalletFailure: (state) => {
      state.loading.account = false;
      state.errors.account = true;
    },

    disconnectAccount: (state) => {
      state.account = null;
      state.role = 'fan';
      state.loading.account = false;
      state.loading.role = false;
      state.errors.account = false;
    },

    updateAccountRequest: (state, { payload }: PayloadAction<Partial<User>>) => {
      state.loading.account = true;
      state.loading.role = true;
      state.errors.update = false;
    },
    updateAccountSuccess: (state, { payload }: PayloadAction<User>) => {
      state.account = payload;
      state.loading.account = false;
      state.loading.role = false;
      state.errors.update = false;
    },
    updateAccountFailure: (state) => {
      state.loading.account = false;
      state.loading.role = false;
      state.errors.update = true;
    },
    addAccountRequest: (
      state,
      { payload }: PayloadAction<{ data: Partial<User>; callback?: () => void }>,
    ) => {
      state.loading.account = true;
      state.loading.role = true;
      state.errors.createAccount = false;
    },
    addAccountSuccess: (state, { payload }: PayloadAction<User>) => {
      state.account = payload;
      state.loading.account = false;
      state.loading.role = false;
      state.errors.createAccount = false;
    },
    addAccountFailure: (state) => {
      state.loading.account = false;
      state.loading.role = false;
      state.errors.createAccount = true;
    },
    setTwitter: (state, { payload }: PayloadAction<TwitterUser>) => {
      // @ts-ignore
      state.account = {
        ...state.account,
        twitter: payload,
      };
    },
    disconnectTwitter: (state) => {
      // @ts-ignore
      state.account = {
        ...state.account,
        twitter: null,
      };
    },
  },
});

export { actions as AccountActions };

export default reducer;
