import React, { ReactNode } from 'react';
import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import { useField } from 'formik';

interface FormTextFieldProps extends Omit<TextFieldProps, 'name'> {
    name: string;
    adornmentContent?: ReactNode;
    position: 'end' | 'start';
    istouched?: number;
}

export default function FormTextFieldAdornment(props: FormTextFieldProps) {
    const { name, adornmentContent, position, istouched, ...otherProps } = props;
    const [field, { error, touched }] = useField(name);

    const adornmentPosition = position === 'end' ? 'endAdornment' : 'startAdornment';

    return (
        <TextField
            {...field}
            {...otherProps}
            error={istouched === 1 ? touched && !!error : !!error}
            helperText={istouched === 1 ? touched && error : error}
            InputProps={{
                [adornmentPosition]: adornmentContent && (
                    <InputAdornment position={position}>
                        {adornmentContent}
                    </InputAdornment>
                ),
            }}
        />
    );
}

FormTextFieldAdornment.defaultProps = {
    istouched: 1,
};
