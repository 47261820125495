import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { WhitePayActions } from '../reducers/whitePay';

export function* initWhitePaymentSaga(action: ReturnType<typeof WhitePayActions.initPaymentRequest>) {
  const { eventId, quantity } = action.payload;

  try {
    const {
      data: {
        data: { acquiringUrl, idempotencyKey },
      },
    } = yield axios.post(
      getLink(endpoints.GAME_INITPAYMENT_CRYPTO, { eventId }),
      { quantity },
      {
        headers: {
          'idempotency-key': uuidv4(),
        },
      },
    );

    yield put(WhitePayActions.initPaymentSuccess({ acquiringUrl, idempotencyKey }));
  } catch (error) {
    yield put(WhitePayActions.initPaymentFailure());
  }
}

export function* initTokenPaymentSaga(action: ReturnType<typeof WhitePayActions.initTokenPaymentRequest>) {
  const { auctionId, quantity } = action.payload;

  try {
    const {
      data: {
        data: { acquiringUrl, idempotencyKey },
      },
    } = yield axios.post(
      getLink(endpoints.TOKEN_BUYOUT_INITPAYMENT_WHITEPAY, { auctionId }),
      { quantity },
      {
        headers: {
          'idempotency-key': uuidv4(),
        },
      },
    );

    yield put(WhitePayActions.initTokenPaymentSuccess({ acquiringUrl, idempotencyKey }));
  } catch (error) {
    yield put(WhitePayActions.initTokenPaymentFailure());
  }
}
