import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GamesState } from './types';
import {Transaction, TransactionStatuses} from "../bridge/types";

const initialTransactionState = {
  status: TransactionStatuses.IDLE,
  hash: null,
  chainId: null,
};

const initialState: GamesState = {
  list: [],
  game:  null,
  loading: false,
  error: false,
  claimed: false,
  hash: '',
  rewards: 0,
  deposit: initialTransactionState,
  transfer: initialTransactionState,
};

const { actions, reducer } = createSlice({
  name: 'games',
  initialState,
  reducers: {
    getGamesListRequest: (state) => {
      state.loading = true;
      state.error = false;
    },
    getGamesListSuccess: (state, { payload }: PayloadAction<{ data: any[] }>) => {
      state.list = payload.data;
      state.loading = false;
    },
    getGamesListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    getGameRequest: (state, { payload }: PayloadAction<{ gameId: number | string }>) => {
      state.loading = true;
      state.error = false;
    },
    getGameSuccess: (state, { payload }: PayloadAction<any>) => {
      state.game = payload.data;
      state.loading = false;
    },
    getGameFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    purchaseTokenRequest: (state, { payload }: PayloadAction<{ gameId: number; address: string; quantity: number; price: number, currencyAsset: string }>) => {
      state.deposit = {
        status: TransactionStatuses.PENDING,
        hash: null,
        chainId: null,
      };
    },
    purchaseTokenSuccess: (state, { payload: { transactionHash, chainId } }: PayloadAction<{ transactionHash: string, chainId: string }>) => {
      state.deposit = {
        status: TransactionStatuses.SUCCESS,
        hash: transactionHash,
        chainId,
      }
    },

    purchaseTokenFailure: (state) => {
      state.deposit = {
        status: TransactionStatuses.FAILED,
        hash: null,
        chainId: null,
      }
    },
    claimRewardsRequest: (state, { payload }: PayloadAction<{ gameId: number; address: string }>) => {
      state.loading = true;
      state.error = false;
    },
    claimRewardsSuccess: (state, { payload: { hash } }: PayloadAction<{ hash: string }>) => {
      state.loading = false;
      state.error = false;
      state.hash = hash;
    },
    claimRewardsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    claimRewardsFiatRequest: (state, { payload }: PayloadAction<{ gameId: number; address: string, idempotencyKey: string, gameAddress: string }>) => {
      state.loading = true;
      state.error = false;
      state.claimed = false;
    },
    claimRewardsFiatSuccess: (state, { payload: { data } }: PayloadAction<{ data: any }>) => {
      state.loading = false;
      state.error = false;
      state.claimed = true;
    },
    claimRewardsFiatFailure: (state) => {
      state.loading = false;
      state.error = true;
      state.claimed = false;
    },
    getCurrentGameRequest: (state, { payload }: PayloadAction<{ gameType: string }>) => {
      state.loading = true;
      state.error = false;
    },
    getCurrentGameSuccess: (state, { payload }: PayloadAction<any>) => {
      state.game = payload.data;
      state.loading = false;
    },
    getCurrentGameFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    checkGameRewardsRequest: (state, { payload }: PayloadAction<{ gameId: number; address: string }>) => {
      state.loading = true;
      state.error = false;
    },
    checkGameRewardsSuccess: (state, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.error = false;
      state.rewards = payload.rewards;
    },
    checkGameRewardsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    transferStatusRequest: (state, { payload }: PayloadAction<{transactionHash: string}>) => {
      state.transfer = {
        status: TransactionStatuses.PENDING,
        hash: null,
        chainId: null,
      }
    },
    transferStatusSuccess: (state, { payload }: PayloadAction<{status: string, transactionHashBridgePayout: string, destinationChainId: string}>) => {
      state.transfer = {
        status: TransactionStatuses.SUCCESS,
        hash: payload.transactionHashBridgePayout,
        chainId: payload.destinationChainId,
      }
    },
    transferStatusFailure: (state) => {
      state.transfer = {
        status: TransactionStatuses.FAILED,
        hash: null,
        chainId: null,
      }
    },
    completedTransfer: (state) => {
      state.transfer = {
        status: TransactionStatuses.IDLE,
        hash: null,
        chainId: null,
      };
      state.deposit = {
        status: TransactionStatuses.IDLE,
        hash: null,
        chainId: null,
      };
    },
  },
});

export { actions as GameActions };

export default reducer;
