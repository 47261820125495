import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Typography,
  Drawer,
  IconButton,
  AppBar,
  Toolbar,
  Paper,
  TextField,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  CardActions,
  Stack,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useStyles } from './styles';
import { SearchActions } from '../../../../store/reducers/search';

let timeoutSearch: any;

const MobileSearch = (props: any) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { loading, celebrityList, nftList } = useSelector((state) => state.search);

  const [searchOpen, setSearchOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [firstTimeOpened, setFirstTimeOpened] = useState(false);

  useEffect(() => {
    if (loading) {
      setFirstTimeOpened(true);
    }
  }, [loading]);

  const handleClearClick = () => {
    setSearchValue('');
    dispatch(SearchActions.clearData());
    setFirstTimeOpened(false);
  };

  const handleClose = () => {
    setSearchOpen(false);
  };

  const getListItems = (items: any[], results: number) => {
    if (loading) {
      return (
        <Grid container justifyContent="center" sx={{ margin: '20px' }}>
          <CircularProgress sx={{ color: '#828282' }} />
        </Grid>
      );
    }

    if (!items.length) {
      return (
        <Grid container justifyContent="center">
          <Typography className={classes.empty}>No options found</Typography>
        </Grid>
      );
    }

    return (
      <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {items.slice(0, results).map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.id}`;
          const link = value.name ? `/profile/${value?.nickName}` : `/collectibles/${value?.tokenId}/${value?.tokenSource}`;
          return (
            <ListItem
              key={value.id}
              secondaryAction={
                value?.name && (
                  <Typography
                    sx={{
                      textAlign: 'end',
                      right: '20px',
                    }}
                  >
                    {value.purchases}
                  </Typography>
                )
              }
              disablePadding
            >
              <ListItemButton sx={{ padding: '12px 20px' }} component={Link} to={link} onClick={() => setSearchOpen(false)}>
                <ListItemAvatar sx={{ minWidth: '40px' }}>
                  <Avatar sx={{ width: '28px', height: '28px' }} src={value?.avatar || value?.imageThumbnail || value?.imageAws} />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={value?.name || value?.title}
                  primaryTypographyProps={{
                    textOverflow: 'ellipsis',
                    maxWidth: '320px',
                    overflow: 'hidden',
                    fontWeight: 600,
                    fontSize: '16px',
                    color: '#333333',
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const handleSearch = (searchQuery: any) => {
    setSearchValue(searchQuery);
    clearTimeout(timeoutSearch);
    if (searchQuery.length > 2) {
      timeoutSearch = setTimeout(() => {
        dispatch(SearchActions.getSearchRequest(searchQuery));
      }, 1000);
    }
  };

  return (
    <div>
      <IconButton aria-label="delete" size="small" onClick={() => setSearchOpen(true)}>
        <SearchIcon className={classes.iconSearch} />
      </IconButton>

      <Drawer anchor="left" open={searchOpen} onClose={() => setSearchOpen(false)} className={classes.mobileDrawer}>
        <AppBar className={classes.appBar} sx={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <ArrowBackIcon sx={{ color: '#828282' }} />
            </IconButton>
            <Paper component="form" className={classes.paperTextField}>
              <TextField
                className={classes.input}
                onChange={(newValue) => {
                  handleSearch(newValue.target.value);
                }}
                variant="outlined"
                defaultValue={searchValue}
                value={searchValue}
                placeholder="Search"
                InputProps={{
                  endAdornment: (
                    <IconButton sx={{ visibility: searchValue ? 'visible' : 'hidden' }} onClick={handleClearClick}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  m: 2,
                  '& .Mui-focused .MuiIconButton-root': { color: 'primary.main' },
                }}
              />
            </Paper>
          </Toolbar>
        </AppBar>
        {!loading && firstTimeOpened ? (
          <CardContent sx={{ p: 0, overflow: 'auto' }}>
            <CardContent sx={{ p: 0, overflow: 'auto' }}>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Typography className={classes.listGroup}>Creator</Typography>
                </Grid>
                {getListItems(celebrityList, 3)}
              </Grid>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Typography className={classes.listGroup} sx={{ paddingTop: '12px !important' }}>
                    Item
                  </Typography>
                </Grid>
                {getListItems(nftList, 5)}
              </Grid>
            </CardContent>
          </CardContent>
        ) : null}
        {!loading && (celebrityList.length || nftList.length) ? (
          <CardActions className={classes.cardActions}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
              <Button
                variant="text"
                component={Link}
                to={`/marketplace?search=${searchValue}`}
                sx={{ width: '100%', borderRadius: 0 }}
                onClick={() => setSearchOpen(false)}
              >
                Show All {nftList.length || celebrityList.length ? `(${nftList.length + celebrityList.length})` : null}
              </Button>
              <IconButton color="primary" aria-label="upload picture" component="label" sx={{ marginLeft: '-40px !important' }}>
                <ArrowForwardIosIcon fontSize="medium" sx={{ color: '#828282' }} />
              </IconButton>
            </Stack>
          </CardActions>
        ) : null}
      </Drawer>
    </div>
  );
};

export default MobileSearch;
