import { eventChannel, END } from 'redux-saga';
import { put, call, take, fork } from 'redux-saga/effects';
import axios from 'axios';

import * as endpoints from 'services/api';

import web3 from 'helpers/getWeb3';
import { fromWei } from 'helpers/web3';
import { getLink } from 'helpers/routes';
import { hexToDec } from 'helpers/format';
import { networkConstants } from 'constants/network';
import { MetaMaskActions } from 'store/reducers/metamask';
import { SubscribingActions } from 'store/reducers/subscribing';
import { NetworkActions } from 'store/reducers/network';
import { AccountActions } from '../reducers/account';
import { WalletActions } from '../reducers/wallet';
import { AlertActions } from '../reducers/alert';

declare const window: any;

export const deleteAuthorizationToken = () => {
  delete axios.defaults.headers.Authorization;
};

export const setAuthorizationToken = (token: string) => {
  if (token) {
    axios.defaults.headers = {
      Authorization: `${token}`,
      Accept: 'application/json',
    };
  } else {
    deleteAuthorizationToken();
  }
};

export function* disconnectWalletSaga(action: ReturnType<typeof WalletActions.disconnectWallet>) {
  const { reload } = action.payload;

  yield call(setLocalSaga, false, 'fan');
  yield call(deleteAuthorizationToken);
  yield put(AccountActions.disconnectAccount());
  yield put(SubscribingActions.resetSubscribingList());
  yield put(MetaMaskActions.setLoading(false));
  yield localStorage.removeItem('userId');
  yield localStorage.removeItem('expDate');
  yield localStorage.removeItem('token');
  reload && window.location.reload();
}

export function* setLocalSaga(connected: boolean, role?: string, userId?: string, token?: string) {
  yield localStorage.setItem('connected', JSON.stringify(connected));
  const currentDate = new Date();
  const expDate = Math.floor(new Date(new Date(currentDate).setHours(currentDate.getHours() + 1)).getTime() / 1000);

  if (role) {
    yield localStorage.setItem('role', role);
  }
  if (userId) {
    yield localStorage.setItem('userId', userId);
  }
  if (token) {
    yield localStorage.setItem('token', token);
    yield localStorage.setItem('expDate', JSON.stringify(expDate));
    yield call(setAuthorizationToken, token);
  }
}

function* checkTokenExpirationDate() {
  const token: string = yield localStorage.getItem('token');
  const expDate: string = yield localStorage.getItem('expDate');

  //  Check token expiration
  if (Number(expDate) > Math.floor(Date.now() / 1000)) {
    yield call(setAuthorizationToken, token);
    yield call(getWalletSaga);
  } else {
    yield put(WalletActions.disconnectWallet({}));
  }
}

export function* authCheckLocalStorageSaga() {
  if (!localStorage) {
    return;
  }

  const token: string = yield localStorage.getItem('token');

  if (token) {
    try {
      yield call(checkTokenExpirationDate);
    } catch (error) {
      yield put(WalletActions.disconnectWallet({}));
    }
  }
}

export function* connectWalletSaga(action: ReturnType<typeof WalletActions.connectWallet>) {
  const { callback } = action.payload;

  yield put(MetaMaskActions.setLoading(true));
  try {
    const networkId: number = yield call([web3.eth.net, web3.eth.net.getId]);
    yield call(checkAndSwitchNetwork, networkId);

    const accounts: string[] = yield web3.eth.requestAccounts();
    const address = accounts[0];

    if (accounts) {
      const {
        data: {
          data: { message },
        },
      } = yield axios.get(getLink(endpoints.GET_AUTH_MESSAGE, { address }));

      if (message) {
        const signMessage: string = yield web3.eth.personal.sign(message, address, '');

        const {
          data: { data },
        } = yield axios.post(getLink(endpoints.POST_AUTH_MESSAGE, { address }), {
          messageHash: signMessage,
        });

        yield call(setLocalSaga, true, '', '', data);
        yield put(WalletActions.setWalletRequest({ address: accounts[0] }));
      }

      callback && callback();
      yield put(MetaMaskActions.setLoading(false));
    }
  } catch (error) {
    if ((error as any).code === 4001) {
      yield put(WalletActions.disconnectWallet({}));
    }

    yield put(WalletActions.setWalletFailure());
  }
}

export function* getWalletSaga() {
  const connected = JSON.parse(localStorage.getItem('connected') as string);

  if (window.ethereum && connected) {
    try {
      const accounts: string[] = yield web3.eth.getAccounts();

      if (accounts.length) {
        yield put(WalletActions.setWalletRequest({ address: accounts[0] }));
      } else {
        yield put(WalletActions.setWalletSuccess({ address: '', balance: 0, chainId: '' }));
      }
    } catch (error) {
      yield put(WalletActions.disconnectWallet({}));
    }
  }
}

export function* setWalletSaga(action: ReturnType<typeof WalletActions.setWalletRequest>) {
  const { address } = action.payload;

  try {
    const balance: string = yield web3.eth.getBalance(address);
    const chainId: string = yield web3.eth.getChainId();
    const weiValue: string = yield fromWei(balance);

    yield put(WalletActions.setWalletSuccess({ address, balance: +(Math.round(+weiValue * 1e4) / 1e4).toFixed(2), chainId }));

    yield put(AccountActions.getAccountWalletRequest({ address }));

    // subscribe to EIP-1193 events
    yield fork(watchChangeChannelSaga);
  } catch (error) {
    // @ts-ignore
    yield call(disconnectWalletSaga, {});
    yield put(WalletActions.disconnectWallet({}));
  }
}

// Create channel to listen a websocket for a change
function* watchChangeChannelSaga() {
  // @ts-ignore
  const provider = yield web3.eth.currentProvider;
  // @ts-ignore
  const web3ProviderChannel: any = yield call(walletconnectChangeChannel, provider);
  while (true) {
    // @ts-ignore
    const action = yield take(web3ProviderChannel);
    yield put(action);
  }
}

export function* updateWalletSaga(action: ReturnType<typeof WalletActions.updateWalletRequest>) {
  const connected = JSON.parse(localStorage.getItem('connected') as string);

  if ( !connected || !window.ethereum ) {
    yield put(WalletActions.setWalletIdle());
    return;
  }

  try {
    const accounts: string[] = yield web3.eth.getAccounts();

    if (accounts.length) {
      const address = accounts[0];

      const balance: string = yield web3.eth.getBalance(address);
      const chainId: string = yield web3.eth.getChainId();
      const weiValue: string = yield fromWei(balance);

      const roundedBalance = +(Math.round(+weiValue * 1e4) / 1e4).toFixed(2);

      yield put(WalletActions.setWalletSuccess({ address, balance: roundedBalance, chainId }));
    }
  } catch (error) {
    yield put(WalletActions.setWalletFailure());
  }
  

}

// translate accountChanged|chainChanged events from socket to sagas
export function walletconnectChangeChannel(provider: any) {
  return eventChannel((emit) => {
    const accountsChanged = (accounts: string[]) => {
      if(accounts.length === 0) {
        emit(WalletActions.getWalletRequest());
       return;
      }
      emit(WalletActions.updateWalletRequest());
    };

    const chainChanged = (chainId: string) => {
      const chainIdDec = hexToDec(chainId);

      // @ts-ignore
      if (networkConstants.NETWORKS[chainIdDec]) {
        const {
          network: net,
          currency,
          link,
          // @ts-ignore
        } = networkConstants.NETWORKS[chainIdDec];

        emit(NetworkActions.setCurrentNetwork({ network: net, currency, link, loading: false }));
        // @fixme: dont update account, update
        emit(WalletActions.updateWalletRequest());
        // emit(WalletActions.getWalletRequest());
        // emit(END);
      }
    };

    const disconect = () => {
      emit(WalletActions.disconnectWallet({ reload: true }));
      emit(END);
    };

    provider.on('accountsChanged', accountsChanged);
    provider.on("chainChanged", chainChanged);

    return () => {
      provider.removeListener("accountsChanged", accountsChanged);
      provider.removeListener("chainChanged", chainChanged);
    };
  });
}

// @ts-ignore
export function* checkAndSwitchNetwork(currentNetworkId: any) {
  try {
    const supportedNetworkConfigs = Object.values(networkConstants.NETWORK_CONFIGS);
    const supportedNetworkIds = supportedNetworkConfigs.map((config) => parseInt(config.chainId, 16));

    if (supportedNetworkIds.includes(currentNetworkId)) {
      return;
    }

    // @ts-ignore
    const currentChainConfig = networkConstants.NETWORKS[currentNetworkId];

    // check if current network is supported analog
    if (currentChainConfig) {
      const { network } = currentChainConfig;

      // @ts-ignore
      if (networkConstants.NETWORK_CONFIGS[network]) {
        yield put(NetworkActions.changeMetamaskRequest({ networkKey: network }));
        return;
      }
    }
    yield put(NetworkActions.changeMetamaskRequest({ networkKey: 'bsc' }));
  } catch (error: any) {
    yield put(AlertActions.showAlert({ message: error.message, severity: 'error' }));
  }
}
