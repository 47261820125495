import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import { StatusState } from './types';

const transaction = {
  statusTransaction: createSlice({
    name: 'token/statusTransaction',
    initialState: {
      status: 'start',
    } as StatusState,
    reducers: {
      setStatusTransaction: (state, { payload }: PayloadAction<string>) => {
        state.status = payload;
      },
      setInitial: (state) => {
        state.status = 'start';
      },
    },
  }),
  statusApproveTransaction: createSlice({
    name: 'token/statusApprove',
    initialState: {
      status: 'start',
    } as StatusState,
    reducers: {
      setStatusApproveTransaction: (state, { payload }: PayloadAction<string>) => {
        state.status = payload;
      },
    },
  }),
};

const actions = combineSliceActions(transaction);

export { actions as TransactionActions };

export default combineSliceReducers(transaction);
