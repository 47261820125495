import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchState, SearchOptions } from './types';

const initialState: SearchState = {
    loading: false,
    celebrityList: [],
    nftList: [],
    error: false,
}

const { actions, reducer } = createSlice({
    name: 'search',
    initialState,
    reducers: {
        getSearchRequest: (state, { payload }: PayloadAction<{ searchQuery: string }>) => {
            state.loading = true;
            state.error = false;
        },
        getSearchSuccess: (state, { payload }: PayloadAction<{ data: SearchOptions }>) => {
            state.loading = false;
            state.celebrityList = payload.data.celebrity.data;
            state.nftList = payload.data.nft.data;
        },
        getSearchFailed: (state) => {
            state.loading = false;
            state.error = true;
        },
        clearData: (state) => {
            state.celebrityList = [];
            state.nftList = [];
        },
    },
});

export { actions as SearchActions };

export default reducer;