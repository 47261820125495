import React from 'react';
import { Grid, IconButton, Typography, Paper, Stack } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { CoinsIcon } from 'icons';
import { getAddressLink } from 'helpers/links';
import { useStyles } from '../../styles';

const Balance = (props: any) => {
	const { address, balance, currency, network, icon, handleAddFunds } = props;
	const { classes } = useStyles();
	return (
		<Paper className={classes.balanceArea}>
			<Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: '100%', padding: '0 4px' }}>
				<Grid item xs={9}>
					<Stack direction="row" spacing={1}>
						<IconButton aria-label="blockchain">{icon}</IconButton>
						<Stack>
							<Typography className={classes.totalBalance}>Total balance</Typography>
							<Typography className={classes.balance}>{`${balance} ${currency}`}</Typography>
						</Stack>
					</Stack>
				</Grid>
				<Grid item xs={3}>
					<Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
						<IconButton aria-label="funds" onClick={handleAddFunds}>
							<CoinsIcon className={classes.iconsControl} />
						</IconButton>
						<IconButton aria-label="scan" href={getAddressLink(address, network)} target="_blank" rel="noopener">
							<LaunchIcon className={classes.iconsControl} />
						</IconButton>
					</Stack>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default Balance;
