import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { white, bsBlue } from 'constants/colors';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: '100%',
      backgroundColor: bsBlue,
      paddingTop: theme.spacing(4),
      paddingBottom: 0,

      '& .MuiButton-containedPrimary': {
        background: `linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%)`,
        color: bsBlue,

        '&:hover': {
          background: 'linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%)',
        },
      },
      '& .MuiTypography-root': {
        color: white,
      },

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },

      [theme.breakpoints.up('lg')]: {
        backgroundColor: '#F2F2F2',
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        paddingLeft: 0,
        paddingRight: 0,

        '& .MuiButton-containedPrimary': {
          background: `linear-gradient(90deg, ${bsBlue} 0%, ${bsBlue} 100%)`,
          color: white,
          '&:hover': {
            background: 'linear-gradient(90deg, #3788F9 0%, #0A44CA 100%)',
          },
        },
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    joinText: {
      fontWeight: 600,
      fontSize: '1.6rem',
      lineHeight: '2rem',

      [theme.breakpoints.up('lg')]: {
        fontSize: '2.8rem',
        lineHeight: '3.6rem',
      },
    },
    linksBox: {
      '& .MuiListItem-root': {
        padding: 0,
        width: 'auto',
        '& svg': {
          color: white,
          '& circle': {
            fill: bsBlue,
            stroke: white,
          },
        },
      },
      '& > div': {
        flexWrap: 'wrap',
        '& .MuiListItem-root': {
          marginLeft: 0,
          marginRight: theme.spacing(2),
          '&:last-of-type': {
            marginRight: 0,
          },
        },
      },
      [theme.breakpoints.up('sm')]: {
        '& > div': {
          justifyContent: 'center',
        },
      },
      [theme.breakpoints.up('lg')]: {
        '& .MuiListItem-root': {
          padding: 0,
          width: 'auto',
          '& svg': {
            color: theme.palette.primary.main,
            '& circle': {
              fill: white,
            },
          },
        },
        '& > div': {
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
        },
      },
    },
    linksContainer: {
      '& .MuiList-root': {
        display: 'grid',
        gridTemplateColumns: '100%',
      },
      '& .MuiListItem-root': {
        paddingLeft: 0,
        color: white,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',

        '& a, & p': {
          color: 'inherit',
          fontWeight: 600,
          cursor: 'pointer',
        },
      },
      [theme.breakpoints.up('sm')]: {
        '& .MuiList-root': {
          gridTemplateColumns: '50% 50%',
        },
      },
      [theme.breakpoints.up('lg')]: {
        '& .MuiList-root': {
          gridTemplateColumns: '106px auto',
        },
        '& .MuiListItem-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    copyright: {
      '&.mobile': {
        lineHeight: '6rem',
        width: '100vw',
        marginLeft: theme.spacing(-2),
        paddingLeft: theme.spacing(2),
        borderTop: '1px solid #FFFFFF',
      },

      [theme.breakpoints.up('sm')]: {
        '&.mobile': {
          marginLeft: -56,
          textAlign: 'center',
        },
      },

      [theme.breakpoints.up('lg')]: {
        color: '#828282',
      },
    },
    btnsBox: {
      '& .MuiButton-root': {
        height: 50,
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        backgroundImage: 'none',
        color: '#FFFFFF',
        borderColor: '#FFFFFF',

        '&:before, &:after': {
          display: 'none',
        },

        [theme.breakpoints.up('sm')]: {
          width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
          color: '#3889FA',
          borderColor: '#3889FA',
        },
      },
    },
  };
});
