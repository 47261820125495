import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FollowSubscribe } from 'entities/Account';
import { SubscriberState } from './types';

const initialState: SubscriberState = {
  list: [],
  loading: true,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'subscriber',
  initialState,
  reducers: {
    getSubscriberListRequest: (state, { payload }: PayloadAction<{ celebrityId: number }>) => {
      state.loading = true;
      state.error = false;
    },
    getSubscriberListSuccess: (state, { payload }: PayloadAction<{ data: FollowSubscribe[] }>) => {
      state.list = payload.data;
      state.loading = false;
    },
    getSubscriberListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as SubscriberActions };

export default reducer;
