import axios from 'axios';
import { put } from 'redux-saga/effects';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { TwitterActions } from '../reducers/twitter';
import { AccountActions } from '../reducers/account';

export function* requestOathToken() {
  try {
    const {
      data: {
        data: { oauthToken },
      },
    } = yield axios.post(getLink(endpoints.TWITTER_OATH_GET_TOKEN));
    yield put(TwitterActions.oathTokenSuccess({ oauthToken }));
  } catch {
    yield put(TwitterActions.oathTokenFailure());
  }
}

export function* requestConnect(action: ReturnType<typeof TwitterActions.connectRequest>) {
  try {
    const { oauthToken, oauthVerifier } = action.payload;
    const {
      data: { data },
    } = yield axios.post(getLink(endpoints.TWITTER_CONNECT), { oauthToken, oauthVerifier });

    yield put(TwitterActions.connectSuccess(data));
    yield put(AccountActions.setTwitter(data));
  } catch {
    yield put(TwitterActions.connectFailure());
  }
}

export function* disconnect() {
  try {
    yield axios.post(getLink(endpoints.TWITTER_DISCONNECT));
    yield put(AccountActions.disconnectTwitter());
    yield put(TwitterActions.disconnectSuccess());
  } catch {
    yield put(TwitterActions.disconnectFailure());
  }
}
