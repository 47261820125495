import axios from 'axios';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { KycActions } from '../reducers/kyc';
import { getLink } from '../../helpers/routes';

export function* getVerificationsByUserIdSaga(action: ReturnType<typeof KycActions.getVerificationsByUserIdRequest>) {
  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.GET_VERIFICATIONS_BY_USER_ID, { userId: action.payload.userId }));
    yield put(KycActions.getVerificationsByUserIdSuccess({ data }));
  } catch (error: any) {
    yield put(KycActions.getVerificationsByUserIdFailure({ error: error.message }));
  }
}
