import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  Typography,
  Box,
  Button,
  DialogTitle,
} from '@mui/material';
import { CloseIcon, RejectIcon } from 'icons';
import { useDialogStyles } from '../styles';

interface MetaMaskProps {
  open: boolean;
  handleClose(): void;
  handleButton(): void;
  targetNetwork: string;
}

const ChangeNetwork = ({ open, targetNetwork, handleClose, handleButton }: MetaMaskProps) => {
  const { classes: dialogClasses } = useDialogStyles();
  const title = 'Wrong network';
  const description = `In order to continue, please switch to a ${targetNetwork} network.`;
  const button = `Change to ${targetNetwork}`;

  return (
    <Dialog open={open} onClose={handleClose} >
      <Box mt={2} mb={4}>
        <DialogTitle className={dialogClasses.title}>
          <RejectIcon />
        </DialogTitle>
        <IconButton onClick={handleClose} className={dialogClasses.closeBtn} size="large">
          <CloseIcon />
        </IconButton>
        <Box mb={6} mt={0}>
          <DialogContent className={dialogClasses.content}>
            <Typography classes={{ h3: dialogClasses.title }} variant="h3">
              {title}
            </Typography>
            <Typography variant="subtitle1">{description}</Typography>
          </DialogContent>
        </Box>
        <DialogActions className={dialogClasses.actions}>
          <Button variant="outlined" className={dialogClasses.actionBtn} onClick={handleButton}>
            {button}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ChangeNetwork;
