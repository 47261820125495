import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { bsBlue, white } from 'constants/colors';

export const useMobileStyles = makeStyles()((theme: Theme) => {
  const headerHeight = 60;

  return {
    root: {
      display: 'flex',
      width: '100vw',
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: 1000,
      transition: 'background 800ms ease',
    },
    appBar: {
      background: `var(--bg-color)`,
      borderRadius: 0,
      transition: 'background 0.1s ease-in',

      '& .MuiToolbar-root': {
        height: headerHeight,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },

      '& svg': {
        color: `var(--text-color)`,
      },
    },
    appBarOpen: {
      background: `linear-gradient(90deg, ${bsBlue} 0%, ${bsBlue} 100%);`,
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& svg': {
        height: 21,
        width: 120,
      },
    },
    menuOpenDialog: {
      top: headerHeight,

      '& .MuiBackdrop-root': {
        top: headerHeight,
      },

      '& .MuiPaper-root': {
        background: bsBlue,
      },
      '& .MuiDialogContent-root': {
        padding: `24px 16px`,
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
        paddingLeft: 16,
        paddingRight: 16,
      },
      '& .MuiButton-root': {
        fontWeight: 600,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        color: bsBlue,
        background: '#FFFFFF',
        width: '100%',
        marginBottom: 30,
        padding: '9px 44px',
      },
    },
    menuBox: {
      '& .MuiListItem-root': {
        paddingLeft: 0,
        fontWeight: 600,
        fontSize: '1.6rem',
        lineSeight: '2.4rem',
        marginBottom: 8,

        '& > a': {
          color: '#FFFFFF',
        },
      },
    },
    balanceBox: {
      '& .MuiButtonBase-root': {
        padding: `8px 24px`,
        whiteSpace: 'nowrap',
        background: 'rgba(255, 255, 255, 0.3)',
        borderRadius: 30,
      },
      '& .MuiTypography-root': {
        color: white,
      },
    },
    profileLinkBox: {
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
      '& .MuiDivider-root': {
        borderColor: 'white',
      },
      '& .MuiList-root': {
        paddingBottom: 0,
      },
      '& .MuiListItem-root': {
        marginBottom: 0,
        paddingBottom: 0,
      },
      '& .MuiMenuItem-root': {
        marginBottom: '8px',
      },
      '& .MuiListItem-root, & .MuiMenuItem-root': {
        paddingLeft: 0,
        fontWeight: 600,
        fontSize: '1.6rem',
        color: '#FFFFFF',
        '& > a': {
          color: '#FFFFFF',
        },
      },
    },
    expandIcon: {
      '&.profileExpanded': {},
    },
  };
});
