import { Typography, Container } from '@mui/material';
import React from 'react';
import SEO from 'shared/components/Seo';

import { useStyles } from './styles';

export default function PrivacyPolicy() {
  const { classes } = useStyles();

  return (
    <>
      <SEO title="Privacy Policy" />
      <Container>
        <div className={classes.wrapper}>
          <Typography variant="h1" className={classes.name}>
            Privacy Policy
          </Typography>
          <Typography variant="body2" className={classes.date}>
            Effective date: 01/10/2021
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            Blocksport (hereinafter – “Blocksport”, “we”, “our”, “us”) is a service which providing
            its users possibility to create, purchase, sell or exchange any digital and virtual
            assets, including without limitation non-fungible tokens (NFTs) and Fan Tokens via auction
            or within any procedure defined by Blocksport. Service is provided according to the{' '}
            <a href="/terms" rel="noreferrer">
              {' '}
              Terms & Conditions
            </a>{' '}
            and is implemented and provided on different platforms like mobile apps, our website
            located at{' '}
            <a href={`${window.location.host}/`} target="_blank" rel="noreferrer">
              {`${window.location.host}/`}
            </a>
            , our related social media pages and others. For simplicity we refer to all of these as
            our “services” in this Privacy Notice.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            By using the services, you also agree to our{' '}
            <a href="/terms" rel="noreferrer">
              {' '}
              Terms & Conditions
            </a>
            . Please make sure that you read and agree with our{' '}
            <a href="/terms" rel="noreferrer">
              {' '}
              Terms & Conditions
            </a>{' '}
            if you want to use Blocksport.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            To provide you with the services and contract with you we need your personal data.
            Blocksport is the data controller of the personal data collected, and is responsible for
            the processing of your personal data. We respect your privacy and are committed to
            protecting your personal data, and therefore we collect it according to the applicable
            law.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            This Privacy Notice sets out how we collect, use and protect your personal data that you
            provide to us or that is otherwise generated or obtained by us, in relation to your use of
            any our services specified by the{' '}
            <a href="/terms" rel="noreferrer">
              {' '}
              Terms & Conditions
            </a>
            . In this document we describe our privacy practices in plain and simple language avoiding
            any unnecessary legalese.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Who is Blocksport
          </Typography>

          <Typography variant="h4" className={classes.text}>
            The company that is responsible for your personal data under this Privacy Notice (the
            “data controller”) is: <br />
            <br />
            Blocksport AG <br />
            Dammstrasse 16, 6300, Zug, Switzerland
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Applicability
          </Typography>

          <Typography variant="h4" className={classes.text}>
            This Privacy Notice applies to personal information collected through our websites, apps
            and other platforms and services operated by Blocksport.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Personal data we collect
          </Typography>

          <Typography variant="h4" className={classes.text}>
            <strong>Information you give us</strong>
          </Typography>

          <Typography variant="h4" className={classes.text}>
            When you use our mobile apps, visit our website and related social media pages or use any
            other our services through these or other platforms, subscribe to our news and updates, we
            ask you to provide us with, in particular:
          </Typography>
          <ul className={classes.ul}>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                your name and username
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                age
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                email address
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                mobile phone number
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                date of birth
              </Typography>
            </li>
          </ul>

          <Typography variant="h4" className={classes.text}>
            While using Blocksport service we may also collect device and usage information, such as
            mobile device identifiers, browser and device characteristics, operating system, language
            preferences, device name, your geographic position, your Internet provider, mobile carrier
            and your browsing behavior and further actions on the Blocksport.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            We may also ask you to provide us some additional information about you if such
            information is necessary for us to fulfill your request, including when you wish to access
            your personal information stored with us or to exercise any of your other rights in
            connection with your personal information, we may request from you certain information in
            order to verify your identity. The personal information we collect from you depends on the
            context of your interactions with us and may include your full name, passport or ID data,
            mailing address, contact preferences and other similar information. Please note that, if
            you do not want us to process particular information about you (including sensitive
            information such as data relating to your health, racial or ethnic origin, political
            opinion, religious or philosophical beliefs, sex life), you should take care not to
            provide such information to us.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            How we use your personal data
          </Typography>

          <Typography variant="h4" className={classes.text}>
            We try to ensure that you receive and enjoy our services in the best possible way. That’s
            why the primary reason we use your information is to render and improve our services.
            Additionally, we use your information to help keep you safe and to provide you with
            advertising that may be of interest to you.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            Therefore, we use your personal data and other information provided by or obtained from
            you for the following purposes:
          </Typography>
          <ul className={classes.ul}>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                providing you with our services
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                sending you letters with our news, updates, interesting offers of advertising nature
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                providing you with discounts on our services
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                communicating with you
              </Typography>
            </li>
          </ul>

          <Typography variant="h2" className={classes.caption}>
            Legal basis for the processing
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            We collect and process your personal data for or on the basis of one or more following
            legal bases:
          </Typography>

          <ol type="a" className={classes.ulLeters}>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                Conclusion and performance of a contract. By using our services, you have contracted
                with us through the{' '}
                <a href="/terms" rel="noreferrer">
                  {' '}
                  Terms & Conditions
                </a>
                , and therefore we have to process certain personal data to execute the contract and
                comply with it. Please note, that the provision of your data is a necessary
                requirement to enter into and execute the{' '}
                <a href="/terms" rel="noreferrer">
                  {' '}
                  Terms & Conditions
                </a>{' '}
                and therefore - enjoy our services. If you fail to provide us with such the data, you
                won’t be able to use our services.{' '}
              </Typography>
            </li>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                Legitimate Interests. We may process your personal data for our legitimate interests,
                in particular protecting our, our users’ rights, safety and property; detecting and
                resolving any security concerns or fraud; complying with any applicable law, act,
                regulation, other regulatory legal act, any inquiry or remedy; enforcing the{' '}
                <a href="/terms" rel="noreferrer">
                  {' '}
                  Terms & Conditions
                </a>
                .{' '}
              </Typography>
            </li>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                Your explicit consent to the processing. You may choose to give your explicit consent
                to processing of your personal data by us or a third party for the abovesaid purposes
                of the processing.{' '}
              </Typography>
            </li>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                Compliance with other obligations set out by applicable law, governmental or other
                competent bodies’ requests.{' '}
              </Typography>
            </li>
          </ol>

          <Typography variant="h2" className={classes.caption}>
            How we store your data
          </Typography>

          <Typography variant="h4" className={classes.text}>
            The term of storing and processing your personal data shall depend on the purpose of its
            processing, as well as on the applicable technology (this applies to cookies). For
            instance, Blocksport will keep and process your personal data related to the provision of
            our services to you, including based on the{' '}
            <a href="/terms" rel="noreferrer">
              {' '}
              Terms & Conditions
            </a>
            , within the period of service provision and/or contract validity.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            We will store and process your personal data for marketing (advertising) purposes for
            providing you with discounts on our services and products as well as for communication
            purposes until user`s account removal in order to ensure user`s security on and off our
            service.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            The servers with your data are hosted by our service provider Blocksport, Inc. This
            service provider is located in the Zug, Switzerland and its affiliated companies –
            throughout the world. From there, your data may be stored in or accessed from multiple
            countries, including the United States. Whenever your personal data is transferred to
            other jurisdictions, we and our service provider will ensure that the data is transferred
            in accordance with this Privacy Notice and as permitted by applicable data protection
            laws.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            Please note, that we don’t define the terms of keeping and processing your personal data
            by third parties, as it completely depends on their approved rules.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Who we share your data with
          </Typography>

          <Typography variant="h4" className={classes.text}>
            To provide you with our services we share your data with some categories of third parties.
            Such categories of third parties include:
          </Typography>

          <ol type="a" className={classes.ulLeters}>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                Our service providers. We engage third parties to assist us in operating and enhancing
                our services. Such third parties help us with hosting and security operations as to
                your data, delivery our services to you as well as marketing and advertising.{' '}
              </Typography>
            </li>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                Competent authorities, regulators, law enforcement bodies. Where required or permitted
                by applicable law we may share your data with competent authorities, regulators and
                law enforcement bodies.{' '}
              </Typography>
            </li>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                Other third parties. We can share your data to protect our rights, legitimate
                interests, property, or safety, and/or that of our users and others.{' '}
              </Typography>
            </li>
          </ol>

          <Typography variant="h2" className={classes.caption}>
            How we secure your data
          </Typography>

          <Typography variant="h4" className={classes.text}>
            To secure and protect your personal data, we use reasonable and appropriate technical,
            physical and organizational safeguards. Such safeguards are designed to facilitate the
            security of our systems and protect the confidentiality, integrity, availability and
            resilience of your personal data. We also engage our service providers to secure and
            protect your personal data. The above safeguards include:
          </Typography>

          <ul className={classes.ul}>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                the encryption of your personal data where appropriate
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                adoption of measures to ensure your personal data is backed up and remains available
                in the сase of a physical or technical incident
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                regularly testing, assessment, and evaluation of the effectiveness of our safeguards
              </Typography>
            </li>
          </ul>

          <Typography variant="h2" className={classes.caption}>
            International transfers
          </Typography>

          <Typography variant="h4" className={classes.text}>
            Your personal information may be transferred to, stored and processed by us, and by those
            third parties which we may share your personal information with, in the countries of the
            European Economic Area (EEA) and in other countries.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            The countries to which we transfer your data may not have the same data protection laws as
            your jurisdiction. We take reasonable cyber security measures and/or put in place the
            Standard Contractual Clauses (e.g. Model Clauses, Data Processing Agreement/Addendum) to
            ensure your data is adequately protected.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Your content
          </Typography>

          <Typography variant="h4" className={classes.text}>
            Your explicit consent is one of the legal bases for processing your personal data. You
            give your consent in different forms, including by entering into the{' '}
            <a href="/terms" rel="noreferrer">
              {' '}
              Terms & Conditions
            </a>
            .
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            We may ask you for your consent to process your data. For example, we may ask you to share
            your information with third parties. In any such event, we will make it clear why we want
            to process your personal data, including by sharing such information with third parties.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            If you have given your consent to process your personal data, you’re entitled to withdraw
            it at any time. Please note that, if the withdrawn consent was given to enter into the{' '}
            <a href="/terms" rel="noreferrer">
              {' '}
              Terms & Conditions
            </a>
            , you will not be able to use our services.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            We may ask you for your consent to track your activity across other companies` apps and
            websites including, but not limited to for advertisement purposes such as:
          </Typography>

          <ul className={classes.ul}>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                displaying targeted advertisements based on your data collected from third parties.
              </Typography>
            </li>
          </ul>

          <Typography variant="h4" className={classes.text}>
            If you haven`t given your consent to track your activity, we will not have any opportunity
            to show you personalized ads.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Your rights regarding your personal data
          </Typography>

          <Typography variant="h4" className={classes.text}>
            We collect and process your personal data exclusively pursuant to the applicable law. You
            may exercise your rights provided for by applicable law at any time. You may have, in
            particular, the following rights.
          </Typography>

          <Typography variant="h3" className={classes.text}>
            <strong>Your rights under General Data Protection Regulation</strong>
          </Typography>

          <Typography variant="h4" className={classes.text}>
            If you are on the territory of the European Union or on the territory of such countries
            as: Iceland, Liechtenstein, Norway, Switzerland, then you have the right to:
          </Typography>

          <ul className={classes.ul}>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                withdraw your consent where you have given it
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                request an access to and rectification or erasure (right to be forgotten) of your
                personal data
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                in certain circumstances restrict the processing of your personal data or object to
                processing it
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                request data portability
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                receive information on the retention term of your personal data or the criteria by
                which such a term can be determined
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                receive information about the source from which your personal data originates, and if
                applicable, whether it came from publicly accessible sources (if personal data was not
                received from you)
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                lodge a complaint with a competent supervisory authority on the territory you’re
                located in
              </Typography>
            </li>
            <li className={classes.item}>
              <Typography variant="h4" className={classes.text}>
                not be subject to a decision based solely on automated processing, including
                profiling, unless it’s necessary for entering into or performance of the{' '}
                <a href="/terms" rel="noreferrer">
                  {' '}
                  Terms & Conditions
                </a>{' '}
                to which you’re subject
              </Typography>
            </li>
          </ul>

          <Typography variant="h4" className={classes.textBig}>
            To get more details about your rights, please make aware of the official text of the
            <a
              href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&from=EN"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              General Data Protection Regulation
            </a>
            .
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            What are cookies
          </Typography>

          <Typography variant="h4" className={classes.text}>
            Cookies is a small piece of data that is sent from our website (or from another domain)
            and is saved by a web browser on your computer, mobile phone, or another portable device.
            Cookies can be placed, read, and/or written to by our services, or other websites or
            services that recognize a particular cookie, which allows the website to «remember» or
            «recognize» a particular browser or device and, in some cases, store information about
            that browser or device.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Purposes of cookies
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            Cookies help you to make better use of our services, and also help us to better understand
            you, in particular learn about your preferences and offer you personalized services and
            goods. Most of web browsers automatically accept cookies, however you can change these
            settings. A more detailed description of how to do this can be found on the Internet by
            going to the website of the developer of your browser. Unfortunately, if you disable
            cookies, the functionality of many websites will not be accessible.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            How long cookies are stores on my system
          </Typography>

          <Typography variant="h4" className={classes.text}>
            We use cookies for a number of different purposes. We use both «transient» and
            «persistent» cookies. We use a transient cookie to identify you during a particular visit
            to Blocksport. Transient cookies expire after a short time, or when you close your web
            browser after your visit. We use a persistent cookies to help your device remember
            information, settings, preferences, or sign-on credentials that you have previously saved.
            Basically, this type of cookies is saved on your device, so when you close it and start it
            up again, the cookie is still there.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            We don’t use web storage, web beacons, flash cookies or other technologies that track your
            browsing history across multiple apps or websites.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            What cookies we use
          </Typography>

          <Typography variant="h4" className={classes.text}>
            Like many other websites, we use the following types of cookies:
          </Typography>

          <ol type="a" className={classes.ulLeters}>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                <strong>Preferences. </strong> We use cookies to remember your settings and
                preferences. For example, it may be the language you prefer. They can help you fill
                out forms on Blocksport more easily.
              </Typography>
            </li>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                <strong>Authentication. </strong> We use cookies to remind us who you are and to find
                your account info in our database when you access our services, so that you do not
                need to log in at every visit. It helps us to provide you with services tailored to
                your specific needs and interests.
              </Typography>
            </li>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                <strong>Analytics and performance. </strong> We use cookies to optimize performance by
                collecting data on how users interact with our services. We use these details to
                improve our website functions and understand how you interact with it.
              </Typography>
            </li>
            <li className={classes.itemLeters}>
              <Typography variant="h4" className={classes.text}>
                <strong>Advertising. </strong> We may use cookies to optimize our advertising. We use
                these cookies to show you relevant advertising both on and off our website.
              </Typography>
            </li>
          </ol>

          <Typography variant="h2" className={classes.caption}>
            Third-party cookies
          </Typography>

          <Typography variant="h4" className={classes.text}>
            We provide an opportunity for other organizations through our website to post cookies on
            your device, so that third parties can receive information about your preferences. This is
            called “third-party cookies”. In simple terms third-party cookies are those created and
            placed by parties (third parties) other than the owner of the website you’re visiting.
            Such personal data is processed in accordance with the processing rules for such third
            parties.
          </Typography>
          <Typography variant="h4" className={classes.textBig}>
            We use Google Analytics, a web analytics service provided by Google, Inc. (“Google”), on
            our services. This service uses cookies to make it easier for us to analyze how you
            interact with and use our website and services. Google Analytics may collect data such as
            your IP address, time of visit, whether you are a return visitor, and any referring
            website or app. The generated information is usually transmitted and stored by Google Inc.
            on servers that are located in the United States of America and are subject to Google’s
            privacy policies. Google Inc. processes such information in an automated mode and in our
            interests, we, in turn, based on the results of the analysis of reports, better understand
            your interests and needs both in our services/products and in the usability of our
            website.
          </Typography>
          <Typography variant="h4" className={classes.textBig}>
            To learn more about Google Analytics and to learn how to opt out of tracking of analytics
            by Google{' '}
            <a
              href="https://policies.google.com/technologies/partner-sites"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              click here
            </a>
            .
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            You can find a full list of cookies, the terms of its storage, by whom it is installed and
            for what purpose,{' '}
            <a href="/cookies-policy" rel="noreferrer">
              {' '}
              here
            </a>
            .
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Managing cookies
          </Typography>

          <Typography variant="h4" className={classes.text}>
            You can manage the cookies stored on your device, or turn them off completely, through
            your browser settings. Please note, that such actions may affect your browsing experience
            or even prevent you from using our services. All contemporary browsers will let you change
            your cookie settings to give you full control over whether cookies can be set or not. To
            learn more, please visit the help chapter of the browser you use.
          </Typography>

          <Typography variant="h4" className={classes.textBig}>
            To learn more about our cookie practices, please make aware of our{' '}
            <a href="/cookies-policy" rel="noreferrer">
              {' '}
              Cookie Policy
            </a>
            .
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Children’s personal data
          </Typography>

          <Typography variant="h4" className={classes.text}>
            Our services are designed only for users who are 17 years or older. Anyone under 17 is not
            permitted to use the services and we do not purposely collect personal data from children
            under 17. By using the services, you represent that you are 17 years or older.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Payment information
          </Typography>

          <Typography variant="h4" className={classes.text}>
            Any payment made by users to access our services and products are processed by our payment
            providers (third parties). When you make a payment, for instance through Moon Pay /
            MetaMask service, which is processed by a payment provider, you’ll provide that third
            party with your crypto wallet address and other financial information. Such information
            will be stored and processed by that third parties pursuant to their privacy policy and
            terms of use. Please note that we don’t have access to your financial or other information
            provided by you to a payment provider. If we receive in some way such the information,
            we’ll definitely communicate it to you.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Links to other websites
          </Typography>

          <Typography variant="h4" className={classes.text}>
            On our website we can post links to other websites, in particular to our pages on social
            networks. In this case, we do not establish the rules for processing your personal data,
            which can be collected on such websites or social networks, and we cannot be responsible
            for such processing. For more detailed information you can make aware of the privacy rules
            of the respective websites and social networks.
          </Typography>

          <Typography variant="h2" className={classes.caption}>
            Contact details
          </Typography>

          <Typography variant="h4" className={classes.text}>
            If you have any questions regarding the processing of your personal data or you wish to
            delete your data from the mailing list or other databases, or you want to communicate
            personal data breach to us, you can contact us at any time on any of the contacts listed
            below:
          </Typography>
          <Typography variant="h4" className={classes.textBig}>
            Blocksport AG <br />
            Email:{' '}
            <a href="mailto:support@blocksport.io" rel="noreferrer">
              {' '}
              support@blocksport.io
            </a>{' '}
            <br />
            Dammstrasse 16, 6300 Zug, Switzerland
          </Typography>
        </div>
      </Container>
    </>
  );
}
