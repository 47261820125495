import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import * as routePaths from 'constants/routes';
import { ROLE_FAN, ROLE_CELEBRITY } from 'constants/roles';
import LayoutRoute from '../hoc/LayoutRoute';

const Home = lazy(() => import('scenes/Home'));
const MarketplaceList = lazy(() => import('scenes/Collectibles/scenes/List'));
const CelebritiesList = lazy(() => import('scenes/Celebrities/scenes/List'));
const FanTokensList = lazy(() => import('scenes/FanTokens/scenes/List'));
const PlayEarn = lazy(() => import('scenes/PlayEarn'));
const CollectiblesDetails = lazy(() => import('scenes/Collectibles/scenes/Detail'));
const ProfileDetails = lazy(() => import('scenes/Profile/scenes/Details'));
const ProfileEdit = lazy(() => import('scenes/Profile/scenes/Edit'));
const ProfileView = lazy(() => import('scenes/Profile/scenes/View'));
const CreateToken = lazy(() => import('scenes/CreateToken'));
const BidsList = lazy(() => import('scenes/Profile/scenes/Bids/scenes/History'));
const PlaceBid = lazy(() => import('scenes/Collectibles/scenes/PlaceBid'));
const Invite = lazy(() => import('scenes/Invite'));
const About = lazy(() => import('scenes/About'));
const Privacy = lazy(() => import('scenes/PrivacyPolicy'));
const Help = lazy(() => import('scenes/Help'));
const TAC = lazy(() => import('scenes/TermsAndConditions'));
const CookiesPolicy = lazy(() => import('scenes/CookiesPolicy'));
const FanTokenPromo = lazy(() => import('scenes/FanTokens/scenes/Promo'));
const DepositNFT = lazy(() => import('scenes/DepositNFT'));
const FantokenStake = lazy(() => import('scenes/PlayEarn/scenes/FantokenStake/components/StakeForm/StakeForm'));
const Page404 = lazy(() => import('scenes/404'));
const ConnectPage = lazy(() => import('scenes/Connect'));
const Purchase = lazy(() => import('scenes/Collectibles/scenes/Purchase'));
const OwnYourPitch = lazy(() => import('scenes/PlayEarn/scenes/OwnYourPitch/scenes/Landing'));
const OwnYourPitchNBA = lazy(() => import('scenes/PlayEarn/scenes/OwnYourPitch/scenes/Landing2'));
const OwnYourPitchPurchase = lazy(() => import('scenes/PlayEarn/scenes/OwnYourPitch/scenes/Purchase'));
const BSPTBridge = lazy(() => import('scenes/BSPTBridge'));
const DaoProposalList = lazy(() => import('scenes/Dao/scenes/ProposalList/ProposalList'));
const DaoProposalDetails = lazy(() => import('scenes/Dao/scenes/ProposalDetails'));
const DaoProposalForm = lazy(() => import('scenes/Dao/scenes/ProposalForm/ProposalForm'));
const WhitePayOrderFailure = lazy(() => import('../scenes/WhitePayOrderStatus/WhitePayOrderFailure'));
const WhitePayOrderSuccess = lazy(() => import('../scenes/WhitePayOrderStatus/WhitePayOrderSuccess'));

const routes = () => {
  return (
    <Switch>
      <LayoutRoute path={routePaths.ROUTE_CREATE_TOKEN} component={CreateToken} exact roles={[ROLE_CELEBRITY]} protection />
      <LayoutRoute path={routePaths.ROUTE_EDIT_TOKEN} component={CreateToken} exact roles={[ROLE_FAN, ROLE_CELEBRITY]} protection />
      <LayoutRoute path={routePaths.ROUTE_BIDS} component={BidsList} exact roles={[ROLE_FAN]} protection />
      <LayoutRoute path={routePaths.ROUTE_PLACE_BID} component={PlaceBid} exact roles={[ROLE_FAN, ROLE_CELEBRITY]} protection />
      <LayoutRoute path={routePaths.ROUTE_PROFILE_EDIT} component={ProfileEdit} exact fullwidth roles={[ROLE_FAN, ROLE_CELEBRITY]} protection />
      <LayoutRoute path={routePaths.ROUTE_HOME} component={Home} exact fullwidth />
      <LayoutRoute path={routePaths.ROUTE_MARKETPLACE} component={MarketplaceList} />
      <LayoutRoute path={routePaths.ROUTE_CELEBRITIES} component={CelebritiesList} exact />
      <LayoutRoute path={routePaths.ROUTE_FAN_TOKEN} component={FanTokensList} exact />
      <LayoutRoute path={routePaths.ROUTE_FAN_TOKEN_TEAM} component={FanTokenPromo} exact fullwidth />
      <LayoutRoute path={routePaths.ROUTE_PROFILE_VIEW} component={ProfileView} exact fullwidth />
      <LayoutRoute path={routePaths.ROUTE_PROFILE_DETAILS} component={ProfileDetails} exact fullwidth />
      <LayoutRoute path={routePaths.ROUTE_INVITE} component={Invite} exact />
      <LayoutRoute path={routePaths.ROUTE_ABOUT} component={About} exact />
      <LayoutRoute path={routePaths.ROUTE_PRIVACY} component={Privacy} exact />
      <LayoutRoute path={routePaths.ROUTE_HELP} component={Help} exact fullwidth />
      <LayoutRoute path={routePaths.ROUTE_TERM} component={TAC} exact />
      <LayoutRoute path={routePaths.ROUTE_COOKIES_POLICY} component={CookiesPolicy} exact />
      <LayoutRoute path={routePaths.DEPOSIT_NFT} component={DepositNFT} roles={[ROLE_FAN]} exact protection allowedForAnonymouse />

      <LayoutRoute path={routePaths.ROUTE_COLLECTIBLES_DETAILS} component={CollectiblesDetails} exact fullwidth />
      <LayoutRoute path={routePaths.ROUTE_PLAY_EARN} component={PlayEarn} roles={[ROLE_FAN]} exact protection allowedForAnonymouse />
      <LayoutRoute path={routePaths.ROUTE_STAKE_FANTOKENS} component={FantokenStake} roles={[ROLE_FAN]} exact protection />

      <LayoutRoute path={routePaths.ROUTE_404} component={Page404} exact />
      <LayoutRoute path={routePaths.ROUTE_CONNECT} component={ConnectPage} />

      <LayoutRoute path={routePaths.ROUTE_INSTANT_PURCHASE} component={Purchase} exact protection roles={[ROLE_FAN]} />
      <LayoutRoute path={routePaths.ROUTE_INSTANT_PURCHASE_MULTI} component={Purchase} exact protection roles={[ROLE_FAN]} />

      <LayoutRoute path={routePaths.ROUTE_GAME_OWN_YOUR_PITCH} component={OwnYourPitch} exact protection roles={[ROLE_FAN, ROLE_CELEBRITY]} allowedForAnonymouse />
      <LayoutRoute
        path={routePaths.ROUTE_GAME_OWN_YOUR_PITCH_NBA}
        component={OwnYourPitchNBA}
        exact
        protection
        roles={[ROLE_FAN, ROLE_CELEBRITY]}
        allowedForAnonymouse
        fullwidth
      />
      <LayoutRoute path={routePaths.ROUTE_GAME_OWN_YOUR_PITCH_PURCHASE} component={OwnYourPitchPurchase} exact protection roles={[ROLE_FAN, ROLE_CELEBRITY]} />

      <LayoutRoute path={routePaths.ROUTE_BSPT_BRIDGE} component={BSPTBridge} exact />

      <LayoutRoute path={routePaths.ROUTE_DAO} component={DaoProposalList} exact />
      <LayoutRoute path={routePaths.ROUTE_DAO_NEW} component={DaoProposalForm} exact protection roles={[ROLE_FAN, ROLE_CELEBRITY]} />
      <LayoutRoute path={routePaths.ROUTE_DAO_PROPOSAL} component={DaoProposalDetails} fullwidth />

      <LayoutRoute path={routePaths.WHITE_PAY_ORDER_FAILURE} component={WhitePayOrderFailure} fullwidth />
      <LayoutRoute path={routePaths.WHITE_PAY_ORDER_SUCCESS} component={WhitePayOrderSuccess} fullwidth />

      {/* Fallback */}
      {/* <Redirect to={routePaths.ROUTE_404} /> */}
      <LayoutRoute path="*" component={Page404} />
    </Switch>
  );
};

export default routes;
