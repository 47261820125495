import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'entities/Account';
import { CelebrityState } from './types';

const initialState: CelebrityState = {
  loading: false,
  error: false,
  list: [],
  total: 0,
};

const { actions, reducer } = createSlice({
  name: 'celebrity',
  initialState,
  reducers: {
    getCelebritiesListRequest: (
      state,
      { payload }: PayloadAction<{ query: CelebrityState['query']; append?: boolean }>,
    ) => {
      if (!payload.append) {
        state.list = [];
      }
      state.query = payload.query;
      state.loading = true;
      state.error = false;
    },
    getCelebritiesListSuccess: (
      state,
      { payload }: PayloadAction<{ data: User[]; total: number; append?: boolean }>,
    ) => {
      state.list = payload.append ? state.list.concat(payload.data) : payload.data;
      state.total = payload.total;
      state.loading = false;
    },
    getCelebritiesListFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as CelebrityActions };

export default reducer;
