import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as routePaths from 'constants/routes';
import { List, ListItem } from '@mui/material';
import { ROUTE_BSPT_BRIDGE, ROUTE_DAO, ROUTE_DAO_NEW, ROUTE_GAME_OWN_YOUR_PITCH_NBA } from 'constants/routes';

interface MenuInterface {
	setOpen: any | null;
}

const Menu = (props: MenuInterface) => {
	const { setOpen } = props;
	// Selectors
	const {
		account: { role },
	} = useSelector((state) => state);

	// Constants
	const isCelebrity = role === 'celebrity';

	const navLinks = [
		{
			title: 'Games',
			path: routePaths.ROUTE_GAME_OWN_YOUR_PITCH_NBA,
			disabled: false,
			hidden: false,
		},
		{ title: 'Marketplace', path: routePaths.ROUTE_MARKETPLACE, disabled: false },
		{
			title: 'Fan Token',
			path: routePaths.ROUTE_FAN_TOKEN,
			disabled: false,
			hidden: false,
		},
		{
			title: 'Stake',
			path: routePaths.ROUTE_PLAY_EARN,
			tooltip: '',
			disabled: isCelebrity,
			hidden: isCelebrity,
		},
	];

	const onChoice = () => {
		if (setOpen) {
			setOpen(false);
		}
	};

	return (
		<List>
			<>
				{navLinks.map(({ title, path, disabled, hidden }, idx: number) => {
					return (
						hidden || (
							<ListItem key={idx} disabled={disabled}>
								<NavLink to={path} onClick={onChoice}>
									{title}
								</NavLink>
							</ListItem>
						)
					);
				})}
			</>
		</List>
	);
};

export default Menu;
