import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Collapse,
  List,
  ListItem,
  AppBar,
  Box,
  IconButton,
  Button,
  Container,
  Toolbar,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonBase,
  Typography,
  Divider,
} from '@mui/material';
import { NavLink, useHistory } from 'react-router-dom';
import { Logo } from 'icons';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import * as routePaths from 'constants/routes';
import Avatar from 'shared/components/Avatar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { MyWalletActions } from '../../../../store/reducers/mywallet';
import MobileSearch from '../MobileSearch';
import ProfileMenu from '../ProfileMenu';
import Menu from '../Menu';
// import { HeaderTheme } from '../styles';
import { useMobileStyles } from './styles';

interface MobileHeaderProps {
  bannerTooltip: any;
  setBannerTooltip: any;
  walletLoading: boolean;
  walletAddress: string;
  handleShowMetaMask: () => void;
  errors: any;
  handleConnect: () => void;
  handleDisconnect: () => void;
  isProfile?: boolean;
  headerStyle: React.CSSProperties;
}

const MobileHeader: FC<MobileHeaderProps> = ({
  bannerTooltip,
  setBannerTooltip,
  handleShowMetaMask,
  walletLoading,
  walletAddress,
  errors,
  handleConnect,
  handleDisconnect,
  isProfile,
  headerStyle,
}) => {
  const { classes: mobileClasses, cx } = useMobileStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [profileExpanded, setProfileExpanded] = useState<boolean>(false);

  const {
    account: { account },
    wallet: { wallet },
    network: { networkCurrency },
  } = useSelector((state) => state);

  const avatar = account?.avatar ?? null;

  useEffect(() => {
    closeAppBar();
  }, [history]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleSearch = () => {
    setOpen(false);
  };

  const handleMyWallet = () => {
    dispatch(MyWalletActions.toggleMyWallet());
  };

  const closeAppBar = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} style={headerStyle as React.CSSProperties} className={mobileClasses.root}>
        <AppBar position="sticky" className={cx(mobileClasses.appBar, { [mobileClasses.appBarOpen]: open })}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <NavLink to={routePaths.ROUTE_HOME} className={mobileClasses.logo}>
                <Logo />
              </NavLink>

              <Stack direction="row">
                <IconButton color="inherit" aria-label="open drawer" onClick={handleSearch} size="large">
                  <MobileSearch />
                </IconButton>

                <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} size="large" sx={{ marginRight: '-12px' }}>
                  {open ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </Stack>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>

      <Dialog fullScreen open={open} onClose={() => setOpen(false)} className={mobileClasses.menuOpenDialog}>
        <DialogContent>
          {account && (
            <>
              <Box display="flex" alignItems="center">
                <Avatar size="xl" src={avatar} />
                <Box ml={2} flexGrow={1} className={mobileClasses.balanceBox}>
                  <ButtonBase onClick={handleMyWallet}>
                    <Typography variant="h4">
                      {wallet.balance} {networkCurrency}
                    </Typography>
                  </ButtonBase>
                </Box>
              </Box>
              <Box className={mobileClasses.profileLinkBox}>
                <List>
                  <ListItem>
                    <NavLink to={routePaths.ROUTE_PROFILE_DETAILS} onClick={closeAppBar}>
                      View Profile
                    </NavLink>
                    <IconButton
                      aria-label="toggle profile"
                      className={cx(mobileClasses.expandIcon, { profileExpanded })}
                      onClick={() => setProfileExpanded(!profileExpanded)}
                    >
                      {profileExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </ListItem>
                </List>
                <Collapse in={profileExpanded}>
                  <List>
                    <ProfileMenu handleClick={closeAppBar} />
                  </List>
                  <Divider />
                </Collapse>
              </Box>
            </>
          )}
          <Box className={mobileClasses.menuBox}>
            <Menu setOpen={setOpen} />
          </Box>
        </DialogContent>
        <DialogActions>
          {!walletAddress || errors ? (
            <Button variant="contained" onClick={handleConnect}>
              Connect wallet
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                handleDisconnect();
              }}
            >
              Disconnect Wallet
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MobileHeader;
