import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Wallet } from 'entities/Account';
import { WalletState } from './types';

const initialState: WalletState = {
  wallet: {
    address: '',
    balance: 0,
    chainId: '',
  },
  loading: false,
  error: false,
  connectModalIsOpen: false,
};

const { actions, reducer } = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletIdle: (state) => {
      state.loading = false;
      state.error = false;
    },
    setWalletRequest: (state, { payload }: PayloadAction<{ address: string; callBack?(): void }>) => {
      state.loading = true;
      state.error = false;
    },
    setWalletSuccess: (state, { payload }: PayloadAction<Wallet>) => {
      state.wallet = payload;
      state.loading = false;
      state.error = false;
    },
    setWalletFailure: (state) => {
      state.loading = false;
      state.error = true;
    },

    connectWallet: (state, { payload }: PayloadAction<{ callback?: () => void }>) => {
      state.loading = true;
    },

    disconnectWallet: (state, { payload }: PayloadAction<{ reload?: boolean }>) => {
      state.wallet.address = '';
      state.wallet.balance = 0;
      state.loading = false;
      state.error = false;
    },

    openModal: (state) => {
      state.connectModalIsOpen = true;
    },
    closeModal: (state) => {
      state.connectModalIsOpen = false;
    },

    getWalletRequest: (state) => {
      state.loading = true;
      state.error = false;
    },
    
    updateWalletRequest: (state) => {
      state.loading = true;
      state.error = false;
    },
  },
});

export { actions as WalletActions };

export default reducer;
