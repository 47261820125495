import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';
import {DaoState, VoterUser} from './types';

const initialState: DaoState = {
	loading: false,
	error: false,
	proposals: [],
	details: null,
	transactionSuccess: false,
	daoParams: {
		votingPeriod: '0',
		proposalThreshold: '0',
		quorumFraction: '0',
	},
	votingInfo: {
		hasVoted: false,
		nonce: 0,
	},
};

const { actions, reducer } = createSlice({
	name: 'dao',
	initialState,
	reducers: {
		getProposalsRequest: (state) => {
			state.loading = true;
			state.error = false;
		},
		getProposalsSuccess: (state, { payload }: PayloadAction<{ data: any[] }>) => {
			state.proposals = payload.data;
			state.loading = false;
		},
		getProposalsFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getProposalRequest: (state, { payload }: PayloadAction<{ id: number | string }>) => {
			state.loading = true;
			state.error = false;
		},
		getProposalSuccess: (state, { payload }: PayloadAction<{ data: any }>) => {
			state.details = payload.data;
			state.loading = false;
		},
		getProposalFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
		createProposalRequest: (state, { payload }: PayloadAction<{ dataHash: string }>) => {
			state.loading = true;
			state.error = false;
			state.transactionSuccess = false;
		},
		createProposalSuccess: (state, { payload }: PayloadAction<{ data: any }>) => {
			state.loading = false;
			state.transactionSuccess = payload.data;
		},
		createProposalFailure: (state) => {
			state.loading = false;
			state.error = true;
			state.transactionSuccess = false;
		},
		resetTransactionSuccess: (state) => {
			state.transactionSuccess = false;
			state.loading = false;
			state.error = false;
		},
		getDaoParamsRequest: (state) => {
			state.loading = true;
			state.error = false;
		},
		getDaoParamsSuccess: (state, { payload }: PayloadAction<{ data: any }>) => {
			state.loading = false;
			state.daoParams = payload.data;
		},
		getDaoParamsFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
		voteProposalRequest: (state, { payload }: PayloadAction<{ voter: string, proposalId: number, nonce: number }>) => {
			state.loading = true;
			state.error = false;
		},
		voteProposalSuccess: (state, { payload }: PayloadAction<{data: any}>) => {
			state.loading = false;
			if (state.details) {
				state.details.voters.push(payload.data);
				state.details.totalWeight += +payload.data.weight;
				state.votingInfo.hasVoted = true;
				state.votingInfo.nonce += payload.data.nonce;
			}
		},
		voteProposalFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
		getVotingInfoRequest: (state, { payload }: PayloadAction<{ voter: string, proposalId: number }>) => {
			state.loading = true;
			state.error = false;
		},
		getVotingInfoSuccess: (state, { payload }: PayloadAction<{ data: any }>) => {
			state.loading = false;
			state.votingInfo = payload.data;
		},
		getVotingInfoFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
	},
});

export { actions as DaoActions };

export default reducer;
