import axios from 'axios';
import { History } from 'history';
import { ROUTE_MARKETPLACE } from 'constants/routes';
import { WalletActions } from '../store/reducers/wallet';
import { NotificationActions } from '../store/reducers/notification';

const httpService = {
  // we pass the redux store and history in order to dispatch the logout actions
  // and push the user to login

  setupInterceptors: (store: any, history: History) => {
    axios.interceptors.response.use(
      (response) => {
        // simply return the response if there is no error

        return response;
      },
      (error) => {
        if (!error.response) {
          return Promise.reject(error);
        }

        // in this case we only care about unauthorized errors
        if (error.response.status === 401) {
          // we dispatch our logout action (more than likely changes a boolean
          // somewhere in your store ex. isAuthenticated: false)
          // send the user to the login page since the user/token is not valid
          store.dispatch(WalletActions.disconnectWallet({}));
          store.dispatch(
            NotificationActions.pushNotification({
              message: 'Your session has expired, please connect your wallet.',
              options: {
                variant: 'error',
              },
            }),
          );

          history.push(ROUTE_MARKETPLACE);
        }

        return Promise.reject(error);
      },
    );
  },
};

export default httpService;
