import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMetamaskWebsiteUrl } from 'helpers/links';
import { WalletActions } from 'store/reducers/wallet';
import MetaMaskModal from 'shared/dialogs/MetaMask';

const ConnectWallet = () => {
	const dispatch = useDispatch();

	// Selectors
	const {
		wallet: { connectModalIsOpen, error: walletError },
		account: {
			errors: { account: accountError },
		},
		metamask: { isMetaMask, loading: metamaskLoading },
	} = useSelector((state) => state);

	const handleCloseMetaMask = () => {
		dispatch(WalletActions.closeModal());
	};

	const handleMetaMaskButton = () => {
		if (!isMetaMask) {
			return window.open(getMetamaskWebsiteUrl(), '_blank');
		}

		dispatch(WalletActions.connectWallet({ callback: () => handleCloseMetaMask() }));
	};

	return (
		<MetaMaskModal
			open={connectModalIsOpen}
			error={walletError || accountError}
			handleClose={handleCloseMetaMask}
			loading={metamaskLoading}
			isMetaMask={isMetaMask}
			handleButton={handleMetaMaskButton}
		/>
	);
};

export default ConnectWallet;
