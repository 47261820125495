import React, { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';
import Profile from '../Profile';
import { useStyles } from './styles';

interface GetHeaderProfileProps {
  walletLoading: boolean;
  walletAddress: string;
  handleShowMetaMask: () => void;
  errors: any;
}

const GetHeaderProfile: FC<GetHeaderProfileProps> = ({ walletLoading, walletAddress, handleShowMetaMask, errors }) => {
  const { classes } = useStyles();

  if (walletLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.progress} />
      </div>
    );
  }

  if (walletAddress && !errors) {
    return <Profile />;
  }

  return (
    <div className={classes.connectWalletContainer}>
      <Button variant="contained" color="primary" onClick={handleShowMetaMask}>
        Connect wallet
      </Button>
    </div>
  );
};

export default GetHeaderProfile;
