import React from 'react';
import { useMediaQuery } from '@mui/material';

const DisplayOn = (props: any) => {
	const { children, media } = props;

	const matches = useMediaQuery(media);

	return <>{matches && children}</>;
};

export default DisplayOn;
