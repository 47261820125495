// components/CustomAlert.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertTitle } from '@mui/lab'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';
import { AlertActions } from '../../../store/reducers/alert';
import theme from '../../../theme/materialTheme'

const autoHideDuration = 8000;

const CustomAlert = () => {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector(state => state.alert);
  const hideAlert = () => dispatch(AlertActions.hideAlert());

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={hideAlert}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={severity} variant="standard" onClose={hideAlert}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
