// Endpoints
export const UPDATE_ME = '/:role/:id';

export const GET_ME_WALLET = '/wallet/:wallet';

export const GET_ACCOUNT = 'profile/:wallet';

export const ADD_ROLE_CELEBRITY = 'profile/:id/celebrity';

export const ADD_MEDIA = '/media';

export const GET_PRESIGNED_LINK = '/media/getPresignedLink';

export const ADD_PROFILE = '/profile';

export const GET_PROFILE = '/profile/:id';

export const GET_LISTED = '/nft/listed';

export const GET_AUCTION_LISTED = '/nft/auctions';

export const GET_LATEST = '/nft/latest';

export const GET_HOT = '/nft/hot';

export const GET_PROFILE_NFT = '/profile/:id/nftList';

export const GET_INFO_NFT = '/nft/:nftId/detailed';

export const GET_INFO_ACC_NFT = '/nft/:nftId/:userId/detailed';

export const PUT_TOKEN = '/nft/:id/:tokenSource';

export const PIN_IPFS_FILE = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

export const PIN_IPFS_DATA = 'https://api.pinata.cloud/pinning/pinJSONToIPFS';

export const GET_POSTS = '/celebrity/:celebrityId/feed';

export const GET_POST = '/celebrity/:celebrityId/feed/:id';

export const GET_CELEBRITIES = '/celebrity';

export const INACTIVE_COLLECTION = 'auction/:id/inactive';

export const PUT_COLLECTION = '/auction/:id';

export const FOLLOWINGS = '/fan/:fanId/follow';

export const SUBSCRIBINGS = '/fan/:fanId/subscribe';

export const FOLLOWERS = '/celebrity/:celebrityId/follow';

export const SUBSCRIBERS = '/celebrity/:celebrityId/subscribe';

export const INVITATION = '/invitation/:invitationToken';

export const TOKEN_HISTORY = '/celebrity/:celebrityId/nft/:idToken/history';

export const LIST_COLLECTION_TOKEN = '/auction';

export const POST_SIGN = '/multibid';

export const GET_PHRASE = '/profile/:id/message';

export const WIN_MODAL_OPEN_FAN = '/fan/:fanId/nft/:nftId/opened';

export const WIN_MODAL_OPEN_CELEBRITY = '/celebrity/:celebrityId/nft/:id/opened';

export const GET_BIDS = '/profile/:profileId/bid ';

export const GET_DETAIL_OFFERS = '/auction/:auctionId/multibids';

export const SIGN_MOONPAY = '/moonpay/sign';

export const GET_PROFILE_BY_NICKNAME = '/profile/nick-name/:nickname';

export const GET_AUTH_MESSAGE = '/profile/:address/message';

export const POST_AUTH_MESSAGE = '/auth/:address';

export const POST_COLLECTION_AUCTION = '/auction/sell';

export const COLLECTION_HISTORY = '/nft/multihistory/:idToken';

export const FAN_TOKEN_GET_TOKEN_LIST = '/fan-tokens';
export const FAN_TOKEN_GET_TOKEN = '/fan-tokens/:tokenSymbol';
export const FAN_TOKEN_GET_TRANSACTION_FEE = '/fan-tokens/:tokenSymbol/transaction-fee';
export const FAN_TOKEN_GET_ACCOUNT_BALANCE = '/fan-tokens/accounts/:address/balance';
export const FAN_TOKEN_GET_ACCOUNT_TRANSACTIONS = '/fan-tokens/accounts/:address/transactions';
export const FAN_TOKEN_POST_INIT_PAYMENT = '/fan-tokens/:tokenSymbol/accounts/:address/payment';
export const FAN_TOKEN_SEND_RECIEPT = '/fan-tokens/payments/:paymentIntentId';

export const SEND_EMAIL_JOIN = '/email/admin/minting-approve';
export const SUBSCRIBE_EMAIL = '/email/subscribe';
export const VALIDATE_EMAIL_REQUEST = '/email/verifyRequest';
export const VALIDATE_EMAIL_VERIFY = '/email/verifyCode';

export const DEPOSIT_NFT = '/token/nft/addNonPlatform';
// export const GET_STAKE_INFO = '/staking/:id';

export const MY_WALLET_GET_TRANSACTION_LIST = '/transaction/:address';
export const SEARCH_GET_SEARCH_LIST = '/search/:searchQuery';

export const TWITTER_OATH_GET_TOKEN = '/twitter/oauth/request-token';
export const TWITTER_CONNECT = '/twitter/connect';
export const TWITTER_DISCONNECT = '/twitter/disconnect';

export const GET_INFO_NFT_BY_ADDRESS = '/nft/byAddress/:nftId/:nftAddress';

export const GET_STAKED_FANTOKENS = '/fantoken/:userId';

export const GET_TOKEN_BUYOUT_PRICE = '/nft/buyout/:auctionId/prices';
export const TOKEN_BUYOUT_INITPAYMENT = '/nft/buyout/:auctionId/initPayment';
export const TOKEN_BUYOUT_INITPAYMENT_WHITEPAY = '/nft/buyout/:auctionId/initWhitepayPayment';

export const GET_FT_STAKE_AFACTORS = '/staking/fanToken/apy';

export const GET_MEXC_RATE = '/statistics/mexc/rate';

export const GET_WALLET_TRANSACTIONS = '/wallet/:address/transactions';

export const GET_WALLET_BSPT_BALANCE = '/bspt-public/wallet-balance'

export const STAKE_BSPT_HISTORY = '/staking/history/:wallet';

export const GET_NFT_HISTORY = '/staking/nft/history';
export const GET_BSPT_HISTORY = '/staking/bspt/history';
export const GET_FT_HISTORY = '/staking/ft/history';

export const GET_CELEBRITY_TOKENS_MINTED = '/celebrity/:celebrityId/nft/minted';

export const GET_GAMES_LIST = '/games';
export const GET_GAME = '/games/:gameId';

export const GAME_CLIME_REWARD = '/games/claimReward/:gameId/init';
export const GAME_INITPAYMENT = '/games/buyout/:eventId/initPayment';

export const GAME_INITPAYMENT_CRYPTO = '/games/buyout/:eventId/initCryptoPayment';
export const GET_GAME_CURRENT = '/games/currenEvent';

export const GET_NFT_GAME_STATUS = '/games/:transactionHash/status';

export const GET_EXCHANGE_STATUS = 'bridge/exchange/status/:transactionHash';

export const GET_PROPOSAL = '/dao/proposal/:id';
export const GET_PROPOSALS = '/dao/proposals';
export const GET_PROPOSAL_VOTES = '/dao/proposal/:id/votes';
export const POST_PROPOSAL_VOTE = '/dao/proposal/:id/vote';

export const GET_PROPOSAL_VOTING_INFO = '/dao/proposal/:id/votingInfo';

export const GET_STATS_TOKEN_MARKET_STATE = '/statistics/tokenMarketState';
export const GET_TOP_GAINERS = '/staking/bspt/topGainers';

// KYC Verification

export const CREATE_VERIFICATION_SESSION = '/kyc';
export const GET_VERIFICATIONS_BY_USER_ID = '/kyc/:userId';
