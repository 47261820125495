import * as contracts from 'contracts/addresses';
import { getBlockchainABI } from 'contracts';
import web3 from './getWeb3';

export const isDepositActive = (deposit:any)=>{
    const isUnstaked = deposit.claim === false && Number(deposit.amount) === 0;
    return Number(deposit.aproxReward) > 0 && !isUnstaked;
}

export const isDepositLockedTimePasses = (deposit:any)=>{
    return Number(deposit.end) < Math.floor(Date.now() / 1000)
}

export const getStakeContracts = async (network:string, isOld:boolean = false)=>{
    const prefix = isOld ? 'Old' : '';
    // @ts-ignore
    const bsptStakeAddr: string = contracts[`stakeBSPTAddress${prefix}`][network];
    // @ts-ignore
    const ftStakeAddr: string = contracts[`stakeFantokenAddress${prefix}`][network];
    // @ts-ignore
    const nftStakeAddr: string = contracts.stakeNFTAddress[network];

    const bsptStakeContract = new web3.eth.Contract(getBlockchainABI(network, `stakeBSPT${prefix}`), bsptStakeAddr);
    const ftStakeContract = new web3.eth.Contract(getBlockchainABI(network, `stakeFantoken${prefix}`), ftStakeAddr);
    const nftStakeContract = new web3.eth.Contract(getBlockchainABI(network, 'stakeNFT'), nftStakeAddr);

    return { bsptStakeAddr, ftStakeAddr,nftStakeAddr,bsptStakeContract, ftStakeContract, nftStakeContract };
}