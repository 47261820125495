import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BSPTBridgeState, TransactionStatuses } from './types'

const initialBalanceState = {
	eth: 0,
	bsc: 0,
};

const initialTransactionState = {
	status: TransactionStatuses.IDLE,
	hash: null,
	chainId: null,
};

const { actions, reducer } = createSlice({
	name: 'bridge',
	initialState: {
		deposit: initialTransactionState,
		transfer: initialTransactionState,
		balances: initialBalanceState,
		fee: initialBalanceState,
		availableBalance: initialBalanceState,
		loading: false,
		error: false,
	} as BSPTBridgeState,
	reducers: {
		transferStatusRequest: (state, { payload }: PayloadAction<{transactionHash: string}>) => {
			state.transfer = {
				status: TransactionStatuses.PENDING,
				hash: null,
				chainId: null,
			}
		},
		transferStatusSuccess: (state, { payload }: PayloadAction<{status: string, transactionHashBridgePayout: string, destinationChainId: string}>) => {
			state.transfer = {
				status: TransactionStatuses.SUCCESS,
				hash: payload.transactionHashBridgePayout,
				chainId: payload.destinationChainId,
			}
		},
		transferStatusFailure: (state) => {
			state.transfer = {
				status: TransactionStatuses.FAILED,
				hash: null,
				chainId: null,
			}
		},
		completedTransfer: (state) => {
			state.transfer = {
				status: TransactionStatuses.IDLE,
				hash: null,
				chainId: null,
			};
			state.deposit = {
				status: TransactionStatuses.IDLE,
				hash: null,
				chainId: null,
			};
		},
		depositRequest: (state, { payload }: PayloadAction<any>) => {
			state.deposit = {
				status: TransactionStatuses.PENDING,
				hash: null,
				chainId: null,
			}
		},
		depositSuccess: (state, { payload }: PayloadAction<any>) => {
			state.deposit = {
				status: TransactionStatuses.SUCCESS,
				hash: payload.transactionHash,
				chainId: payload.chainId,
			}
		},
		depositFailure: (state) => {
			state.deposit = {
				status: TransactionStatuses.FAILED,
				hash: null,
				chainId: null,
			}
		},
		getBSPTBalancesRequest: (state, { payload }: PayloadAction<any>) => {
			state.loading = true;
			state.error = false;
		},
		getBSPTBalancesSuccess: (state, { payload }: PayloadAction<any>) => {
			state.loading = false;
			state.error = false;
			state.balances = {
				...state.balances,
				[payload.network]: payload.balance,
			}
		},
		getBSPTBalancesFailure: (state) => {
			state.error = true;
			state.loading = false;
		},
		getBSPTFeeRequest: (state, { payload }: PayloadAction<any>) => {
			state.loading = true;
			state.error = false;
		},
		getBSPTFeeSuccess: (state, { payload }: PayloadAction<any>) => {
			state.loading = false;
			state.error = false;
			state.fee = {
				...state.fee,
				[payload.network]: payload.fee,
			}
		},
		getBSPTFeeFailure: (state) => {
			state.error = true;
			state.loading = false;
		},
		getBSPTAvailableBalanceRequest: (state, { payload }: PayloadAction<any>) => {
			state.loading = true;
			state.error = false;
		},
		getBSPTAvailableBalanceSuccess: (state, { payload }: PayloadAction<any>) => {
			state.loading = false;
			state.error = false;
			state.availableBalance = {
				...state.availableBalance,
				[payload.network]: payload.availableBalance,
			}
		},
		getBSPTAvailableBalanceFailure: (state) => {
			state.error = true;
			state.loading = false;
		},
	},
});

export { actions as BridgeActions };

export default reducer;
