import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  Paper,
  IconButton,
  InputBase,
  Grid,
  Popper,
  Fade,
  Typography,
  Button,
  List,
  Card,
  CardActions,
  CardContent,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Stack,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { SearchActions } from 'store/reducers/search';

import { useStyles } from './styles';

let timeoutSearch: any;

const Search = (props: any) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [width, setWidth] = React.useState(400);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    // @ts-ignore
    const widthSearch: number = document.getElementById('search-paper')?.offsetWidth;
    if (widthSearch < 400) setWidth(400);
    else setWidth(widthSearch);
  }, [document.getElementById('search-paper')?.offsetWidth]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (inputValue.length > 2) setOpen(true);
  };

  const handleClickAway = () => {
    if (open) setOpen(false);
  };

  const { loading, celebrityList, nftList } = useSelector((state) => state.search);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  const getListItems = (items: any[], results: number) => {
    if (loading) {
      return (
        <Grid container justifyContent="center" sx={{ margin: '20px' }}>
          <CircularProgress sx={{ color: '#828282' }} />
        </Grid>
      );
    }

    if (!items.length) {
      return (
        <Grid container justifyContent="center">
          <Typography className={classes.empty}>No options found</Typography>
        </Grid>
      );
    }

    const data = items.slice(0, results);

    return (
      <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {data.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.id}`;
          const link = value.name ? `/profile/${value?.nickName}` : `/collectibles/${value?.id}/${value?.tokenSource}`;
          return (
            <ListItem
              key={value.id}
              secondaryAction={
                value?.name && (
                  <Typography
                    sx={{
                      textAlign: 'end',
                      right: '20px',
                    }}
                  >
                    {value.purchases}
                  </Typography>
                )
              }
              disablePadding
            >
              <ListItemButton sx={{ padding: '12px 20px' }} component={Link} to={link} onClick={() => setOpen(false)}>
                <ListItemAvatar sx={{ minWidth: '40px' }}>
                  <Avatar sx={{ width: '28px', height: '28px' }} src={value?.avatar || value?.imageThumbnail || value?.imageAws} />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={value?.name || value?.title}
                  primaryTypographyProps={{
                    textOverflow: 'ellipsis',
                    maxWidth: '320px',
                    overflow: 'hidden',
                    fontWeight: 600,
                    fontSize: '16px',
                    color: '#333333',
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const handleSearch = (event: any) => {
    const searchQuery = event.target.value;
    setInputValue(searchQuery);
    clearTimeout(timeoutSearch);
    if (searchQuery.length > 2) {
      timeoutSearch = setTimeout(() => {
        setOpen(true);
        dispatch(SearchActions.getSearchRequest(event.target.value));
      }, 500);
    } else {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper id="search-paper" className={classes.input}>
        <SearchIcon className={classes.iconSearch} />
        <>
          <InputBase
            className={classes.inputSearch}
            placeholder="Type of sports, Creator, Item"
            aria-describedby={id}
            value={inputValue}
            onClick={handleClick}
            onChange={handleSearch}
          />
          {open && (
            <Popper id={id} open={open} anchorEl={anchorEl} transition className={classes.searchPopper} placement="bottom-end" style={{ width: `${width}px` }}>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Box className={classes.boxSearchPepper} sx={{ p: 1, bgcolor: 'background.paper' }}>
                    <Card sx={{ height: '100%', padding: 0 }}>
                      <CardContent sx={{ p: 0, maxHeight: 447, overflow: 'auto' }}>
                        <Grid container direction="row">
                          <Grid item xs={12}>
                            <Typography className={classes.listGroup}>Celebrities ({celebrityList.length})</Typography>
                          </Grid>
                          {getListItems(celebrityList, 3)}
                        </Grid>
                        <Grid container direction="row">
                          <Grid item xs={12}>
                            <Typography className={classes.listGroup} sx={{ paddingTop: '12px !important' }}>
                              Item ({nftList.length})
                            </Typography>
                          </Grid>
                          {getListItems(nftList, 5)}
                        </Grid>
                      </CardContent>

                      {!loading && (celebrityList.length || nftList.length) ? (
                        <CardActions className={classes.cardActions}>
                          <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                            <Button
                              variant="text"
                              component={Link}
                              to={`/marketplace?title=${inputValue}`}
                              sx={{ width: '100%', borderRadius: 0 }}
                              onClick={() => setOpen(false)}
                            >
                              Show All {nftList.length || celebrityList.length ? `(${nftList.length + celebrityList.length})` : null}
                            </Button>
                            <IconButton color="primary" aria-label="upload picture" component="label" sx={{ marginLeft: '-40px !important' }}>
                              <ArrowForwardIosIcon fontSize="medium" sx={{ color: '#828282' }} />
                            </IconButton>
                          </Stack>
                        </CardActions>
                      ) : null}
                    </Card>
                  </Box>
                </Fade>
              )}
            </Popper>
          )}
        </>
      </Paper>
    </ClickAwayListener>
  );
};

export default Search;
