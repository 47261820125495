import axios from 'axios';
import analytics from 'analytics';
import { put, select, call } from 'redux-saga/effects';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import web3 from 'helpers/getWeb3';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';

import { DepositActions } from '../reducers/deposit';
import { TransactionActions } from '../reducers/transaction';
import { NotificationActions } from '../reducers/notification';

import { NetworkStateInterface } from '../reducers/network/types';
import { WalletState } from '../reducers/wallet/types';

import { getBlockchainABI } from '../../contracts';
import { whiteListAddress, nftWhitelistAddresses } from '../../contracts/addresses';

async function fetchIPFS(url: string) {
	const result = await fetch(url)
		.then((response) => {
			if (!response.ok) {
				return Promise.reject(new Error(response.statusText));
			}

			return response.json();
		})
		.catch((error) => {
			throw new Error('Error searching an NFT. Please try later.');
		});

	return result;
}

export function* getNFTSaga(action: ReturnType<typeof DepositActions.getNFTRequest>) {
	const { address, contract, tokenId } = action.payload;
	const { network }: NetworkStateInterface = yield select((state) => state.network);

	let depositContract;
	let ownerAddress;
	let tokenURI;

	try {
		// @ts-ignore
		depositContract = yield new web3.eth.Contract(getBlockchainABI(network, 'deposit'), contract);
		// @ts-ignore
		ownerAddress = yield depositContract.methods.ownerOf(tokenId).call();
		// @ts-ignore
		// const totalNftBalance = yield depositContract.methods.balanceOf(address).call();
		// @ts-ignore
		tokenURI = yield depositContract.methods.tokenURI(tokenId).call();
	} catch (metamaskError: any) {
		yield put(DepositActions.getNFTFailure({ error: 'RPC error' }));
		return;
	}

	try {
		if (ownerAddress === address) {
			const ipfsUrl = tokenURI.replace(process.env.REACT_APP_ENV === 'production' ? 'ipfs://' : 'ipfs:/', 'https://ipfs.io/ipfs/');
			// @ts-ignore
			const data: any = yield call(fetchIPFS, ipfsUrl);
			if (data) {
				yield put(
					DepositActions.getNFTSuccess({
						data: {
							tokenAddress: contract,
							tokenId,
							title: process.env.REACT_APP_ENV === 'production' ? data.name : data.title,
							description: data.description,
							image: data.image.replace('ipfs://', process.env.REACT_APP_ENV === 'production' ? 'https://ipfs.io/ipfs/' : 'https://ipfs.io/'),
							ipfs: ipfsUrl,
						},
					}),
				);
			}
		} else {
			throw new Error('Wrong token owner!');
		}
	} catch (error: any) {
		yield put(DepositActions.getNFTFailure({ error: error.message }));
	}
}

export function* whitelistSaga(action: ReturnType<typeof DepositActions.whitelistRequest>) {
	const { tokenAddress, tokenId } = action.payload;
	const { network }: NetworkStateInterface = yield select((state) => state.network);
	const {
		wallet: { address },
	}: WalletState = yield select((state) => state.wallet);

	yield put(TransactionActions.statusTransaction.setStatusTransaction('in_pending'));

	try {
		// @ts-ignore
		const addressWhiteList: string = whiteListAddress[network];
		// @ts-ignore
		const contractIndexId = yield nftWhitelistAddresses.indexOf(tokenAddress.toLowerCase());
		// @ts-ignore
		const NftWhiteList = yield new web3.eth.Contract(getBlockchainABI(network, 'whitelist'), addressWhiteList);
		// @ts-ignore
		const leafNodes = yield nftWhitelistAddresses.map((addr) => keccak256(addr));
		// @ts-ignore
		const merkleTree = yield new MerkleTree(leafNodes, keccak256, { sortPairs: true });
		// @ts-ignore
		// const rootHash = yield merkleTree.getRoot(); // for debug purposes only
		// @ts-ignore
		const hexProof = yield merkleTree.getHexProof(leafNodes[contractIndexId]);
		// @ts-ignore
		const { transactionHash } = yield web3.eth.sendTransaction({
			to: addressWhiteList,
			from: address,
			data: NftWhiteList.methods.whiteList(tokenAddress, tokenId, hexProof).encodeABI(),
			// value: 1000,
		});

		if (transactionHash) {
			yield put(TransactionActions.statusTransaction.setStatusTransaction('done'));
			yield put(DepositActions.whitelistSuccess({ transactionHash }));
			yield analytics.sendEvent({
				category: 'NFT',
				action: 'NFT is imported',
			});
		}
	} catch (error) {
		yield put(TransactionActions.statusTransaction.setStatusTransaction('fail'));
		yield put(DepositActions.whitelistFailure());
	}
}

export function* saveNFTSaga(action: ReturnType<typeof DepositActions.saveNFTRequest>) {
	try {
		const {
			data: { data, errors, status },
		} = yield axios.post(
			getLink(endpoints.DEPOSIT_NFT),
			{ ...action.payload },
			{
				validateStatus: (responseStatus) => {
					return responseStatus === 200 || responseStatus === 400;
				},
			},
		);

		if (status === false) {
			throw new Error(errors);
		}

		yield put(DepositActions.saveNFTSuccess({ data }));
	} catch (error: any) {
		yield put(DepositActions.saveNFTFailure());
		yield put(
			NotificationActions.pushNotification({
				message: error.message || 'Error saving an NFT',
				options: {
					variant: 'error',
				},
			}),
		);
	}
}
