import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'entities/Account';
import { PopupsState } from './types';

const initialState: PopupsState = {
  noRights: true,
  noRightsModalOpen: false,
  changeNetwork: false,
  networkToChange: 'ETH',
  currentTokenCreatorId: 0,
  creator: null,
};

const { actions, reducer } = createSlice({
  name: 'popups',
  initialState,
  reducers: {
    notEnoughRights: (state, { payload }: PayloadAction<boolean>) => {
      state.noRights = payload;
    },
    toggleNoRightsModal: (state, { payload }: PayloadAction<boolean>) => {
      state.noRightsModalOpen = payload;
    },

    setCurrentTokenCreatorId: (state, { payload }: PayloadAction<number>) => {
      state.currentTokenCreatorId = payload;
    },

    setCreator: (state, { payload }: PayloadAction<User | null>) => {
      state.creator = payload;
    },

    setChangeNetwork: (state, { payload }: PayloadAction<{ changeNetwork: boolean; networkToChange: string }>) => {
      state.changeNetwork = payload.changeNetwork;
      state.networkToChange = payload?.networkToChange;
    },
  },
});

export { actions as PopupsActions };

export default reducer;
