import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import colors, { bsBlue, white, waterLoo, athensGray, royalBlue, mineShaft, yankeesBlue, sunray, darkSunray } from 'constants/colors';

export const useDialogStyles = makeStyles()((theme: Theme) => {
  return {
    // common dialog
    title: {
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
    },

    privateWrapper: {
      padding: '0 20px',
    },

    nickName: {
      width: 20,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    closeBtnFixed: {
      position: 'fixed',
      width: '100%',
      margin: '-20px -70px',
    },

    closeBtn: {
      position: 'absolute',
      right: 10,
      top: 10,
      color: '#828282',
      '& svg': {
        width: 14,
        height: 14,
      },
    },

    content: {
      textAlign: 'center',
      // long nickname fix
      overflowX: 'hidden',

      [theme.breakpoints.up('sm')]: {
        padding: '0 32px !important',
      },
    },

    actions: {
      display: 'flex',
      justifyContent: 'center',
    },

    actionBtn: {
      padding: '12px 16px',
      [theme.breakpoints.up('sm')]: {
        padding: '12px 50px',
      },
    },

    exit: {
      position: 'absolute',
      top: '35px',
      backgroundColor: white,
      borderRadius: 8,
      padding: 16,
      '& svg': { margin: '0 8px' },
      '& p': {
        color: waterLoo,

        display: 'flex',
        alignItems: 'center',
      },
    },

    decreaseBtn: {
      position: 'absolute',
      right: 16,
      top: '50px',
      border: `2px solid ${royalBlue}`,
      borderRadius: '50%',
      padding: 0,
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        right: 100,
      },
    },

    image: {
      width: '100%',
      background: athensGray,
      padding: '150px 0',
      '& video': {
        position: 'absolute',
        width: '50% !important',
        top: '50%',
        left: '50%',
        transform: ' translate(-50%, -50%)',
      },
      '& img': {
        position: 'absolute',
        width: '100%',
        height: '50%',
        objectFit: 'contain',
        top: '50%',
        left: '50%',
        transform: ' translate(-50%, -50%)',
        [theme.breakpoints.up('md')]: {
          width: '50%',
        },
      },
    },

    description: {
      position: 'absolute',
      bottom: '50px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    descriptionOwner: {
      color: waterLoo,
      '& span': {
        padding: '0 4px',
        fontWeight: 'bold',
        color: mineShaft,
      },
    },

    boldText: {
      fontWeight: 'bold',
    },

    actionBtnFullwidth: {
      width: '100%',
      maxWidth: 250,
      // marginLeft: 'auto',
      // marginRight: 'auto',
    },

    // token dialog
    tokenWrap: {
      width: '100%',
      padding: '0 32px',
    },
    dialogContent: {
      paddingTop: '0 !important',
    },

    // follow dialog
    follow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 32,
      padding: '32px 0',

      [theme.breakpoints.up('sm')]: {
        padding: 32,
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
    },
    followIcon: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& p': {
        fontWeight: 600,
      },
    },
    followDescription: {
      textAlign: 'center',
      '& p': {
        paddingTop: 8,
      },

      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
      },
    },

    // with divider dialog
    divider: {
      width: ' 100%',
      backgroundColor: colors.profileBorder,
    },
    dividerTitle: {
      textAlign: 'center',
      padding: 20,
      fontSize: 18,

      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
      },
    },
    dividerActions: {
      width: ' 100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      padding: '0 32px',

      '& button': {
        maxWidth: 450,
        padding: '12px 50px',
      },
      [theme.breakpoints.up('sm')]: {
        padding: 0,
      },
    },

    // private
    privateTitle: {
      marginTop: '6px',
    },
    privateText: {
      marginTop: 24,
    },
    closeIcon: {
      width: '24px',
      height: '24px',
      color: royalBlue,
    },
    pageDialog: {
      width: '100%',
      margin: '0 auto',
      '& .MuiDialog-paperWidthSm,& .MuiDialog-paperWidthLg,': {
        alignItems: 'stretch',
        maxWidth: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    nameWidthWrapper: {
      maxWidth: '50vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      verticalAlign: 'middle',
    },

    // Application Form
    appForm: {},
    appFormLoader: {
      height: 430,
    },
    appFormTitle: {
      textAlign: 'center',
      padding: 20,
      paddingTop: 80,
      fontSize: '2rem',

      [theme.breakpoints.up('sm')]: {
        fontSize: '2.4rem',
      },
    },
    appFormIntro: {
      textAlign: 'center',
      fontSize: '1.6rem',
      whiteSpace: 'break-spaces',
      fontWeight: 500,
      lineHeight: '26px',
      color: '#828282',

      '&.df': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 18,
      },

      '& span': {
        color: bsBlue,
        fontWeight: 600,
        fontSize: '1.8rem',
        lineHeight: '24px',
        paddingLeft: 4,
      },
    },
    appFormBox: {
      '& .MuiOutlinedInput-root': {
        paddingLeft: 0,

        '& input': {
          border: 0,
          paddingLeft: 0,
        },
        '& textarea': {
          paddingLeft: 0,
        },
        '& fieldset': {
          border: 0,
          borderBottom: '1px solid #E0E0E0',
          borderRadius: 0,
        },
      },
    },
    appFormLabel: {
      fontWeight: 600,
      fontSize: '1.8rem',
      lineHeight: '24px',
      color: '#333333',
    },
    appFormSubmit: {
      maxWidth: 243,
    },

    // FanWallet
    FWroot: {
      '& .MuiDialog-paper': {
        maxWidth: 500,
        background: yankeesBlue,
        color: white,
      },
      '& .MuiButton-containedPrimary': {
        backgroundColor: sunray,
        '&.Mui-disabled, &:hover': {
          backgroundColor: darkSunray,
        },
      },
      '& .MuiButton-outlinedPrimary': {
        color: sunray,
        borderColor: sunray,
        '&:hover': {
          backgroundColor: sunray,
          color: white,
        },
      },
    },
    FWheadingActions: {
      paddingTop: 44,
    },
    FWDialogContent: {
      paddingBottom: 60,
      width: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },

    FWbackBtn: {
      position: 'absolute',
      left: 12,
      top: 12,
    },
    FWcloseBtn: {
      position: 'absolute',
      right: 12,
      top: 12,
      color: sunray,
    },
    FWmodalHeading: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '32px',
      textAlign: 'center',
      letterSpacing: '0.02em',
      paddingBottom: 24,
    },
    FWTabs: {
      minHeight: 24,

      '& .MuiTabs-indicator': {
        backgroundColor: sunray,
      },

      '& .MuiTab-root': {
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 400,
        minWidth: 120,
      },
    },
    FWTab: {
      textTransform: 'none',
      textAlign: 'center',
      letterSpacing: '0.02em',
      color: white,
      padding: 0,
      minHeight: 24,

      '&.MuiTab-textColorPrimary.Mui-selected': {
        color: white,
      },
      '&:first-of-type': {
        '&::after': {
          content: "''",
          height: 20,
          width: 1,
          background: white,
          position: 'absolute',
          right: 0,
          top: '50%',
          marginTop: -10,
        },
      },
    },
    FWTokenBalance: {
      padding: '24px 0',
      maxHeight: '50vh',
      overflowY: 'scroll',
      background: 'rgba(72, 132, 232, .1)',

      '&::-webkit-scrollbar': {
        width: '0.5px',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '0.5px',
      },
    },
    FWTokenBalanceAccountName: {
      fontWeight: 400,
    },
    FWTokenBalanceAddress: {
      color: sunray,
    },
    FWTokenBalanceTokensName: {
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '29px',
    },
    FWTokenBalanceTokensBalance: {
      color: sunray,
    },
    FWTokenTransactionsWrapper: {
      overflowY: 'scroll',
      marginTop: 32,

      '&::-webkit-scrollbar': {
        width: '0.5px',
      },
      '&::-webkit-scrollbar-thumb': {
        width: '0.5px',
      },
    },
    FWTransaction: {
      width: '100%',
      padding: '5px 16px',
      background: 'rgba(72, 132, 232, 0.1)',
      borderRadius: 10,
      marginBottom: 10,
      '&:last-of-type': {
        marginBottom: 0,
      },
      '& .MuiTypography-colorPrimary': {
        color: white,
      },
      '& .MuiLink-underlineHover:hover': {
        textDecoration: 'none',
      },
    },
    FWTransactionIcon1: {
      marginRight: 12,
    },
    FWTransactionCredName: {
      fontWeight: 500,
      fontSize: 17,
      lineHeight: '22px',
      letterSpacing: '0.2125px',
    },
    FWTransactionCredAddr: {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '17px',
      letterSpacing: '0.1625px',
      color: 'rgba(255, 255, 255, 0.5)',
    },
    FWTransactionIcon2: {
      '&.pending svg': {
        fill: '#808080',
      },
      '&.success svg': {
        fill: '#DEBC5A',
      },
      '&.failure svg': {
        fill: '#D60F08',
      },
    },
    FWTransactionIcon3: {
      marginLeft: 12,
    },
    FWTransactionDate: {
      fontWeight: 500,
      fontSize: 11,
      lineHeight: '14px',
      textAlign: 'center',
      letterSpacing: '0.1625px',
      color: 'rgba(255, 255, 255, 0.5)',
    },

    // FanTokens
    FTFirst: {
      textAlign: 'center',
    },
    FTFirstImage: {
      textAlign: 'center',
    },
    FTDisclaimer: {
      textAlign: 'center',
    },
    FTDisclaimerIcon: {
      // opacity: '.2',
    },
    FTDisclaimerText: {
      '& p a': {
        textDecoration: 'underline',
      },
      '& p ~ p': {
        marginTop: 16,
      },
    },
    FTDisclaimerActions: {
      '& .MuiButton-root': {
        width: 250,
        marginBottom: 24,
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
    },
    FTCart: {
      textAlign: 'center',
      background: '#3C3C40',
      padding: '24px 0',
    },
    FTCartPrice: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      textAlign: 'center',
      letterSpacing: '0.02em',
      color: sunray,
    },
    FTCartTotal: {
      textAlign: 'left',
      maxWidth: 420,
      marginLeft: 'auto',
      marginRight: 'auto',
      flexDirection: 'column',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    FTCartFees: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.02em',
      '& a': {
        color: sunray,
      },
    },
    FTCartTotalSum: {
      color: sunray,
    },
    FTCartActions: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        '& .MuiButton-root': {
          width: 360,
        },
      },
    },
    FTCartNote: {
      fontWeight: 400,
      textAlign: 'center',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: white,
      opacity: '0.5',
    },
    FTCartLink: {
      textAlign: 'center',
      '& a': {
        color: sunray,
        textDecoration: 'underline',
      },
    },
    FTCartQtyIcon: {
      margin: '0 25px',
    },
    FTCartQtyControlInput: {
      background: '#242426',
      borderRadius: 10,
      border: 0,
      boxShadow: 'none',
      outline: 'none',
      color: white,
      width: 120,
      '& .MuiInputBase-root': {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
        paddingBottom: 7,
        alignItems: 'flex-start',
      },
      '& .MuiInputAdornment-root': {
        textAlign: 'left',
        '& .MuiTypography-colorTextSecondary': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
        '& .MuiTypography-body1': {
          fontWeight: 600,
          fontSize: 13,
          lineHeight: '17px',
          letterSpacing: '0.1625px',
        },
      },
      '& .MuiInputBase-input': {
        color: white,
        margin: 0,
        padding: '17px 0 0',
      },
      '& fieldset': {
        border: 0,
      },
    },
    FTStatus: {
      textAlign: 'center',
    },
    FTStatusText: {
      fontWeight: 400,

      '& span': {
        color: sunray,
      },

      '& ~ .MuiTypography-root': {
        marginTop: 12,
      },
    },
    FTStatusActions: {
      maxWidth: 360,
      marginLeft: 'auto',
      marginRight: 'auto',

      '& .MuiButton-root': {
        width: '100%',
        '& ~ .MuiButton-root': {
          marginTop: 24,
        },
      },
    },
    FTTransactionLine: {
      background: '#3C3C40',
      marginBottom: 2,
      padding: '24px 0',
      textAlign: 'left',
    },
    FTTransactionWrapper: {
      margin: '0 auto',
      width: '100%',
      paddingLeft: 16,
      paddingRight: 16,

      [theme.breakpoints.up('sm')]: {
        maxWidth: 360,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    FTRecieptActions: {
      maxWidth: 360,
      margin: '24px auto 0',
      position: 'relative',

      '& .MuiButton-root': {
        '& ~ .MuiButton-root': {
          marginTop: 24,
        },
        '&.Mui-disabled': {
          backgroundColor: darkSunray,
          color: 'rgba(255, 255, 255, .5)',
        },
      },
    },
    FTRecieptInput: {
      '& .MuiInputBase-input': {
        color: white,
        borderBottom: `1px solid ${sunray}`,
        '&::placeholder': {
          color: white,
        },
        '&:invalid': {
          borderBottom: `1px solid #FF443D`,
        },
      },
      '& .MuiInput-underline:before': {
        display: 'none',
      },
    },
    FTRecieptLoader: {
      height: 169,
    },
    FTRecieptError: {
      position: 'absolute',
      left: 0,
      top: 50,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: '#FF443D',
    },
    FTEmailInput: {},
    FTEmailError: {},
    FWTokenBalanceImage: {
      width: '40%',
      textAlign: 'center',
      '& img': {
        objectFit: 'contain',
        width: '100%',
      },
    },
    backDrop: { backdropFilter: 'blur(15px)' },
    ConnectRoot: {
      '& .MuiPaper-root': {
        maxWidth: 456,
        padding: '40px 8px',
      },
      '& .MuiLoadingButton-loadingIndicator': {
        color: 'white',
      },
    },
  };
});
