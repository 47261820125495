import axios from 'axios';
import { getLink } from 'helpers/routes';
import * as endpoints from 'services/api';
import { put } from 'redux-saga/effects';
import { EmailActions } from '../reducers/email';
import { NotificationActions } from '../reducers/notification';

export function* sendJoinEmailSaga(action: ReturnType<typeof EmailActions.joinEmail.sendJoinEmailRequest>) {
	try {
		yield axios.post(getLink(endpoints.SEND_EMAIL_JOIN), { message: action.payload });
		yield put(EmailActions.joinEmail.sendJoinEmailSuccess());
	} catch (error) {
		yield put(EmailActions.joinEmail.sendJoinEmailFailure());
		yield put(
			NotificationActions.pushNotification({
				message: 'Error sending email',
				options: {
					variant: 'error',
				},
			}),
		);
	}
}

export function* subscribeEmailSaga(action: ReturnType<typeof EmailActions.subscribeEmail.sendRequest>) {
	try {
		yield axios.post(endpoints.SUBSCRIBE_EMAIL, { ...action.payload });
		yield put(EmailActions.subscribeEmail.sendSuccess());
		yield put(
			NotificationActions.pushNotification({
				message: 'Subscribed!',
				options: {
					variant: 'success',
				},
			}),
		);
	} catch (error: any) {
		yield put(EmailActions.subscribeEmail.sendFailure());
		yield put(
			NotificationActions.pushNotification({
				message: 'Error sending email',
				options: {
					variant: 'error',
				},
			}),
		);
	}
}

export function* sendVerifyRequestSaga(action: ReturnType<typeof EmailActions.verifyEmail.sendVerifyRequest>) {
	const { email } = action.payload;
	try {
		// @ts-ignore
		const {
			data: { data, status, success, errors, err },
		} = yield axios.post(
			endpoints.VALIDATE_EMAIL_REQUEST,
			{ email },
			{
				validateStatus: (responseStatus) => {
					return responseStatus === 200 || responseStatus === 400;
				},
			},
		);

		if (status === false) {
			throw new Error(errors);
		}

		if (success) {
			yield put(EmailActions.verifyEmail.sendVerifySuccess({ data }));
		} else {
			throw new Error(err);
		}
	} catch (error: any) {
		yield put(EmailActions.verifyEmail.sendVerifyFailure({ error: error.message }));
	}
}

export function* validateCodeRequestSaga(action: ReturnType<typeof EmailActions.verifyEmail.validateCodeRequest>) {
	const { verificationCode } = action.payload;
	let errorMessage;
	try {
		const {
			data: { data, status, success, err, reason },
		} = yield axios.post(
			endpoints.VALIDATE_EMAIL_VERIFY,
			{ verificationCode },
			{
				validateStatus: (responseStatus) => {
					return responseStatus === 200 || responseStatus === 400;
				},
			},
		);

		if (status === false) {
			if (reason) {
				switch (reason) {
					case 'CODE_INVALID':
						errorMessage = 'The code is wrong. Try again or resend it.';
						break;
					case 'EXPIRED':
						errorMessage = 'The code is expired. Try resend a new one.';
						break;
					default:
						errorMessage = 'Invalid validation code';
				}
			}
			throw new Error(errorMessage);
		}

		if (success) {
			yield put(EmailActions.verifyEmail.validateCodeSuccess({ data }));
		} else {
			throw new Error(err);
		}
	} catch (error: any) {
		yield put(EmailActions.verifyEmail.validateCodeFailure({ error: error.message }));
	}
}
