import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import SnackbarProvider from 'shared/snackbar/Provider';
import { ThemeProvider, Box } from '@mui/material';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { NotifyErrorIcon, NotifySuccessIcon } from 'icons';

import history from 'helpers/history';
import configureStore from 'store/configureStore';

import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';

import Blocksport from 'theme/blocksportTheme';

import { setDefaultAxios } from 'store/configDefaulApi';
import httpService from './services/http';

import App from './App';

import './index.scss';

const store = configureStore();

// set API config
setDefaultAxios();
// auth Interceptor
httpService.setupInterceptors(store, history);

const iconVariant = {
  success: (
    <Box marginRight={1} className="notifyIcon">
      <NotifySuccessIcon />
    </Box>
  ),
  error: (
    <Box marginRight={1} className="notifyIcon">
      <NotifyErrorIcon />
    </Box>
  ),
};

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={Blocksport}>
            <I18nextProvider i18n={i18n}>
              <SnackbarProvider maxSnack={3} iconVariant={iconVariant}>
                <App />
              </SnackbarProvider>
            </I18nextProvider>
          </ThemeProvider>
        </CacheProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
