import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmailActions } from 'store/reducers/email';
import { Button, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import { FormTextField } from 'shared/components/Form';

import { getInterest } from 'helpers/email';

import { useStyles } from './styles';

import { validationSchema } from './lib';

interface Subscription {
	email: string;
}

const SubscriptionForm = (props: any) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();

	const {
		subscribeEmail: { loading },
	} = useSelector((state) => state.email);

	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

	const onSubmit = (values: any, { resetForm }: { resetForm: () => void }) => {
		dispatch(
			EmailActions.subscribeEmail.sendRequest({
				...values,
				interest: getInterest('nft'),
			}),
		);
		resetForm();
	};

	const getInitialValues = ({ email = '' }: Subscription) => ({ email });

	return (
		<div className={classes.root}>
			<Formik initialValues={getInitialValues({} as Subscription)} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
				{({ dirty, isValid, values, setFieldValue }) => {
					return (
						<Form>
							<Box display="flex" className={classes.block}>
								<Box className={classes.input}>
									<FormTextField istouched={0} name="email" type="email" variant={isDesktop ? 'outlined' : 'standard'} placeholder="Your email address" fullWidth />
								</Box>
								<Box display="flex" justifyContent="center" className={classes.action}>
									<Button type="submit" variant="contained" color="primary" disabled={!isValid || loading} fullWidth>
										{loading ? 'Subscribing' : 'Subscribe'}
									</Button>
								</Box>
							</Box>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default SubscriptionForm;
